import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import KeySource from '../../../../../../images/keysource-logo.png';
;
export const ImageHeader = memo(({ width = 200, showContact = true, imgSrc, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        image: { width, },
        contactText: {
            fontSize: 12,
            paddingLeft: 20,
            alignSelf: 'flex-end',
            color: '#fb8c00',
        },
        contactContainer: {
            marginBottom: 10,
        },
    });
    const src = imgSrc ? imgSrc : KeySource;
    const contact = showContact
        ? [
            '7820 Palace Drive',
            'Cincinnati, OH 45249',
            'Voice:  1.800.842.5991',
            'Fax:  1.513.725.0698',
            'newaccount@keysource.com',
        ]
        : [];
    return (_jsxs(View, { style: styles.container, children: [_jsx(View, { children: _jsx(Image, { style: styles.image, src: src }) }), showContact &&
                _jsx(View, { style: styles.contactContainer, children: contact.map((text, index) => (_jsx(Text, { style: styles.contactText, children: text }, index))) })] }));
});
