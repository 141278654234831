import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Initials } from '../Initials';
;
export const PageTwo = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        entryContainer: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        entry: {
            paddingBottom: 12,
            fontSize: 10,
        },
        entryHeader: {
            fontSize: 11,
            backgroundColor: '#003399',
            color: 'white',
        },
        entryFooter: {
            paddingTop: 20,
            fontSize: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        spacerSmall: {
            paddingBottom: 15,
        },
        spacerTiny: {
            paddingBottom: 2,
        },
        divider: {
            borderBottom: 1.5,
        },
        termText: {
            fontSize: 8,
        },
        termTextIndent: {
            fontSize: 8,
            marginLeft: 18,
        },
        termsContainer: {
            paddingLeft: 40,
            paddingRight: 40,
        },
    });
    const { primary, } = traderInfo;
    const tradeReferences = [
        { text: '', style: styles.spacerSmall, },
        {
            text: 'Section 4 – Trade References',
            style: styles.entryHeader,
        },
        { text: '', style: styles.entry, },
        {
            text: `Primary Supplier:  ${primary.name}     Account #:  ${pharmacy.mckessonId || '___________'}     Phone #:  ${primary.phone}`,
            style: styles.entry,
        },
        { text: '', style: styles.entry, },
        { text: '', style: styles.divider, },
        { text: '', style: styles.entry, },
        {
            text: 'Applicant understands and agrees to the following terms and conditions of sale between applicant and Prescription Supply, Inc. (PSI):',
            style: styles.termText,
        }
    ];
    const terms = useMemo(() => {
        return [
            { text: '', style: styles.entry, },
            {
                text: '•       The following payment terms, as well as those stated on invoices, apply to all purchases of goods and services: A) A',
                style: styles.termText,
            },
            {
                text: 'processing fee of $50 will be invoiced for each dishonored payment. B. PSI may, in addition to PSI’s right to exercise other remedies, assess service charges of 1.5% per month or the highest lawful rate, whichever is lower on any past due balance, and all actual attorney fees and costs of collection. C. Applicant acknowledges that PSI had the absolute right to change credit terms, or suspend delivery of products to Applicant without any liability being uncured by PSI.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      Applicant/Guarantor authorizes PSI or its designee to obtain, verify or otherwise investigate any reference, statement,',
                style: styles.termText,
            },
            {
                text: 'credit report or other information obtained with respect to Applicant as PSI deems appropriate. Applicant will comply with all applicable laws and all PSI policies, as amended. The Applicant further represents that its professional licenses are in good standing and not the subject of any proceedings by any governmental agency and agrees to notify PSI immediately upon the commencement of any such proceedings. Applicant is responsible for any applicable sales tax and other charged imposed by federal, state, local or foreign governments on manufacture, sales, shipment, import, export or use of products or services.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      Upon a breach of Applicant’s obligations hereunder or if PSI becomes a party to any suit or proceeding, including',
                style: styles.termText,
            },
            {
                text: 'bankruptcy or insolvency, affecting Applicant, or if PSI engages counsel to collect any of the Obligations or to enforce or preserve any of the rights and remedies of PSI under this Agreement or any other agreement between PSI and Applicant, PSI’s costs, expenses and reasonable counsel fees, whether or not suit is instated, will be paid by Applicant to PSI on demand and, until paid, will be additional Obligations under this Agreement.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•     PSI shall not be liable to the applicant for any loss of profits, revenue, or business opportunities or indirect,',
                style: styles.termText,
            },
            {
                text: 'special consequential, exemplary, punitive or consequential damages of any kind whatsoever.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•     This Agreement will be governed by the internal laws of the State of Ohio, and any proceedings of any type',
                style: styles.termText,
            },
            {
                text: 'whatsoever arising out of this Agreement or any of the transactions contemplated hereby shall be initiated solely in the state of Ohio and the parties hereby irrevocably submit to the subject matter and personal jurisdictions of such courts. Notwithstanding the foregoing, the Applicant agrees that PSI may seek injunctive relief in any court of competent jurisdiction.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      The parties hereby waive any rights they may have to a jury trial in connections with litigation commenced by or',
                style: styles.termText,
            },
            {
                text: 'against PSI regarding their rights and obligations A) under this Agreement or any other agreement between the parties and B) in any manner connected with, related to or incidental to transactions between the parties, whether sounding in contract, tort or otherwise.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      Applicant agrees that all purchases from PSI are for their “own use” as that term is defined in Abbott Labs et al v.',
                style: styles.termText,
            },
            {
                text: 'Portland Retail Druggists Assoc. et al, decided by the U.S. Supreme Court on March 24, 1976.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      Terms of sale have been fully explained and the undersigned understand that shipments may be held if account is',
                style: styles.termText,
            },
            {
                text: 'delinquent or exceeds the established line of credit. The undersigned agrees to provide PrescriptionSupply, Inc. within sixty days written notice of any intention to sell substantially all of its assets, or stock or other equity interests, or to merge or consolidate with a third party.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      Each person signing below personally guarantees prompt and full performance and payment according to the',
                style: styles.termText,
            },
            {
                text: 'tenor of this Agreement, jointly and severally.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•      The Applicant and Prescription Supply, Inc. agree that the use of electronic transmission, including but not limited',
                style: styles.termText,
            },
            {
                text: 'to e-mail, facsimile or other transmissions via the Internet or World Wide Web, in entering into and in exercising their rights and performing their obligations under this Agreement is in the best interests of all of the parties and shall be permissible, notwithstanding any requirement of the Uniform Commercial Code or any other applicable law that might otherwise be considered unsatisfied as a result of the utilization of such media. The applicant and Prescription Supply, Inc. therefore agree that the Uniform Electronic Transactions Act, as promulgated by the National Conference of Commissions on Uniform State Laws, shall be applicable to the construction of this Agreement and any transactions hereunder, whether or not such Act shall have been adopted in any jurisdiction. By way of illustration and not of limitation the Applicant and Prescription Supply, Inc. agree to the following:',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerTiny, },
            {
                text: '•       (a) any document (including this agreement) transmitted by electronic transmission shall be treated in all respects',
                style: styles.termText,
            },
            {
                text: 'as an original signed document; (b) the signature (including an electronic signature) of any Party shall be considered for these purposes as an original signature: (c) any such electronic transmission shall be considered to have the same binding legal effect as an original document; and (d) neither Party shall raise the use of electronic transmission as a defense to this Agreement or in matters relating to any transaction pursuant to this Agreement, and each Party waives such defense.',
                style: styles.termTextIndent,
            },
            { text: '', style: styles.spacerSmall, },
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(Initials, { pageNum: 2 }), _jsx(View, { style: styles.entryContainer, children: tradeReferences.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) }), _jsx(View, { style: styles.termsContainer, children: terms.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) }), _jsxs(View, { children: [_jsx(Text, { style: styles.spacerSmall }), _jsx(Text, { style: styles.entryFooter, children: "Please attach a copy of the following: DEA License, State Pharmacy License, Resale certificate, pharmacy inspection, Tax exempt form, Copy of owners Drivers license, and 3 months of statements if applying for terms." })] })] }) }));
});
