import ApiClient, { ApiUrl, ReqOptions } from './ApiClient';
import { Onboard, GetUserExistence, SimpleAuthSuccessRes } from './types';

export const get = {
	checkEmail: async (id: string, email: string): Promise<GetUserExistence> => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.read('/onboarding', id, { params: { email, jwt: localToken } });
	},
	checkPassword: async (email: string, password: string): Promise<SimpleAuthSuccessRes> => {
		const client = await ApiClient.getInstance();
		return client.read(
			'/auth',
			encodeURIComponent(email),
			{ params: { password: encodeURIComponent(password) } },
		);
	},
	eventSource: async (): Promise<EventSource> => {
		const client = await ApiClient.getInstance();
		client.setEventSource();
		return client.eventSource as EventSource;
	},
	onboardById: async (id?: string, opts?: ReqOptions): Promise<Onboard> => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.read('/onboarding', id, { params: { jwt: localToken }, ...opts }, undefined, ApiUrl.MARKETPLACE_REST);
	},
	coupon: async (code: string, _opts?: ReqOptions): Promise<Onboard> => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.read('/coupon', code, { params: { jwt: localToken } }, undefined, ApiUrl.MARKETPLACE_REST);
	},
	npiValidate: async (opts: ReqOptions) => {
		const client = await ApiClient.getInstance();
		return client.read('/search', undefined, opts, undefined, ApiUrl.NPI_CLINICAL_TABLES);
	}
};
