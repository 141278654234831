import { FC, useMemo, memo } from 'react';
import { useSetRecoilState } from 'recoil';
import { Button, Typography } from '@mui/material';
import { SubscriptionType } from '@pm/shared';
import { registrationFormStepState, registrationFormState } from '../../../../atoms';
import {
	Card, CardBody, CardFooter, CardHeader,
} from '../../../../components';
import { FeaturesSlider } from '../components';
import { StyledGrid, classes } from './PvacTierCard.styles';
import { PharmacyPostData } from '../../../../services/types';

export const PvacTierCard: FC = memo(() => {
	const setFormStep = useSetRecoilState(registrationFormStepState);
	const setForm = useSetRecoilState(registrationFormState);

	const onClick = () => {
		setForm((form: PharmacyPostData) => ({
			...form,
			subscription: SubscriptionType.PVAC,
			banking: {
				...form.banking,
			},
			dispensing: {
				...form.dispensing,
				managementSystem: '',
			},
		}));
		setFormStep((step) => step + 1);
	};
	const slides = useMemo(() => [
		{
			description: `Strengthen your business viability with access to a team 
				of experts dedicated to helping you navigate the complexities of your primary vendor agreement. `,
		},
		{
			description: `PVAC Navigator includes access to PVAC software 
				that uses a proprietary algorithm to curate a recommended purchase order, strategically increasing your compliance and profitability`,
		},
	], []);

	return (
		<StyledGrid item xs={12} sx={{ mt: 2 }}>
			<Card className={classes.card}>
				<CardHeader color='primary' className={classes.cardHeader}>
					<Typography variant='h5'>PVAC Navigator</Typography>
					<div className={classes.tierTitle}>
						<Typography component='span'>$</Typography>
						<Typography variant='h5'>300</Typography>
						<Typography component='span'>/mo</Typography>
					</div>
				</CardHeader>
				<CardBody className={classes.cardBody}>
					<FeaturesSlider slides={slides} />
				</CardBody>
				<CardFooter className={classes.cardFooter}>
					<Button
						variant='outlined'
						size='large'
						className={classes.btn}
						onClick={onClick}
					>
						Get started
					</Button>
				</CardFooter>
			</Card>
		</StyledGrid>
	);
});
