import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Svg, Rect, Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const Checkbox = memo(({ checked = false, isLast, span, text, startText, boldText, endText, }) => {
    const row = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 6,
        paddingLeft: 16,
    };
    const squareHeight = 12;
    const styles = StyleSheet.create({
        row,
        lastRow: {
            ...row,
            marginBottom: 3,
        },
        entry: {
            paddingLeft: 8,
            fontSize: 10,
            fontFamily: 'Roboto',
        },
        bold: {
            fontSize: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        svg: {
            height: squareHeight,
            width: squareHeight,
        },
    });
    const rectProps = {
        width: squareHeight,
        height: squareHeight,
        stroke: '#000',
        fill: checked ? '#000' : '#fff',
    };
    const CheckboxText = span
        ? _jsxs(Text, { style: styles.entry, children: [`${startText} `, _jsx(Text, { style: styles.bold, children: boldText }), ` ${endText}`] })
        : _jsx(Text, { style: styles.entry, children: text });
    return (_jsxs(View, { style: isLast ? styles.lastRow : styles.row, children: [_jsx(Svg, { style: styles.svg, children: _jsx(Rect, { ...rectProps }) }), CheckboxText] }));
});
