import { styled } from '@mui/material/styles';

const PREFIX = 'Nav';
export const classes = {
	nav: `${PREFIX}-nav`,
	stepper: `${PREFIX}-stepper`,
	link: `${PREFIX}-link`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.nav}`]: {
		padding: theme.spacing(1),
		margin: 0,
		height: theme.dimensions.navHeight,
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		width: '100%',
		background: 'linear-gradient(95deg, #1A6AE1 0%, #158DEB 50%, #257CFF 100%)',
	},
	[`& .${classes.stepper}`]: {
		background: 'transparent',
	},
	[`& .${classes.link}`]: {
		color: '#fff',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		zIndex: 9999,
	},
}));
