import { FC, memo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Button, Card, Stack, Typography } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import {
	onboardIdState, onboardStepState, onboardCompleted,
	pharmacyIdState, fingerprintEpochPostfix, fingerprintEpochPostfixKey, registrationFormState,
} from '../../../../../../atoms';
import { api } from '../../../../../../services';
import ApiClient from '../../../../../../services/ApiClient';
import { StyledGrid, classes } from '../../../../../Intro/Intro.styles';
import { PreparingView } from '../../../../../../components';

const FinalStep: FC = memo(() => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState<boolean>(true);
	const [onboardId] = useRecoilState(onboardIdState);
	const [pharmacyId] = useRecoilState(pharmacyIdState);
	const form = useRecoilValue(registrationFormState);
	const { npiNumber } = form;
	const setFingerprintEpochPostfix = useSetRecoilState(fingerprintEpochPostfix);
	const resetOnboardStep = useResetRecoilState(onboardStepState);
	const setOnboardCompleted = useSetRecoilState(onboardCompleted);

	const postDocuments = async () => {
		try {
			if (onboardId) {
				await api.post.docusign({ onboardId, });
				await api.patch.onboard(onboardId, { step: 4, status: 'Completed', });
			}
			if (pharmacyId && onboardId) {
				setLoading(false);
				await api.post.documents({ onboardId, pharmacyId, });
			}
			await api.put.pharmacyDrugDispensingTable(npiNumber.toString());

			setLoading(false);
		} catch (error) {
			setLoading(false);
			Sentry.captureException(error);
		}
	};

	const onRestart = async () => {
		try {
			localStorage.clear();
			resetOnboardStep();
			setOnboardCompleted(false);

			const now = dayjs().unix().toString();
			localStorage.setItem(fingerprintEpochPostfixKey, now);
			await ApiClient.getInstance();

			setFingerprintEpochPostfix(now);
			history.push('/step/1');
		} catch (_) {
			enqueueSnackbar(
				'Something went wrong while re-initializing your onboarding session',
				{ variant: 'error' });
		}
	};

	useEffect(() => {
		postDocuments();

		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			onRestart();
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	if (loading) {
		return (
			<PreparingView
				bgImgIndex={4}
				title='Please be patient while we securely prepare your documents'
			/>
		);
	}

	return (
		<StyledGrid
			container
			direction='column'
			alignItems='center'
			justifyContent='center'
			className={classes.root}
		>
			<Card sx={{ margin: 'auto', padding: 2 }}>
				<Stack direction='column' alignItems={'center'} spacing={1}>
					<Typography variant='h5'>
						Registered Successfully <CheckCircleOutlined color='success' sx={{ position: 'relative', top: 3 }} />
					</Typography>
					<Typography variant='body1'>
						Thank you for registering your pharmacy. You will receive an email shortly to review and sign the agreements.
					</Typography>
					<Button variant='outlined' onClick={onRestart}>Register Another Pharmacy</Button>
				</Stack>
			</Card>
		</StyledGrid>
	);
});

export default FinalStep;
