import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'BestTierCard';
export const classes = {
	card: `${PREFIX}-card`,
	cardHeader: `${PREFIX}-cardHeader`,
	cardBody: `${PREFIX}-cardBody`,
	divider: `${PREFIX}-divider`,
	cardFooter: `${PREFIX}-cardFooter`,
	title: `${PREFIX}-title`,
	subtitle: `${PREFIX}-subtitle`,
	btn: `${PREFIX}-btn`,
	btn2: `${PREFIX}-btn2`,
	icon: `${PREFIX}-icon`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.card}`]: {
		height: 550,
		[theme.breakpoints.down('lg')]: {
			height: 'calc(100vh - 300px)',
		},
	},
	[`& .${classes.cardHeader}`]: {
		width: 'auto',
		textAlign: 'center',
		marginLeft: '20px',
		marginRight: '20px',
		marginTop: '-40px',
		padding: '20px 0',
	},
	[`& .${classes.cardBody}`]: {
		[theme.breakpoints.only('md')]: {
			overflowY: 'scroll',
		},
	},
	[`& .${classes.divider}`]: {
		marginTop: '20px',
		marginBottom: '-10px',
		textAlign: 'center',
	},
	[`& .${classes.cardFooter}`]: {
		marginTop: -theme.spacing(1),
		paddingBottom: theme.spacing(1),
		border: '0',
		borderRadius: '6px',
		justifyContent: 'center !important',
	},
	[`& .${classes.subtitle}`]: {
		marginTop: '1rem',
		textAlign: 'center',
		padding: '0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.title}`]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.btn}`]: {
		color: '#D1B000',
		border: '1px solid #D1B000',
	},
	[`& .${classes.btn2}`]: {
		color: '#A38A00',
	},
	[`& .${classes.icon}`]: {
		color: '#43a047',
		marginLeft: 4,
		position: 'absolute',
	},
}));
