import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
const PREFIX = 'Calendar';
export const classes = {
    root: `${PREFIX}-root`,
};
export const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.root}`]: {
        minHeight: 650,
        marginTop: theme.spacing(2),
    },
}));
