import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Checkbox } from '../Checkbox';
import { ImageHeader } from '../ImageHeader';
;
export const PageOne = memo(({ pharmacy, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '10px 10px 0px 10px',
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 8,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 9,
        },
        entrySm: {
            paddingLeft: 0,
            paddingBottom: 5,
            fontSize: 10,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 11,
            fontSize: 12,
        },
    });
    const applicant = [
        { text: `Name of Applicant:  ${pharmacy.name}`, style: styles.entry, },
        {
            text: 'Please use this checklist to ensure you are providing us with all necessary documents we need for the credit review process. Please return all documents to one of the following:',
            style: styles.entrySm,
        },
        {
            text: 'Email to newaccounts@bloodworthwholesale.com or Fax to 1.229.388.8388',
            style: styles.entrySm,
        },
    ];
    const checklist = useMemo(() => [
        { checkmark: 'X', text: 'COMPLETED AND SIGNED NEW CUSTOMER APPLICATION', },
        { checkmark: 'X', text: 'COMPLETED, SIGNED, AND NOTARIZED GUARANTY', },
        { checkmark: 'X', text: 'COPY OF CURRENT STATE LICENSE', },
        { checkmark: 'X', text: 'COPY OF CURRENT DEA LICENSE', },
        { checkmark: 'X', text: 'NO CONTROLS', },
        { checkmark: '', text: 'STORE PHOTOS', },
    ], []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Bloodworth New Customer Application Checklist" }) }), _jsx(View, { children: applicant.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(View, { style: styles.entryContainer, children: checklist.map((checkbox, index) => (_jsx(Checkbox, { ...checkbox }, index))) })] }) }));
});
