import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from '../ImageHeader';
;
export const PageTwo = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            padding: '10px 10px 0px 10px',
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 8,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 9,
        },
        entryBold: {
            paddingLeft: 0,
            paddingBottom: 10,
            fontSize: 13,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textDecoration: 'underline',
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 11,
            fontSize: 12,
        },
    });
    const formattedDeaExpDate = dayjs(pharmacy.deaExpDate).format('MM/DD/YYYY');
    const formattedStateLicenseExpDate = dayjs(pharmacy.stateLicenseExpDate).format('MM/DD/YYYY');
    const { ap, banking, dispensing, pic, yearsInBusiness, } = traderInfo;
    const applicant = [
        { text: 'Business Information', style: styles.entryBold, },
        { text: `Business Name:  ${pharmacy.legalName}`, style: styles.entry, },
        { text: `DBA:  ${pharmacy.name}`, style: styles.entry, },
        { text: `Physical Address:  ${pharmacy.address}`, style: styles.entry, },
        { text: `City:  ${pharmacy.city}     State:  ${pharmacy.state}     Zip:  ${pharmacy.zipCode}`, style: styles.entry, },
        { text: `Phone:  ${pharmacy.phone}     Fax:  ${pharmacy.fax || '__________________'}`, style: styles.entry, },
        { text: `DEA Number:  ${pharmacy.deaNumber}     Expires:  ${formattedDeaExpDate}`, style: styles.entry, },
        { text: `State License:  ${pharmacy.stateLicense}     Expires:  ${formattedStateLicenseExpDate}`, style: styles.entry, },
        { text: `Tax ID Number:  ${pharmacy.federalTaxNumber} (To obtain a business credit report)`, style: styles.entry, },
        { text: `Years In Business:  ${yearsInBusiness}     Website: ______________________`, style: styles.entry, },
        { text: `Accounts Payable Contact:  ${ap.firstName} ${ap.lastName}     Pharmacist In Charge:  ${pic.firstName} ${pic.lastName}`, style: styles.entry, },
        { text: 'Questions', style: styles.entryBold, },
        { text: '1.  Has your pharmacy ever operated under a different name? If YES, please specify __________________', style: styles.entry, },
        { text: `2.  Number of scripts daily:  ${dispensing.estimatedNumberOfDailyScripts}     Walk-ins:  ${dispensing.estimatedWalkInPercent}%`, style: styles.entry, },
        { text: `Mail Orders:  ${dispensing.estimatedMailOrderPercent}%     Internet:  ${dispensing.estimatedInternetPercent}%`, style: styles.entry, },
        { text: `3:  Purchase Percentages: Controlled:  ${dispensing.estimatedControlledPercent}%     Non-Controlled:  ${dispensing.estimatedNonControlledPercent}%     OTC:  ${dispensing.estimatedPurchaseOtcPercent}%`, style: styles.entry, },
        { text: `4.  Do you have a system in place to notify you if one or more prescribing physicians are writing a high percentage of controlled substances being filled by your pharmacy?  ${dispensing.highPercentControlledSubstanceSystem ? 'Yes' : 'No'}`, style: styles.entry, },
        { text: `5.  Does this pharmacy fill prescriptions for out-of-state customers?  ${dispensing.fillOutOfStateCustomers ? 'Yes' : 'No'}`, style: styles.entry, },
        { text: `If YES, approximately how many does the pharmacy fill per month?  ${dispensing.outOfStateCustomersCount || '_________'}`, style: styles.entry, },
        { text: 'Bank Information', style: styles.entryBold, },
        { text: `Bank Name:  ${banking.bankName}`, style: styles.entry, },
        { text: `Address:  ${banking.bankAddress} ${banking.bankCity}, ${banking.bankState} ${banking.bankZip}`, style: styles.entry, },
        { text: `Phone:  ${banking.bankPhone}     Fax: _______________`, style: styles.entry, },
        { text: `Officer:  ${banking.accountOfficer}     Account Number:  ${banking.accountNumber}`, style: styles.entry, },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Bloodworth Application For Credit" }) }), _jsx(View, { style: styles.entryContainer, children: applicant.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) })] }) }));
});
