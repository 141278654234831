import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import {
	Checkbox, FormControlLabel, Grid, TextField,
} from '@mui/material';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const PPInformation: FC<Props> = memo(({ formik }) => {
	const PP_SAME_AS_OWNER = 'pp-same-as-owner';
	const PURCHASER_TITLE = 'purchaser-title';
	const PURCHASER_EMAIL = 'purchaser-email';
	const PURCHASER_FAX = 'purchaser-fax';

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const { pp: ppErrors = {} } = errors;
	const { pp: ppTouched = {} } = touched;
	const {
		ppInfoSameAsOwner, ppInfoSameAsPic, title, email,
	} = values;
	const owner = values?.owner ?? {} as PharmacyPostData['owner'];
	const pic = values?.pic ?? {} as PharmacyPostData['pic'];
	const pp = values?.pp ?? {} as PharmacyPostData['pp'];

	const updatePPFields = (checked: boolean, field: 'owner' | 'pic') => {
		const keys = ['pp.firstName', 'pp.lastName', 'pp.title', 'pp.phone', 'pp.email'];
		// eslint-disable-next-line no-nested-ternary
		const vals = checked
			? field === 'owner'
				? [owner.firstName, owner.lastName, title, owner.phone, email]
				: [pic.firstName, pic.lastName, 'PIC', pic.phone, pic.email]
			: [pp.firstName, pp.lastName, pp.title, pp.phone, pp.email];
		let index = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			formik.setFieldValue(keys[index], vals[index]);
			formik.setFieldError(key, undefined);
			index += 1;
		}
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={ppInfoSameAsOwner}
							onChange={(e, checked) => {
								handleChange(e);
								updatePPFields(checked, 'owner');
								formik.setFieldValue('ppInfoSameAsPic', false);
							}}
							name='ppInfoSameAsOwner'
							color='primary'
						/>
					}
					data-testid={PP_SAME_AS_OWNER}
					label='PP&apos;s contact information is the same as owner&apos;s'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={ppInfoSameAsPic}
							onChange={(e, checked) => {
								handleChange(e);
								updatePPFields(checked, 'pic');
								formik.setFieldValue('ppInfoSameAsOwner', false);
							}}
							name='ppInfoSameAsPic'
							color='primary'
						/>
					}
					label='PP&apos;s contact information is the same as PIC&apos;s'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pp.firstName'
					label='Purchaser First Name'
					type='text'
					variant='outlined'
					value={pp.firstName}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ppTouched.firstName && Boolean(ppErrors.firstName)}
					helperText={ppTouched.firstName && ppErrors.firstName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pp.lastName'
					label='Purchaser Last Name'
					type='text'
					variant='outlined'
					value={pp.lastName}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ppTouched.lastName && Boolean(ppErrors.lastName)}
					helperText={ppTouched.lastName && ppErrors.lastName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pp.title'
					label='Purchaser Title'
					type='text'
					variant='outlined'
					value={pp.title}
					inputProps={{
						'data-testid': PURCHASER_TITLE
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ppTouched.title && Boolean(ppErrors.title)}
					helperText={ppTouched.title && ppErrors.title}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pp.email'
					label='Purchaser Email'
					type='email'
					variant='outlined'
					value={pp.email}
					inputProps={{
						'data-testid': PURCHASER_EMAIL
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ppTouched.email && Boolean(ppErrors.email)}
					helperText={ppTouched.email && ppErrors.email}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pp.phone'
					label='Purchaser Phone'
					type='text'
					variant='outlined'
					value={pp.phone}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={ppTouched.phone && Boolean(ppErrors.phone)}
					helperText={ppTouched.phone && ppErrors.phone}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					name='pp.fax'
					label='Purchaser Fax'
					type='text'
					variant='outlined'
					value={pp.fax}
					inputProps={{
						'data-testid': PURCHASER_FAX
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={ppTouched.fax && Boolean(ppErrors.fax)}
					helperText={ppTouched.fax && ppErrors.fax}
				/>
			</Grid>
		</Grid>
	);
});
