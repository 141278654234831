import * as Yup from 'yup';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

const options = {
	translations: zxcvbnEnPackage.translations,
	graphs: zxcvbnCommonPackage.adjacencyGraphs,
	dictionary: {
		...zxcvbnCommonPackage.dictionary,
		...zxcvbnEnPackage.dictionary,
	},
};
zxcvbnOptions.setOptions(options);

export const IntroSchema = Yup.object().shape({
	isMultiStoreOwner: Yup.boolean(),
	userName: Yup.string()
		.min(3, 'Your name is required')
		.required('Your name is required'),
	position: Yup.string()
		.oneOf(['Owner', 'Purchaser', 'PIC', 'Technician', 'Other'])
		.required('Position is required'),
	pharmacyName: Yup.string()
		.min(3, 'Your name is required')
		.required('Pharmacy name is required'),
	npiNumber: Yup.number()
		.required('NPI number is required')
		.test('len', 'Must be exactly 10 characters', (val) => val?.toString().length === 10),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email address is required'),
	reCaptchaToken: Yup.string()
		.required(''),
	password: Yup.string()
		.when('isMultiStoreOwner', {
			is: false,
			then: (schema) => schema.test({
				test: (value) => (value ?? '').length >= 8,
				message: 'Password must be at least 8 characters long'
			})
		})
		.when('isMultiStoreOwner', {
			is: false,
			then: (schema) => schema.test({
				test: (value) => zxcvbn(value ?? '').score >= 3,
				message: 'Password is too weak. Please add uppercase, special characters, or digits'
			})
		}),
	confirmPassword: Yup.string()
		.when('isMultiStoreOwner', {
			is: false,
			then: (schema) => schema.required('Confirm password is required')
				.oneOf([Yup.ref('password')], 'Confirm password must equal password'),
		}),
});
