import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import { Grid, TextField } from '@mui/material';
import { PharmacyPostData } from '../../../../../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
	id: string;
	label: string;
	options: JSX.Element[];
};

export const PaymentOptionsSelect: FC<Props> = memo(({
	formik, id, label, options, ...rest
}) => {
	const { handleBlur, handleChange, values } = formik;
	const name = `payments.${id}.paymentMethod`;
	const allowsCC = ['keysource_secondary', 'twosrx_secondary'].includes(id);
	const disableACH = ['twosrx_secondary'].includes(id);
	const onlyACH = ['ipc_secondary'].includes(id);

	const getDefaultPaymentValue = () => {
		if (disableACH) {
			return get(values, `payments.${id}.paymentMethod`, 'Check');
		}
		return get(values, `payments.${id}.paymentMethod`, 'ACH');
	};

	const allowedPaymentOptions = options.filter((node) => {
		if (!allowsCC && node.key === 'Credit Card') {
			return false;
		}
		if (disableACH && node.key === 'ACH') {
			return false;
		}
		if (onlyACH && node.key !== 'ACH') {
			return false;
		}
		return true;
	});

	return (
		<Grid xs item {...rest}>
			<TextField
				select
				fullWidth
				required
				name={name}
				label={label}
				type='text'
				variant='outlined'
				value={getDefaultPaymentValue()}
				onChange={handleChange}
				onBlur={handleBlur}
				data-testid="paymentSelector"
			>
				{allowedPaymentOptions}
			</TextField>
		</Grid>
	);
});
