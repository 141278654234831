import { FC, memo } from 'react';
import {
	List, ListItem, ListItemIcon, ListItemText, ListItemTextProps, Typography,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { StyledGrid, classes } from './CheckList.styles';

export const CheckList: FC = memo(() => {
	const items: ListItemTextProps[] = [
		{ primary: 'Photo of DEA License' },
		{ primary: 'Photo of State License' },
		{ primary: 'Photo of Drivers License' },
		{
			primary: 'Photo of Voided Check',
			secondary: 'KeySource ACH only',
		},
	];

	return (
		<StyledGrid item xs={12} md={4} className={classes.container}>
			<Typography className={classes.title}>
                Required Documents
			</Typography>
			<List dense className={classes.list}>
				{items.map((item, key) => (
					<ListItem key={key}>
						<ListItemIcon>
							<FolderIcon />
						</ListItemIcon>
						<ListItemText {...item} />
					</ListItem>
				))}
			</List>
		</StyledGrid>
	);
}, () => true);
