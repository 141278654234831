export var KPI;
(function (KPI) {
    KPI["GPR"] = "GPR";
    KPI["BPR"] = "BPR";
    KPI["GCR"] = "GCR";
})(KPI || (KPI = {}));
;
export var PSAO;
(function (PSAO) {
    PSAO["APCI"] = "APCI";
    PSAO["AAP"] = "AAP";
    PSAO["AAPA"] = "AAPA";
    PSAO["APSC"] = "APSC";
    PSAO["EPIC"] = "EPIC";
    PSAO["CARE"] = "CARE";
    PSAO["IPC"] = "IPC";
    PSAO["NPSC"] = "NPSC";
    PSAO["CPA"] = "CPA";
    PSAO["Smart_Fill"] = "Smart Fill";
    PSAO["APRX"] = "APRX";
    PSAO["PPSC"] = "PPSC";
    PSAO["SPC"] = "SPC";
    PSAO["IPB"] = "IPB";
    PSAO["IPA"] = "IPA";
    PSAO["MHA"] = "MHA";
    PSAO["OTHER"] = "Other";
})(PSAO || (PSAO = {}));
;
;
;
