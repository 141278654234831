import { createTheme } from '@mui/material/styles';
import components from './components';
import { getPalette } from './palette';
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            lg2: 1320,
            lg3: 1400,
            xl: 1536,
            xl2: 1600,
            xl3: 1700,
            xxl: 1800,
            xxxl: 2000,
        },
    },
    components,
    palette: getPalette(),
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
});
export default theme;
