import { styled } from '@mui/material/styles';

const PREFIX = 'PreparingView';
export const classes = {
	root: `${PREFIX}-root`,
	image: `${PREFIX}-image`,
	bgImg1: `${PREFIX}-bgImg1`,
	bgImg2: `${PREFIX}-bgImg2`,
	bgImg4: `${PREFIX}-bgImg4`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		backgroundColor: '#111B29',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		position: 'absolute',
	},
	[`& .${classes.image}`]: {
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		opacity: 0.24,
		mixBlendMode: 'screen',
		position: 'absolute',
		padding: theme.spacing(2),
	},
	[`& .${classes.bgImg1}`]: {
		background: 'url(/img/pharmacy_1.jpg) no-repeat',
		backgroundSize: 'cover',
	},
	[`& .${classes.bgImg2}`]: {
		background: 'url(/img/pharmacy_2.jpg) no-repeat',
		backgroundSize: 'cover',
	},
	[`& .${classes.bgImg4}`]: {
		background: 'url(/img/pharmacy_4.jpg) no-repeat',
		backgroundSize: 'cover',
	},
}));
