import { styled } from '@mui/material/styles';
import { CardHeader } from '../../../../../../components';

const PREFIX = 'FormHeader';
export const classes = {
	cardHeader: `${PREFIX}-cardHeader`,
	subtitle: `${PREFIX}-subtitle`,
};
export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
	[`&.${classes.cardHeader}`]: {
		width: 'auto',
		textAlign: 'center',
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		marginTop: -60,
		padding: '20px 0px',
	},
	[`& .${classes.subtitle}`]: {
		marginTop: 4,
		textAlign: 'left',
		padding: theme.spacing(0, 8),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
