import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Svg, Polygon, Text, StyleSheet } from '@react-pdf/renderer';
;
export const BorderPolygon = memo(({ border = 'top', }) => {
    const blue = '#1565c0';
    const styles = StyleSheet.create({
        top: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
        bottom: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
        },
        address: {
            fontSize: 9,
        },
        link: {
            fontSize: 11,
        },
    });
    const isTop = border === 'top';
    const [svgProps, polygonProps] = useMemo(() => {
        const svgProps = {
            style: styles.bottom, height: '200', width: '1000',
        };
        const polygonProps = {
            points: '0,200 0,180 200,150 600,180 600,200',
            fill: blue,
            stroke: blue,
            strokeWidth: 1,
        };
        if (isTop) {
            svgProps.style = styles.top;
            svgProps.height = '100';
            polygonProps.points = '0,0 0,20 400,40 600,20 600,0';
            polygonProps.fill = blue;
            polygonProps.stroke = blue;
        }
        return [svgProps, polygonProps];
    }, [border]);
    return (_jsxs(Svg, { ...svgProps, children: [_jsx(Polygon, { ...polygonProps }), !isTop &&
                _jsxs(_Fragment, { children: [_jsx(Text, { x: 150, y: 180, fill: '#fff', style: styles.link, children: "PharmacyMarketplace.com" }), _jsx(Text, { x: 370, y: 155, fill: blue, style: styles.address, children: "1090 9th Avenue SW, Suite 110 Bessemer, AL 35022" })] })] }));
});
