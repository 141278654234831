import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Initials } from '../../../DocumentSeven/components/Initials';
export const PageOne = memo(() => {
    const styles = StyleSheet.create({
        container: {
            padding: '10px 30px 0px',
        },
        item: {
            flexDirection: 'row',
            padding: 10,
            marginLeft: 100,
        },
        bulletPoint: {
            fontSize: 9,
            alignContent: 'center',
            justifyContent: 'center',
        },
        itemContent: {
            flex: 1,
            fontSize: 9,
            marginLeft: 10,
        },
        entrySmall: {
            paddingBottom: 10,
            fontSize: 9,
        },
        entrySmallContainer: {
            padding: '0px 10px',
        },
    });
    const items = [
        'This Guaranty has been executed and delivered by the Undersigned “Guarantor” to Prescription Supply, Inc. in consideration of credit extended and to be extended to the organization listed below ("Customer").',
        'In consideration of the foregoing, the undersigned Guarantor hereby jointly and severally unconditionally guarantees to Prescription Supply, Inc., its successors and assigns, the prompt and full payment to Prescription Supply, Inc. when due, whether by acceleration or otherwise, of all and any indebtedness including inventory or liability which may hereafter be owing or become due from the Customer to Prescription Supply, Inc. by reason of merchandise sold and delivered by Prescription Supply, Inc. to the Customer, its successors and assigns, and upon the failure of the Customer to promptly pay the full amount when due, by acceleration or otherwise. The Guarantors, jointly and severally, promise to pay such indebtedness or liability on demand, together with all lawful interest and service charges thereon, and all attorneys\' fees, costs and expenses incurred by Prescription Supply, Inc. in the collection thereof (collectively, the "Obligations").',
        'If the Customer defaults in the payment when due, whether by acceleration or otherwise, of any of the Obligations, Guarantor hereby agrees to pay such sums to Prescription Supply, Inc., its successors and assigns, on demand.',
        'Guarantor assumes all responsibility for being and keeping himself informed of the Customer\'s financial condition and assets, and all other circumstances bearing upon the risk of non-payment of any Obligations of the Customer to Prescription Supply, Inc., and agrees that Prescription Supply, Inc. shall have no duty to advise Guarantor of information known to it regarding such circumstances or risks.',
        'Guarantor hereby expressly waives (a) notice of the acceptance of this Guaranty, of extensions of credit by Prescription Supply, Inc. to the Customer and of renewals or extensions of the Obligations; (b) presentment and demand for payment of any of the Obligations of the Customer; and (c) protest and notice of dishonor or default to Guarantor or to any other person with respect to any of the Obligations of the Customer.',
        'This is a guarantee of payment and not of collection and Guarantor further expressly waives any right to require that an action be brought against the Customer or any other person or guarantor or to require that resort be had to any security or to any balance of any deposit account or to any credit on the books of the Prescription Supply, Inc. in favor of the Customer or any other person before an action may be brought against Guarantor. Guarantor shall have no right of subrogation whatsoever with respect to the Obligations or any collateral therefore, and waives any claim, remedy or right of subrogation, reimbursement, exoneration, contribution or indemnification, unless and until Prescription Supply, Inc. shall have received full payment of Customer\'s Obligations to Prescription Supply, Inc.',
        'Guarantor hereby agrees and consents that from time to time before or after any default by the Customer, and without notice to or assent from Guarantor, any security at any time held by or available to Prescription Supply, Inc. for any Obligations of the Borrower, or any security at any time held by or available to Prescription Supply, Inc. for any obligation of any other person secondarily or otherwise liable for any of the Obligations of the Customer, may be exchanged, surrendered, compromised or released, and any liability of the Customer, or any liability or obligation of any person, may be changed, altered, renewed, extended, continued, surrendered, compromised, waived, or released in whole or in part, or any default with respect thereto waived, and Prescription Supply, Inc. may refrain from setting off and may release in whole or in part, any balance of any deposit account or credit on its books in favor of the Customer or any other person, and may extend further credit in any manner whatsoever to the Customer, and generally deal with any security as Prescription Supply, Inc. may see fit, and Guarantor shall remain bound under this Guaranty notwithstanding any such exchange, surrender, release, change, alteration, renewal, extension, continuance, compromise, waiver, inaction or farther credit or other indulgence or dealing.',
        'In the event that any proceeding is filed under any bankruptcy or insolvency law by or against the Customer or by or against Guarantor, or in the event a receiver is appointed for the Customer or the Guarantor or any of its or his property, or if the Customer or the Guarantor shall make an assignment for the benefit of creditors or shall discontinue business, or become insolvent, or if the Guarantor shall die, then all Obligations of the Customer to Prescription Supply, Inc. shall, for the purpose of this Guaranty, automatically become immediately due and payable without notice or demand.',
        'Guarantor further agrees that, to the extent that the Customer or any guarantor makes a payment or payments to Prescription Supply, Inc., or Prescription Supply, Inc. receives any proceeds of collateral, which payment or payments or proceeds or any part thereof are subsequently invalidated, declared to be fraudulent or preferential, set aside and/or required to be repaid to the Customer, its estate, trustee, receiver or any other party, including without limitation any guarantor, under any bankruptcy law, state or federal law, common law or equitable cause, then to the extent of such payment or repayment, the Obligations or the part thereof which has been paid, reduced or satisfied by such amount shall be reinstated and continued in full force and effect as of the date such initial payment, reduction or satisfaction occurred.',
        'Guarantor agrees to furnish Prescription Supply, Inc. with a true and complete personal financial statement on an annual basis while this Guaranty is in effect. Guarantor further agrees to deliver to Prescription Supply, Inc. information on his financial condition (i) within 30 days after the occurrence of any event which causes a material adverse change in Guarantor\'s financial condition, or (ii) upon request of Prescription Supply, Inc. Guarantor understands and agrees that any failure to furnish such financial statements, information or tax returns to Prescription Supply, Inc. when due constitutes a default hereunder and may trigger acceleration of the Obligations. Guarantor hereby authorizes Prescription Supply, Inc. to obtain from time to time credit reports and other information on Guarantor\'s financial condition from credit reporting agencies and other third party sources.',
        'Guarantor hereby postpones and subordinates in all respects to any and all Obligations all present and future indebtedness and obligations to it by the Customer. Without the express prior written consent of Prescription Supply, Inc., Guarantor will not accept or receive from Customer or any other person, directly or indirectly, any payments, in cash or otherwise, on any such indebtedness or obligations.',
        'Prescription Supply, Inc. may take any action it deems necessary or advisable to enforce this Guaranty and Guarantor will reimburse Prescription Supply, Inc. for all costs, expenses and reasonable attorneys\' fees expended or incurred by Prescription Supply, Inc. in doing so. Guarantor acknowledges and agrees that (a) the Obligations constitute a "contract of indebtedness", and (b) the Obligations have been issued for a business purpose, and not for a purpose that is primarily personal, family or household. Guarantor agrees that attorneys\' fees charged at the rates customarily charged by attorneys rendering services to Prescription Supply, Inc. shall be deemed reasonable.'
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.item, children: [_jsx(Text, { style: styles.bulletPoint, children: "UNCONDITIONAL PERSONAL GUARANTY OF COMMERCIAL DEBT" }), _jsx(Text, { style: styles.itemContent, children: "Northwood, Ohio" })] }), _jsx(View, { style: styles.entrySmallContainer, children: items.map((text, index) => (_jsx(Text, { style: styles.entrySmall, children: text }, index))) }), _jsx(Initials, { paddingTop: 0, pageNum: 1 })] }) }));
});
