import { atom, selector } from 'recoil';
import { SubscriptionType } from '@pm/shared';
import { localStorageEffect } from './effects';
import { PharmacyPostData } from '../services/types';

const registrationFormKey = 'registrationForm';

export const registrationFormState = atom<PharmacyPostData>({
	key: registrationFormKey,
	default: {
		isMultiStoreOwner: false,
		coupon: undefined,
		signer: {
			name: '',
			title: 'Owner'
		},
		firstName: '',
		lastName: '',
		title: '',
		name: '',
		legalName: '',
		stateOfIncorporation: '',
		email: '',
		phone: '',
		fax: '',
		address: '',
		city: '',
		state: '',
		zipCode: '',
		dateBusinessBegan: null,
		yearsInBusiness: '',
		pharmacyType: 'Retail',
		npiNumber: '',
		mckessonId: '',
		deaNumber: '',
		deaExpDate: null,
		hinNumber: '',
		hinExpDate: null,
		stateLicense: '',
		stateLicenseExpDate: null,
		stateIdNumber: '',
		stateIdExpDate: null,
		federalTaxNumber: '',
		federalTaxExpDate: null,
		dunsNumber: '',
		ncpdpNumber: '',
		medicarePartB: '',
		stateSellersPermit: '',
		pharmacyAddressIsShippingAddress: false,
		shippingAddress: '',
		shippingCity: '',
		shippingState: '',
		shippingZip: '',
		multipleShipToFacilities: false,
		relatedToOtherFacilities: false,
		relatedToOtherFacilitiesList: '',
		gln: '',
		dispensing: {
			fillOutOfStateCustomers: false,
			outOfStateCustomersCount: '',
			managementSystem: 'Select',
		},
		owner: {
			firstName: '',
			lastName: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
			social: '',
			percentOwned: '',
			ownershipType: '',
			registerType: '',
			registerTypeOther: '',
		},
		picInfoSameAsOwner: false,
		pic: {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			license: '',
			licenseExpDate: null,
		},
		ppInfoSameAsOwner: false,
		ppInfoSameAsPic: false,
		pp: {
			firstName: '',
			lastName: '',
			title: '',
			phone: '',
			email: '',
			fax: '',
		},
		primary: {
			name: '',
			address: '',
			city: '',
			zip: '',
			state: '',
			phone: '',
			fax: '',
			psao: '',
			psoaOther: '',
		},
		apInfoSameAsOwner: false,
		ap: {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
		},
		banking: {
			bankName: '',
			bankAddress: '',
			bankCity: '',
			bankZip: '',
			bankState: '',
			accountOfficer: '',
			accountNumber: '',
			routingNumber: '',
			bankPhone: '',
			accountType: 'Checking',
			initialChargeAmount: 0,
			initialChargeAmountDesc: '',
			monthlyChargeAmount: 0,
			receiveInvoices: '',
			accountToBeDebited: '',
			accountToBeDebitedOther: ''
		},
		payments: {
			cardNumber: '',
			cardType: '',
			cardExpDate: '',
			cardCvc: '',
			cardZip: '',
			bluepax_secondary: {
				paymentMethod: 'ACH',
			},
			capital_secondary: {
				paymentMethod: 'ACH',
			},
			rx_supply_secondary: {
				paymentMethod: 'ACH',
			},
			bloodworth_secondary: {
				paymentMethod: 'ACH',
			},
			keysource_secondary: {
				paymentMethod: 'ACH',
			},
			toprx_secondary: {
				paymentMethod: 'ACH',
			},
			auburn_secondary: {
				paymentMethod: 'ACH',
			},
			ipc_secondary: {
				paymentMethod: 'ACH',
			},
			billingAddress: '',
			billingCity: '',
			billingState: '',
			billingZip: ''
		},
		tradeRefSameAsPw: true,
		tradeReference1: {
			name: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
			accountNumber: ''
		},
		tradeReference2: {
			name: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
			accountNumber: ''
		},
		convicted: undefined,
		bankruptcy: undefined,
		subscription: SubscriptionType.BASIC,
	},
	effects: [
		localStorageEffect<PharmacyPostData>(registrationFormKey),
	],
});

export const selectSubscription = selector<SubscriptionType>({
	key: 'subscription',
	get: ({ get: getState }) => {
		const { subscription } = getState(registrationFormState);
		return subscription;
	},
	cachePolicy_UNSTABLE: {
		eviction: 'most-recent',
	},
});

export const registrantEmail = selector<string>({
	key: 'email',
	get: ({ get: getState }) => {
		const { email } = getState(registrationFormState);
		return email;
	},
	cachePolicy_UNSTABLE: {
		eviction: 'most-recent',
	},
});
