import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo } from 'react';
import { IMaskInput } from 'react-imask';
import { TextField, TableBody, Tooltip, TableRow, TableCell, Typography, lighten, IconButton, InputAdornment } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
;
const PercentageRangeInput = forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (_jsx(IMaskInput, { ...other, mask: [
            {
                mask: 'num%+',
                blocks: {
                    num: {
                        mask: Number,
                        scale: 0,
                        min: 0,
                        max: 100,
                    },
                },
            },
            {
                mask: 'num-num%',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: ',',
                        scale: 1,
                        min: 0,
                        max: 100,
                    },
                },
            },
        ], inputRef: ref, dispatch: (_appended, dynamicMasked) => {
            if (dynamicMasked.value.includes('+')) {
                return dynamicMasked.compiledMasks[0];
            }
            return dynamicMasked.compiledMasks[1];
        }, onAccept: (value) => {
            onChange({ target: { name: props.name, value } });
        } }));
});
const CenterAlignedCell = muiStyled(TableCell)({
    textAlign: 'center',
    cursor: 'pointer',
});
const FlexAlignedCell = muiStyled(TableCell)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
});
;
const TableRows = ({ rows, colTitlesIndex, handleCellClick, handleRemoveRow, handleCellChange, handleCellBlur, handleCellKeyDown, }) => {
    return (_jsx(TableBody, { children: rows.slice(colTitlesIndex + 1).map((row, rowIndex) => (_jsx(TableRow, { children: row.cells.map((cell, cellIndex) => {
                const firstCol = cellIndex === 0;
                const CellComponent = firstCol ? FlexAlignedCell : CenterAlignedCell;
                const styles = rowIndex === 0 && firstCol
                    ? { display: 'flex', justifyContent: 'flex-end' } : {};
                return (_jsxs(CellComponent, { onClick: () => handleCellClick(row.id, cellIndex), style: {
                        ...styles,
                        fontWeight: firstCol ? 'bold' : 'normal',
                        backgroundColor: cell.editable
                            ? '#fff'
                            : firstCol ? lighten('rgba(255, 99, 132, 1)', .4) : '#fff',
                    }, children: [firstCol && rowIndex > 0 &&
                            _jsx(Tooltip, { title: 'Delete Row', children: _jsx(IconButton, { "aria-label": 'Delete Row', onClick: () => handleRemoveRow(row.id), children: _jsx(DeleteIcon, {}) }) }), cell.editable ? (_jsx(TextField, { autoFocus: true, InputProps: firstCol ? undefined : {
                                startAdornment: _jsx(InputAdornment, { position: 'start', children: "%" }),
                                inputComponent: firstCol ? PercentageRangeInput : undefined,
                            }, type: firstCol ? 'text' : 'number', value: cell.value, onChange: (e) => handleCellChange(row.id, cellIndex, e.target.value), onBlur: () => handleCellBlur(row.id, cellIndex), onKeyDown: (e) => handleCellKeyDown(e, row.id, cellIndex) })) : (_jsx(Typography, { fontWeight: firstCol ? 'bold' : 'normal', children: `${cell.value}${firstCol ? '' : '%'}` }))] }, cellIndex));
            }) }, row.id))) }));
};
export default memo(TableRows);
