import ApiClient, { ApiUrl, ReqOptions } from './ApiClient';

export const put = {
	pharmacyDrugDispensingTable: async (npiNumber: string, opts: ReqOptions = {}) => {
		const client = await ApiClient.getInstance();
		const jwt = localStorage.getItem('onboardJWT') ?? '';
		return client.update(
			`/pharmacy-drug-dispensing-data/${npiNumber}`,
			undefined,
			{},
			{ ...opts, params: { jwt, } },
			{},
			ApiUrl.DISPENSING,
		);
	},
};
