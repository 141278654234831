import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
export const PageFooter = memo(() => {
    const styles = StyleSheet.create({
        parent: {
            padding: '20px 20px 0px',
            textAlign: 'center',
        },
        text: {
            color: 'dodgerblue',
            fontSize: 12,
        },
    });
    return (_jsx(View, { style: styles.parent, children: _jsxs(View, { children: [_jsx(Text, { style: styles.text, children: "160 Raritan Center Parkway, Unit 1, Edison, NJ 08837" }), _jsx(Text, { style: styles.text, children: "Tel: 732.902.6760  |  Fax: 732.902.6761  |  blupaxpharma.com" })] }) }));
});
