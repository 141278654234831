import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import Auburn from '../../../../../../images/AuburnLogo.png';
;
export const PageOne = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '20px 30px',
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: 25,
        },
        image: {
            width: 75,
            height: 75,
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 20,
            textAlign: 'center',
        },
        titleContainer: {
            flexDirection: 'column',
            padding: '5px 0px 5px 15px',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
        },
        p: {
            fontSize: 12,
            fontFamily: 'Roboto',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            marginBottom: 10,
        },
        col: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            marginBottom: 10,
        },
        entry: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        footer: {
            marginTop: 160,
            textAlign: 'center',
        },
        footerText: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
    });
    const sections = useMemo(() => {
        const { dispensing, } = traderInfo;
        return [
            [
                { label: 'Account Name:', value: pharmacy.name, },
                { label: 'DBA:', value: pharmacy.legalName, },
            ],
            [
                { label: 'Address:', value: pharmacy.address, },
                { label: 'City:', value: pharmacy.city, },
                { label: 'State:', value: pharmacy.state, },
                { label: 'Zip:', value: pharmacy.zipCode, },
            ],
            [
                { label: 'Account #:', value: '________', },
                { label: 'DEA #:', value: pharmacy.deaNumber, },
                { label: 'NAPB #:', value: '________', },
                { label: 'Business Type:', value: pharmacy.pharmacyType, },
            ],
            [
                { label: 'Hours of Operation:', value: '', },
                { label: 'Monday-Friday:', value: '________', },
                { label: 'Saturday:', value: '________', },
                { label: 'Sunday:', value: '________', },
            ],
            {
                index: 1,
                isRow: true,
                label: 'How long has your pharmacy been open for business?',
                value: traderInfo.yearsInBusiness,
            },
            {
                index: 2,
                label: 'What is the approximate percentage of your prescription drug business?',
                value: `Walk-in: ${dispensing.estimatedWalkInPercent}%    Fax & Phone: N/A    E-Script: N/A    Mail Order: ${dispensing.estimatedMailOrderPercent}%    Internet: ${dispensing.estimatedInternetPercent}%`,
            },
            {
                index: 3,
                label: 'What is the approximate percentage of payment method your pharmacy receives? ',
                value: 'Insurance: N/A    Medicare/Medicaid: N/A    Worker’s Compensation: N/A    Cash: N/A',
            },
            {
                index: 4,
                isRow: true,
                label: 'Which RX suppliers have you used within the last 12 months?',
                value: traderInfo.primary.name,
            },
            {
                index: 5,
                isRow: true,
                label: 'Has your pharmacy ever operated under a different name?',
                value: 'N/A',
            },
            {
                index: 6,
                isRow: !traderInfo.relatedToOtherFacilities,
                label: 'Is your pharmacy afffiliated with another pharmacy? If yes, please provide name and address:',
                value: traderInfo.relatedToOtherFacilities ? traderInfo.relatedToOtherFacilitiesList : 'No',
            },
            {
                index: 7,
                isRow: true,
                label: 'Has the owner, Pharmacist in Charge, or pharmacy had a license suspended or revoked?',
                value: 'N/A',
            },
            {
                index: 8,
                isRow: true,
                label: 'Has the owner, Pharmacist in Charge, or pharmacy had disciplinary action on a license?',
                value: 'N/A',
            },
            {
                index: 9,
                isRow: true,
                label: 'Is your pharmacy licensed to ship outside the state in which it is located?',
                value: traderInfo.multipleShipToFacilities ? 'Yes' : 'No',
            },
            {
                index: 10,
                isRow: true,
                label: 'Does your pharmacy have a website? If yes, please provide web address:',
                value: 'N/A',
            },
            {
                index: 11,
                label: 'Do you have a system in place to notify you if one or more prescribing physicians are writing a high percentage of controlled substance prescriptions being filled by your pharmacy?',
                value: traderInfo.dispensing.highPercentControlledSubstanceSystem ? 'Yes' : 'No',
            },
            {
                index: 12,
                isRow: true,
                label: 'On average how many total (non-controlled and controlled) scripts do you dispense daily?',
                value: traderInfo.dispensing.estimatedNumberOfDailyScripts,
            },
            {
                index: 13,
                isRow: true,
                label: 'On average how many controlled scripts do you dispense daily?',
                value: `${traderInfo.dispensing.estimatedControlledPercent}% of total scripts`,
            },
        ];
    }, [traderInfo]);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.headerContainer, children: [_jsx(Image, { style: styles.image, src: Auburn }), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Customer Compliance Questionnaire" }) })] }), sections.map((section, i) => {
                    const isRow = Array.isArray(section);
                    return (_jsx(View, { style: isRow ? styles.row : styles.col, children: isRow ? section.map((field, j) => (_jsx(Section, { ...field, parentStyle: styles.entry, textStyle: styles.p }, j))) : (_jsx(Section, { isRow: false, ...section, parentStyle: styles.entry, textStyle: styles.p })) }, i));
                }), _jsx(ControlledSubstancesList, {})] }) }));
});
;
const Section = ({ parentStyle, textStyle, index, label, value, isRow = true, }) => {
    const firstChildStyles = { ...textStyle, marginRight: 5, fontSize: 10, marginBottom: isRow ? 0 : 8, };
    const children = (_jsxs(_Fragment, { children: [_jsx(Text, { style: firstChildStyles, children: index ? `${index}. ${label}` : label }), _jsx(Text, { style: textStyle, children: value })] }));
    if (!isRow) {
        return children;
    }
    return (_jsx(View, { style: parentStyle, children: children }));
};
const ControlledSubstancesList = () => {
    const styles = StyleSheet.create({
        p: {
            fontSize: 10,
            fontFamily: 'Roboto',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            marginBottom: 10,
        },
        col: {
            display: 'flex',
            flexDirection: 'column',
        },
    });
    const items = useMemo(() => [
        'A. Monthly Volume of Oxycodone 30mg:',
        'B. Monthly Volume of Oxycodone 10/325mg:',
        'C. Monthly Volume of all OTHER strengths Oxycodone:',
        'D. Monthly Volume of ALL Oxycodone (TOTAL A+B+C=D):',
        'E. Monthly Volume of Hydrocodone 10/325mg:',
        'F. Monthly Volume of all OTHER strengths Hydrocodone:',
        'G. Monthly Volume of ALL Hydrocodone (TOTAL E+F=G):',
        'H. Monthly Volume of Gabapentin (all strengths):',
    ], []);
    return (_jsxs(View, { style: styles.col, children: [_jsx(Text, { style: styles.p, children: "14.  What is your monthly dispensing average for the following items:" }), _jsx(View, { style: { marginLeft: 40, marginTop: 10, }, children: items.map((item, i) => (_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.p, children: item }), _jsx(Text, { style: styles.p, children: "________ Scripts ________ Tabs" })] }, i))) })] }));
};
