import {
	FC, useCallback, useEffect, useRef, useState, memo,
} from 'react';
import { useRecoilState } from 'recoil';
import { FormikProps } from 'formik';
import { Button, CircularProgress, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import NiceModal from '@ebay/nice-modal-react';
import { registrationFormState, registrationFormStepState } from '../../../../../../atoms';
import { EventEmitter, SET_CC_TYPE } from '../../../../../../emitter';
import { PharmacyPostData } from '../../../../../../services/types';
import { StyledCardFooter, classes } from './FormFooter.styles';
import { getStepCount } from '../../../../../../util/constants';
import Dialog from '../../../../../../components/Dialog/Dialog';
import { getErrorsForView, useErrorState } from '../../../../../../hooks/useErrorState';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const FormFooter: FC<Props> = memo(({ formik }) => {
	const { errors, isSubmitting, values } = formik;
	const mounted = useRef<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [formStep, setFormStep] = useRecoilState(registrationFormStepState);
	const [form, setForm] = useRecoilState(registrationFormState);
	const STEP_COUNT = getStepCount();
	const hasErrors = !isEmpty(errors);
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [_currentViewHasErrors, _count, _steps, fixStep] = useErrorState(formik);

	const onSave = useCallback((newValues: PharmacyPostData) => {
		if (mounted.current) {
			setSaving(true);
			setForm(newValues);
			setTimeout(() => {
				if (mounted && mounted.current) {
					setSaving(false);
				}
			}, 1500);
		}
	}, []);

	useEffect(() => {
		mounted.current = true;
		const timer = setInterval(() => {
			onSave(values);
		}, 60000);
		const onSetCCType = (cardType: string) => {
			const newValues = {
				...values,
				payments: {
					...values.payments,
					cardType,
				},
			};
			onSave(newValues);
		};
		EventEmitter.on(SET_CC_TYPE, onSetCCType);
		return () => {
			mounted.current = false;
			clearInterval(timer);
			EventEmitter.off(SET_CC_TYPE, onSetCCType);
		};
	}, [values]);

	const onModalFix = () => {
		fixStep();
	};

	const onModalContinue = () => {
		if (formStep < STEP_COUNT) {
			setFormStep((step) => step + 1);
			onSave(values);
		}
	};

	const onContinue = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		const checkedErrors = await formik.validateForm();
		const [viewHasErrors] = getErrorsForView(checkedErrors, formStep - 2);
		if (viewHasErrors) {
			NiceModal.show(Dialog, {
				onContinue: onModalContinue,
				onFix: onModalFix
			});
		} else if (formStep < STEP_COUNT) {
			setFormStep((step) => step + 1);
			onSave(values);
		}
	};

	const onGoBack = () => {
		if (formStep > 1) {
			setFormStep((step) => step - 1);
			onSave(values);
		}
	};

	const isLastFormStep = formStep === STEP_COUNT;
	const disabled = (hasErrors && isLastFormStep) || isSubmitting;

	return (
		<StyledCardFooter className={classes.cardFooter}>
			{(formStep === 2 && !form.coupon) && <Button
				type='button'
				variant='text'
				size='large'
				color='inherit'
				onClick={onGoBack}
				startIcon={<ArrowLeft />}
			>
				Change Subscription
			</Button>}
			{formStep !== 2 && <Button
				type='button'
				variant='text'
				size='large'
				color='inherit'
				onClick={onGoBack}
				startIcon={<ArrowLeft />}
			>
				Go Back
			</Button>}
			{!isLastFormStep
				&& <Button
					type='button'
					variant='outlined'
					size='large'
					color='primary'
					onClick={onContinue}
					endIcon={<ArrowRight />}
				>
					Save &amp; Continue
				</Button>
			}
			{isLastFormStep
				&& <LoadingButton
					type='submit'
					variant='contained'
					size='large'
					disabled={disabled}
					loading={isSubmitting}
					loadingPosition='end'
					color={disabled ? 'inherit' : 'primary'}
					endIcon={<ArrowRight />}
				>
					Submit
				</LoadingButton>
			}
			{saving
				&& <div className={classes.saving}>
					<CircularProgress disableShrink size={16} className={classes.progress} />
					<Typography variant='caption'>Saving progress</Typography>
				</div>
			}
		</StyledCardFooter>
	);
});
