import { stepLabelClasses } from '@mui/material/StepLabel';

export default {
	styleOverrides: {
		root: {
			[`& .${stepLabelClasses.alternativeLabel}`]: {
				color: '#fff !important',
			},
			[`& .${stepLabelClasses.completed}`]: {
				color: '#fff !important',
			},
		},
	},
};
