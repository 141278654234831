import ApiClient from './ApiClient';
import { DocumentsPostData, DocuSignPostData, PharmacyPostData } from './types';

export const post = {
	documents: async (data: DocumentsPostData) => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.create('/documents', data, { params: { jwt: localToken } });
	},
	docusign: async (data: DocuSignPostData) => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.create('/docusign', data, { params: { jwt: localToken } });
	},
	pharmacy: async (data: PharmacyPostData) => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.create('/pharmacies', data, { params: { jwt: localToken } });
	},
	upload: async (data: FormData) => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.createWithFormData('/uploads', data, { params: { jwt: localToken } });
	},
};
