import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import RxSupply from '../../../../images/rxsupply-logo-2.png';
;
export const DocumentTen = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        imgContainer: {
            padding: '30px 30px 8px',
            alignItems: 'center',
        },
        image: {
            height: 85,
        },
        header: {
            padding: '16px 30px 0px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        boldText: {
            fontSize: 15,
            fontWeight: 'demibold',
        },
        entryContainer: {
            padding: '0px 20px',
        },
        entryLarge: {
            paddingBottom: 12,
            fontSize: 12,
        },
        spacer: {
            paddingBottom: 25,
        },
        spacerSmall: {
            paddingBottom: 15,
        },
    });
    const header = useMemo(() => [
        { text: 'PRESCRIPTION SUPPLY INC.', style: styles.boldText, },
        { text: '2233 TRACY RD NORTHWOOD, OH 43619', style: styles.boldText, },
        { text: 'Phone: 800-777-0761 FAX: 419-661-6617', style: styles.boldText, },
        { text: '', style: styles.spacer, },
        { text: 'AUTHORIZATION AGREEMENT FOR PREARRANGED', style: styles.boldText, },
        { text: 'PAYMENTS (DEBITS)/ELECTRONIC CHECK PAYMENTS', style: styles.boldText, },
        { text: '(DEBITS)', style: styles.boldText, },
        { text: '', style: styles.spacer, },
    ], []);
    const date = dayjs().format('MM/DD/YYYY');
    const { banking, owner, } = traderInfo;
    const content = [
        {
            text: 'Company:  Prescription Supply Inc.                           Company Federal Id #:  34-4454964',
            style: styles.entryLarge,
        },
        { text: '', style: styles.spacerSmall, },
        {
            text: 'We hereby authorize Prescription Supply, Inc. to initiate debit entries via ACH or Electronic Check to the checking account indicated below and the bank named below, to debit the same to such account.',
            style: styles.entryLarge,
        },
        { text: '', style: styles.spacerSmall, },
        {
            text: `Company Name:  ${pharmacy.legalName}     Customer #: ______________`,
            style: styles.entryLarge,
        },
        {
            text: `Fed ID #:  ${pharmacy.federalTaxNumber}     Bank Name:  ${banking.bankName}`,
            style: styles.entryLarge,
        },
        {
            text: `City:  ${banking.bankCity}     State:  ${banking.bankState}     Zip Code:  ${banking.bankZip}`,
            style: styles.entryLarge,
        },
        {
            text: `Bank Transit/ABA #:  ${banking.routingNumber}     Bank Account #:  ${banking.accountNumber}`,
            style: styles.entryLarge,
        },
        { text: '', style: styles.spacer, },
        {
            text: 'Authorized Signatory:',
            style: styles.entryLarge,
        },
        {
            text: `Printed Name:  ${owner.firstName} ${owner.lastName}`,
            style: styles.entryLarge,
        },
        {
            text: `Social Security #:  ${owner.social}`,
            style: styles.entryLarge,
        },
        {
            text: `Date:  ${date}`,
            style: styles.entryLarge,
        },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(View, { style: styles.imgContainer, children: _jsx(Image, { style: styles.image, src: RxSupply }) }), _jsx(View, { style: styles.header, children: header.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(View, { style: styles.entryContainer, children: content.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) })] }) }));
});
