export default {
    styleOverrides: {
        root: {
            '&:before': {
                flex: 0,
                padding: 0,
            },
        },
    },
};
