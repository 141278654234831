import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { HeaderImage } from '../Shared';
import CapitalDrug from '../../../../images/capital-drug-logo.png';
;
export const DocumentThree = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 10,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 11,
        },
        title: {
            padding: '10px 0px',
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 15,
        },
        titleContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 15,
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
        },
    });
    const entries = useMemo(() => {
        const { banking, owner, } = traderInfo;
        return [
            `Customer Name:  ${owner.firstName} ${owner.lastName}`,
            `Address:  ${pharmacy.address}`,
            `City:  ${pharmacy.city}     State:  ${pharmacy.state}     Zip:  ${pharmacy.zipCode}`,
            'Capital Customer #: _______________',
            `Bank Name:  ${banking.bankName}`,
            `Account #:  ${banking.accountNumber}     Routing #:  ${banking.routingNumber}`,
            'Authorized Signature:                                               Date:',
            'Title:  Owner',
            `Account to be debited:  ${banking.accountToBeDebited}`,
            `Other: Please give details:  ${banking.accountToBeDebitedOther || '________________________________'}`
        ];
    }, [pharmacy]);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(HeaderImage, { imgSrc: CapitalDrug }), _jsxs(View, { style: styles.titleContainer, children: [_jsx(Text, { style: styles.title, children: "BANK ACCOUNT DEBIT AUTHORIZATION" }), _jsx(Text, { style: styles.entry, children: "The undersigned owner or authorized officer of the entity shown below does hereby authorize Capital Wholesale Drug Company to debit the bank acocunt listed below for any purchases from Capital Wholesale Drug Company. This authorization shall continue until written notification is received by Capital Wholesale Drug Company to cancel." })] }), _jsx(View, { style: styles.entryContainer, children: entries.map((entry, index) => (_jsx(Text, { style: styles.entry, children: entry }, index))) })] }) }));
});
