;
;
;
;
;
;
;
;
export var Wholesalers;
(function (Wholesalers) {
    Wholesalers["bluepax_secondary"] = "bluepax_secondary";
    Wholesalers["capital_secondary"] = "capital_secondary";
    Wholesalers["rx_supply_secondary"] = "rx_supply_secondary";
    Wholesalers["bloodworth_secondary"] = "bloodworth_secondary";
    Wholesalers["keysource_secondary"] = "keysource_secondary";
    Wholesalers["toprx_secondary"] = "toprx_secondary";
    Wholesalers["auburn_secondary"] = "auburn_secondary";
    Wholesalers["ipc_secondary"] = "ipc_secondary";
    Wholesalers["twosrx_secondary"] = "twosrx_secondary";
})(Wholesalers || (Wholesalers = {}));
;
;
;
