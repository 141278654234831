import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const TableFooter = memo(({ items, }) => {
    const styles = StyleSheet.create({
        row: {
            height: 24,
            width: '100%',
            paddingRight: 24,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderBottomColor: '#1565c0',
            borderBottomWidth: 1,
        },
        total: {
            fontSize: 14,
            fontStyle: 'bold',
            fontWeight: 700,
        },
    });
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const total = items.reduce((total, item) => total + item.total, 0.0);
    const formattedTotal = formatter.format(total);
    return (_jsx(View, { style: styles.row, children: _jsx(Text, { style: styles.total, children: formattedTotal }) }));
});
