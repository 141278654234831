import { FC, memo } from 'react';
import dayjs from 'dayjs';
import { List, ListItem } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';
import { Root, classes } from './Footer.styles';

export const Footer: FC = memo(() => (
	<Root className={classes.footer}>
		<div className={classes.container}>
			<div className={classes.left}>
				<List className={classes.list}>
					<ListItem className={classes.inlineBlock}>
						<a
							href='https://pharmacymarketplace.com/privacy-policy/'
							rel='noreferrer'
							className={classes.block}
							target='_blank'
						>
							Privacy Policy
						</a>
					</ListItem>
					<ListItem className={classes.inlineBlock}>
						<a
							href='https://pharmacymarketplace.com/#athenaPower'
							rel='noreferrer'
							className={classes.block}
							target='_blank'
						>
							Athena
						</a>
					</ListItem>
					<ListItem className={classes.inlineBlock}>
						<a
							href='https://pharmacymarketplace.com/#testimonials'
							rel='noreferrer'
							className={classes.block}
							target='_blank'
						>
							Testimonials
						</a>
					</ListItem>
				</List>
			</div>
			<div className={classes.right}>
				&copy; {dayjs().year()}, made with{' '}
				<Favorite className={classes.icon} /> by{' '}
				<a
					href='https://pharmacymarketplace.com'
					rel='noreferrer'
					className={classes.a}
					target='_blank'
				>
					Pharmacy Marketplace Inc.
				</a>{' '}
				for a better pharmaceutical industry.
			</div>
		</div>
	</Root>
));
