import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const TaxCheckbox = memo(({ checkmark, checkmark2 = false, text, }) => {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
        },
        checkbox: {
            borderWidth: 1,
            marginRight: 5,
            width: 20,
        },
        entryCheckbox: {
            paddingBottom: 3,
            fontSize: 9,
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.checkbox, children: checkmark }), checkmark2 && _jsx(Text, { style: styles.checkbox, children: checkmark2 }), _jsx(Text, { style: styles.entryCheckbox, children: text })] }));
});
