import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
	Link, Grid, TextField, Tooltip, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const LicensesInformation: FC<Props> = memo(({ formik }) => {
	const DEA_NUMBER = 'dea-number';
	const DEA_EXP_DATE = 'dea-expiration-date';
	const HIN_NUMBER = 'hin-number';
	const HIN_EXP_DATE = 'hin-expiration-date';
	const STATE_LICENSE_NUMBER = 'state-license-number';
	const STATE_LICENSE_EXP_DATE = 'state-license-expiration-date';
	const STATE_ID_TAX_NUMBER = 'state-id-tax-number';
	const STATE_ID_TAX_EXP_DATE = 'state-id-tax-expiration-date';
	const FEDERAL_TAX_NUMBER = 'federal-tax-number';
	const FEDERAL_TAX_NUMBER_EXP_DATE = 'federal-tax-number-expiration-date';
	const DUNS_NUMBER = 'duns-number';
	const NCPDP_NUMBER = 'ncpdp-number';
	const STATE_SELLER_PERMIT_NUMBER = 'state-seller-permit-number';
	const MEDICARE_PART_B_NUMBER = 'medicare-part-b-number';
	const GLN = 'gln';
	const GLN_OTHER = 'gln-other';

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='ncpdpNumber'
					label='NCPDP Number'
					type='text'
					variant='outlined'
					value={formik.values.ncpdpNumber}
					inputProps={{
						'data-testid': NCPDP_NUMBER,
					}}
					required
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.ncpdpNumber && Boolean(formik.errors.ncpdpNumber)}
					helperText={formik.touched.ncpdpNumber && formik.errors.ncpdpNumber}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					required
					name='deaNumber'
					label='DEA Number'
					type='text'
					variant='outlined'
					inputProps={{
						'data-testid': DEA_NUMBER
					}}
					value={formik.values.deaNumber}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.deaNumber && Boolean(formik.errors.deaNumber)}
					helperText={formik.touched.deaNumber && formik.errors.deaNumber}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<DatePicker
					label='DEA License Expiration Date'
					value={formik.values.deaExpDate}
					onChange={(date) => {
						formik.setFieldValue('deaExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('deaExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required
							name='deaExpDate'
							id='date-picker-dialog-2'
							data-testid={DEA_EXP_DATE}
							type='text'
							variant='outlined'
							onBlur={formik.handleBlur}
							error={formik.touched.deaExpDate && Boolean(formik.errors.deaExpDate)}
							helperText={formik.touched.deaExpDate && formik.errors.deaExpDate}
						/>
					)}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='hinNumber'
					label='HIN Number'
					type='text'
					variant='outlined'
					value={formik.values.hinNumber}
					inputProps={{
						'data-testid': HIN_NUMBER
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.hinNumber && Boolean(formik.errors.hinNumber)}
					helperText={formik.touched.hinNumber && formik.errors.hinNumber}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<DatePicker
					label='HIN Expiration Date'
					value={formik.values.hinExpDate}
					onChange={(date) => {
						formik.setFieldValue('hinExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('hinExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							name='hinExpDate'
							id='date-picker-dialog-3'
							type='text'
							variant='outlined'
							data-testid={HIN_EXP_DATE}
							onBlur={formik.handleBlur}
							error={formik.touched.hinExpDate && Boolean(formik.errors.hinExpDate)}
							helperText={formik.touched.hinExpDate && formik.errors.hinExpDate}
						/>
					)}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					required
					name='stateLicense'
					label='State License Number'
					type='text'
					variant='outlined'
					inputProps={{
						'data-testid': STATE_LICENSE_NUMBER
					}}
					value={formik.values.stateLicense}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.stateLicense && Boolean(formik.errors.stateLicense)}
					helperText={formik.touched.stateLicense && formik.errors.stateLicense}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<DatePicker
					label='State License Expiration Date'
					value={formik.values.stateLicenseExpDate}
					onChange={(date) => {
						formik.setFieldValue('stateLicenseExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('stateLicenseExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required
							name='stateLicenseExpDate'
							id='date-picker-dialog-4'
							type='text'
							variant='outlined'
							data-testid={STATE_LICENSE_EXP_DATE}
							onBlur={formik.handleBlur}
							error={formik.touched.stateLicenseExpDate
								&& Boolean(formik.errors.stateLicenseExpDate)}
							helperText={formik.touched.stateLicenseExpDate && formik.errors.stateLicenseExpDate}
						/>
					)}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					required
					name='stateIdNumber'
					label='State ID Tax Number'
					type='text'
					variant='outlined'
					value={formik.values.stateIdNumber}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.stateIdNumber && Boolean(formik.errors.stateIdNumber)}
					helperText={formik.touched.stateIdNumber && formik.errors.stateIdNumber}
					InputProps={{
						startAdornment: (
							<Tooltip
								PopperProps={{ style: { zIndex: 100000, position: 'absolute' } }}
								placement='top'
								title={
									<Typography>
										The pharmacy can find their State Tax ID on their state tax return.
									</Typography>
								}
							>
								<InfoIcon fontSize='small' sx={{ color: '#757575' }} />
							</Tooltip>
						),
					}}
					inputProps={{
						'data-testid': STATE_ID_TAX_NUMBER
					}}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<DatePicker
					label='State ID Tax # Expiration Date'
					value={formik.values.stateIdExpDate}
					onChange={(date) => {
						formik.setFieldValue('stateIdExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('stateIdExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							name='stateIdExpDate'
							id='date-picker-dialog-5'
							type='text'
							variant='outlined'
							onBlur={formik.handleBlur}
							data-testid={STATE_ID_TAX_EXP_DATE}
							error={formik.touched.stateIdExpDate && Boolean(formik.errors.stateIdExpDate)}
							helperText={formik.touched.stateIdExpDate && formik.errors.stateIdExpDate}
						/>
					)}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					required
					name='federalTaxNumber'
					label='Federal Tax Number'
					type='text'
					variant='outlined'
					value={formik.values.federalTaxNumber}
					inputProps={{
						'data-testid': FEDERAL_TAX_NUMBER
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.federalTaxNumber && Boolean(formik.errors.federalTaxNumber)}
					helperText={formik.touched.federalTaxNumber && formik.errors.federalTaxNumber}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<DatePicker
					label='Federal Tax Expiration Date'
					value={formik.values.federalTaxExpDate}
					onChange={(date) => {
						formik.setFieldValue('federalTaxExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('federalTaxExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							name='federalTaxExpDate'
							data-testid={FEDERAL_TAX_NUMBER_EXP_DATE}
							id='date-picker-dialog-6'
							type='text'
							variant='outlined'
							onBlur={formik.handleBlur}
							error={formik.touched.federalTaxExpDate && Boolean(formik.errors.federalTaxExpDate)}
							helperText={formik.touched.federalTaxExpDate && formik.errors.federalTaxExpDate}
						/>
					)}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='stateSellersPermit'
					label="State Seller's Permit"
					type='text'
					variant='outlined'
					value={formik.values.stateSellersPermit}
					inputProps={{
						'data-testid': STATE_SELLER_PERMIT_NUMBER,
					}}
					required
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.stateSellersPermit && Boolean(formik.errors.stateSellersPermit)}
					helperText={formik.touched.stateSellersPermit && formik.errors.stateSellersPermit}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='dunsNumber'
					label='DUNS Number'
					type='text'
					variant='outlined'
					value={formik.values.dunsNumber}
					inputProps={{
						'data-testid': DUNS_NUMBER
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.dunsNumber && Boolean(formik.errors.dunsNumber)}
					helperText={formik.touched.dunsNumber && formik.errors.dunsNumber}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='medicarePartB'
					label='Medicare Part B # / PTAN'
					type='text'
					variant='outlined'
					value={formik.values.medicarePartB}
					inputProps={{
						'data-testid': MEDICARE_PART_B_NUMBER,
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.medicarePartB && Boolean(formik.errors.medicarePartB)}
					helperText={formik.touched.medicarePartB && formik.errors.medicarePartB}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					required
					name='gln'
					label='GLN'
					type='text'
					variant='outlined'
					value={formik.values.gln}
					inputProps={{
						'data-testid': GLN,
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.gln && Boolean(formik.errors.gln)}
					helperText={formik.touched.gln && formik.errors.gln}
					InputProps={{
						startAdornment: (
							<Tooltip
								PopperProps={{ style: { zIndex: 100000, position: 'absolute' } }}
								placement='top'
								title={
									<Typography>
										You can find your 13-digit GLN on an invoice from your primary wholesaler.<br />
										<Link
											underline='none'
											target='_blank'
											rel='noreferrer'
											href='https://pharmacymarketplace.com/knowledge/how-do-i-find-my-gln'
										>
											Learn more
										</Link>
									</Typography>
								}
							>
								<InfoIcon fontSize='small' sx={{ color: '#757575' }} />
							</Tooltip>
						),
					}}
				/>
			</Grid>
			<Grid item md={2.4} xs={12}>
				<TextField
					fullWidth
					name='glnOther'
					label='GLN Other'
					type='text'
					variant='outlined'
					value={formik.values.glnOther}
					inputProps={{
						'data-testid': GLN_OTHER,
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.glnOther && Boolean(formik.errors.glnOther)}
					helperText={formik.touched.glnOther && formik.errors.glnOther}
				/>
			</Grid>
		</Grid>
	);
});
