import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const ListItem = memo(({ number, text, }) => {
    const styles = StyleSheet.create({
        item: {
            flexDirection: 'row',
            marginBottom: 1.8,
        },
        bulletPoint: {
            width: 10,
            fontSize: 9,
        },
        itemContent: {
            flex: 1,
            fontSize: 9,
            marginLeft: 10,
        },
    });
    return (_jsxs(View, { style: styles.item, children: [_jsxs(Text, { style: styles.bulletPoint, children: [number, "."] }), _jsx(Text, { style: styles.itemContent, children: text })] }));
});
;
export const LetterListItem = memo(({ indent = false, letter, text, }) => {
    const styles = StyleSheet.create({
        bulletPoint: {
            width: 10,
            fontSize: 9,
        },
        itemContent: {
            flex: 1,
            fontSize: 9,
            marginLeft: 10,
        },
        letterItem: {
            flexDirection: 'row',
            marginBottom: 2,
            marginLeft: 30,
        },
        letterItemIndent: {
            flexDirection: 'row',
            marginBottom: 2,
            marginLeft: 50,
        },
    });
    return (_jsxs(View, { style: indent ? styles.letterItemIndent : styles.letterItem, children: [_jsx(Text, { style: styles.bulletPoint, children: letter }), _jsx(Text, { style: styles.itemContent, children: text })] }));
});
