import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import { Grid } from '@mui/material';
import { secondaryWholesalers } from '@pm/shared';
import { CreditCardInput } from '../../../../../../../../components';
import { PharmacyPostData } from '../../../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const CreditCard: FC<Props> = memo(({ formik }) => {
	const {
		handleBlur, handleChange, setFieldValue, values,
	} = formik;
	const { payments = {} } = values as any;
	const ccIsSelected = secondaryWholesalers.some((wh) => {
		const paymentMethod = get(payments, `${wh}.paymentMethod`, 'ACH');
		return paymentMethod === 'Credit Card';
	});

	if (!ccIsSelected) {
		return null;
	}

	return (
		<Grid
			item
			xs={12}
			container
			alignItems='center'
			justifyContent='flex-start'
		>
			<CreditCardInput
				handleBlur={handleBlur}
				handleChange={handleChange}
				setFieldValue={setFieldValue}
				cardNumber={payments.cardNumber}
				cardType={payments.cardType}
				cardExpDate={payments.cardExpDate}
				cardCvc={payments.cardCvc}
				cardZip={payments.cardZip}
			/>
		</Grid>
	);
});
