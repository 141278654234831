import { FC, ReactNode, memo } from 'react';
import clsx from 'clsx';
import { Root, classes } from './CardBody.styles';

interface Props {
	className?: string;
	children: ReactNode;
};

export const CardBody: FC<Props> = memo(({
	className, children, ...rest
}) => {
	const cardBodyClasses = clsx(className, classes.cardBody);
	return (
		<Root className={cardBodyClasses} {...rest}>
			{children}
		</Root>
	);
});
