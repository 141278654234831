import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import {
	Checkbox, FormControlLabel, Grid, TextField,
} from '@mui/material';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const APInformation: FC<Props> = memo(({ formik }) => {
	const AP_IS_SAME_AS_OWNER = 'ap-is-same-as-owner';
	const AP_FIRST_NAME = 'ap-first-name';
	const AP_LAST_NAME = 'ap-last-name';
	const AP_EMAIL = 'ap-email';
	const AP_PHONE = 'ap-phone';

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const { ap: apErrors = {} } = errors;
	const { ap: apTouched = {} } = touched;
	const { apInfoSameAsOwner, email } = values;
	const owner = values?.owner ?? {} as PharmacyPostData['owner'];
	const ap = values?.ap ?? {} as PharmacyPostData['ap'];

	const updatePICFields = (useOwnerInfo: boolean) => {
		const keys = ['ap.firstName', 'ap.lastName', 'ap.phone', 'ap.email'];
		const vals = useOwnerInfo
			? [owner.firstName, owner.lastName, owner.phone, email]
			: [ap.firstName, ap.lastName, ap.phone, ap.email];
		let index = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			formik.setFieldValue(keys[index], vals[index]);
			formik.setFieldError(key, undefined);
			index += 1;
		}
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={apInfoSameAsOwner}
							onChange={(e, checked) => {
								handleChange(e);
								updatePICFields(checked);
							}}
							data-testid={AP_IS_SAME_AS_OWNER}
							name='apInfoSameAsOwner'
							color='primary'
						/>
					}
					label='AP&apos;s contact information is the same as owner&apos;s'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='ap.firstName'
					label='A/P First Name'
					type='text'
					variant='outlined'
					value={ap.firstName}
					inputProps={{
						'data-testid': AP_FIRST_NAME
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={apTouched.firstName && Boolean(apErrors.firstName)}
					helperText={apTouched.firstName && apErrors.firstName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='ap.lastName'
					label='A/P Last Name'
					type='text'
					variant='outlined'
					value={ap.lastName}
					inputProps={{
						'data-testid': AP_LAST_NAME
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={apTouched.lastName && Boolean(apErrors.lastName)}
					helperText={apTouched.lastName && apErrors.lastName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='ap.email'
					label='A/P Email'
					type='email'
					variant='outlined'
					value={ap.email}
					inputProps={{
						'data-testid': AP_EMAIL
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={apTouched.email && Boolean(apErrors.email)}
					helperText={apTouched.email && apErrors.email}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='ap.phone'
					label='A/P Phone'
					type='text'
					variant='outlined'
					value={ap.phone}
					inputProps={{
						'data-testid': AP_PHONE
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={apTouched.phone && Boolean(apErrors.phone)}
					helperText={apTouched.phone && apErrors.phone}
				/>
			</Grid>
		</Grid>
	);
});
