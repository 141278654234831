import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Image, View, StyleSheet } from '@react-pdf/renderer';
;
export const HeaderImage = memo(({ imgSrc, }) => {
    const styles = StyleSheet.create({
        container: {
            paddingTop: 20,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        image: {
            width: 200,
        },
    });
    return (_jsx(View, { style: styles.container, children: _jsx(Image, { style: styles.image, src: imgSrc }) }));
});
