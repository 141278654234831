import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, StyleSheet } from '@react-pdf/renderer';
import { Header, CreditAppTable, TermsAndConditions } from './components';
;
export const DocumentNineteen = memo((props) => {
    const styles = StyleSheet.create({
        container: {
            padding: 10,
        },
    });
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(Header, {}), _jsx(CreditAppTable, { ...props }), _jsx(TermsAndConditions, { ...props })] }) }));
});
