import { styled } from '@mui/material/styles';

const PREFIX = 'CardBody';
export const classes = {
	cardBody: `${PREFIX}-cardBody`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.cardBody}`]: {
		padding: theme.spacing(1),
		flex: '1 1 auto',
	},
}));
