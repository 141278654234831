import { FC, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import {
	onboardIdState, onboardStepState, pharmacyIdState,
	registrationFormState, fingerprintEpochPostfix,
} from '../../atoms';
import { PreparingView } from '../../components';
import ApiClient from '../../services/ApiClient';
import { splitUserName } from '../../util';
import { Nav, Steps, Footer } from './components';
import { StyledSwitch, classes } from './App.styles';

const App: FC = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const setPharmacyId = useSetRecoilState(pharmacyIdState);
	const setOnboardId = useSetRecoilState(onboardIdState);
	const setOnboardStep = useSetRecoilState(onboardStepState);
	const setForm = useSetRecoilState(registrationFormState);
	const [fingerprintPostfix] = useRecoilState(fingerprintEpochPostfix);
	const { enqueueSnackbar } = useSnackbar();
	const queryParameters = new URLSearchParams(window.location.search);
	const agent = queryParameters.get('agent');

	if (agent) {
		const decodedAgent = Buffer.from(agent, 'base64').toString();
		localStorage.setItem('agent', decodedAgent);
	}

	const seedFormFields = ({
		pharmacyName: name, npiNumber, userName, position, coupon
	}: { pharmacyName: any, npiNumber: any, userName: any; position: any; coupon: any }) => {
		const [firstName, lastName] = splitUserName(userName);
		setForm((form) => ({
			...form, firstName, lastName, title: position, name, npiNumber, coupon,
		}));
	};

	const startSession = async () => {
		setLoading(true);
		try {
			const client = await ApiClient.getInstance();
			const onboard = await client.checkIfOnboardingToken();
			const { _id, pharmacyId, step, ...rest } = onboard;

			if (pharmacyId) {
				setPharmacyId(pharmacyId);
			}
			seedFormFields({
				pharmacyName: rest.pharmacyName,
				npiNumber: rest.npiNumber,
				userName: rest.userName,
				position: rest.position,
				coupon: rest.coupon,
			});
			setOnboardId(_id);

			if (step === 2) {
				setOnboardStep(3);
			} else {
				setOnboardStep(step);
			}
			setLoading(false);
		} catch (error) {
			Sentry.captureException(error);
			enqueueSnackbar(
				'Something went wrong while preparing your session. Please refresh your browser',
				{ variant: 'error' });
		}
	};

	useEffect(() => {
		startSession();
	}, [fingerprintPostfix]);

	if (loading) {
		return <PreparingView bgImgIndex={1} title='Securely starting your session' />;
	}

	return (
		<StyledSwitch>
			<Route path='*'>
				<div className={classes.fill}>
					<Nav />
					<Steps />
				</div>
				<Footer />
			</Route>
		</StyledSwitch>
	);
};

export default App;
