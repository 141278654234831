export const secondaryWholesalers = ['bluepax_secondary',
    // Secondary Insert
    'twosrx_secondary',
    'capital_secondary',
    'rx_supply_secondary',
    'bloodworth_secondary',
    'keysource_secondary',
    'toprx_secondary',
    'auburn_secondary',
    'ipc_secondary'];
export var WholesalerKeys;
(function (WholesalerKeys) {
    WholesalerKeys["BLUPAX"] = "bluepax_secondary";
    WholesalerKeys["CAPITAL"] = "capital_secondary";
    WholesalerKeys["RXSUPPLY"] = "rx_supply_secondary";
    WholesalerKeys["BLOODWORTH"] = "bloodworth_secondary";
    WholesalerKeys["KEYSOURCE"] = "keysource_secondary";
    WholesalerKeys["TOPRX"] = "toprx_secondary";
    WholesalerKeys["AUBURN"] = "auburn_secondary";
    WholesalerKeys["IPC"] = "ipc_secondary";
    WholesalerKeys["TWOSRX"] = "twosrx_secondary";
    WholesalerKeys["MCKESSON"] = "mckesson_primary";
    WholesalerKeys["CARDINAL"] = "cardinal_primary";
    WholesalerKeys["ABC"] = "amerisource_primary";
})(WholesalerKeys || (WholesalerKeys = {}));
;
export const primaryWholesalers = ['mckesson_primary',
    // Primary Insert
    'cardinal_primary',
    'amerisource_primary'];
export const wholesalers = [...secondaryWholesalers, ...primaryWholesalers];
