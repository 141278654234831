import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import CapitalDrug from '../../../../../../images/capital-drug-logo.png';
;
export const PageOne = memo(({ pharmacy, traderInfo, user, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        entryContainer: {
            flexDirection: 'column',
            border: 1,
            marginBottom: 10,
        },
        entry: {
            paddingLeft: 20,
            paddingBottom: 12,
            fontSize: 10,
        },
        nestedEntry: {
            paddingLeft: 31,
            paddingBottom: 12,
            fontSize: 10,
        },
        entryFooter: {
            fontSize: 7,
            padding: 8,
        },
        title: {
            paddingBottom: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 10,
            paddingLeft: 3,
        },
    });
    const entries = useMemo(() => {
        const { ap, pp, pic, owner, primary, } = traderInfo;
        return [
            { text: `1: Type of Ownership:  ${owner.ownershipType}`, style: styles.entry, },
            { text: `2: Legal Corporate Name:  ${pharmacy.legalName}`, style: styles.entry, },
            { text: `3: Federal Tax I.D. #:  ${pharmacy.federalTaxNumber}     State I.D. #:  ${pharmacy.stateIdNumber}     HIN #:  ${pharmacy.hinNumber || '_________________'}`, style: styles.entry, },
            { text: `State Pharmacy License #:  ${pharmacy.stateLicense}     DEA License #:  ${pharmacy.deaNumber}`, style: styles.nestedEntry, },
            { text: 'State Controlled Substance License #: ______________', style: styles.nestedEntry, },
            { text: `4: Shipping Address:  ${pharmacy.shippingAddress || pharmacy.address}, ${pharmacy.shippingCity || pharmacy.city}, ${pharmacy.shippingState || pharmacy.state}, ${pharmacy.shippingZip || pharmacy.zipCode}`, style: styles.entry, },
            { text: `5: Accounts Payable Contact:  ${ap.firstName} ${ap.lastName}`, style: styles.entry, },
            { text: `Phone #:  ${ap.phone}     Email:  ${ap.email}`, style: styles.nestedEntry, },
            { text: `6: Primary Business Contact:  ${user.firstName} ${user.lastName}`, style: styles.entry, },
            { text: `Title:  ${user.title || '____________'}     Email:  ${user.email}`, style: styles.nestedEntry, },
            { text: `Business Telephone:  ${pharmacy.phone}     Fax #:  ${pharmacy.fax || '_____________'}`, style: styles.nestedEntry, },
            { text: `7: Primary Purchasing Contact:  ${pp.firstName} ${pp.lastName}`, style: styles.entry, },
            { text: `Title:  ${pp.title}     Phone #:  ${pp.phone}     Email:  ${pp.email}`, style: styles.nestedEntry, },
            { text: `Business Phone #:  ${pharmacy.phone}     Fax #:  ${pharmacy.fax || '_____________'}`, style: styles.nestedEntry, },
            { text: `8: Pharmacist In Charge:  ${pic.firstName} ${pic.lastName}     PIC License #:  ${pic.license}`, style: styles.entry, },
            { text: '9: Pharmaceutical Wholesalers:', style: styles.entry, },
            { text: `Primary Wholesaler:  ${primary.name}`, style: styles.nestedEntry, },
            { text: `Primary Wholesaler Address:  ${primary.address}, ${primary.city}, ${primary.state}, ${primary.zip}`, style: styles.nestedEntry, },
            { text: 'Credit Limit: _____________  Terms: _______________', style: styles.nestedEntry, },
            { text: 'Secondary Wholesaler: ______________', style: styles.nestedEntry, },
            { text: 'Secondary Wholesaler Address: ______________', style: styles.nestedEntry, },
            { text: 'Credit Limit: _____________  Terms: _______________', style: styles.nestedEntry, },
        ];
    }, [pharmacy]);
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsx(PageHeader, { imgSrc: CapitalDrug }), _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.title, children: "SECTION I: GENERAL INFORMATION" }), _jsxs(Text, { style: styles.entry, children: ["1: Business / Trade Name:  ", pharmacy.name] }), _jsxs(Text, { style: styles.entry, children: ["2: Business / Trade Address:  ", pharmacy.address, ", ", pharmacy.city, ", ", pharmacy.state, ", ", pharmacy.zipCode] }), _jsx(Text, { style: styles.entryFooter, children: "(Additional stores under common ownership and same legal entity must be identified and listed with business name and address, attach as Exhibit A. Any store with a different ownership or legal entity must complete and execute a separate credit application and agreement.)" })] }), _jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.title, children: "SECTION II: FINACIAL INFORMATION" }), entries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index)))] }), _jsx(PageFooter, {})] })] }));
});
