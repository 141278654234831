import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Svg, Rect, Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const CardType = memo(({ cardType, }) => {
    const row = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    };
    const squareHeight = 6;
    const styles = StyleSheet.create({
        container: {
            ...row,
            marginBottom: 8,
        },
        row,
        svg: {
            height: squareHeight,
            width: squareHeight,
        },
        title: {
            fontSize: 11,
            paddingRight: 12,
        },
        cardType: {
            fontSize: 11,
            padding: '0px 12px 0px 3px',
        },
    });
    const Checkboxes = useMemo(() => {
        const types = ['MasterCard', 'Visa', 'American Express', 'Discover Card'];
        const selectedIndex = types.findIndex((type) => ((cardType && type.toLowerCase() === cardType.toLowerCase()) ||
            (type === types[3] && cardType === 'Discover')));
        const rectProps = {
            width: squareHeight,
            height: squareHeight,
            stroke: '#000',
        };
        return types.map((type, index) => (_jsxs(View, { style: styles.row, children: [_jsx(Svg, { style: styles.svg, children: _jsx(Rect, { ...rectProps, fill: index === selectedIndex ? '#000' : '#fff' }) }), _jsx(Text, { style: styles.cardType, children: type })] }, type)));
    }, []);
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.title, children: "Credit Card Type:" }), Checkboxes] }));
});
