import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from '../DocumentSixteen/components/ImageHeader';
import { Checkbox } from './components';
;
export const DocumentEighteen = memo(({ pharmacy, traderInfo, }) => {
    const table = {
        display: 'table',
        width: 'auto',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        margin: '10px 0px',
    };
    const tableRow = {
        borderColor: '#000',
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        padding: '4px 8px',
    };
    const rowText = { fontSize: 13, };
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        table,
        firstTable: {
            ...table,
            margin: '24px 0px 10px',
        },
        tableHeader: {
            borderColor: '#000',
            backgroundColor: '#d6e3bc',
            borderWidth: 1,
            width: '100%',
            padding: 8,
        },
        headerText: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 14,
            textAlign: 'left',
        },
        tableRow,
        tableRowEven: {
            ...tableRow,
            justifyContent: 'space-evenly',
        },
        rowText,
        rowText2: {
            ...rowText,
            marginTop: 12,
        },
        rowText3: {
            ...rowText,
            marginRight: 32,
        },
        rowTextSm: {
            fontSize: 11,
        },
    });
    const tableOneRows = useMemo(() => [
        { label: 'NEW Setup', checked: true, },
        { label: 'CHANGE Bank Information', checked: false, },
        { label: 'CANCEL Draft', checked: false, },
    ], []);
    const { banking, ap, owner, } = traderInfo;
    const ownerName = `${owner.firstName} ${owner.lastName}`;
    const isChecking = banking.accountType.toLowerCase().indexOf('checking') > -1;
    const monthlyDraft = banking.accountToBeDebited === 'Monthly';
    const tableTwoRows = [
        `Business Name:  ${pharmacy.name}`,
        `Address:  ${pharmacy.address}, ${pharmacy.city},  ${pharmacy.state} ${pharmacy.zipCode}`,
        `Accounts Payable Contact Name:  ${ap.firstName} ${ap.lastName}`,
        `Phone Number:  ${ap.phone}`,
        `Email Address:  ${ap.email}`,
    ];
    const date = dayjs().format('MM/DD/YYYY');
    const tableThreeRows = [
        { text: `Bank Name:  ${banking.bankName}`, multiple: false, },
        { text: `Name on Account:  ${ownerName}`, multiple: false, },
        { text: `Bank Account Number:  ${banking.accountNumber}`, multiple: false, },
        {
            text: `9 Digit Routing Number/Transit Number (ABA):  ${banking.routingNumber}`,
            multiple: false,
        },
        {
            title: 'Type of Account:',
            firstLabel: 'Checking',
            firstChecked: isChecking,
            secondLabel: 'Savings',
            secondChecked: !isChecking,
            multiple: true,
        },
        {
            title: 'Draft Frequency:',
            firstLabel: 'Weekly Invoices',
            firstChecked: !monthlyDraft,
            secondLabel: 'Monthly Statement',
            secondChecked: monthlyDraft,
            multiple: true,
        },
        { text: `Effective Date:  ${date}`, },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, { showContact: false }), _jsxs(View, { style: styles.firstTable, children: [_jsx(View, { style: styles.tableHeader, children: _jsx(Text, { style: styles.headerText, children: "Please Check One:" }) }), _jsx(View, { style: styles.tableRowEven, children: tableOneRows.map((row, index) => _jsx(Checkbox, { ...row }, index)) })] }), _jsxs(View, { style: styles.table, children: [_jsx(View, { style: styles.tableHeader, children: _jsx(Text, { style: styles.headerText, children: "Customer Information" }) }), tableTwoRows.map((row, index) => (_jsx(View, { style: styles.tableRow, children: _jsx(Text, { style: styles.rowText, children: row }) }, index)))] }), _jsxs(View, { style: styles.table, children: [_jsx(View, { style: styles.tableHeader, children: _jsx(Text, { style: styles.headerText, children: "Financial Institution Information" }) }), tableThreeRows.map((row, index) => row.multiple
                            ? _jsxs(View, { style: styles.tableRow, children: [_jsx(Text, { style: styles.rowText3, children: row.title }), _jsx(Checkbox, { checked: row.firstChecked, label: row.firstLabel, margin: '0px 32px 0px 0px' }), _jsx(Checkbox, { checked: row.secondChecked, label: row.secondLabel, margin: '0px 32px 0px 0px' })] }, index)
                            : _jsx(View, { style: styles.tableRow, children: _jsx(Text, { style: styles.rowText, children: row.text }) }, index))] }), _jsx(View, { style: styles.table, children: _jsx(View, { style: styles.tableHeader, children: _jsxs(Text, { style: styles.rowText, children: [_jsx(Text, { style: styles.headerText, children: "Approvals/Authorizations" }), " - I certify that the information on this form is correct, and I hereby authorize Bloodworth Wholesale Drugs accounts receivable department to electronically draft funds from this account for payment on account. It is my responsibility to notify the receivables department of any discrepancies of the amount drafted. I understand that I must notify BWD receivables immediately of any changes in banking information. I understand that this authorization will remain in full effect until I provide BWD with written notification requesting changes or cancellations."] }) }) }), _jsxs(View, { children: [_jsxs(Text, { style: styles.rowText2, children: ["Print Name:  ", ownerName] }), _jsx(Text, { style: styles.rowText2, children: "Signature:" }), _jsxs(Text, { style: styles.rowText2, children: ["Date:  ", date] })] })] }) }));
});
