import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const TermsListItem = memo(({ leftText, rightText, }) => {
    const styles = StyleSheet.create({
        termsContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: 7,
        },
        termsLeft: {
            marginRight: 5,
            width: '20%',
        },
        termsRight: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '75%',
        },
        termsText: {
            paddingLeft: 0,
            fontSize: 8,
        },
    });
    return (_jsxs(View, { style: styles.termsContainer, children: [_jsx(View, { style: styles.termsLeft, children: _jsx(Text, { style: styles.termsText, children: leftText }) }), _jsx(View, { style: styles.termsRight, children: _jsx(Text, { style: styles.termsText, children: rightText }) })] }));
});
