import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import Bloodworth from '../../../../../../images/bloodworth-logo.png';
;
export const ImageHeader = memo(({ showContact = true, }) => {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
        },
        image: {
            width: 200,
        },
        contact: {
            marginBottom: 5,
        },
        title: {
            alignSelf: 'flex-end',
            marginTop: 0,
            fontSize: 18,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'right',
        },
        contactText: {
            alignSelf: 'flex-end',
            fontSize: 12,
            paddingLeft: 20,
            textAlign: 'right',
        },
        ach: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        achTitle: {
            fontSize: 14,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            marginTop: 5,
        },
        achSubtitle: {
            fontSize: 14,
        },
    });
    const items = showContact
        ? [
            { text: 'New Customer Application', style: styles.title, },
            { text: 'P.O. BOX 1849 TIFTON, GA 31793', style: styles.contactText, },
            { text: 'PHONE: 800.841.8700 • Fax: 229.388.8388', style: styles.contactText, },
            { text: 'www.bloodworthwholesale.com', style: styles.contactText, },
        ]
        : [];
    return (_jsxs(View, { style: styles.container, children: [_jsx(View, { children: _jsx(Image, { style: styles.image, src: Bloodworth }) }), showContact &&
                _jsx(View, { style: styles.contact, children: items.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), !showContact &&
                _jsxs(View, { style: styles.ach, children: [_jsx(Text, { style: styles.achSubtitle, children: "Customer Account # _________________" }), _jsx(Text, { style: styles.achTitle, children: "ACH Authorization Form" })] })] }));
});
