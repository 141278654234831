import { styled } from '@mui/material/styles';

const PREFIX = 'FormContent';
export const classes = {
	cardBody: `${PREFIX}-cardBody`,
};
export const Root = styled('form')(({ theme }) => ({
	[`& .${classes.cardBody}`]: {
		margin: theme.spacing(1, 0, 4),
	},
}));
