import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const Initials = memo(({ paddingTop = 28, pageNum, }) => {
    const styles = StyleSheet.create({
        initialContainer: {
            paddingTop,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },
        initialHeader: {
            fontSize: 11,
        },
    });
    return (_jsx(View, { style: styles.initialContainer, children: _jsxs(Text, { style: styles.initialHeader, children: ["Initial ________ Page ", pageNum] }) }));
});
