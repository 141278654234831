import { styled } from '@mui/material/styles';
const PREFIX = 'Loader';
export const classes = {
    loaderWrapper: `${PREFIX}-loaderWrapper`,
};
export const Root = styled('div')(({ theme }) => ({
    [`&.${classes.loaderWrapper}`]: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
}));
