import {
	FC, ReactNode, memo,
} from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import {
	onboardIdState, onboardStepState, pharmacyIdState,
	registrationFormStepState, registrationFormState, userIdState,
} from '../../../../../../atoms';
import { CardBody } from '../../../../../../components';
import { api } from '../../../../../../services';
import { PharmacyPostData } from '../../../../../../services/types';
import {
	FormHeader, FormFooter, PharmacyInformation,
	LicensesInformation, ShippingInformation, OwnershipInformation, PICInformation,
	PPInformation, PWInformation, APInformation, BankingInformation,
	PaymentsInformation, DesignateSigner, TradeReferenceInformation,
} from '..';
import { getRegistrationSchema } from './RegistrationSchema';
import { Root, classes } from './FormContent.styles';
import AdditionalQuestions from '../AdditionalQuestions/AdditionalQuestions';
import segmentAction from '../../../../../../util/segmentAction';

export const FormContent: FC = memo(() => {
	const { enqueueSnackbar } = useSnackbar();
	const [form] = useRecoilState(registrationFormState);
	const [formStep] = useRecoilState(registrationFormStepState);
	const [onboardId] = useRecoilState(onboardIdState);
	const setUserId = useSetRecoilState(userIdState);
	const setOnboardStep = useSetRecoilState(onboardStepState);
	const setPharmacyId = useSetRecoilState(pharmacyIdState);
	const resetRegistrationForm = useResetRecoilState(registrationFormState);

	const onSubmit = async (
		fields: PharmacyPostData,
		helpers: FormikHelpers<PharmacyPostData>,
	) => {
		helpers.setSubmitting(true);
		try {
			const isValid = await helpers.validateForm();
			if (!isEmpty(isValid)) {
				return;
			}
			const { pharmacy: { _id: pharmacyId }, user } = await api.post
				.pharmacy({ ...fields, onboardId: onboardId as string });
			segmentAction(user._id, { email: user.email, }, 'identify');
			setUserId(user._id);

			setPharmacyId(pharmacyId);
			helpers.setSubmitting(false);
			setOnboardStep((step) => step + 1);
			resetRegistrationForm();
			enqueueSnackbar('Registration form successfully submitted', { variant: 'success' });
		} catch (error: any) {
			helpers.setSubmitting(false);
			const message = error.status === 409
				? `The pharmacy ${error.data?.keyValue ?? ''} has already been taken. Please use a different email address`
				: 'Something went wrong during form submission. Please try again';
			enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const validationSchema = getRegistrationSchema();
	const formik = useFormik<PharmacyPostData>({
		onSubmit,
		initialValues: form,
		initialTouched: {},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	});

	const formSteps = [
		<PharmacyInformation key='pharmacy' formik={formik} />,
		<LicensesInformation key='licenses' formik={formik} />,
		<ShippingInformation key='shipping' formik={formik} />,
		<OwnershipInformation key='ownership' formik={formik} />,
		<PICInformation key='pic' formik={formik} />,
		<PPInformation key='pp' formik={formik} />,
		<PWInformation key='pw' formik={formik} />,
		<TradeReferenceInformation key='tradeRefernces' formik={formik} />,
		<APInformation key='ap' formik={formik} />,
		<BankingInformation key='banking' formik={formik} />,
		<PaymentsInformation key='payments' formik={formik} />,
		<AdditionalQuestions key='additional' formik={formik} />,
		<DesignateSigner key='signer' formik={formik} />,
	];
	const getFormStepContent = (): ReactNode => formSteps[formStep - 2];

	return (
		<Root onSubmit={formik.handleSubmit}>
			<FormHeader formik={formik} />
			<CardBody className={classes.cardBody}>
				{getFormStepContent()}
			</CardBody>
			<FormFooter
				formik={formik}
			/>
		</Root>
	);
});
