import { styled } from '@mui/material/styles';

const PREFIX = 'CardHeader';
export const classes = {
	cardHeader: `${PREFIX}-cardHeader`,
	cardHeaderPlain: `${PREFIX}-cardHeaderPlain`,
	warningCardHeader: `${PREFIX}-warningCardHeader`,
	goldCardHeader: `${PREFIX}-goldCardHeader`,
	successCardHeader: `${PREFIX}-successCardHeader`,
	dangerCardHeader: `${PREFIX}-dangerCardHeader`,
	infoCardHeader: `${PREFIX}-infoCardHeader`,
	primaryCardHeader: `${PREFIX}-primaryCardHeader`,
	roseCardHeader: `${PREFIX}-roseCardHeader`,
};
export const Root = styled('div')(() => ({
	[`&.${classes.cardHeader}`]: {
		borderRadius: '3px',
		padding: '1rem 15px',
		marginLeft: '15px',
		marginRight: '15px',
		marginTop: '-30px',
		border: '0',
		marginBottom: '0',
	},
	[`&.${classes.cardHeaderPlain}`]: {
		marginLeft: '0px',
		marginRight: '0px',
	},
	[`&.${classes.warningCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
		boxShadow:
			'0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
	},
	[`&.${classes.goldCardHeader}`]: {
		color: '#333',
		background: 'linear-gradient(60deg, #FFE55C, #FFD700)',
		boxShadow:
			'0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
	},
	[`&.${classes.successCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #66bb6a, #43a047)',
		boxShadow:
			'0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
	},
	[`&.${classes.dangerCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #ef5350, #e53935)',
		boxShadow:
			'0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
	},
	[`&.${classes.infoCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #26c6da, #00acc1)',
		boxShadow:
			'0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)',
	},
	[`&.${classes.primaryCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
		boxShadow:
			'0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
	},
	[`&.${classes.roseCardHeader}`]: {
		color: '#fff',
		background: 'linear-gradient(60deg, #ec407a, #d81b60)',
		boxShadow:
			'0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
	},
}));
