export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["PVAC"] = "PVAC Navigator";
    SubscriptionType["BASIC"] = "Basic Shopper";
    SubscriptionType["BEST"] = "Best Shopper";
    SubscriptionType["PREMIUM"] = "Premium Shopper";
})(SubscriptionType || (SubscriptionType = {}));
;
export const getPriceForSubscriptionType = (subType) => {
    switch (subType) {
        case SubscriptionType.PREMIUM:
            return 200;
        case SubscriptionType.PVAC:
            return 300;
        case SubscriptionType.BEST:
            return 300;
        default:
            return 0;
    }
};
export default SubscriptionType;
