import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Stack, Button } from '@mui/material';
import Add from '@mui/icons-material/Add';
;
const EmptyRebates = ({ sx = {}, action, callback, }) => {
    const onClick = (e) => {
        e.stopPropagation();
        action(true);
        if (callback) {
            callback();
        }
    };
    return (_jsx(Stack, { direction: 'row', justifyContent: 'center', children: _jsx(Button, { sx: { mt: 3, mb: 3, ...sx }, startIcon: _jsx(Add, {}), variant: 'outlined', color: 'success', onClick: onClick, children: "Add Rebate Tiers" }) }));
};
export default memo(EmptyRebates);
