import { Theme, createTheme } from '@mui/material/styles';
import components from './components';
import palette from './palette';

const theme: Theme = createTheme({
	palette,
	components,
	dimensions: {
		navHeight: 118,
	},
});

declare module '@mui/material/styles' {
	interface Theme {
		dimensions: {
			navHeight: number;
		};
	}
	interface ThemeOptions {
		dimensions?: {
			navHeight?: number;
		};
	}
}

export default theme;
