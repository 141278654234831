import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import NiceModal from '@ebay/nice-modal-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import * as Sentry from '@sentry/react';
import './assets/css/index.css';
import theme from './theme';
import { App } from './views';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
	environment: import.meta.env.MODE
});
const version = import.meta.env.VITE_VERSION;

const render = (Component: any) => {
	(window as any).VERSION = version;
	const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
	root.render(<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<RecoilRoot>
					<CssBaseline />
					<NiceModal.Provider>
						<SnackbarProvider maxSnack={2}>
							<FlagsmithProvider
								options={{
									environmentID: import.meta.env.VITE_FLAGSMITH_ENV_ID ?? '',
								}}
								flagsmith={flagsmith}
							>
								<Router>
									<Component />
								</Router>
							</FlagsmithProvider>
						</SnackbarProvider>
					</NiceModal.Provider>
				</RecoilRoot>
			</LocalizationProvider>
		</ThemeProvider>
	</StyledEngineProvider>);
};

export const renderRoot = () => {
	render(App);
};
