import { styled } from '@mui/material/styles';

const PREFIX = 'FormContent';
export const classes = {
	btn: `${PREFIX}-btn`,
	divider: `${PREFIX}-divider`,
};
export const Root = styled('form')(({ theme }) => ({
	[`& .${classes.btn}`]: {
		marginLeft: 'auto !important',
		marginRight: theme.spacing(1),
	},
	[`& .${classes.divider}`]: {
		marginBottom: theme.spacing(1),
	},
}));
