import ls from 'localstorage-slim';
import { omit } from 'lodash-es';

export const localStorageEffect = <T>(key: string) => ({ setSelf, onSet }: { setSelf: any, onSet: any }) => {
	if (key === 'password') {
		return;
	}
	const savedValue = ls.get(key) as string | null;
	if (savedValue != null) {
		setSelf(savedValue);
	}
	onSet((newValue: T) => {
		if (key === 'password') {
			return;
		}
		if (key === 'registrationForm') {
			const editValue = {
				...newValue,
			} as any;
			editValue.banking = omit(editValue.banking, 'accountNumber', 'routingNumber');
			editValue.owner = omit(editValue.owner, 'social');
			editValue.payments = omit(editValue.payments, 'cardNumber');
			ls.set(key, editValue);
			return;
		}
		ls.set(key, newValue);
	});
};
