import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import {
	Grid, MenuItem, TextField, Typography,
} from '@mui/material';
import { secondaryWholesalers, getWholesalerDisplayName } from '@pm/shared';
import paymentOptions from '../../../../../../../../assets/json/payment-options.json';
import debits from '../../../../../../../../assets/json/debit-types.json';
import invoices from '../../../../../../../../assets/json/invoice-types.json';
import { PharmacyPostData } from '../../../../../../../../services/types';
import { PaymentOptionsSelect } from './components';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const WholesalerPaymentOptions: FC<Props> = memo(({ formik }) => {
	const PREFERRED_INVOICING_METHOD = 'preferred-invoicing-method';
	const ACCOUNT_TO_BE_DEBITED = 'account-to-be-debited';

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const banking = values?.banking ?? {} as PharmacyPostData['banking'];
	const { banking: bankingErrors = {} } = errors;
	const { banking: bankingTouched = {} } = touched;

	const paymentMethodOptions = useMemo(() => paymentOptions.map(({ option }) => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	)), []);
	const debitOptions = useMemo(() => debits.map(({ option }) => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	)), []);
	const invoiceOptions = useMemo(() => invoices.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);
	const prompt = useMemo(() => (
		<Grid item xs={12} sx={{ mt: 2 }}>
			<Typography gutterBottom>
				Please select your preferred payment methods for each wholesaler listed below.
			</Typography>
		</Grid>
	), []);

	return (
		<Grid
			item
			xs={12}
			container
			spacing={1}
			alignItems='flex-start'
			justifyContent='space-evenly'
		>
			<Grid item md={4} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='banking.receiveInvoices'
					label='Preferred Invoicing Method'
					type='text'
					variant='outlined'
					value={banking.receiveInvoices}
					data-testid={PREFERRED_INVOICING_METHOD}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.receiveInvoices && Boolean(bankingErrors.receiveInvoices)}
					helperText={bankingTouched.receiveInvoices && bankingErrors.receiveInvoices}
				>
					{invoiceOptions}
				</TextField>
			</Grid>
			<Grid item md={4} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='banking.accountToBeDebited'
					label='Account to be debited'
					type='text'
					variant='outlined'
					value={banking.accountToBeDebited}
					data-testid={ACCOUNT_TO_BE_DEBITED}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.accountToBeDebited && Boolean(bankingErrors.accountToBeDebited)}
					helperText={(bankingTouched.accountToBeDebited && bankingErrors.accountToBeDebited)
						? bankingErrors.accountToBeDebited
						: 'This information is requested by Capital Drug.'
					}
				>
					{debitOptions}
				</TextField>
			</Grid>
			<Grid item md={4} xs={12}>
				<TextField
					fullWidth
					required={banking.accountToBeDebited === 'Other'}
					name='banking.accountToBeDebitedOther'
					label='Other details'
					type='text'
					variant='outlined'
					value={banking.accountToBeDebitedOther}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.accountToBeDebitedOther && Boolean(bankingErrors.accountToBeDebitedOther)}
					helperText={(bankingTouched.accountToBeDebitedOther && bankingErrors.accountToBeDebitedOther)
						? bankingErrors.accountToBeDebitedOther
						: 'Please specify any other details about your debited account.'
					}
				/>
			</Grid>
			{prompt}
			{secondaryWholesalers.filter((wh) => wh !== 'keysource_secondary').map((wh) => {
				const wholesalerName = getWholesalerDisplayName(wh);
				return (
					<PaymentOptionsSelect
						id={wh}
						key={wh}
						label={wholesalerName}
						formik={formik}
						options={paymentMethodOptions}
					/>
				);
			})}
		</Grid>
	);
});
