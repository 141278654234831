import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from '../ImageHeader';
;
export const PageThree = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            padding: '10px 10px 0px 10px',
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 8,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 9,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 11,
            fontSize: 12,
        },
    });
    const { owner, } = traderInfo;
    const date = dayjs().format('MM/DD/YYYY');
    const signSection1 = [
        { text: `I, ${owner.firstName} ${owner.lastName}, as the owner, attest that I have truthfully completed this form to the best of my knowledge and ability.`, style: styles.entry, },
        { text: `Owner (please print):  ${owner.firstName} ${owner.lastName}`, style: styles.entry, },
        { text: 'Owner Signature:', style: styles.entry, },
        { text: `Date:  ${date}`, style: styles.entry, },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Bloodworth Affidavit" }) }), _jsx(View, { style: styles.entryContainer, children: signSection1.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsxs(View, { style: styles.entryContainer, children: [_jsxs(Text, { style: styles.entry, children: ["This is to attest that ", pharmacy.name, " (store name) located at ", pharmacy.address, ", ", pharmacy.city, ", ", pharmacy.state, " ", pharmacy.zipCode, " (address) is not engaged in, nor has ever engaged in conducting business as an internet pharmacy supplier of controlled substances. We mail prescriptions only to our customers who are traveling, out of town or unable to physically obtain prescriptions. This location is in an area that is accessible to the public and walk-in customers are welcomed."] }), _jsxs(Text, { style: styles.entry, children: ["Signature: ______________________________  Date:  ", date] })] })] }) }));
});
