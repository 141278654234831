import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import { Grid, MenuItem, TextField } from '@mui/material';
import primaries from '../../../../../../assets/json/primaries.json';
import states from '../../../../../../assets/json/us-states.json';
import psoaTypes from '../../../../../../assets/json/psao-types.json';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const PWInformation: FC<Props> = memo(({ formik }) => {
	const PRIMARY_WHOLESALER = 'primary-wholesaer';
	const PRIMARY_ADDRESS = 'primary-address';
	const PRIMARY_CITY = 'primary-city';
	const PRIMARY_STATE = 'primary-state';
	const PRIMARY_ZIP = 'primary-zip';
	const PRIMARY_PHONE = 'primary-phone';
	const PRIMARY_PSAO = 'primary-psao';
	const MCKESSON_ACCOUNT_NUMBER = 'mckesson-account-number';

	const stateOptions = useMemo(() => states.map((option) => (
		<MenuItem key={option.abbreviation} value={option.abbreviation}>
			{option.name}
		</MenuItem>
	)), []);
	const primaryOptions = useMemo(() => primaries.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);
	const psoaOptions = useMemo(() => psoaTypes.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const primary = values?.primary ?? {} as PharmacyPostData['primary'];
	const { primary: primaryErrors = {} } = errors;
	const { primary: primaryTouched = {} } = touched;

	return (
		<Grid
			container
			spacing={2}
			alignItems='center'
			justifyContent='flex-start'
		>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='primary.name'
					label='Primary Wholesaler'
					type='text'
					variant='outlined'
					value={primary.name}
					onChange={handleChange}
					onBlur={handleBlur}
					data-testid={PRIMARY_WHOLESALER}
					error={primaryTouched.name && Boolean(primaryErrors.name)}
					helperText={primaryTouched.name && primaryErrors.name}
				>
					{primaryOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='primary.address'
					label='Primary Wholesaler Address'
					type='text'
					variant='outlined'
					value={primary.address}
					inputProps={{
						'data-testid': PRIMARY_ADDRESS
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.address && Boolean(primaryErrors.address)}
					helperText={primaryTouched.address && primaryErrors.address}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='primary.city'
					label='Primary Wholesaler City'
					type='text'
					variant='outlined'
					value={primary.city}
					data-testid={PRIMARY_CITY}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.city && Boolean(primaryErrors.city)}
					helperText={primaryTouched.city && primaryErrors.city}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='primary.state'
					label='Primary Wholesaler State'
					type='text'
					variant='outlined'
					value={primary.state}
					data-testid={PRIMARY_STATE}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.state && Boolean(primaryErrors.state)}
					helperText={primaryTouched.state && primaryErrors.state}
				>
					{stateOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='primary.zip'
					label='Primary Wholesaler Zip Code'
					type='text'
					variant='outlined'
					value={primary.zip}
					inputProps={{
						maxLength: 5
					}}
					data-testid={PRIMARY_ZIP}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.zip && Boolean(primaryErrors.zip)}
					helperText={primaryTouched.zip && primaryErrors.zip}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='primary.phone'
					label='Primary Wholesaler Phone'
					type='text'
					variant='outlined'
					value={primary.phone}
					data-testid={PRIMARY_PHONE}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={primaryTouched.phone && Boolean(primaryErrors.phone)}
					helperText={primaryTouched.phone && primaryErrors.phone}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					name='primary.fax'
					label='Primary Wholesaler Fax'
					type='text'
					variant='outlined'
					value={primary.fax}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={primaryTouched.fax && Boolean(primaryErrors.fax)}
					helperText={primaryTouched.fax && primaryErrors.fax}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='primary.psao'
					label='PSAO/Buying Group'
					type='text'
					variant='outlined'
					value={primary.psao}
					data-testid={PRIMARY_PSAO}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.psao && Boolean(primaryErrors.psao)}
					helperText={primaryTouched.psao && primaryErrors.psao}
				>
					{psoaOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required={primary.psao === 'Other'}
					name='primary.psoaOther'
					label='PSAO/Buying Group Other'
					type='text'
					variant='outlined'
					value={primary.psoaOther}
					onChange={handleChange}
					onBlur={handleBlur}
					error={primaryTouched.psoaOther && Boolean(primaryErrors.psoaOther)}
					helperText={primaryTouched.psoaOther && primaryErrors.psoaOther}
				/>
			</Grid>
			{primary.name === 'McKesson'
				&& <>
					<Grid item md={3} xs={12}>
						<TextField
							fullWidth
							required
							name='mckessonId'
							label='McKesson Connect Account #'
							placeholder='Enter digits only'
							type='text'
							variant='outlined'
							value={values.mckessonId}
							inputProps={{
								'data-testid': MCKESSON_ACCOUNT_NUMBER
							}}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.mckessonId && Boolean(errors.mckessonId)}
							helperText={touched.mckessonId && errors.mckessonId}
						/>
					</Grid>
				</>
			}
		</Grid>
	);
});
