import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
export const PageFooter = memo(() => {
    const styles = StyleSheet.create({
        pageFooterTop: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderTop: 1,
            borderTopColor: 'blue',
        },
        pageFooterBottom: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        pageFooterLeftText: {
            alignItems: 'flex-start',
            justifySelf: 'flex-start',
            fontSize: 7,
        },
        pageFooterRightText: {
            fontSize: 7,
        },
    });
    return (_jsxs(View, { children: [_jsxs(View, { style: styles.pageFooterTop, children: [_jsx(Text, { style: styles.pageFooterLeftText, children: "Credit Application and Agreement" }), _jsx(Text, { style: styles.pageFooterRightText, children: "Capital Wholesale Drug Co | 873 Williams Avenue | Columbus, Ohio43212" })] }), _jsxs(View, { style: styles.pageFooterBottom, children: [_jsx(Text, { style: styles.pageFooterLeftText, children: "V.0.4, Effective 5/1/2021" }), _jsx(Text, { style: styles.pageFooterRightText, children: "Phone: (800) 282-2754 | Fax: (614) 297-8224" })] })] }));
});
