import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'CheckList';
export const classes = {
	container: `${PREFIX}-container`,
	list: `${PREFIX}-list`,
	title: `${PREFIX}-title`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.container}`]: {
		border: '1px solid #eee',
		borderRadius: 5,
	},
	[`& .${classes.list}`]: {
		height: 82,
		overflowY: 'scroll',
		cursor: 'ns-resize',
	},
	[`& .${classes.title}`]: {
		margin: theme.spacing(1, 'auto'),
		textAlign: 'center',
	},
}));
