import ApiClient, { ApiUrl } from './ApiClient';
import { OnboardPutData, OnboardEnvelopePutData } from './types';

export const patch = {
	onboard: async (id: string, data: Partial<OnboardPutData> | OnboardEnvelopePutData) => {
		const client = await ApiClient.getInstance();
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		return client.patch('/onboarding', id, data, { params: { jwt: localToken } }, undefined, ApiUrl.MARKETPLACE_REST);
	},
};
