import * as Yup from 'yup';
import { validateDEANumber } from '../../../../../../util/validate';

export const getRegistrationSchema = () => {
	const phoneNumberRegExp = /\([1-9]\d\d\)\s\d\d\d-\d\d\d\d/;
	const ssnRegExp = /\d\d\d-\d\d-\d\d\d\d/;
	const paymentMethods = [
		'bluepax_secondary.paymentMethod',
		'capital_secondary.paymentMethod',
		'rx_supply_secondary.paymentMethod',
		'keysource_secondary.paymentMethod',
		'twosrx_secondary.paymentMethod',
	];
	const cc = 'Credit Card';
	const isCCSelected = (...args: string[]): boolean => args.some((arg) => arg === cc);
	const cardNumberValidator = (fields: string[], schema: Yup.Schema) => {
		const [blupax, capital, rxsupply, keysource] = fields;
		const ccSelected = isCCSelected(blupax, capital, rxsupply, keysource);
		if (ccSelected) {
			return schema
				.test('len', 'Invalid card number', (val) => val.length === 10)
				.required('Card number is required');
		}
		return schema.notRequired();
	};
	const cardExpDateValidator = (fields: string[], schema: Yup.Schema) => {
		const [blupax, capital, rxsupply, keysource] = fields;
		const ccSelected = isCCSelected(blupax, capital, rxsupply, keysource);
		if (ccSelected) {
			return schema
				.test('len', 'Invalid card exp date', (val) => val.length === 7)
				.required('Card exp date is required');
		}
		return schema.notRequired();
	};
	const cardCvcAndZipValidator = (fields: string[], schema: Yup.Schema) => {
		const [blupax, capital, rxsupply, keysource] = fields;
		const ccSelected = isCCSelected(blupax, capital, rxsupply, keysource);
		if (ccSelected) {
			return schema.required('Card cvc and zip are required');
		}
		return schema.notRequired();
	};

	const validation = Yup.object().shape({
		legalName: Yup.string()
			.required('Pharmacy legal name is required'),
		stateOfIncorporation: Yup.string()
			.required('Pharmacy state of incorporation is required'),
		phone: Yup.string()
			.matches(phoneNumberRegExp, 'Pharmacy phone is invalid')
			.required('Pharmacy phone is required'),
		fax: Yup.string()
			.notRequired(),
		address: Yup.string()
			.required('Pharmacy address is required'),
		city: Yup.string()
			.required('Pharmacy city is required'),
		state: Yup.string()
			.required('Pharmacy state is required'),
		zipCode: Yup.string()
			.matches(/^[0-9]+$/, 'Must be only digits')
			.min(5, 'Must be exactly 5 digits')
			.max(5, 'Must be exactly 5 digits')
			.required('Pharmacy zip code is required'),
		dateBusinessBegan: Yup.date()
			.typeError('Pharmacy date business began is invalid')
			.required('Pharmacy date business began is required'),
		pharmacyType: Yup.string()
			.required('Pharmacy type is required'),
		mckessonId: Yup.string()
			.when('primary.name', {
				is: 'McKesson',
				then: (s) => s.matches(/^[0-9]+$/, 'Must be only digits')
					.required('McKesson Account Number is required')
					.min(4, 'Must be at least 4 digits'),
				otherwise: (s) => s.notRequired(),
			}),
		deaNumber: Yup.string()
			.test('dea-validation', 'Invalid DEA Number', validateDEANumber as any)
			.required('DEA # is required'),
		deaExpDate: Yup.date()
			.typeError('DEA # expiration date is invalid')
			.required('DEA # expiration date is required'),
		hinExpDate: Yup.date()
			.nullable()
			.typeError('HIN # expiration date is invalid'),
		stateLicense: Yup.string()
			.required('State license # is required'),
		stateLicenseExpDate: Yup.date()
			.typeError('State license # expiration date is invalid')
			.required('State license # expiration date is required'),
		stateIdNumber: Yup.string()
			.required('State ID Tax # is required'),
		stateIdExpDate: Yup.date()
			.nullable()
			.typeError('State ID Tax # expiration date is invalid'),
		federalTaxNumber: Yup.string()
			.required('Federal Tax # is required'),
		federalTaxExpDate: Yup.date()
			.nullable()
			.typeError('Federal Tax # expiration date is invalid'),
		shippingAddress: Yup.string()
			.required('Shipping address is required'),
		shippingCity: Yup.string()
			.required('Shipping city is required'),
		shippingState: Yup.string()
			.required('Shipping state is required'),
		shippingZip: Yup.string()
			.matches(/^[0-9]+$/, 'Must be only digits')
			.min(5, 'Must be exactly 5 digits')
			.max(5, 'Must be exactly 5 digits')
			.required('Shipping zip code is required'),
		relatedToOtherFacilitiesList: Yup.string()
			.notRequired()
			.when('relatedToOtherFacilities', {
				is: true,
				then: (s) => s.required('Related facilities list is required'),
				otherwise: (s) => s.notRequired(),
			}),
		dispensing: Yup.object().shape({
			managementSystem: Yup.string()
				.required('Pharmacy management system is required')
				.notOneOf(['Select'], 'Please select a management system.'),
			outOfStateCustomersCount: Yup.number()
				.notRequired()
				.when('fillOutOfStateCustomers', {
					is: true,
					then: (s) => s.min(1, 'Number of Rxs filled out of state monthly is invalid')
						.required('Number of Rxs filled out of state monthly is required'),
					otherwise: (s) => s.notRequired(),
				}),
		}),
		owner: Yup.object().shape({
			firstName: Yup.string()
				.required('Owner first name is required'),
			lastName: Yup.string()
				.required('Owner last name is required'),
			address: Yup.string()
				.required('Owner address is required'),
			city: Yup.string()
				.required('Owner city is required'),
			state: Yup.string()
				.required('Owner state is required'),
			zip: Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(5, 'Must be exactly 5 digits')
				.max(5, 'Must be exactly 5 digits')
				.required('Owner zip code is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'Owner phone number is invalid')
				.required('Owner phone is required'),
			social: Yup.string()
				.matches(ssnRegExp, 'Owner SSN is invalid')
				.required('Owner SSN is required'),
			percentOwned: Yup.number()
				.min(0, 'Owner % ownership must exceed zero')
				.max(100, 'Owner % ownership cannot exceed 100')
				.typeError('Owner % ownership is invalid')
				.required('Owner % ownership is required'),
			ownershipType: Yup.string()
				.required('Ownership type is required'),
			registerType: Yup.string()
				.required('Ownership register type is required'),
			registerTypeOther: Yup.string()
				.notRequired()
				.when('registerType', {
					is: 'Other',
					then: (s) => s.required('Ownership register type other is required'),
					otherwise: (s) => s.notRequired(),
				}),
		}),
		pic: Yup.object().shape({
			firstName: Yup.string()
				.required('PIC first name is required'),
			lastName: Yup.string()
				.required('PIC last name is required'),
			email: Yup.string()
				.email('PIC email is invalid')
				.required('PIC email is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'PIC phone is invalid')
				.required('PIC phone is required'),
			license: Yup.string()
				.required('PIC license is required'),
			licenseExpDate: Yup.date()
				.typeError('PIC license expiration date is invalid')
				.required('PIC license expiration date is required'),
		}),
		pp: Yup.object().shape({
			firstName: Yup.string()
				.required('PP first name is required'),
			lastName: Yup.string()
				.required('PP last name is required'),
			title: Yup.string()
				.required('PP title is required'),
			email: Yup.string()
				.email('PP email is invalid')
				.required('PP email is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'PP phone is invalid')
				.required('PP phone is required'),
			fax: Yup.string()
				.notRequired(),
		}),
		primary: Yup.object().shape({
			name: Yup.string()
				.required('Primary wholesaler name is required'),
			address: Yup.string()
				.required('Primary wholesaler address is required'),
			city: Yup.string()
				.required('Primary wholesaler city is required'),
			state: Yup.string()
				.required('Primary wholesaler state is required'),
			zip: Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(5, 'Must be exactly 5 digits')
				.max(5, 'Must be exactly 5 digits')
				.required('Primary wholesaler zip code is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'Primary wholesaler phone is invalid')
				.required('Primary wholesaler phone is required'),
			fax: Yup.string()
				.notRequired(),
			psao: Yup.string()
				.required('PSAO/buying group is required'),
			psoaOther: Yup.string()
				.notRequired()
				.when('psao', {
					is: 'Other',
					then: (s) => s.required('PSAO/buying group other is required'),
					otherwise: (s) => s.notRequired(),
				}),
		}),
		tradeReference1: Yup.object().shape({
			name: Yup.string()
				.required('Name is required'),
			city: Yup.string()
				.required('City is required'),
			state: Yup.string()
				.required('State is required'),
			address: Yup.string()
				.required('Address is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'Phone is invalid')
				.required('Phone is required'),
			zip: Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(5, 'Must be exactly 5 digits')
				.max(5, 'Must be exactly 5 digits')
				.required('Zip is required'),
			accountNumber: Yup.string()
				.required('Account number is required'),
		}),
		tradeReference2: Yup.object().shape({
			name: Yup.string()
				.required('Name is required'),
			city: Yup.string()
				.required('City is required'),
			state: Yup.string()
				.required('State is required'),
			address: Yup.string()
				.required('Address is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'Phone is invalid')
				.required('Phone is required'),
			zip: Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(5, 'Must be exactly 5 digits')
				.max(5, 'Must be exactly 5 digits')
				.required('Zip is required'),
		}),
		ap: Yup.object().shape({
			firstName: Yup.string()
				.required('AP first name is required'),
			lastName: Yup.string()
				.required('AP last name is required'),
			email: Yup.string()
				.email('AP email is invalid')
				.required('AP email is required'),
			phone: Yup.string()
				.matches(phoneNumberRegExp, 'AP phone is invalid')
				.required('AP phone is required'),
		}),
		banking: Yup.object().shape({
			bankName: Yup.string()
				.required('Bank name is required'),
			bankAddress: Yup.string()
				.required('Bank address is required'),
			bankCity: Yup.string()
				.required('Bank city is required'),
			bankState: Yup.string()
				.required('Bank state is required'),
			bankZip: Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(5, 'Must be exactly 5 digits')
				.max(5, 'Must be exactly 5 digits')
				.required('Bank zip code is required'),
			accountOfficer: Yup.string()
				.required('Bank account officer is required'),
			accountNumber: Yup.string()
				.required('Bank account number is required'),
			routingNumber: Yup.string()
				.required('Bank account routing number is required'),
			bankPhone: Yup.string()
				.matches(phoneNumberRegExp, 'Bank phone is invalid')
				.required('Bank phone is required'),
			accountType: Yup.string()
				.required('Bank account type is required'),
			receiveInvoices: Yup.string()
				.required('Bank preferred invoice method is required'),
			accountToBeDebited: Yup.string()
				.required('Bank account to be debited frequency is required'),
			accountToBeDebitedOther: Yup.string()
				.notRequired()
				.when('accountToBeDebited', {
					is: 'Other',
					then: (s) => s.required('Bank account to be debited other frequency is required'),
					otherwise: (s) => s.notRequired(),
				}),
		}),
		payments: Yup.object().shape({
			cardNumber: Yup.string()
				.notRequired()
				.when(paymentMethods, cardNumberValidator),
			cardExpDate: Yup.string()
				.notRequired()
				.when(paymentMethods, cardExpDateValidator),
			cardCvc: Yup.string()
				.notRequired()
				.when(paymentMethods, cardCvcAndZipValidator),
			cardZip: Yup.string()
				.notRequired()
				.when(paymentMethods, cardCvcAndZipValidator),
			billingAddress: Yup.string()
				.required('Billing address is required'),
			billingCity: Yup.string()
				.required('Billing city is required'),
			billingState: Yup.string()
				.required('Billing state is required'),
			billingZip: Yup.string()
				.required('Billing zip is required'),
		}),
		ncpdpNumber: Yup.string()
			.required('NCPDP number is required'),
		stateSellersPermit: Yup.string()
			.required('State seller permit is required'),
		convicted: Yup.boolean()
			.required('You must provide a response for if convicted'),
		convictedExplanation: Yup.string()
			.when(['convicted'], {
				is: (convicted: boolean) => convicted === true,
				then: (s) => s.required('You must provide a description of convictions')
			}),
		bankruptcy: Yup.boolean()
			.required('You must provide a response for bankruptcy'),
		bankruptcyExplanation: Yup.string()
			.when(['bankruptcy'], {
				is: (bankruptcy: boolean) => bankruptcy === true,
				then: (s) => s.required('You must provide a description of bankruptcy')
			}),
		gln: Yup.string()
			.required('GLN is required'),
		glnOther: Yup.string(),
	});

	return validation;
};
