import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const BusinessType = memo(({ circleText, leftText, rightText, }) => {
    const styles = StyleSheet.create({
        termsContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: 2,
        },
        termsLeft: {
            marginRight: 0,
            width: '50%',
        },
        termsRight: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '50%',
        },
        termsText: {
            fontSize: 11,
        },
        circleText: {
            padding: 2,
            fontSize: 11,
            borderWidth: 2,
            width: 17,
            borderRadius: 3,
        },
    });
    return (_jsxs(View, { style: styles.termsContainer, children: [_jsx(View, { style: styles.termsLeft, children: _jsx(Text, { style: styles.termsText, children: leftText }) }), _jsxs(View, { style: styles.termsRight, children: [circleText && _jsx(Text, { style: styles.circleText, children: circleText }), _jsx(Text, { style: styles.termsText, children: rightText })] })] }));
});
