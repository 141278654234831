import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import Auburn from '../../../../../../images/AuburnLogo.png';
export const PageTwo = memo(() => {
    const styles = StyleSheet.create({
        container: {
            padding: '20px 30px',
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: 25,
        },
        image: {
            width: 75,
            height: 75,
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 20,
            textAlign: 'center',
        },
        titleContainer: {
            flexDirection: 'column',
            padding: '5px 0px 5px 15px',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
        },
        olItem: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignContent: 'center',
            margin: '15px 15px 15px 40px',
        },
        olNum: {
            fontSize: 13,
            fontFamily: 'Roboto',
            marginRight: 25,
        },
        col: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'flex-start',
        },
        ulItem: {
            marginLeft: 25,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignContent: 'center',
        },
        p: {
            fontSize: 13,
            fontFamily: 'Roboto',
        },
        subtitle: {
            fontSize: 13,
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontStyle: 'bold',
        },
    });
    const licenses = useMemo(() => [
        'DEA License – submit DEA License #',
        'State License – submit State License # or upload copy',
        'Controlled Substance License (if applicable) – upload copy',
        'Sales Tax Exemption Certificate (if applicable)',
        'California Pharmacist Agreement (California Only)',
    ], []);
    const sections = useMemo(() => [
        {
            index: 1,
            content: (_jsxs(View, { style: styles.col, children: [_jsx(Text, { style: styles.subtitle, children: "Licenses/documents required to open your Auburn account:" }), licenses.map((license, index) => (_jsx(View, { style: styles.ulItem, children: _jsxs(Text, { style: styles.p, children: ["\u2022 ", license] }) }, index))), _jsx(Text, { style: { ...styles.subtitle, marginTop: 5, }, children: "Submit to: Compliance@auburnpharm.com or fax to 248-247-3272" })] }))
        },
        {
            index: 2,
            content: (_jsx(Text, { style: { ...styles.p, fontStyle: 'italic', }, children: "Once the above is submitted & approved, your account will be opened. You will receive an email from customerservice@auburnpharm.com that you may place your order online. Your Auburn Account Manager will contact you shortly." }))
        },
        {
            index: 3,
            content: (_jsxs(View, { style: styles.col, children: [_jsx(Text, { style: styles.subtitle, children: "To open a line of credit, complete the:" }), _jsx(View, { style: styles.ulItem, children: _jsx(Text, { style: styles.p, children: "\u2022 Confidential Credit Agreement" }) }), _jsx(Text, { style: { ...styles.subtitle, marginTop: 5, }, children: "Submit to: accounting@auburnpharm.com or fax 248-526-3713" })] }))
        },
        {
            index: 4,
            content: (_jsxs(View, { style: styles.col, children: [_jsx(Text, { style: styles.subtitle, children: "To choose your preferred method of payment, complete the:" }), _jsx(View, { style: styles.ulItem, children: _jsx(Text, { style: styles.p, children: "\u2022 ACH or Credit Card Authorization Form" }) }), _jsx(Text, { style: { ...styles.subtitle, marginTop: 5, }, children: "Submit to: accounting@auburnpharm.com or fax 248-526-3713" })] }))
        },
        {
            index: 5,
            content: (_jsxs(View, { style: styles.col, children: [_jsx(Text, { style: styles.subtitle, children: "For Controlled Substance Purchasing, complete the:" }), _jsx(View, { style: styles.ulItem, children: _jsx(Text, { style: styles.p, children: "\u2022 Customer Compliance Questionnaire + pharmacy photos (pg.2)" }) }), _jsx(Text, { style: { ...styles.subtitle, marginTop: 5, }, children: "Submit to: Compliance@auburnpharm.com or fax 248-247-3272" })] }))
        },
    ], []);
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.headerContainer, children: [_jsx(Image, { style: styles.image, src: Auburn }), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "How to Start Your Savings..." }) })] }), sections.map(({ index, content, }) => (_jsxs(View, { style: styles.olItem, children: [_jsxs(Text, { style: styles.olNum, children: [index, "."] }), content] }, index))), _jsxs(View, { style: { flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '65px auto 0px', }, children: [_jsx(Text, { style: { fontSize: 20, fontWeight: 700, fontStyle: 'bold', color: '#0070C0', textAlign: 'center', }, children: "Welcome to Auburn!" }), _jsx(Text, { style: { fontSize: 14, fontWeight: 700, fontStyle: 'bold', color: '#0070C0', textAlign: 'center', }, children: "www.auburngenerics.com" })] })] }), _jsx(Text, { style: { ...styles.p, position: 'absolute', bottom: 30, left: 30, }, children: "Rev 04/26/21" })] }));
});
