import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, memo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, styled, Stack, Pagination, } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
}));
const ExpandableTableRow = memo((props) => {
    const { row, DetailComponent, columns } = props;
    const [open, setOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(StyledTableCell, { children: _jsx(IconButton, { "aria-label": "expand row", size: "small", onClick: () => setOpen(!open), children: open ? _jsx(KeyboardArrowUp, {}) : _jsx(KeyboardArrowDown, {}) }) }), columns.map((column, index) => (_jsx(StyledTableCell, { align: "left", children: column.render(row) }, index)))] }), _jsx(TableRow, { children: _jsx(StyledTableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: columns.length + 1, children: _jsx(Collapse, { in: open, timeout: "auto", unmountOnExit: true, children: _jsx(DetailComponent, { ...row }) }) }) })] }));
});
const OrdersTable = (props) => {
    const { DetailComponent, orders, columns, total, limit, page, handlePageChange } = props;
    const pageCount = Math.floor(total / limit);
    return (_jsxs(TableContainer, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(StyledTableCell, {}), columns.map((column, index) => (_jsx(StyledTableCell, { align: "left", children: column.title }, index)))] }) }), _jsx(TableBody, { children: orders.map((row, index) => (_jsx(ExpandableTableRow, { row: row, DetailComponent: DetailComponent, columns: columns }, index))) })] }), pageCount > 1 && (_jsx(Stack, { direction: 'row', justifyContent: 'flex-end', p: 2, children: _jsx(Pagination, { count: pageCount, page: page, onChange: handlePageChange }) }))] }));
};
export default memo(OrdersTable);
