import { FC, memo, useState } from 'react';
import { FormikProps } from 'formik';
import {
	Checkbox,
	FormControlLabel,
	Grid, TextField,
} from '@mui/material';
import { SelectState } from '@pm/shared';
import { PharmacyPostData } from '../../../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

const BillingAddress: FC<Props> = memo(({ formik }) => {
	const BILLING_ADDRESS = 'billing-address';
	const BILLING_CITY = 'billing-city';
	const BILLING_STATE = 'billing-state';
	const BILLING_ZIP = 'billing-zip';

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const payments = values?.payments ?? {} as PharmacyPostData['payments'];
	const { payments: paymentsErrors = {} } = errors;
	const { payments: paymentsTouched = {} } = touched;
	const [billingAddressSameAsPhysical, setBillingAddressSameAsPhysical] = useState(false);

	return (
		<Grid
			item
			xs={12}
			container
			spacing={1}
			alignItems='flex-start'
			justifyContent='space-evenly'
		>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={billingAddressSameAsPhysical}
							onChange={(e, checked) => {
								setBillingAddressSameAsPhysical(checked);
								if (checked) {
									formik.setFieldValue('payments.billingAddress', values.address);
									formik.setFieldValue('payments.billingCity', values.city);
									formik.setFieldValue('payments.billingState', values.state);
									formik.setFieldValue('payments.billingZip', values.zipCode);
								}
							}}
							data-testid={''}
							color='primary'
						/>
					}
					label='Billing address is the same as the pharmacy&apos;s physical address'
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<TextField
					fullWidth
					required
					name='payments.billingAddress'
					label='Billing Address'
					type='text'
					variant='outlined'
					value={payments.billingAddress}
					data-testid={BILLING_ADDRESS}
					onChange={handleChange}
					onBlur={handleBlur}
					error={paymentsTouched.billingAddress && Boolean(paymentsErrors.billingAddress)}
					helperText={paymentsTouched.billingAddress && paymentsErrors.billingAddress}
				/>
			</Grid>
			<Grid item md={2} xs={12}>
				<TextField
					fullWidth
					required
					name='payments.billingCity'
					label='Billing City'
					type='text'
					variant='outlined'
					value={payments.billingCity}
					data-testid={BILLING_CITY}
					onChange={handleChange}
					onBlur={handleBlur}
					error={paymentsTouched.billingCity && Boolean(paymentsErrors.billingCity)}
					helperText={paymentsTouched.billingCity && paymentsErrors.billingCity}
				/>
			</Grid>
			<Grid item md={2} xs={12}>
				<SelectState
					onChange={handleChange}
					onBlur={handleBlur}
					value={payments.billingState}
					name={'payments.billingState'}
					label={'Billing State'}
					error={!!paymentsTouched.billingState && Boolean(paymentsErrors.billingState)}
					helperText={!!paymentsTouched.billingState && !!paymentsErrors.billingState}
				/>
			</Grid>
			<Grid item md={2} xs={12}>
				<TextField
					fullWidth
					required
					name='payments.billingZip'
					label='Billing Zip Code'
					type='text'
					variant='outlined'
					value={payments.billingZip}
					data-testid={BILLING_ZIP}
					onChange={handleChange}
					onBlur={handleBlur}
					inputProps={{
						maxLength: 5
					}}
					error={paymentsTouched.billingZip && Boolean(paymentsErrors.billingZip)}
					helperText={paymentsTouched.billingZip && paymentsErrors.billingZip}
				/>
			</Grid>
		</Grid>
	);
});

export default BillingAddress;
