import { useEffect, useRef } from 'react';

export const useDidUpdate = (fn: Function, deps: any[] = []) => {
	const isMounting = useRef<boolean>(true);

	useEffect(() => {
		if (isMounting?.current) {
			isMounting.current = false;
		} else {
			fn();
		}
	}, deps);
};
