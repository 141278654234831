import {
	FC, useState, useMemo, memo,
} from 'react';
import { useSetRecoilState } from 'recoil';
import clsx from 'clsx';
import {
	Button, Divider, List, ListItem, ListItemText, ListItemIcon, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import { SubscriptionType } from '@pm/shared';
import { registrationFormStepState, registrationFormState } from '../../../../atoms';
import {
	Card, CardBody, CardFooter, CardHeader,
} from '../../../../components';
import { StyledGrid, classes } from './BestTierCard.styles';
import { PharmacyPostData } from '../../../../services/types';

export const BestTierCard: FC = memo(() => {
	const setFormStep = useSetRecoilState(registrationFormStepState);
	const setForm = useSetRecoilState(registrationFormState);
	const formOpen = false;

	const onClickStart = (code?: string) => {
		setForm((form: PharmacyPostData) => ({
			...form,
			code,
			subscription: SubscriptionType.BEST,
			banking: {
				...form.banking,
			},
			dispensing: {
				...form.dispensing,
			},
		}));
		setFormStep((step) => step + 1);
	};

	const items: any[] = useMemo(() => [
		{
			primary: 'Benefit from professional PVA consultations catered specifically to your pharmacy.',
			icon: <CheckCircleIcon className={classes.icon} />,
		},
		{ divider: true },
		{
			primary: 'Have confidence in your purchasing activity knowing a holistic approach of your business is taken to increase your long-term profitability.',
			icon: <CheckCircleIcon className={classes.icon} />,
		},
		{ divider: true },
		{
			primary: 'Streamline your purchasing decisions with curated recommendations for what to purchase, from who, and when.',
			icon: <CheckCircleIcon className={classes.icon} />,
		},
	], []);

	return (
		<StyledGrid item xs={12} md={4}>
			<Card className={classes.card}>
				<CardHeader color='gold' className={classes.cardHeader}>
					<Typography variant='h5' className={classes.title}>
						<StarIcon sx={{ mr: 0.5 }} /> Best Shopper <StarIcon sx={{ ml: 0.5 }} />
					</Typography>
					<div className={classes.subtitle}>
						<Typography component='span'>$</Typography>
						<Typography variant='h5'>300</Typography>
						<Typography component='span'>/mo</Typography>
					</div>
				</CardHeader>
				<Typography className={classes.divider}>
					Our most popular option, the <b>Comprehensive Purchasing Intelligence solution</b> provides access to all features in Premium Shopper and PVAC Navigator.
				</Typography>
				<CardBody className={classes.cardBody}>
					{!formOpen
						&& <List>
							{items.map((item, i) => (item.divider
								? <Divider key={i} component='li' />
								: (
									<ListItem key={i} alignItems='flex-start'>
										<ListItemIcon>{item.icon}</ListItemIcon>
										<ListItemText primary={item.primary} />
									</ListItem>
								)))}
						</List>
					}
				</CardBody>
				<CardFooter className={classes.cardFooter}>
					<Button
						variant='outlined'
						size='large'
						className={classes.btn}
						data-testid="bestShopperGetStarted"
						onClick={() => { onClickStart(); }}
					>
						Get started
					</Button>
				</CardFooter>
			</Card>
		</StyledGrid>
	);
});
