import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatNdc, formatDrugName } from '../../../../../util';
;
export const TableRows = memo(({ items, }) => {
    const text = {
        fontSize: 12,
        fontFamily: 'Roboto',
    };
    const borderColor = '#1565c0';
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 60,
        },
        column: {
            width: '50%',
            paddingLeft: 8,
        },
        title: {
            textAlign: 'left',
            ...text,
        },
        subtitle: {
            textAlign: 'left',
            marginTop: 4,
            fontFamily: 'Roboto',
            fontSize: 10,
        },
        qty: {
            width: '10%',
            textAlign: 'center',
            ...text,
        },
        uom: {
            width: '10%',
            textAlign: 'center',
            paddingRight: 8,
            ...text,
        },
        price: {
            width: '15%',
            textAlign: 'center',
            paddingRight: 8,
            ...text,
        },
    });
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (_jsx(View, { children: items.map((item, index) => {
            const formattedPrice = formatter.format(item.price);
            const formattedTotal = formatter.format(item.total);
            return (_jsxs(View, { style: styles.row, children: [_jsxs(View, { style: styles.column, children: [_jsx(Text, { style: styles.title, children: formatDrugName(item.name) }), _jsx(Text, { style: styles.subtitle, children: formatNdc(item.ndc, item.vendorItemNumber) }), _jsx(Text, { style: styles.subtitle, children: `Vendor Item #: ${item.vendorItemNumber}` })] }), _jsx(Text, { style: styles.qty, children: item.quantity }), _jsx(Text, { style: styles.uom, children: item.unitOfMeasure }), _jsx(Text, { style: styles.price, children: formattedPrice }), _jsx(Text, { style: styles.price, children: formattedTotal })] }, index));
        }) }));
});
