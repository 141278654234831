import { FC, memo } from 'react';
import clsx from 'clsx';
import { Loader } from './Loader';
import { Root, classes } from './PreparingView.styles';

interface Props {
	bgImgIndex?: 1 | 2 | 4;
	title?: string;
};

export const PreparingView: FC<Props> = memo(({
	bgImgIndex = 2, title = 'Securely preparing your documents',
}) => {
	const className = clsx(classes.image, classes[`bgImg${bgImgIndex}`]);
	return (
		<>
			<Loader title={title} />
			<Root className={classes.root}>
				<div className={className} />
			</Root>
		</>
	);
});
