import { styled } from '@mui/material/styles';
import { CardFooter } from '../../../../../../components';

const PREFIX = 'FormFooter';
export const classes = {
	cardFooter: `${PREFIX}-cardFooter`,
	saving: `${PREFIX}-saving`,
	progress: `${PREFIX}-progress`,
};
export const StyledCardFooter = styled(CardFooter)(({ theme }) => ({
	[`&.${classes.cardFooter}`]: {
		marginTop: -theme.spacing(1),
		paddingBottom: theme.spacing(1),
		border: '0',
		borderRadius: '6px',
		justifyContent: 'center !important',
	},
	[`& .${classes.saving}`]: {
		position: 'absolute',
		right: 24,
		display: 'flex',
		alignItems: 'center',
	},
	[`& .${classes.progress}`]: {
		marginRight: 4,
		marginTop: -2,
	},
}));
