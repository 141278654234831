import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo } from 'react';
import { IMaskInput } from 'react-imask';
import { CircularProgress, TableHead, TableCell, TextField, Tooltip, Box, TableRow, lighten, IconButton, Typography, useTheme } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
;
const SpendRangeInput = forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (_jsx(IMaskInput, { ...other, mask: [
            {
                mask: '$num+',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: ',',
                        scale: 0,
                    },
                },
            },
            {
                mask: '$num-$num',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: ',',
                        scale: 0,
                    },
                },
            },
        ], inputRef: ref, dispatch: (_appended, dynamicMasked) => {
            if (dynamicMasked.value.includes('+')) {
                return dynamicMasked.compiledMasks[0];
            }
            return dynamicMasked.compiledMasks[1];
        }, onAccept: (value) => {
            onChange({ target: { name: props.name, value } });
        } }));
});
const HeaderCell = muiStyled(TableCell)(() => ({
    fontWeight: 'bold',
    textAlign: 'center',
}));
;
const TableHeader = ({ rows, fetching, syncing, isGeneric, colTitlesIndex, handleCellClick, handleRemoveColumn, handleCellChange, handleCellBlur, handleCellKeyDown }) => {
    const { palette } = useTheme();
    const titleRow = rows[colTitlesIndex];
    return (_jsxs(TableHead, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: (syncing || fetching) &&
                            _jsxs("div", { style: { display: 'flex', alignItems: 'center', }, children: [_jsx(CircularProgress, { disableShrink: true, size: 18, sx: { mr: 1 } }), _jsx(Typography, { variant: 'subtitle2', children: syncing ? 'Saving updates' : 'Loading table' })] }) }), _jsx(HeaderCell, { colSpan: titleRow.cells.length - 1, children: _jsx(Box, { display: "flex", justifyContent: "center", children: `Monthly Net ${isGeneric ? 'Generic' : 'Brand'} Spend` }) })] }), _jsx(TableRow, { children: titleRow.cells.map((cell, cellIndex) => {
                    const first = cellIndex === 0;
                    const { id: rowId, } = titleRow;
                    return (_jsx(HeaderCell, { onClick: () => first ? null : handleCellClick(rowId, cellIndex), style: {
                            cursor: first ? 'default' : 'pointer',
                            backgroundColor: cell.editable
                                ? '#fff'
                                : first ? lighten('rgba(255, 99, 132, 1)', .15) : palette.primary.main,
                            color: first ? '#000' : '#fff'
                        }, children: _jsxs(Box, { display: 'flex', alignItems: 'center', justifyContent: 'center', children: [cellIndex > 1 &&
                                    _jsx(Tooltip, { title: 'Delete Column', children: _jsx(IconButton, { "aria-label": 'Delete Column', onClick: (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleRemoveColumn(cellIndex);
                                            }, children: _jsx(DeleteIcon, { style: { color: '#fff', } }) }) }), cell.editable ? (_jsx(TextField, { autoFocus: true, value: cell.value, onChange: (e) => handleCellChange(rowId, cellIndex, e.target.value), onBlur: () => handleCellBlur(rowId, cellIndex), onKeyDown: (e) => handleCellKeyDown(e, rowId, cellIndex), InputProps: { inputComponent: SpendRangeInput } })) : (cell.value)] }) }, cellIndex));
                }) })] }));
};
export default memo(TableHeader);
