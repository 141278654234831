import { FC, ReactNode, memo } from 'react';
import clsx from 'clsx';
import { Root, classes } from './CardFooter.styles';

interface Props {
	className?: string;
	children: ReactNode;
};

export const CardFooter: FC<Props> = memo(({
	className, children, ...rest
}) => {
	const cardFooterClasses = clsx(className, classes.cardFooter);
	return (
		<Root className={cardFooterClasses} {...rest}>
			{children}
		</Root>
	);
});
