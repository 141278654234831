import { styled } from '@mui/material/styles';

const PREFIX = 'ColorlibStepIcon';
export const classes = {
	root: `${PREFIX}-root`,
	active: `${PREFIX}-active`,
	completed: `${PREFIX}-completed`,
};
export const Root = styled('div')({
	[`&.${classes.root}`]: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	[`&.${classes.active}`]: {
		backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	[`&.${classes.completed}`]: {
		backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
	},
});
