import {
	FC, useCallback, useReducer, useMemo, memo,
} from 'react';
import { useRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { Grid, GridTypeMap, Typography } from '@mui/material';
import { onboardIdState } from '../../atoms';
import { EventEmitter, CACHE_UPLOADS, UPLOADING_FILES } from '../../emitter';
import { useDidUpdate } from '../../hooks';
import { api } from '../../services';
import { Upload } from '../../services/types';
import { Loader } from '../Loaders';
import { CheckList, Thumbnails } from './components';
import { dzStyles } from './Dropzone.styles';

interface Props {
	cachedUploads: string; // JSON string
	maxFiles?: number;
	md?: GridTypeMap['props']['md'];
};
interface State {
	pendingUploads: number;
	uploading: boolean;
	uploads: Upload[];
};
interface Action {
	type: 'MERGE_STATE' | 'INC_PENDING_UPLOADS' | 'TOGGLE_UPLOADING';
	payload?: Partial<State>;
};
const reducer = (state: State, action: Action): State => {
	const { type, payload } = action;
	switch (type) {
		case 'MERGE_STATE':
			return { ...state, ...payload };
		case 'INC_PENDING_UPLOADS':
			return { ...state, pendingUploads: state.pendingUploads + 1 };
		case 'TOGGLE_UPLOADING':
			return { ...state, uploading: !state.uploading };
		default:
			throw new Error(`Invalid Dropzone action type: ${type}`);
	}
};

export const Dropzone: FC<Props> = memo(({
	cachedUploads, maxFiles = 4, md = 12,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [onboardId] = useRecoilState(onboardIdState);
	const [state, dispatch] = useReducer(reducer, {
		pendingUploads: 0,
		uploading: false,
		uploads: JSON.parse(cachedUploads),
	});
	const { pendingUploads, uploading, uploads } = state;

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		getRootProps,
		getInputProps,
	} = useDropzone({
		accept: 'image/jpeg, image/jpg, image/png, application/pdf',
		maxFiles,
	});
	const style = useMemo((): any => ({
		...dzStyles.baseStyle,
		padding: uploading
			? '52px 0px'
			: uploads.length === 0
				? '56px 0px'
				: '12px 0px',
		...(isDragActive ? dzStyles.activeStyle : {}),
		...(isDragAccept ? dzStyles.acceptStyle : {}),
		...(isDragReject ? dzStyles.rejectStyle : {}),
	}), [isDragActive, isDragReject, isDragAccept, uploads, uploading]);

	useDidUpdate(() => {
		if (acceptedFiles.length > 0) {
			onUploadFiles();
		}
	}, [acceptedFiles]);

	const uploadFile = async (file: File): Promise<Upload> => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('public', 'false');
		formData.append('linkedParentType', 'onboard');
		formData.append('linkedParentId', onboardId as string);
		return api.post.upload(formData);
	};

	const onUploadFiles = async () => {
		try {
			EventEmitter.emit(UPLOADING_FILES, true);
			dispatch({ type: 'TOGGLE_UPLOADING' });
			const nextUploads = await Promise.all(acceptedFiles.map(async (file) => {
				const upload = await uploadFile(file);
				dispatch({ type: 'INC_PENDING_UPLOADS' });
				return upload;
			}));
			const newUploads = [...uploads, ...nextUploads];
			dispatch({
				type: 'MERGE_STATE',
				payload: { pendingUploads: 0, uploads: newUploads, uploading: false },
			});
			EventEmitter.emit(CACHE_UPLOADS, newUploads);
			EventEmitter.emit(UPLOADING_FILES, false);
		} catch (_) {
			enqueueSnackbar(
				'Something went wrong while uploading your files. Please try again',
				{ variant: 'error' },
			);
			EventEmitter.emit(UPLOADING_FILES, false);
		}
	};

	const removeFile = useCallback(async (uploadId: string, fileIndex: number) => {
		try {
			await api.del.uploadById(uploadId);
			const newUploads = [...uploads];
			newUploads.splice(fileIndex, 1);
			dispatch({ type: 'MERGE_STATE', payload: { uploads: newUploads } });
			EventEmitter.emit(CACHE_UPLOADS, newUploads);
		} catch (_) {
			enqueueSnackbar(
				'Something went wrong while removing your file. Please try again',
				{ variant: 'error' },
			);
		}
	}, [uploads]);

	return (
		<Grid
			item
			xs={12}
			md={md}
			container
			spacing={1}
			alignItems='flex-end'
			justifyContent='space-between'
		>
			<CheckList />
			<Grid item xs={12} md={8}>
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					{uploading
						&& <Loader
							color='black'
							absolutelyPositioned={false}
							ellipsisClass='ellipsis-height-sm'
							title={`Uploaded ${pendingUploads} of ${acceptedFiles.length}`}
						/>
					}
					{!uploading
						&& <>
							<Typography gutterBottom={uploads.length > 0}>
								{isDragActive
									? 'Drop your files here...'
									: 'Drag \'n\' drop your files here, or click to select files'
								}
							</Typography>
							<Thumbnails removeFile={removeFile} uploads={uploads} />
						</>
					}
				</div>
			</Grid>
		</Grid>
	);
});
