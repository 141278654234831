import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Svg, Rect, Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const Checkbox = memo(({ checked = false, margin = '0px', label, }) => {
    const squareHeight = 10;
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin,
        },
        svg: {
            height: squareHeight,
            width: squareHeight,
        },
        label: {
            fontSize: 12,
            padding: '0px 12px 0px 4px',
        },
    });
    const rectProps = {
        width: squareHeight,
        height: squareHeight,
        fill: checked ? '#000' : '#fff',
        stroke: '#000',
    };
    return (_jsxs(View, { style: styles.row, children: [_jsx(Svg, { style: styles.svg, children: _jsx(Rect, { ...rectProps }) }), _jsx(Text, { style: styles.label, children: label })] }));
});
