import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { HeaderImage } from '../Shared';
import { BorderPolygon } from './components';
import PMLogo from '../../../../images/PM-logo-black.png';
;
export const DocumentOne = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 10,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 11,
        },
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            marginBottom: 8,
            padding: 15,
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
        },
        title: {
            padding: '10px 0px 10px 3px',
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 15,
        },
    });
    const entries = useMemo(() => {
        const { banking, owner, } = traderInfo;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const initialChargeAmount = formatter.format(banking.initialChargeAmount);
        const monthlyChargeAmount = formatter.format(banking.monthlyChargeAmount);
        const date = dayjs().format('MM/DD/YYYY');
        return [
            `Name:  ${owner.firstName} ${owner.lastName}`,
            `Address:  ${pharmacy.address}`,
            `City:  ${pharmacy.city}     State:  ${pharmacy.state}     Zip:  ${pharmacy.zipCode}`,
            '',
            `Bank Name:  ${banking.bankName}     Bank Phone:  ${banking.bankPhone}`,
            `Account Type:  ${banking.accountType}     Account #:  ${banking.accountNumber}     Routing #:  ${banking.routingNumber}`,
            '',
            `Initial Charge Amount:  ${initialChargeAmount}     Date:  ${date}`,
            `Terms of Billing Amount:  ${monthlyChargeAmount}     Terms of Billing Date:  first of every month`,
            `Customer Printed Name:  ${owner.firstName} ${owner.lastName}`,
            '',
            'Authorized Signature:                                            Date:',
        ];
    }, [pharmacy]);
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsx(BorderPolygon, { border: 'top' }), _jsxs(View, { style: styles.container, children: [_jsx(HeaderImage, { imgSrc: PMLogo }), _jsxs(View, { style: styles.titleContainer, children: [_jsx(Text, { style: styles.title, children: "BANK ACCOUNT TRANSFER AUTHORIZATION" }), _jsxs(Text, { style: styles.entry, children: ["I authorize Pharmacy Marketplace to electronically debit my bank account according to the terms outlined below. I acknowledge that electronic debits against my account must comply with United States law. This payment authorization is to remain in effect until I,", pharmacy.legalName, ", notify Pharmacy Marketplace LLC of its cancellation by giving written notice in enough time for the business and receiving financial institution to have a reasonable opportunity to act on it."] })] }), _jsx(View, { style: styles.entryContainer, children: entries.map((entry, index) => (_jsx(Text, { style: styles.entry, children: entry }, index))) })] }), _jsx(BorderPolygon, { border: 'bottom' })] }));
});
