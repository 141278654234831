import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { StateIdTable } from './components';
;
export const UniformSalesTaxCertificate = memo(({ pharmacy, traderInfo, sellerName, sellerAddress, introText, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        entryContainer: {
            padding: '0px 20px',
        },
        entry: {
            paddingBottom: 12,
            fontSize: 10,
        },
        entrySmallPadding: {
            paddingBottom: 8,
            fontSize: 10,
        },
        entrySmallUnder: {
            fontSize: 9,
            marginLeft: 100,
        },
        boldUnderlineHeader: {
            fontSize: 11,
            textDecoration: 'underline',
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
        spacer: {
            paddingBottom: 25,
        },
        spacerSmall: {
            paddingBottom: 15,
        },
        certifyContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 30px',
        },
        certifyTextRight: {
            padding: '3px 0px 4px 3px',
            fontSize: 10,
            alignItems: 'flex-end',
            justifySelf: 'flex-end',
        },
    });
    const header = useMemo(() => [
        { text: '', style: styles.spacer, },
        {
            text: 'UNIFORM SALES AND USE TAX EXEMPTION/RESALE CERTIFICATE - MULTIJURISDICTION',
            style: styles.boldUnderlineHeader,
        },
        { text: '', style: styles.spacer, },
        {
            text: introText,
            style: styles.entry,
        },
        {
            text: `Issued to Seller:  ${sellerName}`,
            style: styles.entry,
        },
        {
            text: `Address:  ${sellerAddress}`,
            style: styles.entry,
        },
    ], [sellerName, sellerAddress, introText]);
    const { owner, } = traderInfo;
    const businessInfo = [
        { text: '', style: styles.spacerSmall, },
        {
            text: 'and is registered with the below-listed states and cities within which your firm would deliver purchases to us and that any such purchases are for wholesale, resale, or ingredients or components of a new product or service to be resold, leased, or rented in the normal course of business. We are in the business of wholesaling, retailing, manufacturing, leasing (renting) selling (California) the following:',
            style: styles.entry,
        },
        {
            text: `Description of Business:  ${pharmacy.pharmacyType}`,
            style: styles.entry,
        },
        {
            text: 'General description of tangible property or taxable services to be purchased from the Seller:',
            style: styles.entry,
        },
    ];
    const signature = useMemo(() => {
        const date = dayjs().format('MM/DD/YYYY');
        return [
            { text: '', style: styles.spacerSmall, },
            { text: 'I further certify that if any property or service so purchased tax free is used or consumed as to make it subject to a Sales or Use tax we will pay that tax due directly to the proper taxing authority when state law so provides or inform the Seller for added tax billing. This certificate shall be part of each order that we may hereafter give to you, unless otherwise specified, and shall be valid until canceled by us in writing or revoked by thee city or state.', style: styles.entrySmallPadding, },
            { text: 'Under penalties of perjury, I swear or affirm that the information on this form is true and correct as to every material matter.', style: styles.entrySmallPadding, },
            { text: 'Authorized Signature:                                                                 Title:  Owner', style: styles.entrySmallPadding, },
            { text: '(Owner, Partner, Corporate Officer, or Other Authorized Signer)', style: styles.entrySmallUnder, },
            { text: `Date:  ${date}`, style: styles.entrySmallPadding, },
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(View, { style: styles.entryContainer, children: header.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsxs(View, { style: styles.certifyContainer, children: [_jsxs(View, { children: [_jsx(Text, { style: styles.entrySmallPadding, children: "I certify that:" }), _jsxs(Text, { style: styles.entrySmallPadding, children: ["Name of Firm (Buyer):  ", pharmacy.name] }), _jsxs(Text, { style: styles.entrySmallPadding, children: ["Address:  ", pharmacy.address, ", ", pharmacy.city, ", ", pharmacy.state, " ", pharmacy.zipCode] })] }), _jsxs(View, { children: [_jsx(Text, { style: styles.certifyTextRight, children: "is engaged as registered:" }), _jsx(Text, { style: styles.certifyTextRight, children: owner.ownershipType })] })] }), _jsx(View, { style: styles.entryContainer, children: businessInfo.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(StateIdTable, { pharmacy: pharmacy }), _jsx(View, { style: styles.entryContainer, children: signature.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) })] }) }));
});
