import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { memo, useEffect, useMemo, useState, } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@mui/material';
import { formatNdc } from '../../util';
;
const DispensingTable = ({ date, items }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const pkgSizeFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => () => setPage(0), []);
    const columns = useMemo(() => [
        { id: 'drugName', label: 'Drug' },
        { id: 'ndc', label: 'NDC' },
        { id: 'quantityDispensed', label: 'Qty' },
        { id: 'packageSize', label: 'Pck Size' },
        { id: 'dosageForm', label: 'UoM / Dosage Form' },
        { id: 'profit', label: 'Profit' },
        { id: 'cost', label: 'Cost' },
    ], []);
    return (_jsx(_Fragment, { children: _jsxs(TableContainer, { sx: { maxHeight: 600 }, children: [date
                    && _jsx(Typography, { align: 'center', variant: 'h6', sx: { mb: 1 }, children: `Prescriptions Dispensed on ${dayjs(date).format('M/D/YY')}` }), _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: columns.map((column) => (_jsx(TableCell, { children: column.label }, column.id))) }) }), _jsx(TableBody, { children: items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                const sx = {
                                    color: row.profit > 0 ? 'green' : 'red',
                                    fontStyle: 'bold',
                                    fontWeight: 600,
                                };
                                return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.drugName ?? 'N/A' }), _jsx(TableCell, { children: formatNdc(row.ndc) }), _jsx(TableCell, { children: pkgSizeFormatter.format(row.quantityDispensed) }), _jsx(TableCell, { children: row.packageSize ? pkgSizeFormatter.format(row.packageSize) : 'N/A' }), _jsx(TableCell, { children: `${row.dosageForm ?? 'N/A'}${row.unitOfMeasure ? ` (${row.unitOfMeasure})` : ''}` }), _jsx(TableCell, { sx: sx, children: currencyFormatter.format(row.profit) }), _jsx(TableCell, { children: currencyFormatter.format(row.cost) })] }, index));
                            }) })] }), _jsx(TablePagination, { rowsPerPageOptions: [25, 50, 100], component: 'div', count: items.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage })] }) }));
};
export default memo(DispensingTable);
