import {
	FC, MouseEvent as ReactMouseEvent, useMemo, memo,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	Breadcrumbs, Link, LinearProgress, Typography, Badge, Box,
} from '@mui/material';
import { FormikProps } from 'formik';
import { SubscriptionType } from '@pm/shared';
import {
	registrationFormStepState, selectSubscription,
} from '../../../../../../atoms';
import { StyledCardHeader, classes } from './FormHeader.styles';
import { getStepCount } from '../../../../../../util/constants';
import { getErrorsForView, useErrorState } from '../../../../../../hooks/useErrorState';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const FormHeader: FC<Props> = memo(({ formik }) => {
	const [formStep, setFormStep] = useRecoilState(registrationFormStepState);
	const subscription = useRecoilValue(selectSubscription);
	const STEP_COUNT = getStepCount();
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [_has, _count, viewedSteps, setSubStepTouched] = useErrorState(formik);

	const progress = Math.ceil((formStep / STEP_COUNT) * 100);
	const formStepTitles: string[] = [
		'Pharmacy Information',
		'Licenses and ID Information',
		'Shipping Information',
		'Ownership Information',
		'Pharmacist In Charge (PIC) Information',
		'Primary Purchaser (PP) Information',
		'Primary Wholesaler (PW) Information',
		'Trade References',
		'Accounts Payable (A/P) Information',
		'Banking Information',
		'Payments Information',
		'Additional Questions',
		'Designate Signer'
	];

	const breadCrumbTitles = ['Pharmacy', 'Licenses', 'Shipping', 'Ownership',
		'PIC', 'PP', 'PW', 'Trade References', 'AP', 'Banking', 'Payments', 'Additional', 'Signer'];
	const formStepTitle = formStepTitles[formStep - 2];

	const breadcrumbs = breadCrumbTitles.map((step: string, index: number) => {
		const stepIndex = index + 2;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const [_hasErrors, errorCount] = getErrorsForView(formik.errors, index);
		const badge = (errorCount > 0 && viewedSteps.includes(stepIndex))
			? <Badge sx={{ marginLeft: 2, marginRight: 1 }} color='error' badgeContent={errorCount} data-testid='badge' />
			: null;
		return stepIndex === formStep
			? (
				<Box key={`breadcrumb-${index}`}>
					<Typography key={step} color='textPrimary' data-testid={`${step}-breadcrumb`} sx={{ fontWeight: 'bold' }}>
						{step}
						{badge}
					</Typography>
				</Box>
			) : (
				<Box key={`breadcrumb-${index}`}>
					<Link
						key={step}
						color='inherit'
						href='#'
						underline='none'
						onClick={(event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
							event.preventDefault();
							setFormStep(stepIndex);
							setSubStepTouched();
						}}
						data-testid={`${step}-breadcrumb`}
					>
						{step}
					</Link>
					{badge}
				</Box>
			);
	});
	const subtitle = useMemo(() => (
		<div className={classes.subtitle}>
			<Typography variant='subtitle1'>
				{formStep < STEP_COUNT
					&& `Your responses are securely captured to auto-generate agreements between
					 your pharmacy and each integrated NABP-accredited wholesaler. Each party
					 can then conveniently review and sign the agreements within our embedded DocuSign viewer.`
				}
				{formStep === STEP_COUNT
					&& `Next, you will be guided through signing multiple documents.
					Please specify which pharmacy representative will be signing.`
				}
			</Typography>
		</div>
	), [formStep]);

	const cardHeaderProps: any = {
		// eslint-disable-next-line no-nested-ternary
		color: subscription === SubscriptionType.BEST
			? 'gold'
			// eslint-disable-next-line no-nested-ternary
			: subscription === SubscriptionType.PVAC
				? 'primary'
				: subscription === SubscriptionType.BASIC
					? 'rose'
					: 'success',
		className: classes.cardHeader,
	};

	return (
		<>
			<StyledCardHeader data-testid='formHeaderCardHeader' {...cardHeaderProps}>
				<Typography variant='h5'>{formStepTitle}</Typography>
				{subtitle}
			</StyledCardHeader>
			<Breadcrumbs
				sx={{
					mt: 3, mr: 2, ml: 2, mb: 0,
				}}
				aria-label='registration form step breadcrumbs'
				itemsBeforeCollapse={STEP_COUNT - 1}
				maxItems={STEP_COUNT - 1}
			>
				{breadcrumbs.map((breadcrumb) => breadcrumb)}
			</Breadcrumbs>
			<LinearProgress
				sx={{
					mt: 3, mr: -1, ml: -1, mb: 2,
				}}
				role='progressbar'
				variant='determinate'
				value={progress}
			/>
		</>
	);
});
