import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'Intro';
export const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		position: 'absolute',
		padding: theme.spacing(2),
		backgroundSize: 'cover',
		background: 'url(/img/pharmacy_1.jpg) no-repeat',
	},
	[`& .${classes.card}`]: {
		width: '90%',
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		overflowY: 'scroll',
	},
}));
