import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
export const TableHeader = memo(() => {
    const borderColor = '#1976d2';
    const text = {
        fontSize: 16,
        fontFamily: 'Roboto',
        fontStyle: 'bold',
        color: '#fff',
    };
    const styles = StyleSheet.create({
        container: {
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            flexGrow: 1,
            borderBottomColor: borderColor,
            backgroundColor: borderColor,
            borderBottomWidth: 1,
        },
        description: {
            width: '50%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            ...text,
        },
        qty: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            ...text,
        },
        uom: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            ...text,
        },
        price: {
            borderRightColor: borderColor,
            borderRightWidth: 1,
            width: '15%',
            ...text,
        },
        total: {
            width: '15%',
            ...text,
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.description, children: "Drug" }), _jsx(Text, { style: styles.qty, children: "Qty" }), _jsx(Text, { style: styles.uom, children: "UoM" }), _jsx(Text, { style: styles.price, children: "Price" }), _jsx(Text, { style: styles.total, children: "Total" })] }));
});
