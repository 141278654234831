/**
 * Give NDC a 5-4-2 format for readability.
 * e.g. 50000050010 becomes 50000-0500-10.
 */
const stringSplice = (str, idx, rem, substr) => `${str.slice(0, idx)}${substr}${str.slice(idx + Math.abs(rem))}`;
export const formatNdc = (ndc, vendorItemNumber) => {
    if (!ndc || ndc.length === 0) {
        return vendorItemNumber || 'N/A';
    }
    const unformattedNdc = ndc.replace(/-/g, '');
    return stringSplice(stringSplice(unformattedNdc, 5, 0, '-'), 10, 0, '-');
};
