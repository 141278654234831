import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Dropzone } from '../Dropzone';
;
export const FileUploader = memo(({ type, postFile }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);
    const getTypeDisplayText = () => {
        switch (type) {
            case 'dispensing':
                return 'dispensing file';
            case 'mckessoncatalogs':
                return 'McKesson catalog file';
            case 'mckessonpurchases':
                'McKesson purchases file';
        }
    };
    const typeString = getTypeDisplayText();
    const onAcceptedFiles = async (files) => {
        try {
            setUploading(true);
            const filePromises = files.map((file) => postFile(file));
            await Promise.all(filePromises);
            setUploading(false);
            enqueueSnackbar(`Successfully processed ${typeString}`, { variant: 'success' });
        }
        catch (error) {
            enqueueSnackbar(`Failed to process ${typeString}`, { variant: 'error' });
            setUploading(false);
        }
    };
    return (_jsx(Dropzone, { onAcceptedFiles: onAcceptedFiles, uploading: uploading, loadingText: typeString ? undefined : `Processing ${typeString}. Please be patient...`, promptText: typeString ? undefined : `Drag \'n\' drop ${typeString} CSV files here, or click to select files` }));
});
