/* eslint-disable max-len */
import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SubscriptionType } from '@pm/shared';
import states from '../../../../../../assets/json/us-states.json';
import pms from '../../../../../../assets/json/pms.json';
import types from '../../../../../../assets/json/pharmacy-types.json';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const PharmacyInformation: FC<Props> = memo(({ formik }) => {
	const LEGAL_CORPORATE_NAME = 'legalCorporateName';
	const STATE_OF_INCORPORATION = 'stateOfIncorporation';
	const PHARMACY_PHONE = 'pharmacyPhone';
	const PHARMACY_FAX = 'pharmacyFax';
	const PHARMACY_ADDRESS = 'pharmacyAddress';
	const PHARMACY_CITY = 'pharmacyCity';
	const PHARMACY_ZIP = 'pharmacyZip';
	const PHARMACY_STATE = 'pharmacyState';
	const PHARMACY_BUSINESS_START_DATE = 'pharmacyBusinessStartDate';
	const PHARMACY_TYPE = 'pharmacyType';
	const MANAGEMENT_SYSTEM = 'management-system';
	const FILL_OUT_OF_STATE_CUSTOMERS = 'fill-out-of-state-customers';
	const OUT_OF_STATE_CUSTOMERS_COUNT = 'out-of-state-customers-count';

	const stateOptions = useMemo(() => states.map((option) => (
		<MenuItem key={option.abbreviation} value={option.abbreviation}>
			{option.name}
		</MenuItem>
	)), []);
	const pharmacyTypes = useMemo(() => types.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);
	const pharmacyManagementSystems = useMemo(() => pms.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);

	const isDev = !import.meta.env.PROD;
	const fillValues = () => {
		const { setValues } = formik;
		setValues({
			isMultiStoreOwner: false,
			npiNumber: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)).join(''),
			coupon: undefined,
			subscription: SubscriptionType.BEST,
			signer: { name: 'testF testL', title: 'test title' },
			firstName: '-',
			lastName: '-',
			legalName: 'Bayer - Schiller',
			stateOfIncorporation: 'DE',
			email: 'mattdaronco+baa11@gmail.com',
			phone: '(579) 441-3756',
			fax: '(523) 505-2235',
			address: '5096 Feil Crossing',
			city: 'West Priscillaberg',
			state: 'DE',
			zipCode: '17306',
			dateBusinessBegan: new Date(),
			yearsInBusiness: 0,
			pharmacyType: 'Retail',
			mckessonId: '9748358',
			deaNumber: 'BE1234563',
			deaExpDate: new Date(),
			hinNumber: '86865',
			hinExpDate: new Date(),
			stateLicense: '96029',
			stateLicenseExpDate: new Date(),
			stateIdNumber: '18199',
			stateIdExpDate: new Date(),
			federalTaxNumber: '10668',
			federalTaxExpDate: new Date(),
			dunsNumber: '27748',
			ncpdpNumber: '17740',
			medicarePartB: '9144863488',
			stateSellersPermit: '7852243',
			pharmacyAddressIsShippingAddress: true,
			shippingAddress: '5096 Feil Crossing',
			shippingCity: 'West Priscillaberg',
			shippingState: 'DE',
			shippingZip: '17306',
			multipleShipToFacilities: false,
			relatedToOtherFacilities: false,
			relatedToOtherFacilitiesList: '',
			gln: '112312',
			// eslint-disable-next-line max-len
			dispensing: { fillOutOfStateCustomers: true, outOfStateCustomersCount: 23, managementSystem: 'Pioneer' },
			// eslint-disable-next-line max-len
			owner: { firstName: 'Glenda', lastName: 'Wilkinson', address: '66282 Schaden Village', city: 'North Melodychester', state: 'DE', zip: '94238', phone: '(845) 823-6297', social: '234-16-4633', percentOwned: '100', ownershipType: 'Partnership', registerType: 'Wholesaler', registerTypeOther: '' },
			picInfoSameAsOwner: true,
			// eslint-disable-next-line max-len
			pic: { firstName: 'Glenda', lastName: 'Wilkinson', phone: '(845) 823-6297', email: 'Eldora_Wolf@hotmail.com', license: '87381', licenseExpDate: new Date(), },
			ppInfoSameAsOwner: true,
			ppInfoSameAsPic: false,
			// eslint-disable-next-line max-len
			pp: { firstName: 'Glenda', lastName: 'Wilkinson', phone: '(845) 823-6297', email: 'Lucinda64@hotmail.com', fax: '', title: 'Dynamic Implementation Administrator' },
			// eslint-disable-next-line max-len
			primary: { name: 'McKesson', address: '41933 Gislason Rapids', city: 'West Hayley', zip: '23930', state: 'KY', phone: '(382) 699-2468', fax: '', psao: 'APCI', psoaOther: '' },
			apInfoSameAsOwner: false,
			// eslint-disable-next-line max-len
			ap: { firstName: 'Mikayla', lastName: 'Dietrich', phone: '(340) 932-1456', email: 'Horace_Moore29@hotmail.com' },
			banking: {
				bankName: 'Rowe, Volkman and Dare',
				bankAddress: '4109 Roy Mission',
				bankCity: 'Pfefferbury',
				bankZip: '42007',
				bankState: 'DE',
				accountOfficer: 'Ruth Lockman',
				accountNumber: '21358019',
				routingNumber: '042757272',
				bankPhone: '(192) 076-4528',
				accountType: 'Checking',
				initialChargeAmount: 0,
				initialChargeAmountDesc: '',
				monthlyChargeAmount: 300,
				receiveInvoices: 'Email',
				accountToBeDebited: 'By Invoice',
				accountToBeDebitedOther: '',
			},
			payments: {
				billingAddress: '111 Billing Rd',
				billingCity: 'Birmingham',
				billingState: 'AL',
				billingZip: '23422',
				cardNumber: '',
				cardType: '',
				cardExpDate: '',
				cardCvc: '',
				cardZip: '',
				bluepax_secondary: { paymentMethod: 'ACH' },
				capital_secondary: { paymentMethod: 'ACH' },
				rx_supply_secondary: { paymentMethod: 'ACH' },
				bloodworth_secondary: { paymentMethod: 'ACH' },
				keysource_secondary: { paymentMethod: 'ACH' },
				toprx_secondary: { paymentMethod: 'ACH' },
				auburn_secondary: { paymentMethod: 'ACH' },
				ipc_secondary: { paymentMethod: 'ACH' },
			},
			tradeRefSameAsPw: true,
			// eslint-disable-next-line max-len
			tradeReference1: { name: 'McKesson', address: '41933 Gislason Rapids', city: 'West Hayley', zip: '23930', state: 'KY', phone: '(382) 699-2468', accountNumber: '9748358' },
			// eslint-disable-next-line max-len
			tradeReference2: { name: 'O\'Conner Inc', address: '5813 Kirlin Alley', city: 'West Elfrieda', state: 'IL', zip: '15888', phone: '(501) 613-3361', accountNumber: '' },
			convicted: true,
			bankruptcy: true,
			// eslint-disable-next-line max-len
			convictedExplanation: 'Dollar steradian Home male Unbranded Latin Burundi Electronic Northeast Berkshire Soap North Bedfordshire cohesive concerning Northeast Strontium Liberia seamless sky',
			// eslint-disable-next-line max-len
			bankruptcyExplanation: 'aw Kentwood Chlorine Mazda Peso Gibraltar Investor boohoo edge transmitting plus Southeast IB Agent after Lutetium Phosphorus Demiflux DNS Bicycle',
			name: 'test pharmacy',
			title: 'test',
		});
	};

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const dispensing = values?.dispensing ?? {} as PharmacyPostData['dispensing'];
	const { dispensing: dispensingErrors = {} } = errors;
	const { dispensing: dispensingTouched = {} } = touched;

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			{isDev
				&& (<Grid item xs={12}>
					<Button onClick={() => fillValues()}>Fill Values</Button>
				</Grid>)
			}
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='legalName'
					label='Legal Corporate Name'
					inputProps={{
						'data-testid': LEGAL_CORPORATE_NAME
					}}
					type='text'
					variant='outlined'
					value={values.legalName}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.legalName && Boolean(errors.legalName)}
					helperText={touched.legalName && errors.legalName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='stateOfIncorporation'
					label='State Of Incorporation'
					data-testid={STATE_OF_INCORPORATION}
					type='text'
					variant='outlined'
					value={values.stateOfIncorporation}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.stateOfIncorporation && Boolean(errors.stateOfIncorporation)}
					helperText={touched.stateOfIncorporation && errors.stateOfIncorporation}
				>
					{stateOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='phone'
					label='Pharmacy Phone'
					data-testid={PHARMACY_PHONE}
					type='text'
					variant='outlined'
					value={values.phone}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={touched.phone && Boolean(errors.phone)}
					helperText={touched.phone && errors.phone}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					name='fax'
					label='Pharmacy Fax'
					data-testid={PHARMACY_FAX}
					type='text'
					variant='outlined'
					value={values.fax}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={touched.fax && Boolean(errors.fax)}
					helperText={touched.fax && errors.fax}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='address'
					label='Pharmacy Address'
					data-testid={PHARMACY_ADDRESS}
					type='text'
					variant='outlined'
					value={values.address}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.address && Boolean(errors.address)}
					helperText={touched.address && errors.address}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='city'
					label='Pharmacy City'
					data-testid={PHARMACY_CITY}
					type='text'
					variant='outlined'
					value={values.city}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.city && Boolean(errors.city)}
					helperText={touched.city && errors.city}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='state'
					label='Pharmacy State'
					data-testid={PHARMACY_STATE}
					type='text'
					variant='outlined'
					value={values.state}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.state && Boolean(errors.state)}
					helperText={touched.state && errors.state}
				>
					{stateOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='zipCode'
					label='Pharmacy Zip Code'
					inputProps={{
						maxLength: 5
					}}
					data-testid={PHARMACY_ZIP}
					type='text'
					variant='outlined'
					value={values.zipCode}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.zipCode && Boolean(errors.zipCode)}
					helperText={touched.zipCode && errors.zipCode}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<DatePicker
					label='Date Pharmacy Began Business'
					value={values.dateBusinessBegan}
					onChange={(date) => {
						formik.setFieldValue('dateBusinessBegan', date);
						const startYear = dayjs(date).year();
						const currentYear = dayjs().year();
						const yearsInBusiness = currentYear - startYear;
						formik.setFieldValue('yearsInBusiness', yearsInBusiness);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('dateBusinessBegan', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required
							name='dateBusinessBegan'
							data-testid={PHARMACY_BUSINESS_START_DATE}
							type='text'
							variant='outlined'
							onBlur={handleBlur}
							error={touched.dateBusinessBegan && Boolean(errors.dateBusinessBegan)}
							helperText={touched.dateBusinessBegan && errors.dateBusinessBegan}
						/>
					)}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='pharmacyType'
					data-testid={PHARMACY_TYPE}
					label='Pharmacy Type'
					type='text'
					variant='outlined'
					value={formik.values.pharmacyType}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.pharmacyType && Boolean(formik.errors.pharmacyType)}
					helperText={formik.touched.pharmacyType && formik.errors.pharmacyType}
				>
					{pharmacyTypes}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='dispensing.managementSystem'
					label='PMS'
					type='text'
					variant='outlined'
					value={dispensing.managementSystem}
					inputProps={{
						'data-testid': MANAGEMENT_SYSTEM
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={dispensingTouched.managementSystem && Boolean(dispensingErrors.managementSystem)}
					helperText={dispensingTouched.managementSystem && dispensingErrors.managementSystem}
				>
					{pharmacyManagementSystems}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={dispensing.fillOutOfStateCustomers}
							onChange={(e, checked) => {
								handleChange(e);
								if (!checked) {
									formik.setFieldError('dispensing.outOfStateCustomersCount', undefined);
								}
							}}
							name='dispensing.fillOutOfStateCustomers'
							color='primary'
							data-testid={FILL_OUT_OF_STATE_CUSTOMERS}
						/>
					}
					label={
						<Typography variant='body2'>
							Does this pharmacy fill prescriptions for out-of-state customers?
						</Typography>
					}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required={dispensing.fillOutOfStateCustomers}
					name='dispensing.outOfStateCustomersCount'
					label='Out-of-State Rxs'
					type='number'
					variant='outlined'
					value={dispensing.outOfStateCustomersCount}
					inputProps={{
						'data-testid': OUT_OF_STATE_CUSTOMERS_COUNT
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					// eslint-disable-next-line max-len
					error={dispensingTouched.outOfStateCustomersCount && Boolean(dispensingErrors.outOfStateCustomersCount)}
					helperText={(dispensingTouched.outOfStateCustomersCount
						&& dispensingErrors.outOfStateCustomersCount) || '# of Rxs filled out of state monthly'}
				/>
			</Grid>
		</Grid>
	);
});
