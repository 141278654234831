import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
;
const Dropzone = ({ onAcceptedFiles, loadingText = 'Processing dispensing file. Please be patient...', promptText = 'Drag \'n\' drop dispensing CSV files here, or click to select files', uploading = false, ...rest }) => {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: { 'application/csv': ['.csv'] },
        maxFiles: 1,
        maxSize: 2e+7,
        ...rest,
    });
    const dzStyle = {
        flex: 1,
        height: 175,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };
    const activeStyle = { borderColor: '#2196f3' };
    const acceptStyle = { borderColor: '#00e676' };
    const rejectStyle = { borderColor: '#ff1744' };
    const style = useMemo(() => ({
        ...dzStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [isDragActive, isDragReject, isDragAccept]);
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            onAcceptedFiles(acceptedFiles);
        }
    }, [acceptedFiles]);
    let textToDisplay = _jsx(_Fragment, {});
    if (uploading) {
        textToDisplay = _jsx("p", { children: loadingText });
    }
    else if (isDragActive) {
        textToDisplay = _jsx("p", { children: "Drop the files here ..." });
    }
    else {
        textToDisplay = _jsx("p", { children: promptText });
    }
    return (_jsxs("div", { ...getRootProps({ style }), children: [_jsx("input", { ...getInputProps(), "data-testid": 'dropzone' }), textToDisplay, uploading && _jsx(CircularProgress, { color: 'primary', sx: { mt: 1 } })] }));
};
export default memo(Dropzone);
