import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'RegistrationFormCard';
export const classes = {
	card: `${PREFIX}-card`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.card}`]: {
		padding: theme.spacing(0, 1),
	},
}));
