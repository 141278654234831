import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
;
export const FetchError = memo(({ message, imgHeight = 350, icon, style = {}, }) => {
    const bucket = process.env.REACT_APP_IMAGE_ASSET_S3_BUCKET;
    const imgUrl = `https://${bucket}.s3.amazonaws.com/pharmacist_graphic.png`;
    return (_jsxs(Stack, { spacing: 2, alignItems: 'center', justifyContent: 'center', style: {
            height: '100%',
            width: '100%',
            margin: '0px auto',
            textAlign: 'center',
            ...style,
        }, children: [_jsx("img", { src: imgUrl, height: imgHeight, alt: 'Pharmacist Graphic', style: { borderRadius: 15, } }), _jsxs("div", { style: { display: 'inline-flex', alignItems: 'center', gap: 5, }, children: [icon || _jsx(ErrorOutline, {}), _jsx(Typography, { variant: 'subtitle1', style: { fontWeight: 'bold', }, children: message })] })] }));
});
