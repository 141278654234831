import { styled } from '@mui/material/styles';

const PREFIX = 'Requirements';
export const classes = {
	container: `${PREFIX}-container`,
	header: `${PREFIX}-header`,
	icon: `${PREFIX}-icon`,
};
export const StyledDiv = styled('div')(({ theme }) => ({
	[`&.${classes.container}`]: {
		backgroundColor: '#e0e0e0',
		borderRadius: 5,
		padding: theme.spacing(1),
	},
	[`& .${classes.header}`]: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 4,
	},
	[`& .${classes.icon}`]: {
		color: '#546e7a',
	},
}));
