import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Initials } from '../Initials';
;
export const PageThree = memo(({ traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        entryContainer: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        entry: {
            paddingBottom: 12,
            fontSize: 10,
        },
        entryFooter: {
            paddingTop: 20,
            fontSize: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        boldUnderlineHeader: {
            fontSize: 11,
            textDecoration: 'underline',
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        spacerBig: {
            paddingBottom: 35,
        },
        spacer: {
            paddingBottom: 25,
        },
        termText: {
            fontSize: 8,
        },
    });
    const entries = useMemo(() => {
        const { owner, } = traderInfo;
        const date = dayjs().format('MM/DD/YYYY');
        return [
            { text: '', style: styles.spacerBig, },
            {
                text: 'I/We certify that A) all information provided in this Application and Credit Agreement is true and complete, B) I/we have read and agree to the terms thereof, and C) I/we enter into this Application and Credit Agreement on behalf of the Applicant and, with respect to the Guaranty set forth above, in my individual capacity, as applicable.',
                style: styles.entry,
            },
            {
                text: 'PSI makes no warranties, express, implied or statutory, including without limitation: Implied warranties of merchantability, fitness for a particular purpose, of title and against infringement. Applicant agrees that all goods are sold "as is" and "with all faults."',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'SIGNATURES--GUARANTORS / OWNERS / AUTHORIZED OFFICERS:',
                style: styles.boldUnderlineHeader,
            },
            { text: '', style: styles.spacer, },
            {
                text: `Signature:                                                    Printed name:  ${owner.firstName} ${owner.lastName}`,
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: `Title:  Owner     Date: ${date}`,
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Signature: _______________________     Printed name: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Title: _______________________          Date: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'SIGNATURES--AUTHORIZED PURCHASING AND RECEIVING AGENTS OF SCHEDULED DRUGS:',
                style: styles.boldUnderlineHeader,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Signature: _______________________     Printed name: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Title: _______________________     Date: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Signature: _______________________     Printed name: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacer, },
            {
                text: 'Title: _______________________     Date: _______________________',
                style: styles.entry,
            },
            { text: '', style: styles.spacerBig, },
            { text: 'Revised: 8/29/19', style: styles.termText, },
            { text: '', style: styles.spacerBig, },
            {
                text: 'Please attach a copy of the following: DEA License, State Pharmacy License, Resale certificate, pharmacy inspection, Tax exempt form, Copy of owners Drivers license, and 3 months of statements if applying for terms.',
                style: styles.entryFooter,
            }
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(Initials, { pageNum: 3 }), _jsx(View, { style: styles.entryContainer, children: entries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) })] }) }));
});
