import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const Checkbox = memo(({ checkmark, text, }) => {
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
        },
        checkbox: {
            borderWidth: 1,
            marginRight: 10,
            width: 20,
        },
        entryCheckbox: {
            paddingBottom: 10,
            fontSize: 15,
        },
    });
    return (_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.checkbox, children: checkmark }), _jsx(Text, { style: styles.entryCheckbox, children: text })] }));
});
