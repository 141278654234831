import { styled } from '@mui/material/styles';
const PREFIX = 'DispensingDetailsDialogSkeleton';
export const classes = {
    container: `${PREFIX}-container`,
};
export const Root = styled('div')(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: 900,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));
