type SegmentAction = 'track' | 'identify' | 'group' | 'page';

const segmentAction = (
	eventId: string,
	eventData?: Record<string, any>,
	action: SegmentAction = 'track',
// eslint-disable-next-line consistent-return
) => {
	const { analytics } = (window as any);
	if (action === 'page') {
		return analytics.page(eventId);
	}
	analytics[action](eventId, eventData);
};

export default segmentAction;
