import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import {
	Grid, MenuItem, TextField, Theme, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SelectState } from '@pm/shared';
import bankAccountTypes from '../../../../../../assets/json/bank-account-types.json';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';
import { MonthlyChargeAmount } from './components';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const BankingInformation: FC<Props> = memo(({ formik }) => {
	const BANK_NAME = 'bank-name';
	const BANK_ADDRESS = 'bank-address';
	const BANK_CITY = 'bank-city';
	const BANK_ZIP = 'bank-zip';
	const BANK_ACCOUNT_NUMBER = 'bank-account-number';
	const BANK_ROUTING_NUMBER = 'bank-routing-number';
	const BANK_PHONE = 'bank-phone';
	const BANK_ACCOUNT_TYPE = 'bank-account-type';
	const BANK_ACCOUNT_OFFICER = 'bank-account-officer';

	const theme: Theme = useTheme();
	const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
	const md = xlUp ? 3 : 2;

	const accountTypes = useMemo(() => bankAccountTypes.map(({ option }) => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	)), []);

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const banking = values?.banking ?? {} as PharmacyPostData['banking'];
	const { banking: bankingErrors = {} } = errors;
	const { banking: bankingTouched = {} } = touched;

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='flex-start'
		>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.bankName'
					label='Bank Name'
					type='text'
					variant='outlined'
					value={banking.bankName}
					data-testid={BANK_NAME}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.bankName && Boolean(bankingErrors.bankName)}
					helperText={bankingTouched.bankName && bankingErrors.bankName}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.bankAddress'
					label='Bank Address'
					type='text'
					variant='outlined'
					value={banking.bankAddress}
					data-testid={BANK_ADDRESS}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.bankAddress && Boolean(bankingErrors.bankAddress)}
					helperText={bankingTouched.bankAddress && bankingErrors.bankAddress}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.bankCity'
					label='Bank City'
					type='text'
					variant='outlined'
					value={banking.bankCity}
					data-testid={BANK_CITY}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.bankCity && Boolean(bankingErrors.bankCity)}
					helperText={bankingTouched.bankCity && bankingErrors.bankCity}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<SelectState
					onChange={handleChange}
					onBlur={handleBlur}
					value={banking.bankState}
					name={'banking.bankState'}
					label={'State'}
					error={!!bankingTouched.bankState && Boolean(bankingErrors.bankState)}
					helperText={!!bankingTouched.bankState && !!bankingErrors.bankState}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.bankZip'
					label='Bank Zip Code'
					type='text'
					variant='outlined'
					value={banking.bankZip}
					inputProps={{
						maxLength: 5
					}}
					data-testid={BANK_ZIP}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.bankZip && Boolean(bankingErrors.bankZip)}
					helperText={bankingTouched.bankZip && bankingErrors.bankZip}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.accountNumber'
					label='Account Number'
					type='password'
					variant='outlined'
					value={banking.accountNumber}
					data-testid={BANK_ACCOUNT_NUMBER}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.accountNumber && Boolean(bankingErrors.accountNumber)}
					helperText={bankingTouched.accountNumber && bankingErrors.accountNumber}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.routingNumber'
					label='Routing Number'
					type='text'
					variant='outlined'
					value={banking.routingNumber}
					data-testid={BANK_ROUTING_NUMBER}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.routingNumber && Boolean(bankingErrors.routingNumber)}
					helperText={bankingTouched.routingNumber && bankingErrors.routingNumber}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.bankPhone'
					label='Bank Phone'
					type='text'
					variant='outlined'
					value={banking.bankPhone}
					data-testid={BANK_PHONE}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={bankingTouched.bankPhone && Boolean(bankingErrors.bankPhone)}
					helperText={bankingTouched.bankPhone && bankingErrors.bankPhone}
				/>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='banking.accountType'
					label='Account Type'
					type='text'
					variant='outlined'
					value={banking.accountType}
					data-testid={BANK_ACCOUNT_TYPE}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.accountType && Boolean(bankingErrors.accountType)}
					helperText={bankingTouched.accountType && bankingErrors.accountType}
				>
					{accountTypes}
				</TextField>
			</Grid>
			<Grid item md={md} xs={12}>
				<TextField
					fullWidth
					required
					name='banking.accountOfficer'
					label='Account Officer'
					type='text'
					variant='outlined'
					value={banking.accountOfficer}
					inputProps={{
						'data-testid': BANK_ACCOUNT_OFFICER
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={bankingTouched.accountOfficer && Boolean(bankingErrors.accountOfficer)}
					helperText={bankingTouched.accountOfficer && bankingErrors.accountOfficer}
				/>
			</Grid>
			<MonthlyChargeAmount
				subType={values.subscription}
				discountApplied={!!values.coupon}
			/>
		</Grid>
	);
});
