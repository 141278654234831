// @ts-nocheck
import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import { Link, Typography } from '@mui/material';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { EventEmitter, SET_CC_TYPE } from '../../emitter';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { CreditCard } from '../../services/types';

type Props = Pick<FormikProps<any>, 'handleBlur' | 'handleChange' | 'setFieldValue'>
& CreditCard;

export const CreditCardInput: FC<Props> = memo(({
	cardNumber, cardType, cardExpDate, cardCvc, cardZip,
	handleBlur, handleChange, setFieldValue,
}) => {
	const {
		meta,
		getCardImageProps,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps,
		getZIPProps,
		wrapperProps,
	} = usePaymentInputs();

	useDidUpdate(() => {
		onSetCardType();
	}, [meta.cardType]);

	const onSetCardType = () => {
		const newCardType = get(meta, 'cardType.displayName', false);
		if (newCardType) {
			setFieldValue('payments.cardType', newCardType);
			EventEmitter.emit(SET_CC_TYPE, newCardType);
		}
	};

	const prompt = useMemo(() => {
		const linkProps: any = {
			underline: 'none', target: '_blank', rel: 'noreferrer',
		};
		const link1 = (
			<Link
				{...linkProps}
				href='https://en.wikipedia.org/wiki/Advanced_Encryption_Standard'
			>
                AES-256
			</Link>
		);
		const link2 = (
			<Link
				{...linkProps}
				href='https://en.wikipedia.org/wiki/HTTPS'
			>
                HTTPS/TLS
			</Link>
		);
		return (
			<div style={{ width: 400 }}>
				<Typography gutterBottom variant='caption'>
                    All of your sensitive information is securely encrypted
                    both in transit and at rest using {link1} and {link2}.
				</Typography>
			</div>
		);
	}, []);

	return (
		<>
			{prompt}
			<PaymentInputsWrapper {...wrapperProps}>
				<svg {...getCardImageProps({ images })} />
				<input
					value={cardType}
					name='payments.cardType'
					type='hidden'
				/>
				<input
					{...getCardNumberProps({
						type: 'text',
						name: 'payments.cardNumber',
						value: cardNumber,
						onBlur: handleBlur,
						onChange: handleChange,
					})}
				/>
				<input
					{...getExpiryDateProps({
						type: 'text',
						name: 'payments.cardExpDate',
						value: cardExpDate,
						onBlur: handleBlur,
						onChange: handleChange,
					})}
				/>
				<input
					{...getCVCProps({
						type: 'text',
						name: 'payments.cardCvc',
						value: cardCvc,
						onBlur: handleBlur,
						onChange: handleChange,
					})}
				/>
				<input
					{...getZIPProps({
						type: 'text',
						name: 'payments.cardZip',
						value: cardZip,
						onBlur: handleBlur,
						onChange: handleChange,
					})}
				/>
			</PaymentInputsWrapper>
		</>
	);
});
