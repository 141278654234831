import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { onboardStepState } from '../atoms';

export const useValidateStep = () => {
	const history = useHistory();
	const location = useLocation();
	const [step] = useRecoilState(onboardStepState);

	const onValidateStep = () => {
		const { pathname } = location;
		const pathnameStep = parseInt(pathname.slice(-1), 10);
		if (pathnameStep !== step) {
			history.push(`/step/${step}`);
		}
	};

	useEffect(() => {
		onValidateStep();
	}, [step]);

	return step;
};
