import { Grid, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'Thumbnail';
export const classes = {
	card: `${PREFIX}-card`,
	cardMedia: `${PREFIX}-cardMedia`,
	icon: `${PREFIX}-icon`,
	popover: `${PREFIX}-popover`,
	paper: `${PREFIX}-paper`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.card}`]: {
		height: 75,
		width: 75,
	},
	[`& .${classes.cardMedia}`]: {
		height: 0,
		paddingTop: '100%',
	},
	[`& .${classes.icon}`]: {
		position: 'absolute',
		marginLeft: 58,
		marginTop: -14,
		color: theme.palette.error.main,
		cursor: 'pointer',
	},
}));
export const StyledPopover = styled(Popover)(({ theme }) => ({
	[`&.${classes.popover}`]: {
		pointerEvents: 'none',
	},
	[`& .${classes.paper}`]: {
		padding: theme.spacing(1),
	},
}));
