import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { useEffect, useState, memo, } from 'react';
import { Button, Dialog, DialogContent, DialogActions, } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FetchError } from '../components/FetchError';
import DispensingTable from './DispensingTable';
import { DispensingTableSkeleton } from './DispensingTableSkeleton';
;
;
const DispensingDetailsDialog = ({ date, npiNumber, onClose, getDispensingByDate, }) => {
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    const open = date !== null;
    const showTable = !fetching && !error;
    const fetchDispensingDataByDate = async () => {
        if (date) {
            setFetching(true);
            setError(false);
            try {
                const dispDate = dayjs(date).toDate();
                const res = await getDispensingByDate(npiNumber, dispDate);
                setItems(res);
                setFetching(false);
            }
            catch (_error) {
                setError(true);
                setFetching(false);
            }
        }
    };
    useEffect(() => {
        fetchDispensingDataByDate();
    }, [date]);
    return (_jsxs(Dialog, { "aria-labelledby": 'dispensing-details', "aria-describedby": 'dispensing-details', open: open, onClose: onClose, maxWidth: 'lg', keepMounted: false, children: [_jsxs(DialogContent, { children: [fetching && _jsx(DispensingTableSkeleton, {}), !fetching && error
                        && _jsx(FetchError, { message: 'Failed to load dispensing records.' }), showTable
                        && _jsx(DispensingTable, { date: date, items: items })] }), _jsx(DialogActions, { children: _jsx(Button, { variant: 'outlined', endIcon: _jsx(CloseIcon, {}), onClick: onClose, children: "Close" }) })] }));
};
export default memo(DispensingDetailsDialog);
