import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Initials } from '../../../DocumentSeven/components/Initials';
import { ListItem, LetterListItem } from '../ListItem';
import RxSupply from '../../../../../../images/rxsupply-logo-2.png';
export const PageOne = memo(() => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        imgContainer: {
            padding: '0px 20px 8px',
            alignItems: 'center',
        },
        image: {
            height: 70,
        },
        entryContainer: {
            padding: '0px 20px',
        },
        security: {
            fontSize: 18,
            color: '#003399',
            textAlign: 'center',
            paddingBottom: 16,
        },
        termText: {
            fontSize: 8,
            paddingBottom: 8,
        },
    });
    const items = useMemo(() => {
        return [
            {
                text: 'Debtor hereby grants to Secured Party a security interest in the Collateral described in Paragraph 2 to secure the performance and payment of all indebtedness of the Debtor to the Secured Party now or hereafter existing. Indebtedness shall be defined as all liabilities and obligations of the Debtor to the Secured party now existing or hereafter incurred, matured or unmatured, direct or contingent in any renewals and extensions thereof and substitutions therefore.',
                number: 1,
            },
            {
                text: 'The Collateral of the Security Agreement, herein referred to as “-Collateral", consists of the following described property: All currently owned or hereafter acquired property and assets of the debtor of any kind or nature, whether real or personal, tangible or intangible, wherever located, and all proceeds, products, rents and profits thereof, including without limitation all health care insurance receivables, cash, goods, accounts, inventory, cash in advance deposits, general intangibles, investment property, deposit accounts, letter of credit rights, instruments, real estate, fixtures, machinery, equipment, vehicles, trademarks, trade names, licenses, claims and causes of action, rights to payment, including tax refund claims, insurance proceeds and tort claims and the proceeds, products, rents and profits of all of the foregoing.',
                number: 2,
            },
            {
                text: 'Debtor hereby grants to Secured Party a security interest in and to all proceeds of Collateral as defined by the Uniform Commercial Code.',
                number: 3,
            },
            {
                text: 'If in the judgment of the Secured Party, the Collateral materially decreases in value or if the Secured Party shall meantime deem itself insecure, the Debtor shall either provide additional Collateral sufficient to satisfy the Secured Party or reduce the total indebtedness by an amount sufficient to satisfy Secured Party.',
                number: 4,
            },
            {
                text: 'At the request of the Secured Party, Debtor will join in executing all necessary financing statements in a form satisfactory to Secured Party. Debtor will further execute all other instruments deemed necessary by Secured Party. Debtor warrants that no financing statement covering the Collateral described in this Security Agreement, or any part thereof, or any proceeds thereof, is presently pledged or secured to any other person or entity except as:',
                number: 5,
            },
            {
                text: 'So long as Debtor is not in default in the payment of any amount due to secured party. Debtor shall have the right to sell the inventory in the usual and ordinary course of business.',
                number: 6,
            },
            {
                text: 'Debtor shall insure the Collateral with companies acceptable to the Secured Party in an amount not less than the outstanding indebtedness at any one time. Such insurance shall be for the benefit of the Debtor and Secured Party as their interest may appear. The Secured Party is hereby authorized to collect any sums that may become due under any of such insurance policies and to apply the sums to the obligations securedhereby.',
                number: 7,
            },
            {
                text: 'Debtor agrees to keep all Collateral free from any adverse lien, security interest or encumbrance and in good condition, and agrees not to waste or destroy any of the Collateral and further agrees not to remove any of the Collateral from the address shown below.',
                number: 8,
            },
            {
                text: 'Debtor agrees to permit Secured Party or its agents the right to examine and inspect the Collateral wherever located and to examine, inspect and make abstract from Debtor\'s books and records with respect to the Collateral. Debtor shall keep accurate and complete records of the Collateral and shall, if requested by Secured Party, submit to the Secured Party periodic statements regarding Debtor\'s financial status.',
                number: 9,
            },
            {
                text: 'When performing any act under the Security Agreement, time shall be of the essence.',
                number: 10,
            },
            {
                text: 'The failure of the Secured Party to exercise any right or remedy, including Secured Party\'s acceptance of partial or delinquent payments, shall not be a waiver of any obligation of the Debtor or right of the Secured Party or constitute a waiver of any civil or default subsequently occurring.',
                number: 11,
            },
            {
                text: 'The following events shall constitute a default on the part of the debtor:',
                number: 12,
            },
            {
                text: 'Failure to pay when due any amount payable to Secured Party for merchandise or for any other indebtedness of the debtor secured hereby, in which event the entire balance then due and owing shall immediately become due and payable without further notice to Debtor.',
                letter: 'a',
                number: -1,
            },
            {
                text: 'Failure of the Debtor to observe or perform any of the provisions of this agreement including, but not limited to, the following:',
                letter: 'b',
                number: -1,
            },
            {
                text: 'The making of any warranty, representation or statement made or furnished to the Secured Party by or on behalf of the Debtor proven to have been false in any material respect when made or furnished.',
                letter: 'i',
                indent: true,
                number: -1,
            },
            {
                text: 'Failure of the Debtor after request by the Secured Party to furnish financial information.',
                letter: 'ii',
                indent: true,
                number: -1,
            },
            {
                text: 'Any loss, theft, damage, damage, destruction, sale or encumbrance to any of the Collateral not covered by insurance or the making of any levy, seizure or attachment thereon.',
                letter: 'iii',
                indent: true,
                number: -1,
            },
            {
                text: 'Death, dissolution, termination of existence, insolvency, appointment of a receiver, assignment for the benefit of creditors or the commencement of any proceedings under any bankruptcy or insolvency laws by or against Debtor.',
                letter: 'iv',
                indent: true,
                number: -1,
            },
            {
                text: 'Any change in the condition or affairs, financial or otherwise, of Debtor, which in the opinion of Secured Party impairs Secured Party\'s security or increases its risk.',
                letter: 'v',
                indent: true,
                number: -1,
            },
            {
                text: 'In the event of any default by the Debtor or at the election of the Secured Party pursuant to the preceding paragraph and any time thereafter:',
                number: 15,
            },
            {
                text: 'The Secured Party may declare all obligations secured hereby immediately due and payable and may proceed to enforce payment of all the obligations and exercise any and all of the rights and remedies provided by the Uniform Commercial Code as well as any and all other rights and remedies possessed by Secured party.',
                letter: 'a',
                number: -1,
            },
            {
                text: 'The Secured Party shall have the right to remove Collateral from Debtor\'s premises. For purposes of removal and possession of Collateral, Secured Party or its representative may enter any premises of Debtor without legal process. Debtor hereby',
                letter: 'b',
                number: -1,
            }
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(View, { style: styles.imgContainer, children: _jsx(Image, { style: styles.image, src: RxSupply }) }), _jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.security, children: "Security Agreement" }), _jsx(Text, { style: styles.termText, children: "AGREEMENT made between the Undersigned Debtor and PRESCRIPTION SUPPLY, INC., hereinafter referred to as \"Secured Party\". In consideration of the mutual covenants and promises set forth herein, Debtor and Secured Party agree as follows:" }), items.map((item, index) => item.letter
                            ? _jsx(LetterListItem, { ...item }, index)
                            : _jsx(ListItem, { ...item }, index))] }), _jsx(Initials, { pageNum: 1 })] }) }));
});
