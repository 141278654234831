import { atom, selector } from 'recoil';
import { localStorageEffect } from './effects';

type State = number;

const defaultState: State = 1;
export const onboardStepKey = 'onboardStep';
const registrationFormStepKey = 'registrationFormStep';
const onboardCompletedKey = 'onboardCompleted';
export const fingerprintEpochPostfixKey = 'fingerprintEpochPostfix';

export const onboardStepState = atom<State>({
	key: onboardStepKey,
	default: defaultState,
	effects: [
		localStorageEffect<State>(onboardStepKey),
	],
});

export const registrationFormStepState = atom<State>({
	key: registrationFormStepKey,
	default: defaultState,
	effects: [
		localStorageEffect<State>(registrationFormStepKey),
	],
});

export const selectIsFirstFormStep = selector<boolean>({
	key: 'isFirstFormStep',
	get: ({ get: getState }) => {
		const step = getState(registrationFormStepState);
		return step === 1;
	},
	cachePolicy_UNSTABLE: {
		eviction: 'most-recent',
	},
});

export const selectIsThirdFormStep = selector<boolean>({
	key: 'isThirdFormStep',
	get: ({ get: getState }) => {
		const step = getState(registrationFormStepState);
		return step === 3;
	},
	cachePolicy_UNSTABLE: {
		eviction: 'most-recent',
	},
});

export const onboardCompleted = atom<boolean>({
	key: onboardCompletedKey,
	default: false,
	effects: [
		localStorageEffect<boolean>(onboardCompletedKey),
	],
});

export const fingerprintEpochPostfix = atom<string | null>({
	key: fingerprintEpochPostfixKey,
	default: null,
	effects: [
		localStorageEffect<string | null>(fingerprintEpochPostfixKey),
	],
});
