import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
	Checkbox, FormControlLabel, Grid, TextField,
} from '@mui/material';
import { PhoneInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const PICInformation: FC<Props> = memo(({ formik }) => {
	const PIC_IS_OWNER = 'pic-is-owner';
	const PIC_LICENSE_NUMBER = 'pic-license-number';
	const PIC_LICENSE_NUMBER_EXP_DATE = 'pic-license-number-exp-date';
	const PIC_EMAIL = 'pic-email';

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const { pic: picErrors = {} } = errors;
	const { pic: picTouched = {} } = touched;
	const { picInfoSameAsOwner, email } = values;
	const owner = values?.owner ?? {} as PharmacyPostData['owner'];
	const pic = values?.pic ?? {} as PharmacyPostData['pic'];

	const updatePICFields = (useOwnerInfo: boolean) => {
		const keys = ['pic.firstName', 'pic.lastName', 'pic.phone', 'pic.email'];
		const vals = useOwnerInfo
			? [owner.firstName, owner.lastName, owner.phone, email]
			: [pic.firstName, pic.lastName, pic.phone, pic.email];
		let index = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			formik.setFieldValue(keys[index], vals[index]);
			formik.setFieldError(key, undefined);
			index += 1;
		}
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={picInfoSameAsOwner}
							onChange={(e, checked) => {
								handleChange(e);
								updatePICFields(checked);
							}}
							name='picInfoSameAsOwner'
							color='primary'
						/>
					}
					label='PIC&apos;s contact information is the same as owner&apos;s'
					data-testid={PIC_IS_OWNER}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pic.firstName'
					label='PIC First Name'
					type='text'
					variant='outlined'
					value={pic.firstName}
					onChange={handleChange}
					onBlur={handleBlur}
					error={picTouched.firstName && Boolean(picErrors.firstName)}
					helperText={picTouched.firstName && picErrors.firstName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pic.lastName'
					label='PIC Last Name'
					type='text'
					variant='outlined'
					value={pic.lastName}
					onChange={handleChange}
					onBlur={handleBlur}
					error={picTouched.lastName && Boolean(picErrors.lastName)}
					helperText={picTouched.lastName && picErrors.lastName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pic.email'
					label='PIC Email'
					type='email'
					variant='outlined'
					value={pic.email}
					data-testid={PIC_EMAIL}
					onChange={handleChange}
					onBlur={handleBlur}
					error={picTouched.email && Boolean(picErrors.email)}
					helperText={picTouched.email && picErrors.email}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pic.phone'
					label='PIC Phone'
					type='text'
					variant='outlined'
					value={pic.phone}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={picTouched.phone && Boolean(picErrors.phone)}
					helperText={picTouched.phone && picErrors.phone}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='pic.license'
					label='PIC License Number'
					type='text'
					variant='outlined'
					value={pic.license}
					inputProps={{
						'data-testid': PIC_LICENSE_NUMBER
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={picTouched.license && Boolean(picErrors.license)}
					helperText={picTouched.license && picErrors.license}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<DatePicker
					label='PIC License Number Exp. Date'
					value={pic.licenseExpDate}
					onChange={(date) => {
						formik.setFieldValue('pic.licenseExpDate', date);
						if (date?.toString() !== 'Invalid Date') {
							formik.setFieldError('pic.licenseExpDate', undefined);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required
							name='pic.licenseExpDate'
							id='date-picker-dialog-8'
							type='text'
							variant='outlined'
							onBlur={handleBlur}
							data-testid={PIC_LICENSE_NUMBER_EXP_DATE}
							error={picTouched.licenseExpDate && Boolean(picErrors.licenseExpDate)}
							helperText={picTouched.licenseExpDate && picErrors.licenseExpDate}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
});
