import { FC, memo } from 'react';
import {
	Switch, Redirect, Route, useLocation,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { onboardStepState } from '../../../../atoms';
import { Intro } from '../../../Intro';
// import { UserAgreements } from '../../../UserAgreements';
import { Registration } from '../../../Registration';
// import { WholesalerAgreements } from '../../../WholesalerAgreements';
import { Root, classes } from './Steps.styles';
import FinalStep from '../../../Registration/components/RegistrationFormCard/components/FinalStep/FinalStep';

export const Steps: FC = memo(() => {
	const location = useLocation();
	const [step] = useRecoilState(onboardStepState);

	return (
		<Root className={classes.content}>
			<TransitionGroup>
				<CSSTransition
					key={location.key}
					classNames='fade'
					timeout={200}
				>
					<Switch location={location}>
						<Route path='/step/1' children={<Intro />} />
						{/* <Route path='/step/2' children={<UserAgreements />} /> */}
						<Route path='/step/3' children={<Registration />} />
						{/* <Route path='/step/4' children={<WholesalerAgreements />} /> */}
						<Route path='/step/4' children={<FinalStep />} />
						<Redirect to={`/step/${step}`} />
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		</Root>
	);
});
