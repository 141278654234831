import { useRecoilValue } from 'recoil';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { FormikErrors, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { registrationFormStepState } from '../atoms';
import { PharmacyPostData } from '../services/types';

export enum STEPS {
	PHARMACY = 0,
	LICENSES = 1,
	SHIPPING = 2,
	OWNERSHIP = 3,
	PIC = 4,
	PP = 5,
	PW = 6,
	TRADE_REF = 7,
	AP = 8,
	BANKING = 9,
	PAYMENTS = 10,
	ADDITIONAL = 11
};

const pharmacyFields = [
	'legalName',
	'stateOfIncorporation',
	'email',
	'phone',
	'fax',
	'address',
	'city',
	'state',
	'zipCode',
	'dateBusinessBegan'
];
const licenseFields = [
	'ncpdpNumber',
	'deaNumber',
	'deaExpDate',
	'stateLicense',
	'stateLicenseExpDate',
	'stateIdNumber',
	'stateIdExpDate',
	'federalTaxNumber',
	'federalTaxExpDate',
	'stateSellersPermit',
	'medicarePartB',
	'gln'
];
const shippingFields = [
	'shippingAddress',
	'shippingCity',
	'shippingState',
	'shippingZip',
];
const additionalFields = [
	'bankruptcy',
	'bankruptcyExplanation',
	'convicted',
	'convictedExplanation',
];

export const getErrorsForView = (
	errors: FormikErrors<PharmacyPostData>,
	passedStep: number,
): [boolean, number] => {
	const step = passedStep;
	let hasErrors = false;
	let errorCount = 0;

	switch (step) {
		case STEPS.PHARMACY:
			const pharmacyErrors = { ..._pick(errors.dispensing, ['managementSystem']),
				..._pick(errors, pharmacyFields) };
			hasErrors = !_isEmpty(pharmacyErrors);
			errorCount = Object.keys(pharmacyErrors).length;
			break;

		case STEPS.LICENSES:
			const licenseErrors = _pick(errors, licenseFields);
			hasErrors = !_isEmpty(licenseErrors);
			errorCount = Object.keys(licenseErrors).length;
			break;

		case STEPS.SHIPPING:
			const shippingErrors = _pick(errors, shippingFields);
			hasErrors = !_isEmpty(shippingErrors);
			errorCount = Object.keys(shippingErrors).length;
			break;

		case STEPS.OWNERSHIP:
			hasErrors = !_isEmpty(errors.owner);
			errorCount = Object.keys(errors.owner ?? {}).length;
			break;

		case STEPS.PIC:
			hasErrors = !_isEmpty(errors.pic);
			errorCount = Object.keys(errors.pic ?? {}).length;
			break;

		case STEPS.PP:
			hasErrors = !_isEmpty(errors.pp);
			errorCount = Object.keys(errors.pp ?? {}).length;
			break;

		case STEPS.PW:
			const pwErrors = { ...errors.primary, ..._pick(errors, 'mckessonId') };
			hasErrors = !_isEmpty(pwErrors);
			errorCount = Object.keys(pwErrors).length;
			break;

		case STEPS.TRADE_REF:
			hasErrors = !_isEmpty(errors.tradeReference1) || !_isEmpty(errors.tradeReference2);
			errorCount = Object.keys(errors.tradeReference1 ?? {}).length
				+ Object.keys(errors.tradeReference2 ?? {}).length;
			break;

		case STEPS.AP:
			hasErrors = !_isEmpty(errors.ap);
			errorCount = Object.keys(errors.ap ?? {}).length;
			break;

		case STEPS.BANKING:
			const bankingObject = Object.keys(errors.banking ?? {})
				.filter((key) => key !== 'accountToBeDebited' && key !== 'receiveInvoices');
			hasErrors = !_isEmpty(bankingObject);
			errorCount = bankingObject.length;
			break;

		case STEPS.PAYMENTS:
			const paymentErrors = { ..._pick(errors.payments, ['billingAddress', 'billingCity', 'billingZip', 'billingState']),
				..._pick(errors.banking, ['accountToBeDebited', 'receiveInvoices']) };
			errorCount = Object.keys(paymentErrors).length;
			hasErrors = !_isEmpty(paymentErrors);
			break;

		case STEPS.ADDITIONAL:
			const additionalErrors = _pick(errors, additionalFields);
			hasErrors = !_isEmpty(additionalErrors);
			errorCount = Object.keys(additionalErrors).length;
			break;

		default:
			break;
	}

	return [hasErrors, errorCount];
};

export const useErrorState = (
	formik: FormikProps<PharmacyPostData>
): [boolean, number, STEPS[], () => void] => {
	const { values, errors, setFieldTouched } = formik;
	const formStep: STEPS = useRecoilValue(registrationFormStepState);
	const [viewedSteps, setViewedSteps] = useState<STEPS[]>([]);

	const setSubStepTouched = () => {
		switch (formStep - 2) {
			case STEPS.PHARMACY:
				setFieldTouched('dispensing.managementSystem');
				pharmacyFields.forEach((key) => {
					setFieldTouched(key);
				});
				break;

			case STEPS.LICENSES:
				licenseFields.forEach((key) => {
					setFieldTouched(key);
				});
				break;

			case STEPS.SHIPPING:
				shippingFields.forEach((key) => {
					setFieldTouched(key);
				});
				break;

			case STEPS.OWNERSHIP:
				const owner = values?.owner ?? {} as PharmacyPostData['owner'];
				Object.keys(owner).forEach((key) => {
					setFieldTouched(`owner.${key}`);
				});
				break;

			case STEPS.PIC:
				const pic = values?.pic ?? {} as PharmacyPostData['pic'];
				Object.keys(pic).forEach((key) => {
					setFieldTouched(`pic.${key}`);
				});
				break;

			case STEPS.PP:
				const pp = values?.pp ?? {} as PharmacyPostData['pp'];
				Object.keys(pp).forEach((key) => {
					setFieldTouched(`pp.${key}`);
				});
				break;

			case STEPS.PW:
				const primary = values?.primary ?? {} as PharmacyPostData['primary'];
				Object.keys(primary).forEach((key) => {
					setFieldTouched(`primary.${key}`);
				});
				setFieldTouched('mckessonId');
				break;

			case STEPS.TRADE_REF:
				const tf1 = values?.tradeReference1 ?? {};
				const tf2 = values.tradeReference2 ?? {};
				Object.keys(tf1).forEach((key) => {
					setFieldTouched(`tradeReference1.${key}`);
				});
				Object.keys(tf2).forEach((key) => {
					setFieldTouched(`tradeReference2.${key}`);
				});
				break;

			case STEPS.AP:
				const ap = values?.ap ?? {} as PharmacyPostData['ap'];
				Object.keys(ap).forEach((key) => {
					setFieldTouched(`ap.${key}`);
				});
				break;

			case STEPS.BANKING:
				const banking = values?.banking ?? {} as PharmacyPostData['banking'];
				Object.keys(banking).forEach((key) => {
					setFieldTouched(`banking.${key}`);
				});
				break;

			case STEPS.PAYMENTS:
				const payments = values?.payments ?? {} as PharmacyPostData['payments'];
				Object.keys(payments).forEach((key) => {
					setFieldTouched(`payments.${key}`);
				});
				break;

			case STEPS.ADDITIONAL:
				additionalFields.forEach((key) => {
					setFieldTouched(key);
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		setViewedSteps([...viewedSteps, formStep]);
	}, [formStep]);

	return [...getErrorsForView(errors, formStep - 2), viewedSteps, setSubStepTouched];
};
