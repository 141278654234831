import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const SignaturesBox = memo(({ date, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        image: {
            width: 200,
        },
        text: {
            fontSize: 12,
            paddingLeft: 10,
            alignSelf: 'flex-start',
        },
        textBold: {
            textAlign: 'right',
            marginTop: 0,
            fontSize: 10,
        },
        textUnder: {
            fontSize: 12,
            paddingLeft: 20,
            alignSelf: 'flex-end',
        },
        section2: {
            marginBottom: 10,
        },
    });
    const { owner, } = traderInfo;
    const items = [
        'Signed, sealed and delivered on',
        `this day of ${date}, `,
        'in the presence of:',
        'Unoffical Witness: ______________________',
        'Notary Public: ______________________',
        'Comm. Expires: ______________________',
    ];
    return (_jsxs(View, { style: styles.container, children: [_jsx(View, { children: items.map((text, index) => (_jsx(Text, { style: styles.text, children: text }, index))) }), _jsxs(View, { style: styles.section2, children: [_jsx(Text, { style: styles.textUnder, children: "Signature: ______________________________(LS)" }), _jsxs(Text, { style: styles.textUnder, children: ["Printed Name:  ", owner.firstName, " ", owner.lastName] })] })] }));
});
