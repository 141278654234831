import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import {
	InputAdornment, Grid, MenuItem, TextField,
} from '@mui/material';
import ownerTypes from '../../../../../../assets/json/ownership-types.json';
import registerTypes from '../../../../../../assets/json/registered-types.json';
import states from '../../../../../../assets/json/us-states.json';
import { PhoneInput, SSNInput } from '../../../../../../components';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const OwnershipInformation: FC<Props> = memo(({ formik }) => {
	const OWNER_FIRST_NAME = 'owner-first-name';
	const OWNER_LAST_NAME = 'owner-last-name';
	const OWNER_ADDRESS = 'owner-address';
	const OWNER_CITY = 'owner-city';
	const OWNER_STATE = 'owner-state';
	const OWNER_ZIP = 'owner-zip';
	const OWNER_PHONE_NUMBER = 'owner-phone-number';
	const OWNER_SSN = 'owner-ssn';
	const OWNER_PERCENT_OWNED = 'owner-percent-owned';
	const OWNER_MEMBERSHIP_TYPE = 'owner-membership-type';
	const OWNER_REGISTERED_TYPE = 'owner-registered-type';
	const OWNER_REGISTERED_TYPE_OTHER = 'owner-registered-type-other';

	const stateOptions = useMemo(() => states.map((option) => (
		<MenuItem key={option.abbreviation} value={option.abbreviation}>
			{option.name}
		</MenuItem>
	)), []);
	const ownershipTypes = useMemo(() => ownerTypes.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);
	const registeredTypes = useMemo(() => registerTypes.map((option) => (
		<MenuItem key={option.name} value={option.name}>
			{option.name}
		</MenuItem>
	)), []);

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const owner = values?.owner ?? {} as PharmacyPostData['owner'];
	const { owner: ownerErrors = {} } = errors;
	const { owner: ownerTouched = {} } = touched;

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='flex-start'
		>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.firstName'
					label={'Owner\'s First Name'}
					type='text'
					variant='outlined'
					value={owner.firstName}
					inputProps={{
						'data-testid': OWNER_FIRST_NAME
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.firstName && Boolean(ownerErrors.firstName)}
					helperText={ownerTouched.firstName && ownerErrors.firstName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.lastName'
					label={'Owner\'s Last Name'}
					type='text'
					variant='outlined'
					value={owner.lastName}
					inputProps={{
						'data-testid': OWNER_LAST_NAME
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.lastName && Boolean(ownerErrors.lastName)}
					helperText={ownerTouched.lastName && ownerErrors.lastName}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.address'
					label={'Owner\'s Address'}
					type='text'
					variant='outlined'
					value={owner.address}
					inputProps={{
						'data-testid': OWNER_ADDRESS
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.address && Boolean(ownerErrors.address)}
					helperText={ownerTouched.address && ownerErrors.address}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.city'
					label={'Owner\'s City'}
					type='text'
					variant='outlined'
					value={owner.city}
					inputProps={{
						'data-testid': OWNER_CITY
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.city && Boolean(ownerErrors.city)}
					helperText={ownerTouched.city && ownerErrors.city}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='owner.state'
					label={'Owner\'s State'}
					type='text'
					variant='outlined'
					value={owner.state}
					data-testid={OWNER_STATE}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.state && Boolean(ownerErrors.state)}
					helperText={ownerTouched.state && ownerErrors.state}
				>
					{stateOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.zip'
					label={'Owner\'s Zip Code'}
					type='text'
					variant='outlined'
					value={owner.zip}
					inputProps={{
						'data-testid': OWNER_ZIP,
						maxLength: 5
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.zip && Boolean(ownerErrors.zip)}
					helperText={ownerTouched.zip && ownerErrors.zip}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.phone'
					label={'Owner\'s Cell'}
					type='text'
					variant='outlined'
					value={owner.phone}
					inputProps={{
						'data-testid': OWNER_PHONE_NUMBER
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ inputComponent: PhoneInput as any }}
					error={ownerTouched.phone && Boolean(ownerErrors.phone)}
					helperText={ownerTouched.phone && ownerErrors.phone}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.social'
					label={'Owner\'s SSN'}
					type='text'
					variant='outlined'
					value={owner.social}
					inputProps={{
						'data-testid': OWNER_SSN
					}}
					onChange={(event) => {
						const { value } = event.target;
						formik.setFieldValue('owner.social', value);
					}}
					onBlur={() => {
						formik.setFieldTouched('owner.social', true);
					}}
					InputProps={{ inputComponent: SSNInput as any }}
					error={ownerTouched.social && Boolean(ownerErrors.social)}
					helperText={ownerTouched.social && ownerErrors.social}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='owner.percentOwned'
					label='Percent Owned'
					type='number'
					variant='outlined'
					value={owner.percentOwned}
					inputProps={{
						'data-testid': OWNER_PERCENT_OWNED
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					InputProps={{ startAdornment: <InputAdornment position='start'>%</InputAdornment> }}
					error={ownerTouched.percentOwned && Boolean(ownerErrors.percentOwned)}
					helperText={ownerTouched.percentOwned && ownerErrors.percentOwned}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='owner.ownershipType'
					label='Ownership Type'
					type='text'
					variant='outlined'
					value={owner.ownershipType}
					data-testid={OWNER_MEMBERSHIP_TYPE}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.ownershipType && Boolean(ownerErrors.ownershipType)}
					helperText={ownerTouched.ownershipType && ownerErrors.ownershipType}
				>
					{ownershipTypes}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='owner.registerType'
					label='Registered Type'
					type='text'
					variant='outlined'
					value={owner.registerType}
					data-testid={OWNER_REGISTERED_TYPE}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.registerType && Boolean(ownerErrors.registerType)}
					helperText={(ownerTouched.registerType && ownerErrors.registerType)
						? ownerErrors.registerType
						: 'Please specify your registered type for uniform sales and tax exemption.'
					}
				>
					{registeredTypes}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					name='owner.registerTypeOther'
					label='Other Registered Type'
					type='text'
					variant='outlined'
					value={owner.registerTypeOther}
					inputProps={{
						'data-testid': OWNER_REGISTERED_TYPE_OTHER
					}}
					onChange={handleChange}
					onBlur={handleBlur}
					error={ownerTouched.registerTypeOther && Boolean(ownerErrors.registerTypeOther)}
					helperText={(ownerTouched.registerTypeOther && ownerErrors.registerTypeOther)
						? ownerErrors.registerTypeOther
						: 'Please specify if you are a different registered type.'
					}
				/>
			</Grid>
		</Grid>
	);
});
