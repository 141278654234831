import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BooleanQuestion } from '@pm/shared';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

const getBoolString = (bool: boolean | undefined) => {
	if (bool === undefined) {
		return '';
	}
	return bool ? 'yes' : 'no';
};

const AdditionalQuestions: React.FC<Props> = (props) => {
	const { formik } = props;
	const { values, setValues } = formik;

	const handleConvictedChange = (isConvicted: boolean) => {
		setValues({
			...values,
			convicted: isConvicted
		}, true);
	};

	const handleBankruptcyChange = (wasBankrupt: boolean) => {
		setValues({
			...values,
			bankruptcy: wasBankrupt
		}, true);
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='flex-start'
			alignItems='center'
		>
			<Grid item md={6} xs={12}>
				<BooleanQuestion
					onChange={(value: boolean) => {
						handleConvictedChange(value);
					}}
					onExplanationChange={(value) => {
						setValues({
							...values,
							convictedExplanation: value
						}, true);
					}}
					onBlur={formik.handleBlur}
					name={'convicted'}
					error={!!formik.errors.convicted
						&& Boolean(formik.errors.convicted)
						&& !!formik.touched.convicted
						&& Boolean(formik.touched.convicted)}
					explanationError={!!formik.errors.convictedExplanation
						&& Boolean(formik.errors.convictedExplanation)}
					touched={!!formik.touched.convicted && Boolean(formik.touched.convicted)}
					explanationTouched={!!formik.touched.convictedExplanation
						&& Boolean(formik.touched.convictedExplanation)}
					question={`Have you, co-owners, shareholders, or managers ever been convicted
						of a felony, or had your license revoked or suspended in the last 10 years?`}
					initialValue={getBoolString(values.convicted)}
					initialExplanation={values.convictedExplanation}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<BooleanQuestion
					onChange={(value: boolean) => {
						handleBankruptcyChange(value);
					}}
					onBlur={formik.handleBlur}
					onExplanationChange={(value) => {
						setValues({
							...values,
							bankruptcyExplanation: value
						}, true);
					}}
					name='bankruptcy'
					error={!!formik.errors.bankruptcy
						&& Boolean(formik.errors.bankruptcy)
						&& !!formik.touched.convicted
						&& Boolean(formik.touched.convicted)}
					explanationError={!!formik.errors.bankruptcyExplanation
						&& Boolean(formik.errors.bankruptcyExplanation)}
					touched={!!formik.touched.bankruptcy && Boolean(formik.touched.bankruptcy)}
					explanationTouched={!!formik.touched.bankruptcyExplanation
						&& Boolean(formik.touched.bankruptcyExplanation)}
					initialExplanation={formik.values.bankruptcyExplanation}
					initialValue={getBoolString(formik.values.bankruptcy)}
					question={'Have you, co-owners, shareholders, or managers ever filed for bankruptcy?'}
				/>
			</Grid>
		</Grid>
	);
};

export default AdditionalQuestions;
