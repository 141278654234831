import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useMemo, memo, useRef } from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ConfirmationDialog } from '../confirmation-dialog';
import { PSAO, KPI } from '../types/rebates';
import { RebateType } from './types';
import TableToolbar from './TableToolbar';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import EmptyRebates from './EmptyRebates';
const RebateTierTable = ({ getRebatesByPharmacyId, putRebatesByPharmacyId, patchRebateByPharmacyId, deleteRebateByPharmacyId, primaryWholesaler, psao = PSAO.APCI, kpi = KPI.GCR, pharmacyId, notifyForUpdates, }) => {
    const { enqueueSnackbar, } = useSnackbar();
    const [rebateType, setRebateType] = useState(RebateType.GENERIC);
    const [syncing, setSyncing] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [addTierPressed, setAddTierPressed] = useState(false);
    const [removeDialogOpen, setDrugDialogOpen] = useState(false);
    const [isRemoveRow, setIsRemoveRow] = useState(true);
    const [stagedRemoveId, setStagedRemoveId] = useState(-1);
    const generic = rebateType === RebateType.GENERIC;
    const INDEX_ZERO = 0;
    const SPEND_SENTINEL = 100000000;
    const tableRef = useRef(null);
    const initData = useMemo(() => [
        { id: 0, cells: [{ value: `${kpi} %`, editable: false }, { value: '$0-$10,000', editable: false }] },
        { id: 1, cells: [{ value: '0-10%', editable: false }, { value: '1', editable: false }] },
    ], []);
    const [rows, setRows] = useState([]);
    const parseSpendRange = (range) => {
        const matches = range.match(/\$([\d,]+)-?\$?([\d,]+)?\+?/);
        if (!matches) {
            throw new Error(`invalid spend range format: ${range}`);
        }
        const lowerSpend = parseInt(matches[1].replace(/,/g, ''), 10);
        const upperSpend = matches[0].endsWith('+')
            ? SPEND_SENTINEL
            : parseInt(matches[2].replace(/,/g, ''), 10);
        return { lowerSpend, upperSpend };
    };
    const parsePercentageRange = (range) => {
        const matches = range.match(/^(\d+(\.\d+)?)-(\d+(\.\d+)?)%$|^(\d+(\.\d+)?)%\+$/);
        if (!matches) {
            throw new Error(`invalid percentage range format: ${range}`);
        }
        const isLastRow = matches[1] === undefined && matches[0].includes('+');
        const lowerPercentage = isLastRow ? parseFloat(matches[5]) : parseFloat(matches[1]);
        const upperPercentage = isLastRow ? 100 : parseFloat(matches[3]);
        return { lowerPercentage, upperPercentage };
    };
    const isValidCell = (row, column, updatedCell) => {
        if (row > 0) {
            if (column > 0) {
                // VALIDATING REBATE CELL
                return !isNaN(Number(updatedCell.value));
            }
            // VALIDATING GCR BUCKET CELL
            const matches = updatedCell.value.match(/^(\d+(\.\d+)?)-(\d+(\.\d+)?)%$|^(\d+(\.\d+)?)%\+$/);
            return matches !== null && matches.length > 0;
        }
        // VALIDATING SPEND BUCKET CELL
        const matches = updatedCell.value.match(/\$([\d,]+)-?\$?([\d,]+)?\+?/);
        return matches !== null && matches.length > 0;
    };
    const saveCellUpdates = async (row, column, updatedCell) => {
        if (!isValidCell(row, column, updatedCell)) {
            return;
        }
        setSyncing(true);
        try {
            const thisRow = rows.at(row);
            const data = {
                matrixPosition: { row, column, },
                primaryWholesaler,
                psao,
                kpi,
            };
            notifyForUpdates();
            if (row > 0) {
                const gcrBucketCell = thisRow.cells.at(INDEX_ZERO);
                const { lowerPercentage, upperPercentage } = parsePercentageRange(gcrBucketCell.value);
                data.lowerPercentage = lowerPercentage;
                data.upperPercentage = upperPercentage;
                if (column > 0) {
                    // UPDATING REBATE CELL
                    const spendBucketCell = rows.at(INDEX_ZERO).cells.at(column);
                    const { upperSpend, lowerSpend } = parseSpendRange(spendBucketCell.value);
                    data.lowerSpend = lowerSpend;
                    data.upperSpend = upperSpend;
                    data.rebate = Number(updatedCell.value);
                }
                else {
                    // UPDATING GCR BUCKET CELL
                    await putRebatesByPharmacyId(pharmacyId, data, { params: { bucket: 'spend', generic: generic.toString() } });
                    return setSyncing(false);
                }
            }
            else {
                // UPDATING SPEND BUCKET CELL
                const { upperSpend, lowerSpend } = parseSpendRange(updatedCell.value);
                data.lowerSpend = lowerSpend;
                data.upperSpend = upperSpend;
                await putRebatesByPharmacyId(pharmacyId, data, { params: { bucket: 'spend', generic: generic.toString() } });
                return setSyncing(false);
            }
            await patchRebateByPharmacyId(pharmacyId, data, { params: { generic: generic.toString() } });
            setSyncing(false);
        }
        catch (error) {
            if (!error?.message?.includes('invalid')) {
                enqueueSnackbar('Failed to save rebate table updates', { variant: 'error', });
            }
            setSyncing(false);
        }
    };
    const deleteRebatesByRowOrColumn = async (row, id) => {
        setSyncing(true);
        try {
            const bucket = row ? 'gcr' : 'spend';
            await deleteRebateByPharmacyId(pharmacyId, { params: { bucket, id, generic: generic.toString() } });
            await fetchRebatesMatrix();
            setSyncing(false);
        }
        catch (_error) {
            enqueueSnackbar('Failed to save rebate table updates', { variant: 'error', });
            setSyncing(false);
        }
    };
    const fetchRebatesMatrix = async () => {
        setFetching(true);
        try {
            const isMatrix = true;
            const matrix = await getRebatesByPharmacyId(pharmacyId, { params: { generic: generic.toString() } }, isMatrix);
            if (matrix.length > 0) {
                setRows(matrix.map((row, index) => ({
                    id: index,
                    cells: row.map((cell) => ({ value: cell, editable: false, })),
                })));
            }
            else {
                setRows([]);
            }
            setFetching(false);
        }
        catch (error) {
            setFetching(false);
        }
    };
    useEffect(() => {
        fetchRebatesMatrix();
    }, [rebateType, pharmacyId]);
    useEffect(() => {
        if (addTierPressed) {
            setRows(initData);
            if (tableRef.current) {
                tableRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [addTierPressed]);
    const handleRebateTypeChange = (event, newType) => {
        setRebateType(newType);
    };
    const handleToggleDialogOpen = () => {
        setDrugDialogOpen((open) => !open);
    };
    const handleAddRow = () => {
        const newRow = {
            id: rows.length,
            cells: Array(rows[0].cells.length).fill({ value: '', editable: false, }),
        };
        setRows((prevRows) => [...prevRows, newRow]);
    };
    const handleAddColumn = () => {
        setRows((prevRows) => prevRows.map((row) => ({
            ...row,
            cells: [...row.cells, { value: '', editable: false, }],
        })));
    };
    const onRemoveRowOrColumn = () => {
        if (isRemoveRow) {
            setRows((prevRows) => prevRows.filter((row) => row.id !== stagedRemoveId));
            deleteRebatesByRowOrColumn(true, stagedRemoveId);
        }
        else {
            setRows((prevRows) => prevRows.map((row) => {
                const newCells = row.cells;
                newCells.splice(stagedRemoveId, 1);
                return { ...row, cells: newCells, };
            }));
            deleteRebatesByRowOrColumn(false, stagedRemoveId);
        }
    };
    const handleRemoveRow = (rowId) => {
        setIsRemoveRow(true);
        setDrugDialogOpen(true);
        setStagedRemoveId(rowId);
    };
    const handleRemoveColumn = (cellIndex) => {
        setIsRemoveRow(false);
        setDrugDialogOpen(true);
        setStagedRemoveId(cellIndex);
    };
    const updateCell = (rowId, cellIndex, updates, save = false) => {
        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === rowId) {
                const updatedCell = { ...row.cells[cellIndex], ...updates };
                if (save) {
                    saveCellUpdates(rowId, cellIndex, updatedCell);
                }
                return {
                    ...row,
                    cells: [
                        ...row.cells.slice(0, cellIndex),
                        updatedCell,
                        ...row.cells.slice(cellIndex + 1),
                    ],
                };
            }
            return row;
        }));
    };
    const handleCellChange = (rowId, cellIndex, value) => {
        updateCell(rowId, cellIndex, { value });
    };
    const handleCellClick = (rowId, cellIndex) => {
        updateCell(rowId, cellIndex, { editable: true });
    };
    const handleCellBlur = (rowId, cellIndex) => {
        updateCell(rowId, cellIndex, { editable: false }, true);
    };
    const handleCellKeyDown = (e, rowId, cellIndex) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleCellBlur(rowId, cellIndex);
        }
    };
    return (_jsxs(Paper, { ref: tableRef, id: 'rebateTable', children: [_jsx(TableToolbar, { rebateType: rebateType, handleAddRow: handleAddRow, handleAddColumn: handleAddColumn, handleRebateTypeChange: handleRebateTypeChange, noData: rows.length === 0 }), rows.length > 0 ?
                _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHeader, { rows: rows, syncing: syncing, fetching: fetching, isGeneric: generic, colTitlesIndex: INDEX_ZERO, handleCellClick: handleCellClick, handleRemoveColumn: handleRemoveColumn, handleCellChange: handleCellChange, handleCellBlur: handleCellBlur, handleCellKeyDown: handleCellKeyDown }), _jsx(TableRows, { rows: rows, colTitlesIndex: INDEX_ZERO, handleCellClick: handleCellClick, handleRemoveRow: handleRemoveRow, handleCellChange: handleCellChange, handleCellBlur: handleCellBlur, handleCellKeyDown: handleCellKeyDown })] }) })
                : _jsx(EmptyRebates, { action: setAddTierPressed }), _jsx(ConfirmationDialog, { handleConfirm: onRemoveRowOrColumn, handleToggleOpen: handleToggleDialogOpen, open: removeDialogOpen, removeToggle: true, positiveActionText: `Remove ${isRemoveRow ? 'Row' : 'Column'}`, negativeActionText: 'Cancel', title: `Remove ${isRemoveRow ? 'Row' : 'Column'} Confirmation`, text: `Are you sure you want to remove this table ${isRemoveRow ? 'row' : 'column'}?`, sx: { width: 500, height: 200, margin: 'auto' } })] }));
};
export default memo(RebateTierTable);
