import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'Registration';
export const classes = {
	root: `${PREFIX}-root`,
	image: `${PREFIX}-image`,
	tiersContainer: `${PREFIX}-tiersContainer`,
	formContainer: `${PREFIX}-formContainer`,
	formContainer2: `${PREFIX}-formContainer2`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		backgroundColor: '#111B29',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		position: 'absolute',
	},
	[`& .${classes.image}`]: {
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		opacity: 0.24,
		mixBlendMode: 'screen',
		position: 'absolute',
		padding: theme.spacing(2),
		backgroundSize: 'cover',
		background: 'url(/img/pharmacy_3.jpg) no-repeat',
	},
}));
export const StyledGrid = styled(Grid)(({ theme }) => {
	const baseStyles = {
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 500,
		paddingTop: theme.spacing(3),
	};
	return {
		[`&.${classes.tiersContainer}`]: {
			...baseStyles,
			textAlign: 'center',
			[theme.breakpoints.only('lg')]: {
				paddingTop: theme.spacing(5),
			},
		},
		[`&.${classes.formContainer}`]: baseStyles,
		[`&.${classes.formContainer2}`]: {
			...baseStyles,
			[theme.breakpoints.only('xl')]: {
				paddingTop: theme.spacing(5),
			},
			[theme.breakpoints.down('xl')]: {
				paddingTop: theme.spacing(8),
			},
		},
	};
});
