import { FC, memo } from 'react';
import { Card } from '../../../../components';
import { FormContent } from './components/FormContent';
import { StyledGrid, classes } from './RegistrationFormCard.styles';

export const RegistrationFormCard: FC = memo(() => (
	<StyledGrid item xs={12} md={11} xl={10}>
		<Card className={classes.card}>
			<FormContent />
		</Card>
	</StyledGrid>
));
