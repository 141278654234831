import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Grid } from '@mui/material';
import { registrationFormState, registrationFormStepState, selectIsFirstFormStep, selectIsThirdFormStep } from '../../atoms';
import { useValidateStep } from '../../hooks';
import {
	BestTierCard, FreeTierCard, PremiumTierCard, PvacTierCard, RegistrationFormCard,
} from './components';
import { Root, StyledGrid, classes } from './Registration.styles';

export const Registration: FC = () => {
	const isFirstStep = useRecoilValue(selectIsFirstFormStep);
	const isThirdStep = useRecoilValue(selectIsThirdFormStep);
	const { coupon } = useRecoilValue(registrationFormState);
	const setRegistrationFormStep = useSetRecoilState(registrationFormStepState);

	useEffect(() => {
		if (coupon) {
			setRegistrationFormStep(2);
		}
	}, []);

	useValidateStep();

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<StyledGrid
				container
				spacing={isFirstStep ? 4 : 0}
				justifyContent='center'
				alignItems='center'
				className={clsx({
					[classes.tiersContainer]: isFirstStep,
					[classes.formContainer]: !isFirstStep && !isThirdStep,
					[classes.formContainer2]: isThirdStep,
				})}
			>
				{isFirstStep
					&& <>
						<FreeTierCard />
						<Grid
							item
							xs={12}
							md={4}
							container
							alignItems='center'
							justifyContent='space-between'
							sx={{ height: '100%' }}
						>
							<PremiumTierCard />
							<PvacTierCard />
						</Grid>
						<BestTierCard />
					</>
				}
				{!isFirstStep && <RegistrationFormCard />}
			</StyledGrid>
			<Root className={classes.root}>
				<div className={classes.image} />
			</Root>
		</div>
	);
};
