import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
;
export const Number = memo(({ invoice, }) => {
    const value = {
        fontSize: 12,
        fontFamily: 'Roboto',
        marginLeft: 4,
    };
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            fontSize: 12,
            fontWeight: 700,
            fontStyle: 'bold',
            fontFamily: 'Roboto',
        },
        value,
        value2: {
            ...value,
            fontSize: 14,
        },
    });
    const date = dayjs(invoice.dateCreated).format('M/D/YYYY');
    return (_jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.label, children: "PO #" }), _jsx(Text, { style: styles.value2, children: invoice.poNumber })] }), _jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.label, children: "Invoice Date" }), _jsx(Text, { style: styles.value, children: date })] }), _jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.label, children: "Tx Type Code" }), _jsx(Text, { style: styles.value, children: invoice.transactionTypeCode })] })] }));
});
