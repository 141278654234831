import { FC, useCallback, useState, memo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useFormik, FormikHelpers } from 'formik';
import {
	Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
	Alert, Button, CardContent, CardActions, Divider, Grid,
	MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import InfoIcon from '@mui/icons-material/Info';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { onboardIdState, registrationFormState } from '../../../../atoms';
import { api } from '../../../../services';
import { OnboardPutData } from '../../../../services/types';
import { IntroSchema } from './IntroSchema';
import { Root, classes } from './FormContent.styles';
import { CouponCodeForm } from '../../../Registration/components/components';
import PasswordEntry from './PasswordEntry';

interface Props {
	onSubmit: (
		_fields: OnboardPutData,
		_helpers: FormikHelpers<OnboardPutData>
	) => Promise<void>;
};

export const FormContent: FC<Props> = memo(({ onSubmit }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [onboardId] = useRecoilState(onboardIdState);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);

	const [locked, setLocked] = useState<boolean>(false);
	const [showDuplicateEmailAlert, setShowDuplicateEmailAlert] = useState<boolean>(false);
	const [showLinkAccountDialog, setShowLinkAccountDialog] = useState<boolean>(false);
	const [showAccountLinkedAlert, setShowAccountLinkedAlert] = useState<boolean>(false);
	const resetRegistrationForm = useResetRecoilState(registrationFormState);

	const formik = useFormik({
		initialValues: {
			userName: '',
			position: '',
			pharmacyName: '',
			npiNumber: '',
			email: '',
			coupon: undefined,
			password: undefined,
			confirmPassword: '',
			isMultiStoreOwner: false
		} as any,
		initialTouched: {},
		validateOnMount: false,
		enableReinitialize: true,
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: IntroSchema,
		onSubmit,
	});

	const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY as string;

	const onReCaptchaChange = async (token: string | null) => {
		await formik.setFieldValue('reCaptchaToken', token);
		setTimeout(() => { formik.validateForm(); }, 100);
	};

	const onValidateEmail = async (blurEvent?: any, emailValue?: string) => {
		setLoading(true);
		if (blurEvent) {
			formik.handleBlur(blurEvent);
		}
		const email = emailValue ? emailValue : formik.values.email.trim();
		if (email.length === 0) {
			return setLoading(false);
		}
		try {
			const { exists } = await api.get.checkEmail(
				onboardId as string, email);
			setShowDuplicateEmailAlert(exists);
			if (exists) {
				setShowLinkAccountDialog(true);
			} else {
				await formik.setFieldValue('isMultiStoreOwner', false);
			}
			return setLoading(false);
		} catch (error) {
			enqueueSnackbar(
				'Something went wrong while verifying your email address.',
				{ variant: 'error' },
			);
			return setLoading(false);
		}
	};

	const debouncedOnEmailChange = useCallback(debounce((event) => {
		const { target: { value, } } = event;
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		const isValidEmail = emailRegex.test(value);
		if (isValidEmail) {
			onValidateEmail(undefined, value);
		}
	}, 1500, { leading: false, trailing: true, }), []);
	const onEmailChange = (event: any) => {
		formik.handleChange(event);
		debouncedOnEmailChange(event);
	};

	const onLinkExistingAccount = async () => {
		const { values: { email, password } } = formik;
		if (!password || password.trim().length === 0) {
			return;
		}
		setLoading(true);
		setHasError(false);
		try {
			const { authenticated } = await api.get.checkPassword(email, password);
			if (authenticated) {
				await formik.setFieldValue('isMultiStoreOwner', true);
				setShowDuplicateEmailAlert(false);
				setShowAccountLinkedAlert(true);
				setShowLinkAccountDialog(false);
				// Ensures old form data does not show for a multi account user
				resetRegistrationForm();
			} else {
				await formik.setFieldValue('isMultiStoreOwner', false);
				setHasError(true);
			}
			setLoading(false);
		} catch (error) {
			if ((error as any).status === 423) {
				setHasError(false);
				setLocked(true);
			}
			enqueueSnackbar(
				'Something went wrong while linking your existing account.',
				{ variant: 'error' },
			);
			setLoading(false);
		}
		await formik.setFieldValue('password', '');
	};
	const handleCloseLinkAccountDialog = () => {
		setShowLinkAccountDialog(false);
		setShowAccountLinkedAlert(false);
		setLocked(false);
	};

	const disabled = loading
		|| showDuplicateEmailAlert
		|| formik.isSubmitting
		|| !isEmpty(formik.errors);

	return (
		<Root onSubmit={formik.handleSubmit}>
			<Dialog open={showLinkAccountDialog} onClose={handleCloseLinkAccountDialog}>
				<DialogTitle>Connect Your Existing Account</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{ mb: 2 }}>
						{/* eslint-disable-next-line max-len */}
						We found an existing account under the email address <b>{formik.values.email}</b>. Please enter your password to securely link your account to this new pharmacy.
					</DialogContentText>
					<DialogContentText sx={{ mb: 1 }}>
						{/* eslint-disable-next-line max-len */}
						If you haven&apos;t setup a password yet, please call <b>1 (800) 958-5540</b> or email <b>support@pharmacymarketplace.com</b> for assistance.
					</DialogContentText>
					{hasError
						&& <Alert
							sx={{
								ml: 'auto', mr: 'auto', mt: 1, mb: 1,
							}}
							style={{ fontWeight: 'bold' }}
							variant='filled'
							severity='error'
						>
							Incorrect password.
						</Alert>
					}
					{locked
						&& <Alert
							sx={{
								ml: 'auto', mr: 'auto', mt: 1, mb: 1,
							}}
							style={{ fontWeight: 'bold' }}
							variant='filled'
							severity='warning'
						>
							You&apos;ve exceeded the allowed number of failed login attempts (5). Your account has been locked for security.
							<br /><br />Please call <b>1 (800) 958-5540</b> or email <b>support@pharmacymarketplace.com</b> for assistance.
						</Alert>
					}
					<TextField
						autoFocus
						fullWidth
						sx={{ mt: 2 }}
						required={showLinkAccountDialog}
						id='password'
						label='Account Password'
						type='password'
						variant='outlined'
						value={formik.values.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={(formik.touched.password && formik.errors.password) || ' '}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseLinkAccountDialog} color='inherit'>
						Cancel
					</Button>
					<LoadingButton
						disabled={loading}
						loading={loading}
						onClick={onLinkExistingAccount}
						variant='contained'
					>
						Link Account
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<CardContent>
				<Grid
					container
					spacing={2}
					justifyContent='center'
					alignItems='center'
				>
					{showAccountLinkedAlert
						&& <Grid item xs={12} sx={{ mb: 1 }}>
							<Alert
								sx={{ ml: 'auto', mr: 'auto' }}
								style={{ fontWeight: 'bold' }}
								variant='filled'
								severity='success'
							>
								{`Your account under the email address ${formik.values.email} has been linked successfully!`}
							</Alert>
						</Grid>
					}
					{(showDuplicateEmailAlert && !showLinkAccountDialog)
						&& <Grid item xs={12} sx={{ mb: 1 }}>
							<Alert
								sx={{ ml: 'auto', mr: 'auto', color: '#fff', }}
								style={{ fontWeight: 'bold' }}
								variant='filled'
								severity='warning'
							>
								{`The email address ${formik.values.email} is already in use. Please pick a different one.`}
							</Alert>
						</Grid>
					}
					<Grid item md={4} xs={12}>
						<TextField
							fullWidth
							required
							name='userName'
							label='Full Name'
							type='text'
							variant='outlined'
							inputProps={{ 'data-testid': 'fullNameTextField', }}
							value={formik.values.userName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.userName && Boolean(formik.errors.userName)}
							helperText={(formik.touched.userName && formik.errors.userName) || ' '}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<TextField
							fullWidth
							select
							required
							name='position'
							label='Position'
							type='text'
							variant='outlined'
							data-testid="positionSelectField"
							value={formik.values.position}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.position && Boolean(formik.errors.position)}
							helperText={(formik.touched.position && formik.errors.position) || ' '}
						>
							{['Owner', 'Purchaser', 'PIC', 'Technician', 'Other'].map((label) => (
								<MenuItem key={label} value={label}>
									{label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item md={4} xs={12}>
						<TextField
							fullWidth
							required
							name='pharmacyName'
							label='Pharmacy Name'
							type='text'
							variant='outlined'
							data-testid="pharmacyNameTextField"
							value={formik.values.pharmacyName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.pharmacyName && Boolean(formik.errors.pharmacyName)}
							helperText={(formik.touched.pharmacyName && formik.errors.pharmacyName) || ' '}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<TextField
							fullWidth
							required
							name='npiNumber'
							label='NPI Number'
							type='number'
							variant='outlined'
							data-testid="npiNumberTextField"
							value={formik.values.npiNumber}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.npiNumber && Boolean(formik.errors.npiNumber)}
							helperText={(formik.touched.npiNumber && formik.errors.npiNumber) || ' '}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<TextField
							fullWidth
							required
							name='email'
							label={loading ? 'Checking Email' : 'Email Address'}
							type='email'
							variant='outlined'
							disabled={loading}
							value={formik.values.email}
							data-testid="emailTextField"
							onChange={onEmailChange}
							onBlur={onValidateEmail}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={(formik.touched.email && formik.errors.email) || ' '}
							InputProps={{
								startAdornment: (
									<Tooltip
										PopperProps={{ style: { zIndex: 100000, position: 'absolute' } }}
										placement='top'
										title={
											<Typography>
												You&apos;ll login to your account using this email address.
											</Typography>
										}
									>
										<InfoIcon fontSize='small' sx={{ color: '#757575' }} />
									</Tooltip>
								),
							}}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<CouponCodeForm
							onSuccess={(code) => formik.setFieldValue('coupon', code)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider className={classes.divider} />
						<PasswordEntry formik={formik} />
					</Grid>
				</Grid>
			</CardContent>
			<Divider className={classes.divider} />
			<CardActions>
				<div id="recaptcha">
					<ReCAPTCHA sitekey={siteKey} onChange={onReCaptchaChange} />
				</div>
				<LoadingButton
					type='submit'
					loading={formik.isSubmitting}
					loadingPosition='start'
					color={disabled ? 'inherit' : 'primary'}
					variant='contained'
					data-testid="introSubmitButton"
					disabled={disabled}
					startIcon={<SendIcon />}
					className={classes.btn}
				>
					Submit
				</LoadingButton>
			</CardActions>
		</Root>
	);
});
