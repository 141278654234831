import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'Loader';
export const classes = {
	absoluteContainer: `${PREFIX}-absoluteContainer`,
	staticContainer: `${PREFIX}-staticContainer`,
	black: `${PREFIX}-black`,
	white: `${PREFIX}-white`,
};
export const StyledGrid = styled(Grid)(() => ({
	[`&.${classes.absoluteContainer}`]: {
		height: '100%',
		position: 'absolute',
		zIndex: 99999,
	},
	[`&.${classes.staticContainer}`]: {
		height: 25,
		flexWrap: 'nowrap',
	},
	[`& .${classes.black}`]: {
		color: '#333',
	},
	[`& .${classes.white}`]: {
		color: '#fff',
	},
}));
