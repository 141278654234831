import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from '../DocumentThirteen/components';
import { TermsListItem } from './components';
;
export const DocumentFourteen = memo(({ traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            marginBottom: 0,
            padding: 10,
        },
        title: {
            paddingTop: 0,
            paddingBottom: 8,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            padding: 10,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 12,
        },
        entryLg: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 14,
        },
        sigContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            padding: '25px 10px 0px',
        },
    });
    const terms = useMemo(() => [
        {
            leftText: 'New Customers:',
            rightText: 'An application signed by the owner or authorized individual must be returned to and approved by KeySource',
        },
        {
            leftText: 'Standard Payment Terms:',
            rightText: 'Net 30 Days',
        },
        {
            leftText: 'Payment Methods Accepted:',
            rightText: 'Check, Checkfaxx, ACH, or credit card',
        },
        {
            leftText: 'Credit Limits:',
            rightText: 'Customers are normally granted a credit line sufficient to accommodate their ordering requirements.  Requests for credit limit increases may require additional financial information and/or altered payment terms',
        },
        {
            leftText: 'Returned Checks and ACH:',
            rightText: 'A fee of $30.00 will be assessed per invoice.  Also, accounts with returned checks will be placed on hold until payment by credit card or 5 business days after check/ACH is re-presented for payment.',
        },
        {
            leftText: 'Shipping:',
            rightText: 'Shipping charges are to be paid by customer if minimum order threshold is not met',
        },
        {
            leftText: 'Standard Shipping:',
            rightText: 'UPS ground for orders under $100, over $100 will be shipped overnight with exceptions for hazardous materials and certain items over weight limitations.',
        },
        {
            leftText: 'Return Policy:',
            rightText: 'All products are sold on a non-returnable basis.  Exceptions may apply.  Shipping errors or damaged goods must be submitted and acknowledged by KeySource within 2 business days of receipt of shipment.  Returns require a Returned Merchandise Authorization (RMA).  Products returned without a RMA will be destroyed and no credit will be issued',
        },
        {
            leftText: 'Restocking Charge:',
            rightText: 'Refused orders or product returns may incur a 15% restocking charge ($30 minimum). Note: Returns require a RMA in all cases',
        },
        {
            leftText: 'Finance Charge:',
            rightText: 'Accounts 30 days or more past due may incur a finance charge of 1.5% per month (18% per annum)',
        },
        {
            leftText: 'Past Due Accounts:',
            rightText: 'Accounts that are 30 days or more past due may be sent to collections.  If your account is sent to collections you agree to pay all reasonable costs of collections to include, but not limited to, the following:  Court costs and attorneys’ fees.  These fees will be in addition to the amount you owe and any interest owed.  In addition, you agree to pay a processing fee of $100 on any account submitted by KeySource to a collection agency or attorney for collection.',
        },
        {
            leftText: 'Governing Law:',
            rightText: 'This agreement shall be governed by the laws of the State of Ohio.',
        },
        {
            leftText: 'Forum Selection Clause:',
            rightText: 'Any dispute arising from this contractual relationship shall be decided solely and exclusively by State courts located in Cincinnati, Hamilton County, Ohio.  Any party who unsuccessfully challenges the enforceability of this forum selection clause shall reimburse prevailing party for its attorneys’ fees and the party prevailing in any such dispute shall be awarded its attorneys’ fees.',
        },
        {
            leftText: 'Additional Terms:',
            rightText: 'Terms of this credit agreement shall apply to all current and future charges.  Credit privileges granted by KeySource may be withdrawn at any time for any reason.',
        },
        {
            leftText: 'Remittance Address:',
            rightText: 'KeySource Acquisition LLC (DBA KeySource), PO Box 953, Middletown, OH  45044-0953',
        },
    ], []);
    const { owner, } = traderInfo;
    const date = dayjs().format('MM/DD/YYYY');
    const signature = [
        { text: 'Authorized Signature:', style: styles.entryLg, },
        { text: `Printed Name:  ${owner.firstName} ${owner.lastName}`, style: styles.entryLg, },
        { text: `Date:  ${date}`, style: styles.entryLg, },
        { text: '', style: styles.entry, },
        { text: 'Territory (office use only): _____________________', style: styles.entry, },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Terms and Conditions" }) }), _jsx(View, { children: terms.map((term, index) => (_jsx(TermsListItem, { ...term }, index))) }), _jsx(View, { style: styles.sigContainer, children: signature.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) })] }) }));
});
