import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Initials } from '../Initials';
import RxSupply from '../../../../../../images/rxsupply-logo-2.png';
;
export const PageOne = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        imgContainer: {
            padding: 30,
            alignItems: 'center',
        },
        image: {
            height: 85,
        },
        descContainer: {
            paddingBottom: 20,
        },
        descTitle: {
            fontSize: 12,
            textAlign: 'center',
            marginTop: -10,
            paddingBottom: 7,
        },
        descInfo: {
            fontSize: 12,
            textAlign: 'center',
        },
        entryContainer: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        entry: {
            paddingBottom: 12,
            fontSize: 10,
        },
        entryHeader: {
            fontSize: 11,
            backgroundColor: '#003399',
            color: 'white',
        },
        entryBold: {
            padding: '12px 0px',
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        entryFooter: {
            paddingTop: 20,
            fontSize: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        spacerSmall: {
            paddingBottom: 15,
        },
    });
    const { dateBusinessBegan, deaExpDate, } = pharmacy;
    const { ap, owner, } = traderInfo;
    const description = [
        { text: 'Pharmaceutical Wholesale Distributor – Your Healthcare Partner Since 1955', style: styles.descTitle, },
        { text: '2233 Tracy Road • Northwood, Ohio 43619', style: styles.descInfo, },
        { text: 'Toll Free: 800-777-0761 • Fax: 419-661-6617', style: styles.descInfo, },
        { text: 'www.prescriptionsupply.com', style: styles.descInfo, }
    ];
    const sectionOne = [
        `Legal Entity Name:  ${pharmacy.legalName}     DBA:  ${pharmacy.name}`,
        `Shipping:  ${pharmacy.shippingAddress || pharmacy.address}, ${pharmacy.shippingCity || pharmacy.city}, ${pharmacy.shippingState || pharmacy.state}, ${pharmacy.shippingZip || pharmacy.zipCode}`,
        `Billing Address:  ${pharmacy.address}, ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`,
        `Phone #:  ${pharmacy.phone}     Fax #:  ${pharmacy.fax || '______________'}`,
        `Email address for statements:  ${ap.email}`,
        `A/P Contact:  ${ap.firstName} ${ap.lastName}     A/P Phone #:  ${ap.phone}     State of Incorporation:  ${pharmacy.stateOfIncorporation}`
    ];
    const businessBeganDayjs = dayjs(dateBusinessBegan);
    const formattedDateBusinessBegan = businessBeganDayjs.isValid()
        ? businessBeganDayjs.format('MM/DD/YYYY')
        : dateBusinessBegan;
    const sectionTwo = [
        {
            text: 'Section 2 – Ownership Information ***REQUIRED***',
            style: styles.entryHeader,
        },
        {
            text: '(Check which type of Company)',
            style: styles.entryBold,
        },
        {
            text: `Business Type:  ${owner.ownershipType}`,
            style: styles.entry,
        },
        {
            text: `(Principal/Partner) First Name:  ${owner.firstName}     Last Name:  ${owner.lastName}     Ownership:  ${owner.percentOwned}%`,
            style: styles.entry,
        },
        {
            text: `(Home Address) Street:  ${owner.address}     City:  ${owner.city}     State:  ${owner.state}`,
            style: styles.entry,
        },
        {
            text: `Zip Code:  ${owner.zip}     Home Phone:  ${owner.phone}     Social Security:  ${owner.social}`,
            style: styles.entry,
        },
        {
            text: '(Partner if applies) First Name: ___________________ Last Name: ____________________ %_____ Ownership',
            style: styles.entry,
        },
        {
            text: '(Home Address) Street Number: ________ Street: _______________ City: _____________ State: ______',
            style: styles.entry,
        },
        {
            text: 'Zip Code: _______ Home Phone: ____________________ Social Security: _____________',
            style: styles.entry,
        },
        {
            text: `(Years/Months) the applicant’s company has been under the ownership listed above:  ${formattedDateBusinessBegan}`,
            style: styles.entryFooter,
        },
        { text: '', style: styles.spacerSmall, }
    ];
    const deaExpDateDayjs = dayjs(deaExpDate);
    const formattedDeaExpDate = deaExpDateDayjs.isValid()
        ? deaExpDateDayjs.format('MM/DD/YYYY')
        : deaExpDate;
    const sectionThree = [
        {
            text: 'Section 3 – General Information',
            style: styles.entryHeader,
        },
        { text: '', style: styles.entry, },
        {
            text: `HIN #:  ${pharmacy.hinNumber || '___________'}     DEA #:  ${pharmacy.deaNumber}     Expiration Date:  ${formattedDeaExpDate}`,
            style: styles.entry,
        },
        {
            text: `DUNS #:  ${pharmacy.dunsNumber || '___________'}     State Pharmacy License #:  ${pharmacy.stateLicense}     Fed ID #:  ${pharmacy.federalTaxNumber}`,
            style: styles.entry,
        },
        { text: '', style: styles.spacerSmall, },
        {
            text: 'Please attach a copy of the following: DEA License, State Pharmacy License, Resale certificate, pharmacy inspection, Tax exempt form, Copy of owners Drivers license, and 3 months of statements if applying for terms.',
            style: styles.entryFooter,
        }
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(Initials, { pageNum: 1 }), _jsx(View, { style: styles.imgContainer, children: _jsx(Image, { style: styles.image, src: RxSupply }) }), _jsx(View, { style: styles.descContainer, children: description.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) }), _jsx(View, { style: styles.entryContainer, children: sectionOne.map((text, index) => (_jsx(Text, { style: styles.entry, children: text }, index))) }), _jsx(View, { style: styles.entryContainer, children: sectionTwo.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) }), _jsx(View, { style: styles.entryContainer, children: sectionThree.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) })] }) }));
});
