import EventSource from 'eventsource';
import { EventSourcePolyfill } from 'event-source-polyfill';
import debounce from 'lodash/debounce';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Onboard } from './types';

export type ReqOptions = {
	limit?: number;
	offset?: number;
	query?: string;
	params?: { [param: string]: string | number | boolean };
	[key: string]: any;
};

export enum ApiUrl {
	MARKETPLACE = 1,
	DISPENSING = 2,
	NPI_CLINICAL_TABLES = 3,
	MARKETPLACE_REST = 4
};

export default class ApiClient {
	private static instance: ApiClient;

	private static abortController: AbortController;

	private esRetryFreqSec = 1;

	private _eventSource: EventSource | null = null;

	static async getInstance(): Promise<ApiClient> {
		if (!ApiClient.instance) {
			ApiClient.instance = new ApiClient();
		}
		return ApiClient.instance;
	}

	private constructor() {
		ApiClient.abortController = new AbortController();
	}

	get eventSource() {
		return this._eventSource;
	}

	private static removeAfterLastForwardSlash(input: string): string {
		const lastSlashIndex = input.lastIndexOf('/');
		if (lastSlashIndex !== -1) {
			return input.substring(0, lastSlashIndex + 1);
		}
		return input;
	}

	private async getVisitorId(): Promise<string> {
		const fpAgent = await FingerprintJS.load();
		const { components, } = await fpAgent.get();
		const {
			...otherComponents
		} = components;
		const visitorId = FingerprintJS.hashComponents(
			{ ...otherComponents });
		return visitorId;
	}

	public async checkIfOnboardingToken(): Promise<Onboard> {
		const localToken = localStorage.getItem('onboardJWT') ?? '';
		let onboardRes;
		try {
			if (localToken === '') {
				throw new Error();
			}
			const fingerprint = await this.getVisitorId();
			// eslint-disable-next-line max-len
			onboardRes = await ApiClient.instance.read('/onboarding', undefined, { params: { jwt: localToken, fingerprint } }, undefined, ApiUrl.MARKETPLACE_REST);
			return await Promise.resolve(onboardRes[0]);
		} catch (error: any) {
			onboardRes = await ApiClient.instance.create('/onboarding', {}, undefined, undefined, ApiUrl.MARKETPLACE_REST);
			localStorage.setItem('onboardJWT', onboardRes.jwt);
			return Promise.resolve(onboardRes);
		}
	}

	private static getBaseUrl(apiType: ApiUrl): string | undefined {
		const {
			VITE_MARKETPLACE_API_URL: MARKETPLACE_API_URL,
			VITE_DRUG_DISPENSING_API_URL: DRUG_DISPENSING_API_URL,
		} = import.meta.env;
		switch (apiType) {
			case ApiUrl.MARKETPLACE:
				return MARKETPLACE_API_URL;
			case ApiUrl.DISPENSING:
				return DRUG_DISPENSING_API_URL;
			case ApiUrl.NPI_CLINICAL_TABLES:
				return 'https://clinicaltables.nlm.nih.gov/api/npi_org/v3';
			case ApiUrl.MARKETPLACE_REST:
				return `${this.removeAfterLastForwardSlash(MARKETPLACE_API_URL ?? '')}rest`;
			default:
				return MARKETPLACE_API_URL;
		}
	}

	private static getUrl(
		resource: string,
		id?: string,
		options: ReqOptions = {},
		apiType: ApiUrl = ApiUrl.MARKETPLACE,
	): string {
		if (resource.indexOf('/') !== 0) {
			resource = `/${resource}`;
		}
		const apiUrl = this.getBaseUrl(apiType) as string;
		const formattedUrl = new URL(`${apiUrl}${resource}${id ? `/${id}` : ''}`);
		const {
			limit, offset, query, params,
		} = options;
		if (limit) formattedUrl.searchParams.set('limit', String(limit));
		if (offset !== undefined) formattedUrl.searchParams.set('offset', String(offset));
		if (query) formattedUrl.searchParams.set('q', query);
		if (params) {
			Object.entries(params).forEach((entry: Array<string | number | boolean>) => {
				if (entry[1]) formattedUrl.searchParams.set(entry[0] as string, entry[1] as string);
			});
		}
		return formattedUrl.toString();
	}

	private static checkResponseStatus(response: Response): Promise<Response> {
		if (response.status === 417) {
			this.abortController.abort();
			this.abortController = new AbortController();
		}
		return (response.ok) ? Promise.resolve(response) : Promise.reject(response);
	};

	private static getRequestHeaders(
		mergeHeaders = {},
	): Record<string, string> {
		const mimeType = 'application/json';
		const headers: Record<string, string> = {
			Accept: mimeType,
			'Content-Type': mimeType,
			...mergeHeaders,
		};
		return headers;
	}

	private static parseResponse(response: Response): Promise<any> {
		return response.status === 204
			? response.text()
			: response.json();
	}

	private onOpenEventSource = () => {
		this.esRetryFreqSec = 1;
	};

	private onErrorEventSource = () => {
		if (this.eventSource) {
			this.eventSource.close();
		}
		const reconnect = debounce(() => {
			this.setEventSource(true);
			this.esRetryFreqSec *= 2;
			if (this.esRetryFreqSec >= 64) {
				this.esRetryFreqSec = 64;
			}
		}, this.esRetryFreqSec * 1000);
		reconnect();
	};

	setEventSource = (force = false) => {
		if (!force && this._eventSource) {
			return;
		}
		const url = ApiClient.getUrl('/broadcaster');
		this._eventSource = new EventSourcePolyfill(url, {}) as EventSource;
		this._eventSource.onopen = this.onOpenEventSource;
		this._eventSource.onerror = this.onErrorEventSource;
	};

	createWithFormData(
		resource: string,
		data: FormData,
		options?: ReqOptions,
	): Promise<any> {
		const method = 'POST';
		return fetch(ApiClient.getUrl(resource, undefined, options), {
			method,
			body: data,
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}

	create(
		resource: string,
		data: any,
		options?: ReqOptions,
		headers?: Record<string, string>,
		apiType: ApiUrl = ApiUrl.MARKETPLACE,
	): Promise<any> {
		const method = 'POST';
		return fetch(ApiClient.getUrl(resource, undefined, options, apiType), {
			method,
			body: JSON.stringify(data),
			headers: ApiClient.getRequestHeaders(headers),
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}

	read(
		resource: string,
		id?: string,
		options?: ReqOptions,
		headers?: Record<string, string>,
		apiType?: ApiUrl,
	): Promise<any> {
		const method = 'GET';
		return fetch(ApiClient.getUrl(resource, id, options, apiType), {
			method,
			headers: ApiClient.getRequestHeaders(headers),
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}

	remove(
		resource: string,
		id?: string,
		options?: ReqOptions,
		headers?: Record<string, string>,
	): Promise<any> {
		const method = 'DELETE';
		return fetch(ApiClient.getUrl(resource, id, options), {
			method,
			headers: ApiClient.getRequestHeaders(headers),
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}

	update(
		resource: string,
		id?: string,
		data?: any,
		options?: ReqOptions,
		headers?: Record<string, string>,
		apiType?: ApiUrl,
	): Promise<any> {
		const method = 'PUT';
		return fetch(ApiClient.getUrl(resource, id, options, apiType), {
			method,
			body: JSON.stringify(data),
			headers: ApiClient.getRequestHeaders(headers),
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}

	patch(
		resource: string,
		id?: string,
		data?: any,
		options?: ReqOptions,
		headers?: Record<string, string>,
		apiType?: ApiUrl,
	): Promise<any> {
		const method = 'PATCH';
		return fetch(ApiClient.getUrl(resource, id, options, apiType), {
			method,
			body: JSON.stringify(data),
			headers: ApiClient.getRequestHeaders(headers),
			signal: ApiClient.abortController.signal,
		})
			.then(ApiClient.checkResponseStatus)
			.then(ApiClient.parseResponse);
	}
};
