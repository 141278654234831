import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'PaidTiersCard';
export const classes = {
	card: `${PREFIX}-card`,
	cardHeader: `${PREFIX}-cardHeader`,
	cardBody: `${PREFIX}-cardBody`,
	cardFooter: `${PREFIX}-cardFooter`,
	tierTitle: `${PREFIX}-tierTitle`,
	btn: `${PREFIX}-btn`,
	btn2: `${PREFIX}-btn2`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.card}`]: {
		width: '100%',
		height: 275,
	},
	[`& .${classes.cardHeader}`]: {
		width: 'auto',
		textAlign: 'center',
		marginLeft: '20px',
		marginRight: '20px',
		marginTop: '-40px',
		padding: '20px 0',
	},
	[`& .${classes.cardBody}`]: {
		[theme.breakpoints.only('md')]: {
			overflowY: 'scroll',
		},
	},
	[`& .${classes.cardFooter}`]: {
		border: '0',
		borderRadius: '6px',
		justifyContent: 'center !important',
		marginBottom: theme.spacing(1),
	},
	[`& .${classes.tierTitle}`]: {
		marginTop: '1rem',
		textAlign: 'center',
		padding: '0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.btn}`]: {
		color: '#43a047',
		border: '1px solid #43a047',
		marginTop: theme.spacing(-1),
	},
	[`& .${classes.btn2}`]: {
		color: '#43a047',
		marginTop: theme.spacing(-1),
	},
}));
