export const getWholesalerDisplayName = (type) => {
    switch (type) {
        case 'bluepax_secondary':
            return 'BluPax Pharma';
        case 'bloodworth_secondary':
            return 'Bloodworth Wholesale';
        case 'capital_secondary':
            return 'Capital Drug';
        case 'rx_supply_secondary':
            return 'Prescription Supply';
        case 'keysource_secondary':
            return 'KeySource USA';
        case 'toprx_secondary':
            return 'TopRx Pharma';
        case 'auburn_secondary':
            return 'Auburn Generics';
        case 'ipc_secondary':
            return 'IPC Warehouse';
        case 'mckesson_primary':
            return 'McKesson';
        case 'cardinal_primary':
            return 'Cardinal';
        case 'amerisource_primary':
            return 'AmerisourceBergen';
        case 'twosrx_secondary':
            return 'Second Source Rx';
        default:
            return 'N/A';
    }
};
