import {
	ChangeEvent, FC, useEffect, useState, memo,
} from 'react';
import {
	SxProps, Theme, TextField, InputAdornment, CircularProgress,
} from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { api } from '../../../../../services';
import theme from '../../../../../theme';

export interface CouponCodeFormProps {
	sx?: SxProps<Theme>;
	showCancelBtn?: boolean;
	onCancel?: () => void;
	onSuccess: (code: string) => void;
};

export const CouponCodeForm: FC<CouponCodeFormProps> = memo(({
	onSuccess,
}) => {
	const [code, setCode] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const isValidCouponCode = async (checkCode: string) => {
		try {
			const res = await api.get.coupon(checkCode?.toLowerCase().trim());
			if (res) {
				return true;
			}
		} catch (err) {
			return false;
		}
		return false;
	};

	const checkCode = async () => {
		setLoading(true);
		const didSucceed = await isValidCouponCode(code);
		setLoading(false);

		setSuccess(didSucceed);
		setError(!didSucceed);
		if (didSucceed) {
			onSuccess(code);
		}
	};

	useEffect(() => {
		if (code.length > 0) {
			const delayDebounceFn = setTimeout(() => {
				checkCode();
			}, 1000);
			return () => clearTimeout(delayDebounceFn);
		}
		setError(false);
		return undefined;
	}, [code]);

	const getTextFieldBorderColor = () => {
		if (error) { return theme.palette.error.main; }
		if (success) { return theme.palette.success.main; };
		return undefined;
	};

	const getTextFieldAdornment = () => {
		if (loading) { return <CircularProgress color='primary' size={20} />; };
		if (error) { return <Clear color='error' />; };
		if (success) { return <Check color='success' />; };
		return undefined;
	};

	return (
		<>
			<TextField
				label='Coupon Code'
				type='text'
				variant='outlined'
				fullWidth
				value={code}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					setCode(event.target.value);
				}}
				data-testid={'couponTextField'}
				helperText={' '}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{getTextFieldAdornment()}
						</InputAdornment>
					)
				}}
				sx={{
					fieldset: { borderColor: getTextFieldBorderColor() },
				}}
			/>
		</>
	);
});
