import { FC, ReactNode, memo } from 'react';
import clsx from 'clsx';
import { Root, classes } from './Card.styles';

interface Props {
	className?: string;
	plain?: boolean;
	carousel?: boolean;
	children: ReactNode;
};

export const Card: FC<Props> = memo(({
	className, children, plain, carousel, ...rest
}) => {
	const cardClasses = clsx(className, classes.card, {
		[classes.cardPlain]: plain,
		[classes.cardCarousel]: carousel,
	});
	return (
		<Root className={cardClasses} {...rest}>
			{children}
		</Root>
	);
});
