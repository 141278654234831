// @ts-ignore
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './styles.css';
import { FC, ReactNode, memo } from 'react';
import { Typography } from '@mui/material';
import { StyledGrid, classes } from './FeaturesSlider.styles';

interface Slide {
	children?: ReactNode;
	title?: string;
	description?: string;
	link?: string;
};
interface Props {
	slides: Slide[];
};

export const FeaturesSlider: FC<Props> = memo(({ slides }) => {
	const settings = { duration: 500, autoplay: false };

	return (
		<StyledGrid item xs={12}>
			<Slider {...settings}>
				{slides.map((slide, index) => (
					<div key={index} className={classes.slide}>
						{slide.children && slide.children}
						{!slide.children
							&& <>
								{slide.title
									&& <Typography className={classes.title}>
										{slide.title}
									</Typography>
								}
								<p className={classes.description}>
									{slide.description}
								</p>
							</>
						}
					</div>
				))}
			</Slider>
		</StyledGrid>
	);
});
