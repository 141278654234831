import { FC, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface Props {
	alt?: string;
	src?: string;
	height?: number;
}

const PREFIX = 'Logo';
const classes = {
	wrapper: `${PREFIX}-wrapper`,
};
const StyledRouterLink = styled('div')(() => ({
	[`&.${classes.wrapper}`]: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const Logo: FC<Props> = memo(({
	alt = 'Pharmacy Marketplace Logo', src,
}) => {
	const bucket = import.meta.env.VITE_IMAGE_ASSET_S3_BUCKET;
	const imgSrc = src ? src : `https://${bucket}.s3.amazonaws.com/owl-logo.png`;

	return (
		<StyledRouterLink className={classes.wrapper}>
			<img height={36} alt={alt} src={imgSrc} />
		</StyledRouterLink>
	);
});
