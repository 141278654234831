import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, Document, View, Text, StyleSheet } from '@react-pdf/renderer';
import { BorderPolygon, BillTo, Title, Number, ItemsTable } from './components';
;
export const InvoiceDocument = memo(({ invoice, wholesaler, pharmacy, }) => {
    const { invoiceNumber, } = invoice;
    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            padding: 30,
        },
        footerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 16,
        },
        footer: {
            fontSize: 9,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontStyle: 'italic',
            fontFamily: 'Roboto',
        },
    });
    return (_jsx(Document, { title: `Invoice ${invoiceNumber}.pdf`, children: _jsxs(Page, { size: 'A4', wrap: false, style: styles.page, children: [_jsx(BorderPolygon, { border: 'top' }), _jsx(Title, { title: `Invoice #${invoiceNumber}` }), _jsx(Number, { invoice: invoice }), _jsx(BillTo, { wholesaler: wholesaler, pharmacy: pharmacy }), _jsx(ItemsTable, { invoice: invoice }), _jsx(View, { style: styles.footerContainer, children: _jsx(Text, { style: styles.footer, children: "Thank you for your business!" }) }), _jsx(BorderPolygon, { border: 'bottom' })] }) }));
});
