import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Initials } from '../../../DocumentSeven/components/Initials';
;
const SigItem = ({ text, value = '_____________________', ...rest }) => {
    const styles = StyleSheet.create({
        item: {
            flexDirection: 'row',
            marginBottom: 10,
        },
        bulletPoint: {
            fontSize: 10,
        },
        itemContent: {
            flex: 1,
            fontSize: 11,
            marginLeft: 10,
        },
    });
    return (_jsxs(View, { style: styles.item, ...rest, children: [_jsx(Text, { style: styles.bulletPoint, children: text }), _jsx(Text, { style: styles.itemContent, children: value })] }));
};
;
export const PageTwo = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '10px 30px 0px',
        },
        entrySmall: {
            paddingBottom: 10,
            fontSize: 9,
        },
        entryExtraSmall: {
            paddingBottom: 10,
            fontSize: 8,
        },
        entrySmallContainer: {
            paddingLeft: 10,
            paddingRight: 10,
        },
        spacerSmall: {
            paddingBottom: 15,
        },
    });
    const items = useMemo(() => {
        const { owner, } = traderInfo;
        return [
            { text: '', style: styles.spacerSmall, isSig: false, value: undefined, },
            {
                text: 'No delay or failure on the part of Prescription Supply, Inc. in exercising any rights hereunder shall operate as a waiver of such rights. No modification or waiver of the provisions hereof shall be effective unless in writing, nor shall any waiver be applicable except in the specific instance or matter for which given.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'This Guaranty is and shall be deemed to be a contract entered into and made pursuant to the laws of the State of Ohio and shall in all respects be governed by and construed, applied and enforced in accordance with the laws of the State of Ohio, without regard to its conflicts of laws. No defense given or allowed by the laws of any other state or country shall be interposed unless such defense is also allowed by the laws of the State of Ohio.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'Guarantor acknowledges that, as to any and all disputes that may arise between Guarantor and Prescription Supply, Inc., the commercial nature of the transaction out of which this Guaranty arises would make any such dispute unsuitable for trial by jury. Accordingly, Guarantor hereby knowingly, voluntarily and irrevocably waives any right to trial by jury as to any and all disputes that may arise relating to the Obligations, this Guaranty or any of the other instruments or collateral documents executed in connection herewith.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'Guarantor has been given the right to have the terms and conditions of this Guaranty reviewed by an attorney for Guarantor. Guarantor acknowledges that he understood the terms and conditions of this Guaranty at the time of signing.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'With full knowledge of all constitutional rights under the Constitution of the State of Ohio and the United States of America, Guarantor hereby irrevocably authorizes any attorney at law, including without limitation, any attorney representing Prescription Supply, Inc., to appear on Guarantor\'s behalf in any court of record in the State of Ohio, or in any other state or territory of the United States, or in any court of the United States, after this Guaranty becomes due and payable; to waive the issuing and service of process and all other constitutional rights to due process of law; to confess judgment against Guarantor in favor of Prescription Supply, Inc. or any holder of any of the Obligations for the amount then appearing due together with the costs of suit; to release all errors; and to waive all rights of appeal and stays of execution. Guarantor hereby consents to the confessing attorney receiving a legal fee from Prescription Supply, Inc. or any other holder of this Guaranty. Guarantor voluntarily and knowingly irrevocably waives (i) any conflict of interest with respect to the attorney confession judgment against Guarantor, and (ii) all rights to notice and hearing prior to judgment being so confession against Guarantor.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'The Guarantor(s) and Prescription Supply, Inc. agree that the use of electronic transmission, including but not limited to e-mail, facsimile or other transmissions via the Internet or World Wide Web, in entering into and in exercising their rights and performing their obligations under this Agreement is in the best interests of all of the parties and shall be permissible, notwithstanding any requirement of the Uniform Commercial Code or any other applicable law that might otherwise be considered unsatisfied as a result of the utilization of such media. The Guarantor(s) and Prescription Supply, Inc. therefore agree that the Uniform Electronic Transactions Act, as promulgated by the National Conference of Commissions on Uniform State Laws, shall be applicable to the construction of this Agreement and any transactions hereunder, whether or not such Act shall have been adopted in any jurisdiction. By way of illustration and not of limitation the Guarantor(s) and Prescription Supply, Inc. agree to the following:',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: '(a) any document (including this agreement) transmitted by electronic transmission shall be treated in all respects as an original signed document; (b) the signature (including an electronic signature) of any Party shall be considered for these purposes as an original signature: (c) any such electronic transmission shall be considered to have the same binding legal effect as an original document; and (d) neither Party shall raise the use of electronic transmission as a defense to this Agreement or in matters relating to any transaction pursuant to this Agreement, and each Party waives such defense.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'WARNING - BY SIGNING THIS PAPER YOU GIVE UP YOUR RIGHT TO NOTICE AND COURT TRIAL. IF YOU DO NOT PAY ON TIME A COURT JUDGMENT MAY BE TAKEN AGAINST YOU WITHOUT YOUR PRIOR KNOWLEDGE AND THE POWERS OF A COURT CAN BE USED TO COLLECT FROM YOU REGARDLESS OF ANY CLAIMS YOU MAY HAVE AGAINST THE CREDITOR WHETHER FOR RETURNED GOODS, FAULTY GOODS, FAILURE ON HIS PART TO COMPLY WITH THE AGREEMENT, OR ANY OTHER CAUSE.',
                style: styles.entrySmall,
                isSig: false,
                value: undefined,
            },
            {
                text: 'IN WITNESS WHEREOF, Guarantor has executed this Guaranty in _______________county, in the state of _____ on________20( )',
                style: styles.entryExtraSmall,
                isSig: false,
                value: undefined,
            },
            { text: '', style: styles.spacerSmall, isSig: false, value: undefined, },
            { text: 'Guarantor Signature:', style: undefined, isSig: true, value: '', },
            {
                text: 'Printed Name:',
                style: undefined,
                isSig: true,
                value: `${owner.firstName} ${owner.lastName}`,
            },
            { text: 'Title:', style: undefined, isSig: true, value: 'Owner', },
            {
                text: 'Home Address:',
                style: undefined,
                isSig: true,
                value: `${owner.address}, ${owner.city}, ${owner.state}, ${owner.zip}`,
            },
            { text: '', style: styles.spacerSmall, isSig: false, value: undefined, },
            { text: 'Guarantor Signature:', style: undefined, isSig: true, value: undefined, },
            { text: 'Printed Name:', style: undefined, isSig: true, value: undefined, },
            { text: 'Title:', style: undefined, isSig: true, value: undefined, },
            { text: 'Home Address:', style: undefined, isSig: true, value: undefined, },
            { text: '', style: styles.spacerSmall, isSig: false, value: undefined, },
            {
                text: 'Company Name:',
                style: undefined,
                isSig: true,
                value: pharmacy.legalName,
            },
            {
                text: 'Company Address:',
                style: undefined,
                isSig: true,
                value: `${pharmacy.address}, ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`,
            },
            { text: 'Witness Signature:', style: undefined, isSig: true, value: undefined, },
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(View, { style: styles.entrySmallContainer, children: items.map((item, index) => item.isSig
                        ? _jsx(SigItem, { text: item.text, value: item.value }, index)
                        : _jsx(Text, { style: item.style, children: item.text }, index)) }), _jsx(Initials, { paddingTop: 0, pageNum: 2 })] }) }));
});
