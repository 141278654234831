import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Box, Link, Stack, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { usePopupState, bindHover, bindPopover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { RebateType } from './types';
;
const TableToolbar = ({ rebateType, handleAddRow, handleAddColumn, handleRebateTypeChange, noData, }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'rebateTitlePopover',
    });
    const onHandleAddRow = () => {
        handleAddRow();
    };
    const onHandleAddColumn = () => {
        handleAddColumn();
    };
    return (_jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, pr: 2, children: [!noData &&
                _jsxs(Box, { display: 'flex', alignItems: 'center', pl: 2, children: [_jsx(Button, { "aria-label": 'Add GCR Tier', variant: 'outlined', color: 'inherit', onClick: onHandleAddRow, startIcon: _jsx(AddIcon, {}), sx: { mr: 1, }, children: "Add GCR Tier" }), rebateType === RebateType.GENERIC &&
                            _jsx(Button, { "aria-label": 'Add Generic Spend Tier', variant: 'outlined', color: 'inherit', onClick: onHandleAddColumn, startIcon: _jsx(AddIcon, {}), children: "Add Generic Spend Tier" })] }), _jsxs(Stack, { flex: 1, sx: { ml: noData ? 0 : -12 }, direction: 'row', alignItems: 'center', justifyContent: 'center', spacing: 2, children: [_jsx(Typography, { variant: 'h5', align: 'center', textAlign: 'center', children: `${rebateType === RebateType.GENERIC ? 'Generic' : 'Brand'} Compliance Rebates` }), _jsx(Link, { href: '#', underline: 'none', style: { cursor: 'default' }, ...bindHover(popupState), children: "What's this?" }), _jsx(HoverPopover, { ...bindPopover(popupState), style: { maxWidth: '75%', }, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        }, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }, children: _jsx(Typography, { margin: 2, children: "This table should display your store's GCR ranges that represent a tier eg. 20.0-23.9% (pink cells), Spend tiers if applicable (blue cells), and the associated percentage rebate for each tier (white cells). This information is specified in your primary wholesaler agreement. \u00A0Contact your primary wholesaler representative to obtain the most recent copy." }) })] }), _jsxs(ToggleButtonGroup, { value: rebateType, exclusive: true, onChange: handleRebateTypeChange, "aria-label": "Rebate type", children: [_jsx(ToggleButton, { value: "Generic", "aria-label": "left aligned", children: "Generic" }), _jsx(ToggleButton, { value: "Brand", "aria-label": "centered", children: "Brand" })] })] }));
};
export default memo(TableToolbar);
