import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { Alert, AlertTitle, Button, Grid, Stack, Typography } from '@mui/material';
import { startCase } from 'lodash';
import { useSetRecoilState } from 'recoil';
import { PharmacyPostData } from '../../../../../../../../services/types';
import { STEPS, getErrorsForView } from '../../../../../../../../hooks/useErrorState';
import { registrationFormStepState } from '../../../../../../../../atoms/step';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const ErrorSummary: FC<Props> = memo(({ formik }) => {
	const setFormStep = useSetRecoilState(registrationFormStepState);
	const { errors } = formik;

	const errorMessages = Object.values(errors).reduce<string[]>((msgs: string[], err: any) => (typeof err === 'string'
		? msgs.concat(err) : [...msgs, ...Object.values(err) as any]), []);

	const errorCount = errorMessages.length;
	if (errorCount === 0) {
		return null;
	}

	const stepTitles = Object.keys(STEPS).filter((step) => Number.isNaN(Number(step)));
	const steps = Object.values(STEPS).filter((step) => typeof step === 'number') as STEPS[];
	const errorsForViews = steps.map((step) => getErrorsForView(errors, step));

	return (
		<Grid
			item
			xs={12}
			container
			spacing={1}
			columnSpacing={4}
			alignItems='center'
			justifyContent='flex-start'
		>
			<Grid item xs={12}>
				<Stack spacing={2} direction='column'>
					<Typography variant='h5'>Required Fields</Typography>
					<Stack direction='row' gap={2} justifyContent={'flex-start'} flexWrap={'wrap'}>
						{errorsForViews.map((error, index) => {
							const [stepHasError, stepErrorCount] = error;

							if (stepHasError) {
								const doNotLowercase = ['AP', 'PIC', 'PP', 'PW'];
								const title = doNotLowercase.includes(stepTitles[index])
									? stepTitles[index] : stepTitles[index].toLowerCase();
								const startCaseTitle = startCase(title);

								return (
									<Alert key={`alert-${index}`} severity="error"
										action={
											<Button color="inherit" size="small" onClick={() => {
												setFormStep(index + 2);
											}}>
												<b>View</b>
											</Button>
										}
									>
										<AlertTitle>{startCaseTitle}</AlertTitle>
										{`Step has ${stepErrorCount} error${stepErrorCount > 1 ? 's' : ''}`}
									</Alert>
								);
							}
							return null;
						})}
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
});
