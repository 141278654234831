import { Switch } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const PREFIX = 'App';
export const classes = {
	fill: `${PREFIX}-fill`,
};
export const StyledSwitch = styled(Switch)(() => ({
	[`& .${classes.fill}`]: {
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		position: 'absolute',
	},
}));
