import {
	FC, MouseEvent as ReactMouseEvent, useState, memo,
} from 'react';
import {
	Card, CardContent, CardMedia, IconButton, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import debounce from 'lodash/debounce';
import { Upload } from '../../../../../../services/types';
import { StyledGrid, StyledPopover, classes } from './Thumbnail.styles';

interface Props {
	index: number;
	upload: Upload;
	removeFile: (uploadId: string, fileIndex: number) => void;
};

export const Thumbnail: FC<Props> = memo(({
	index, upload, removeFile, ...rest
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const onRemove = debounce(
		(event) => {
			event.preventDefault();
			event.stopPropagation();
			removeFile(upload._id, index);
		},
		1000,
		{ leading: true, trailing: false, maxWait: 1000 },
	);

	const handlePopoverOpen = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = `${index}-filename-popover`;

	return (
		<StyledGrid item {...rest}>
			<Card className={classes.card}>
				<IconButton
					className={classes.icon}
					onClick={onRemove}
					size='small'
					aria-label='Remove File'
				>
					<DeleteIcon />
				</IconButton>
				{upload.thumbnailLocation
					&& <CardMedia
						className={classes.cardMedia}
						image={upload.thumbnailLocation}
						title={upload.filename}
						aria-owns={open ? id : undefined}
						aria-haspopup='true'
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
				}
				{!upload.thumbnailLocation
					&& <CardContent
						aria-owns={open ? id : undefined}
						aria-haspopup='true'
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					>
						<PdfIcon fontSize='large' />
					</CardContent>
				}
				<StyledPopover
					id={id}
					className={classes.popover}
					classes={{ paper: classes.paper }}
					disableScrollLock={true}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={handlePopoverClose}
				>
					<Typography>{upload.filename}</Typography>
				</StyledPopover>
			</Card>
		</StyledGrid>
	);
});
