import { styled } from '@mui/material/styles';

const PREFIX = 'Steps';
export const classes = {
	content: `${PREFIX}-content`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.content}`]: {
		top: theme.dimensions.navHeight,
		left: 0,
		right: 0,
		bottom: 0,
		position: 'absolute',
	},
}));
