import { FC, memo, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { isEmpty, every } from 'lodash';
import { SelectState } from '@pm/shared';
import { PharmacyPostData } from '../../../../../../services/types';
import { PhoneInput } from '../../../../../../components';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

const uppercaseFirstCharacter = (input: string) => {
	if (!input) {
		return input;
	}
	return input.charAt(0).toUpperCase() + input.slice(1);
};

const TradeReferenceInformation: FC<Props> = memo(({ formik }) => {
	const {
		handleChange, handleBlur, values, setValues
	} = formik;
	const errors = formik.errors as any;
	const touched = formik.touched as any;

	const tradeReference1 = values?.tradeReference1 ?? {} as PharmacyPostData['tradeReference1'];
	const tradeReference2 = values?.tradeReference2 ?? {} as PharmacyPostData['tradeReference1'];
	const tradeRefSameAsPw = values?.tradeRefSameAsPw ?? true;

	const fields = ['name', 'address', 'city', 'state', 'zip', 'phone', 'account Number'];
	const fieldsCopy = [...fields, ...fields];

	const setFromPrimary = () => {
		setValues({
			...values,
			tradeRefSameAsPw: true,
			tradeReference1: {
				...values.primary,
				accountNumber: values.mckessonId,
			}
		}, false);
	};

	useEffect(() => {
		const isTrade1Empty = every(tradeReference1, isEmpty);
		if (tradeRefSameAsPw && isTrade1Empty) {
			setFromPrimary();
		} else if (!isTrade1Empty) {
			setValues({
				...values,
				tradeReference1,
			}, false);
		}
	}, [tradeRefSameAsPw]);

	const handleFillCheckChange = (e: any) => {
		if (e.target.checked) {
			setFromPrimary();
		} else {
			setValues({
				...values,
				tradeRefSameAsPw: false,
				tradeReference1: {
					name: '',
					city: '',
					state: '',
					address: '',
					phone: '',
					zip: '',
					accountNumber: ''
				}
			}, false);
		}
	};

	return (
		<Grid
			container
			spacing={2}
			alignItems='center'
			justifyContent='flex-start'
		>
			<Grid item xs={12}>
				<Typography variant='subtitle1' align='center'>
					Two business / trade references are required to purchase product
					from BluPax on Pharmacy Marketplace.<br />We recommend using your
					primary wholesaler and another secondary wholesaler.
				</Typography>
			</Grid>
			<Stack direction='row' sx={{ width: '100%', mt: 1 }}>
				<Typography variant='body1'
					sx={{ margin: 2, marginBottom: 1 }}
				>
					Trade Reference 1
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={tradeRefSameAsPw}
							onChange={(e) => {
								handleChange(e);
								handleFillCheckChange(e);
							}}
							name='tradeRefSameAsPw'
							color='primary'
						/>
					}
					label='Use Primary Wholesaler information as a trade reference'
					sx={{ marginTop: 1 }}
				/>
			</Stack>
			{fieldsCopy.map((field, index) => {
				const traderReferenceRef = (Math.floor(index / 7) === 0 ? tradeReference1 : tradeReference2) as any;
				const fieldFormatted = `tradeReference${Math.floor(index / 7) + 1}.${field}`.replace(/\s/, '');
				const errorFieldFormatted = `tradeReference${Math.floor(index / 7) + 1}`;
				const fieldNoSpace = field.replace(/\s/, '');

				if (field === 'state') {
					return (
						<Grid item md={3} xs={12} key={index}>
							<SelectState
								key={`${index}-state-select`}
								name={fieldFormatted}
								value={traderReferenceRef && traderReferenceRef[fieldNoSpace]}
								label={uppercaseFirstCharacter(field)}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									errors[errorFieldFormatted]
									&& errors[errorFieldFormatted][fieldNoSpace]
									&& touched && touched[errorFieldFormatted] && touched[errorFieldFormatted][fieldNoSpace]
									&& Boolean(errors[errorFieldFormatted][fieldNoSpace])
								}
								helperText={
									touched[errorFieldFormatted]
									&& errors[errorFieldFormatted]
									&& touched && touched[errorFieldFormatted] && touched[errorFieldFormatted][fieldNoSpace]
									&& errors[errorFieldFormatted][fieldNoSpace]
								}
							/>
						</Grid>
					);
				}

				return (
					<>
						{index === 7
							&& <Typography variant='body1'
								sx={{ margin: 2, marginBottom: 1, width: '100%' }}
							>
								Trade Reference {(index / 7) + 1}
							</Typography>}
						<Grid item md={3} xs={12} key={index}>
							<TextField
								fullWidth
								name={fieldFormatted}
								label={uppercaseFirstCharacter(field)}
								type='text'
								variant='outlined'
								value={traderReferenceRef && traderReferenceRef[fieldNoSpace]}
								inputProps={{
									'data-testid': fieldFormatted,
									maxLength: fieldNoSpace === 'zip' ? 5 : undefined,
								}}
								onChange={handleChange}
								onBlur={handleBlur}
								required={fieldFormatted !== 'tradeReference2.accountNumber'}
								InputProps={{ inputComponent: field === 'phone' && PhoneInput as any }}
								error={
									errors[errorFieldFormatted]
									&& errors[errorFieldFormatted][fieldNoSpace]
									&& touched && touched[errorFieldFormatted] && touched[errorFieldFormatted][fieldNoSpace]
									&& Boolean(errors[errorFieldFormatted][fieldNoSpace])
								}
								helperText={
									touched[errorFieldFormatted]
									&& errors[errorFieldFormatted]
									&& touched && touched[errorFieldFormatted] && touched[errorFieldFormatted][fieldNoSpace]
									&& errors[errorFieldFormatted][fieldNoSpace]
								}
							/>
						</Grid>
					</>
				);
			})}
		</Grid>
	);
});

export default TradeReferenceInformation;
