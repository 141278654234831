import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
export const Entry = memo(({ label, value, }) => {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        label: {
            paddingRight: 8,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 11,
        },
        value: {
            fontSize: 12,
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.label, children: label }), _jsx(Text, { style: styles.value, children: value })] }));
});
