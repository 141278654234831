import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { useState } from 'react';
const BooleanQuestion = (props) => {
    const { onChange, name, error, onExplanationChange, initialValue, initialExplanation, onBlur, explanationError, explanationTouched, question } = props;
    const [value, setValue] = useState(initialValue ?? 'no');
    const [explanation, setExplanation] = useState(initialExplanation ?? '');
    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value === 'yes');
    };
    const handleExplanationChange = (event) => {
        setExplanation(event.target.value);
        onExplanationChange(event.target.value);
    };
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { required: true, error: error, children: question }), _jsxs(RadioGroup, { row: true, name: name, value: value, onChange: handleChange, onBlur: onBlur, "data-testid": `${name}-radio`, children: [_jsx(FormControlLabel, { value: "yes", control: _jsx(Radio, {}), label: "Yes" }), _jsx(FormControlLabel, { value: "no", control: _jsx(Radio, {}), label: "No" })] }), value === 'yes'
                && _jsx(TextField, { name: `${name}Explanation`, placeholder: "List / Describe", multiline: true, minRows: 2, maxRows: 4, value: explanation, "data-testid": `${name}-description`, onChange: (e) => handleExplanationChange(e), onBlur: onBlur, error: explanationError, helperText: explanationTouched })] }));
};
export default BooleanQuestion;
