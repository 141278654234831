import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import Auburn from '../../../../images/AuburnLogo.png';
import Check from '../../../../images/check.png';
;
export const DocumentTwentyTwo = memo(({ pharmacy, traderInfo, user, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '20px 30px',
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: 25,
        },
        image: {
            width: 75,
            height: 75,
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 20,
            textAlign: 'center',
        },
        titleContainer: {
            flexDirection: 'column',
            padding: '5px 0px 5px 15px',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
        },
        p: {
            fontSize: 12,
            fontFamily: 'Roboto',
        },
        section: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            marginBottom: 10,
        },
        entry: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        footer: {
            marginTop: 160,
            textAlign: 'center',
        },
        footerText: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
    });
    const date = dayjs().format('MM/DD/YYYY');
    const sections = useMemo(() => [
        [{ label: 'Auburn Account Number:', value: '_______________________________', }],
        [
            { label: 'Name of Pharmacy:', value: pharmacy.name, },
            { label: 'Date:', value: date, },
        ],
        [
            { label: 'Street Address:', value: pharmacy.address, },
            { label: 'Phone:', value: pharmacy.phone, },
        ],
        [
            { label: 'City:', value: pharmacy.city, },
            { label: 'State:', value: pharmacy.state, },
            { label: 'Zip:', value: pharmacy.zipCode, }
        ]
    ], []);
    const { payments: { auburn_secondary, }, } = traderInfo;
    const paymentMethod = auburn_secondary?.paymentMethod ?? 'ACH';
    const isACH = paymentMethod === 'ACH';
    const isCC = paymentMethod === 'Credit Card';
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.headerContainer, children: [_jsx(Image, { style: styles.image, src: Auburn }), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Please Select Your Preferred Payment Method" }) })] }), sections.map((section, i) => (_jsx(View, { style: styles.section, children: section.map((field, j) => (_jsxs(View, { style: styles.entry, children: [_jsx(Text, { style: { ...styles.p, marginRight: 5, fontSize: 10, }, children: field.label }), _jsx(Text, { style: styles.p, children: field.value })] }, j))) }, i))), isACH && _jsx(ACH, { traderInfo: traderInfo, user: user }), isCC && _jsx(CC, { traderInfo: traderInfo, pharmacy: pharmacy, user: user }), _jsxs(View, { style: { ...styles.section, marginTop: 50, justifyContent: 'flex-start', }, children: [_jsx(View, { style: { ...styles.entry, marginLeft: 70, }, children: _jsx(Text, { style: { ...styles.p, fontSize: 10, fontStyle: 'bold', }, children: "Authorized Signature:" }) }), _jsxs(View, { style: { ...styles.entry, marginLeft: 150, }, children: [_jsx(Text, { style: { ...styles.p, fontSize: 10, fontStyle: 'bold', }, children: "Date:" }), _jsx(Text, { style: { ...styles.p, marginLeft: 5, }, children: date })] })] }), _jsxs(View, { style: styles.footer, children: [_jsx(Text, { style: styles.footerText, children: "1744 Rochester Industrial Drive, Rochester Hills, Michigan 48309 \u2022 800-222-5609 \u2022 FAX 248-526-3713" }), _jsx(Text, { style: styles.footerText, children: "E-mail address: accounting@auburnpharm.com" })] })] }), _jsx(Text, { style: { ...styles.p, position: 'absolute', bottom: 30, left: 30, }, children: "Rev 06/23/21" })] }));
});
const CC = memo(({ traderInfo, pharmacy, user, }) => {
    const styles = StyleSheet.create({
        container: {
            marginTop: 20,
        },
        title: {
            fontSize: 18,
            fontStyle: 'bold',
            fontFamily: 'Roboto',
            fontWeight: 700,
            textAlign: 'center',
            marginBottom: 20,
        },
        label: {
            fontSize: 10,
            fontFamily: 'Roboto',
        },
        value: {
            fontSize: 12,
            fontFamily: 'Roboto',
            marginLeft: 5,
        },
        field: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 8,
        },
    });
    const { payments, } = traderInfo;
    const fields = useMemo(() => [
        { label: 'Type of Card:', value: payments.cardType, },
        { label: 'Credit Card Number:', value: payments.cardNumber, },
        { label: 'Expiration Date:', value: payments.cardExpDate, },
        { label: 'Cardholder’s Name:', value: `${user.firstName} ${user.lastName}`, },
        { label: 'Credit Card Billing Address:', value: `${pharmacy.address} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`, },
    ], [traderInfo]);
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.title, children: "Credit Card Authorization" }), fields.map((field, i) => (_jsxs(View, { style: styles.field, children: [_jsx(Text, { style: styles.label, children: field.label }), _jsx(Text, { style: styles.value, children: field.value })] }, i)))] }));
});
const ACH = memo(({ traderInfo, user, }) => {
    const styles = StyleSheet.create({
        container: {
            marginTop: 20,
        },
        title: {
            fontSize: 18,
            fontStyle: 'bold',
            fontFamily: 'Roboto',
            fontWeight: 700,
            textAlign: 'center',
        },
        p: {
            fontSize: 11,
            fontFamily: 'Roboto',
            margin: '10px 0px',
        },
        label: {
            fontSize: 10,
            fontFamily: 'Roboto',
        },
        value: {
            fontSize: 12,
            fontFamily: 'Roboto',
            marginLeft: 5,
        },
        field: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 8,
        },
    });
    const { banking, } = traderInfo;
    const fields = useMemo(() => [
        { label: 'Name of Financial Institution:', value: banking.bankName, },
        { label: 'Financial Institution Routing Number:', value: banking.routingNumber, },
        { label: 'Checking Account Number:', value: banking.accountNumber, },
    ], [traderInfo]);
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.title, children: "ACH Authorization" }), _jsx(Text, { style: { ...styles.p, marginBottom: 15, }, children: "I hereby authorize Auburn Pharmaceutical Company to initiate entries to my checking account at the financial institution listed below.  This authority will remain in effect until Auburn Pharmaceutical Company is notified, in writing, to cancel said agreement." }), fields.map((field, i) => (_jsxs(View, { style: styles.field, children: [_jsx(Text, { style: styles.label, children: field.label }), _jsx(Text, { style: styles.value, children: field.value })] }, i))), _jsx(Text, { style: styles.label, children: "These numbers are located on the bottom of your check as follows:" }), _jsx(Image, { style: { width: 228, height: 34, marginBottom: 20, }, src: Check }), _jsxs(View, { style: styles.field, children: [_jsx(Text, { style: styles.label, children: "Name - Please Print" }), _jsx(Text, { style: styles.value, children: `${user.firstName} ${user.lastName}` })] }), _jsxs(View, { style: styles.field, children: [_jsx(Text, { style: styles.label, children: "Account To Be Debited:" }), _jsx(Text, { style: styles.value, children: banking.accountToBeDebited === 'Other'
                            ? banking.accountToBeDebitedOther
                            : banking.accountToBeDebited })] })] }));
});
