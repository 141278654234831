import { FC, memo } from 'react';
import { FormikProps } from 'formik';
import { Divider, Grid } from '@mui/material';
import { PharmacyPostData } from '../../../../../../services/types';
import { WholesalerPaymentOptions, CreditCard } from './components';
import BillingAddress from './components/BillingAddress/BillingAddress';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const PaymentsInformation: FC<Props> = memo(({ formik }) => (
	<Grid container rowSpacing={3}>
		<WholesalerPaymentOptions formik={formik} />
		<CreditCard formik={formik} />
		<Divider sx={{ height: 25, width: '100%' }} />
		<BillingAddress formik={formik} />
	</Grid>
));
