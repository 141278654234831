import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'FeaturesSlider';
export const classes = {
	slideChild: 'slider',
	slide: `${PREFIX}-slide`,
	title: `${PREFIX}-title`,
	description: `${PREFIX}-description`,
};
export const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.slideChild}`]: {
		height: 135,
	},
	[`& .${classes.slide}`]: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(0, 2),
	},
	[`& .${classes.title}`]: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(-1),
	},
	[`& .${classes.description}`]: {
		width: '80%',
	},
}));
