export const splitUserName = (userName: string): string[] => {
	if (!userName || userName.length === 0) {
		return ['-', '-'];
	}
	let firstName = userName.substring(0, userName.indexOf(' '));
	let lastName = userName.substring(userName.indexOf(' ') + 1);

	if (firstName.length === 0 && lastName.length > 0) {
		firstName = lastName;
		lastName = '-';
	} else {
		if (firstName.length === 0) {
			firstName = '-';
		}
		if (lastName.length === 0) {
			lastName = '-';
		}
	}
	return [firstName, lastName];
};
