import { FC, memo } from 'react';
import { useRecoilState } from 'recoil';
import {
	Grid, Stepper, Step, StepLabel, Typography,
} from '@mui/material';
import { onboardCompleted, onboardStepState } from '../../../../atoms';
import { Logo } from '../../../../components';
import { StyledStepConnector, StyledStepIcon } from './components';
import { Root, classes } from './Nav.styles';

export const Nav: FC = memo(() => {
	const [step] = useRecoilState(onboardStepState);
	const [completed] = useRecoilState(onboardCompleted);

	return (
		<Root className={classes.nav}>
			<Grid container justifyContent='space-evenly' alignItems='center'>
				<Grid
					item
					xs={2}
					container
					direction='column'
				>
					<Logo />
					<Grid sx={{ mt: 2 }} item container direction='column' justifyContent='center' alignItems='center'>
						<a href='tel:+18009585540' rel='noreferrer'>
							<Typography variant='caption' className={classes.link}>
								1 (800) 958-5540
							</Typography>
						</a>
						<a href='mailto:support@pharmacymarketplace.com' target='_top' rel='noreferrer'>
							<Typography variant='caption' className={classes.link}>
								support@pharmacymarketplace.com
							</Typography>
						</a>
					</Grid>
				</Grid>
				<Grid item xs={10}>
					<Stepper
						alternativeLabel
						activeStep={completed ? step : step - 1}
						connector={<StyledStepConnector />}
						className={classes.stepper}
					>
						{['Introduction', 'Pharmacy Registration']
							.map((label) => (
								<Step key={label}>
									<StepLabel StepIconComponent={StyledStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
					</Stepper>
				</Grid>
			</Grid>
		</Root>
	);
});
