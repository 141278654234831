import { get } from './get';
import { post } from './post';
import { put } from './put';
import { del } from './delete';
import { patch } from './patch';

export const api = {
	get,
	post,
	put,
	del,
	patch
};
