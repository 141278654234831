import { FC, memo } from 'react';
import { Grid } from '@mui/material';
import { Upload } from '../../../../services/types';
import { Thumbnail } from './components';

interface Props {
	uploads: Upload[];
	removeFile: (uploadId: string, fileIndex: number) => void;
};

export const Thumbnails: FC<Props> = memo(({ uploads, removeFile }) => (
	<Grid
		container
		spacing={1}
		wrap='nowrap'
		justifyContent='center'
		alignItems='flex-end'
	>
		{uploads.map((upload, index) => (
			<Thumbnail
				key={index}
				index={index}
				upload={upload}
				removeFile={removeFile}
			/>
		))}
	</Grid>
));
