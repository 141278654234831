import { FC, memo } from 'react';
import {
	CardHeader, Divider, Link, Typography,
} from '@mui/material';

export const FormHeader: FC = memo(() => (
	<>
		<CardHeader
			title={
				<Typography align='center' variant='h5'>
					Welcome to Pharmacy Marketplace!
				</Typography>
			}
			subheader={
				<div>
					<Typography align='center' variant='body1' color='textSecondary' display='block'>
						We&apos;re thrilled to serve you! Please get started by providing some
						basic information about yourself and your pharmacy below.
						All of your data will be securely encrypted both in transit
						and at rest using <Link
							underline='none'
							target='_blank'
							rel='noreferrer'
							href='https://en.wikipedia.org/wiki/Advanced_Encryption_Standard'
						>
							AES-256
						</Link>.
					</Typography>
					<Typography sx={{ mt: 2 }} align='center' variant='body1' color='textSecondary' display='block'>
						Please complete the entire onboarding application using the same <b>computer and browser</b> to prevent your session from being restarted.
					</Typography>
				</div>
			}
		/>
		<Divider />
	</>
));
