import {
	FC, MouseEvent as ReactMouseEvent, useState, memo,
} from 'react';
import {
	FormControl, FormControlProps, Grid, TextField, Theme, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SubscriptionType, getPriceForSubscriptionType } from '@pm/shared';
import { CurrencyInput } from '../../../../../../../../components';

export interface MonthlyChargeAmountProps {
	discountApplied: boolean;
	subType: SubscriptionType;
};

export const MonthlyChargeAmount: FC<MonthlyChargeAmountProps> = memo(({
	discountApplied, subType
}) => {
	const theme: Theme = useTheme();
	const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const open = subType !== SubscriptionType.BASIC && Boolean(anchorEl);
	const id = 'monthly-charge-amount-popover';

	const formControlProps: Partial<FormControlProps> = subType === SubscriptionType.BASIC
		? {}
		: {
			'aria-owns': open ? id : undefined,
			'aria-haspopup': 'true',
			onMouseEnter: handlePopoverOpen,
			onMouseLeave: handlePopoverClose,
		};

	if (discountApplied) {
		return null;
	}

	return (
		<Grid item md={xlUp ? 3 : 4} xs={12}>
			<FormControl
				fullWidth
				variant='outlined'
				{...formControlProps}
			>
				<TextField
					fullWidth
					required
					disabled
					name='banking.monthlyChargeAmount'
					label='Monthly Subscription Charge Amount'
					type='text'
					variant='outlined'
					value={getPriceForSubscriptionType(subType)}
					InputProps={{ inputComponent: CurrencyInput as any }}
				/>
			</FormControl>
		</Grid>
	);
});
