import {
	FC, useMemo, memo,
} from 'react';
import { useSetRecoilState } from 'recoil';
import { Button, Typography } from '@mui/material';
import { SubscriptionType } from '@pm/shared';
import { registrationFormStepState, registrationFormState } from '../../../../atoms';
import {
	Card, CardBody, CardFooter, CardHeader,
} from '../../../../components';
import { FeaturesSlider } from '../components';
import { StyledGrid, classes } from './PremiumTierCard.styles';
import { Requirements } from './components';
import { PharmacyPostData } from '../../../../services/types';

export const PremiumTierCard: FC = memo(() => {
	const setFormStep = useSetRecoilState(registrationFormStepState);
	const setForm = useSetRecoilState(registrationFormState);
	const formOpen = false;

	const onClickStart = (code?: string) => {
		setForm((form: PharmacyPostData) => ({
			...form,
			subscription: SubscriptionType.PREMIUM,
			code,
			banking: {
				...form.banking,
			},
			dispensing: {
				...form.dispensing,
			},
		}));
		setFormStep((step) => step + 1);
	};

	const slides = useMemo(() => [
		{
			title: 'Athena',
			description: `Athena is your intelligent purchasing assistant who analyzes 
				your dispensing history and your wholesaler catalogs to recommend the 
				cheapest, therapeutically equivalent, purchasing option.`,
			link: 'https://pharmacymarketplace.com/#athenaPower',
		},
		{
			children: <Requirements />,
		},
		{
			title: 'Dispensing Analytics',
			description: ' Premium also includes access to dispensing analytics and prescription profit mining.',
			link: 'https://pharmacymarketplace.com/#dispensingAnalytics',
		},
	], []);

	return (
		<StyledGrid item xs={12}>
			<Card className={classes.card}>
				<CardHeader color='success' className={classes.cardHeader}>
					<Typography variant='h5'>Premium Shopper</Typography>
					<div className={classes.tierTitle}>
						<Typography component='span'>$</Typography>
						<Typography variant='h5'>200</Typography>
						<Typography component='span'>/mo</Typography>
					</div>
				</CardHeader>
				<CardBody className={classes.cardBody}>
					{!formOpen && <FeaturesSlider slides={slides} />}
				</CardBody>
				{!formOpen
					&& <CardFooter className={classes.cardFooter}>
						<Button
							variant='outlined'
							size='large'
							className={classes.btn}
							onClick={() => { onClickStart(); }}
						>
							Get started
						</Button>
					</CardFooter>
				}
			</Card>
		</StyledGrid>
	);
});
