/* eslint-disable max-len */
import { FC, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import {
	Checkbox, FormControlLabel, Grid, MenuItem, TextField,
} from '@mui/material';
import states from '../../../../../../assets/json/us-states.json';
import { PharmacyPostData } from '../../../../../../services/types';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};

export const ShippingInformation: FC<Props> = memo(({ formik }) => {
	const PHARMACY_ADDRESS_IS_SHIPPING = 'pharmacy-address-is-shipping';
	const SHIPPING_ADDRESS = 'shipping-address';

	const stateOptions = useMemo(() => states.map((option) => (
		<MenuItem key={option.abbreviation} value={option.abbreviation}>
			{option.name}
		</MenuItem>
	)), []);

	const {
		handleChange, handleBlur, errors, touched, values,
	} = formik;
	const {
		pharmacyAddressIsShippingAddress, relatedToOtherFacilities,
		address, shippingAddress, city, shippingCity, state,
		shippingState, zipCode, shippingZip,
	} = values;
	const updateShippingFields = (usePharmacyAddress: boolean) => {
		const keys = ['shippingAddress', 'shippingCity', 'shippingState', 'shippingZip'];
		const vals = usePharmacyAddress
			? [address, city, state, zipCode]
			: [shippingAddress, shippingCity, shippingState, shippingZip];
		let index = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			formik.setFieldValue(keys[index], vals[index]);
			formik.setFieldError(key, undefined);
			index += 1;
		}
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='center'
			alignItems='center'
		>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={pharmacyAddressIsShippingAddress}
							onChange={(e, checked) => {
								handleChange(e);
								updateShippingFields(checked);
							}}
							name='pharmacyAddressIsShippingAddress'
							color='primary'
						/>
					}
					data-testid={PHARMACY_ADDRESS_IS_SHIPPING}
					label='Pharmacy address is the same as the shipping address'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='shippingAddress'
					label='Shipping Address'
					type='text'
					variant='outlined'
					inputProps={{
						'data-testid': SHIPPING_ADDRESS
					}}
					value={pharmacyAddressIsShippingAddress ? address : shippingAddress}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.shippingAddress && Boolean(errors.shippingAddress)}
					helperText={touched.shippingAddress && errors.shippingAddress}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='shippingCity'
					label='Shipping City'
					type='text'
					variant='outlined'
					value={pharmacyAddressIsShippingAddress ? city : shippingCity}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.shippingCity && Boolean(errors.shippingCity)}
					helperText={touched.shippingCity && errors.shippingCity}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					select
					fullWidth
					required
					name='shippingState'
					label='Shipping State'
					type='text'
					variant='outlined'
					value={pharmacyAddressIsShippingAddress ? state : shippingState}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.shippingState && Boolean(errors.shippingState)}
					helperText={touched.shippingState && errors.shippingState}
				>
					{stateOptions}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required
					name='shippingZip'
					label='Shipping Zip Code'
					type='text'
					variant='outlined'
					value={pharmacyAddressIsShippingAddress ? zipCode : shippingZip}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.shippingZip && Boolean(errors.shippingZip)}
					helperText={touched.shippingZip && errors.shippingZip}
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={values.multipleShipToFacilities}
							onChange={handleChange}
							name='multipleShipToFacilities'
							color='primary'
						/>
					}
					label='Do you have multiple ship-to facilities?'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={values.relatedToOtherFacilities}
							onChange={handleChange}
							name='relatedToOtherFacilities'
							color='primary'
						/>
					}
					label='Are you related to any other facilities?'
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					fullWidth
					required={relatedToOtherFacilities}
					name='relatedToOtherFacilitiesList'
					label='Related Facilities List'
					type='text'
					variant='outlined'
					value={values.relatedToOtherFacilitiesList}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.relatedToOtherFacilitiesList && Boolean(errors.relatedToOtherFacilitiesList)}
					helperText={(touched.relatedToOtherFacilitiesList && errors.relatedToOtherFacilitiesList)
						? errors.relatedToOtherFacilitiesList
						: 'Please list facilities separated by comma'
					}
				/>
			</Grid>
		</Grid>
	);
});
