import { FC, memo } from 'react';
import { StepIconProps } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import clsx from 'clsx';
import { Root, classes } from './StyledStepIcon.styles';

export const StyledStepIcon: FC<StepIconProps> = memo(({
	active, completed,
}) => (
	<Root
		className={clsx(classes.root, {
			[classes.active]: active,
			[classes.completed]: completed,
		})}
	>
		{completed ? <Check /> : <Close />}
	</Root>
));
