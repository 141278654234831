import { FC, ReactNode, memo } from 'react';
import clsx from 'clsx';
import { Root, classes } from './CardHeader.styles';

interface Props {
	className?: string;
	color: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose' | 'gold';
	plain?: boolean;
	children: ReactNode;
};

export const CardHeader: FC<Props> = memo(({
	className, children, color, plain, ...rest
}) => {
	const cardHeaderClasses = clsx(className, classes.cardHeader, {
		[(classes as any)[`${color}CardHeader`]]: color,
		[classes.cardHeaderPlain]: plain,
	});

	return (
		<Root className={cardHeaderClasses} {...rest}>
			{children}
		</Root>
	);
});
