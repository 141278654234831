import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from '../DocumentSixteen/components/ImageHeader';
import { SignaturesBox } from './components';
;
export const DocumentSeventeen = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            padding: '10px 10px 0px 10px',
        },
        title: {
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 8,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            flexDirection: 'column',
            marginBottom: 10,
            padding: 9,
        },
        entryBold: {
            paddingLeft: 0,
            paddingBottom: 10,
            fontSize: 13,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textDecoration: 'underline',
        },
        entrySm: {
            paddingLeft: 0,
            paddingBottom: 5,
            fontSize: 10,
        },
    });
    const date = dayjs().format('MM/DD/YYYY');
    const items = [
        {
            text: `_______________________ County, __________________________ THE UNDERSIGNED, for value received and other good and valuable considerations, including, without limitation, this guaranty as an inducement to BLOODWORTH WHOLESALE DRUGS, INC., as “Lender,” to extend credit to ${pharmacy.name}, as “Borrower,” in the form of an open account for the purchase of pharmaceuticals for Lender, the undersigned does hereby personally guarantee the prompt payment of all indebtedness to Lender accruing upon said open account, this being an unlimited guaranty of such account and account indebtedness.`,
            style: styles.entrySm,
        },
        {
            text: 'This is an absolute, unconditional and continuing guaranty of payment of the indebtedness and shall continue to be in force and be binding upon the undersigned until the indebtedness herein guaranteed is paid in full, or until this guaranty is revoked by written notice actually received by the Lender. Such revocation shall not be effective as to indebtedness existing or committed for at the time of actual receipt of such notice by the Lender, or as to any renewals, extensions and refinancings thereof. The death or incompetence of the undersigned shall not revoke this guaranty, except upon actual receipt of written notice thereof by Lender and then only as to the decedent or the incompetent and only prospectively, as to future transactions, as herein set forth.',
            style: styles.entrySm,
        },
        {
            text: 'If the undersigned shall die, or shall be or become insolvent (however defined) or revoke this guaranty, then the Lender shall have the right to declare immediately due and payable, and the undersigned or the estate of the undersigned will forthwith pay to the Lender, the full amount of all indebtedness, whether due and payable or unmatured. If the undersigned voluntarily commences or there is commenced involuntarily against the undersigned a case under the United States Bankruptcy Code, the full amount of all indebtedness, whether due and payable or unmatured, shall be immediately due and payable without demand or notice thereof.',
            style: styles.entrySm,
        },
        {
            text: 'The undersigned will pay or reimburse Lender for all costs and expenses (including reasonable attorneys’ fees and legal expenses) incurred by Lender in connection with the protection, defense or enforcement of this guaranty in any litigation or bankruptcy or insolvency proceedings.',
            style: styles.entrySm,
        },
        {
            text: 'The undersigned waives any and all defenses, claims and discharges of Borrower, or any other obligor, pertaining to indebtedness, except the defense of discharge by payment in full. Without limiting the generality of the foregoing, the undersigned will not assert, plea or enforce against Lender any defense of waiver, release, statute of limitations, res judicate, statute of frauds, fraud, incapacity, minority, usury, illegality or unenforceability which may be available to Borrower or any other person liable in respect of any indebtedness, or any setoff available against Lender by Borrower or any such person, whether or not on account of a related transaction.',
            style: styles.entrySm,
        },
        {
            text: `The undersigned waives presentment, demand for payment, notice of dishonor or nonpayment, and protest of any instrument evidencing indebtedness. Lender shall not be required first to resort for payment of the indebtedness to Borrower or other persons or their properties, or first to enforce, realize upon or exhaust any collateral security for indebtedness, before enforcing this guaranty. IN WITNESS WHEREOF, this guaranty has been duly executed on the day ${date}, by affixing the hand and seal of the undersigned hereto.`,
            style: styles.entrySm,
        },
        { text: '', style: styles.entrySm, },
        { text: '', style: styles.entrySm, },
    ];
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Guaranty" }) }), _jsx(View, { style: styles.entryContainer, children: items.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(SignaturesBox, { date: date, traderInfo: traderInfo })] }) }));
});
