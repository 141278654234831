import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
;
const State = ({ letter, symbol, }) => {
    const styles = StyleSheet.create({
        stateSymbol: {
            fontSize: 5,
        },
        stateContainer: {
            flexDirection: 'row',
        },
        tableCell: {
            margin: 2,
            fontSize: 8,
        },
    });
    return (_jsxs(View, { style: styles.stateContainer, children: [_jsx(Text, { style: styles.tableCell, children: letter }), _jsx(Text, { style: styles.stateSymbol, children: symbol })] }));
};
;
const Row = ({ firstState, firstSymbol, firstValue, secondState, secondSymbol, secondValue, }) => {
    const styles = StyleSheet.create({
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
        },
        tableColState: {
            width: '10%',
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableColValue: {
            width: '40%',
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCellRight: {
            margin: 2,
            fontSize: 8,
            textAlign: 'right',
        },
    });
    return (_jsxs(View, { style: styles.tableRow, children: [_jsx(View, { style: styles.tableColState, children: _jsx(State, { letter: firstState, symbol: firstSymbol }) }), _jsx(View, { style: styles.tableColValue, children: _jsx(Text, { style: styles.tableCellRight, children: firstValue }) }), _jsx(View, { style: styles.tableColState, children: _jsx(State, { letter: secondState, symbol: secondSymbol }) }), _jsx(View, { style: styles.tableColValue, children: _jsx(Text, { style: styles.tableCellRight, children: secondValue }) })] }));
};
;
export const StateIdTable = memo(({ pharmacy, }) => {
    const colHeader = {
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        backgroundColor: '#d6e3bc',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    };
    const styles = StyleSheet.create({
        container: {
            padding: '0px 50px',
        },
        body: {
            padding: 2,
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
        },
        lastTableRow: {
            margin: 'auto',
            flexDirection: 'row',
            backgroundColor: '#d6e3bc',
            height: 10,
            width: '100%',
        },
        colHeader1: {
            ...colHeader,
            width: '10%',
        },
        colHeader2: {
            ...colHeader,
            width: '40%',
        },
        tableCellHeader: {
            margin: 2,
            fontSize: 8,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
    });
    const getStateValue = (state, stateId, pharmacyState) => {
        if (state === pharmacyState) {
            return stateId;
        }
        return '';
    };
    const { stateIdNumber, state, } = pharmacy;
    const rows = [
        {
            firstState: 'AL',
            firstSymbol: '1',
            firstValue: getStateValue('AL', stateIdNumber, state),
            secondState: 'MO',
            secondSymbol: '16',
            secondValue: getStateValue('MO', stateIdNumber, state),
        },
        {
            firstState: 'AR',
            firstSymbol: '',
            firstValue: getStateValue('AR', stateIdNumber, state),
            secondState: 'NE',
            secondSymbol: '17',
            secondValue: getStateValue('NE', stateIdNumber, state),
        },
        {
            firstState: 'AZ',
            firstSymbol: '2',
            firstValue: getStateValue('AZ', stateIdNumber, state),
            secondState: 'NV',
            secondSymbol: '',
            secondValue: getStateValue('NV', stateIdNumber, state),
        },
        {
            firstState: 'CA',
            firstSymbol: '3',
            firstValue: getStateValue('CA', stateIdNumber, state),
            secondState: 'NJ',
            secondSymbol: '',
            secondValue: getStateValue('NJ', stateIdNumber, state),
        },
        {
            firstState: 'CO',
            firstSymbol: '4',
            firstValue: getStateValue('CO', stateIdNumber, state),
            secondState: 'NM',
            secondSymbol: '4,18',
            secondValue: getStateValue('NM', stateIdNumber, state),
        },
        {
            firstState: 'CT',
            firstSymbol: '5',
            firstValue: getStateValue('CT', stateIdNumber, state),
            secondState: 'NC',
            secondSymbol: '19',
            secondValue: getStateValue('NC', stateIdNumber, state),
        },
        {
            firstState: 'DC',
            firstSymbol: '6',
            firstValue: getStateValue('DC', stateIdNumber, state),
            secondState: 'ND',
            secondSymbol: '',
            secondValue: getStateValue('ND', stateIdNumber, state),
        },
        {
            firstState: 'FL',
            firstSymbol: '7',
            firstValue: getStateValue('FL', stateIdNumber, state),
            secondState: 'OH',
            secondSymbol: '20',
            secondValue: getStateValue('OH', stateIdNumber, state),
        },
        {
            firstState: 'GA',
            firstSymbol: '8',
            firstValue: getStateValue('GA', stateIdNumber, state),
            secondState: 'OK',
            secondSymbol: '21',
            secondValue: getStateValue('OK', stateIdNumber, state),
        },
        {
            firstState: 'HI',
            firstSymbol: '4,9',
            firstValue: getStateValue('HI', stateIdNumber, state),
            secondState: 'PA',
            secondSymbol: '22',
            secondValue: getStateValue('PA', stateIdNumber, state),
        },
        {
            firstState: 'ID',
            firstSymbol: '1',
            firstValue: getStateValue('ID', stateIdNumber, state),
            secondState: 'RI',
            secondSymbol: '23',
            secondValue: getStateValue('RI', stateIdNumber, state),
        },
        {
            firstState: 'IL',
            firstSymbol: '4,10',
            firstValue: getStateValue('IL', stateIdNumber, state),
            secondState: 'SC',
            secondSymbol: '',
            secondValue: getStateValue('SC', stateIdNumber, state),
        },
        {
            firstState: 'IA',
            firstSymbol: '1',
            firstValue: getStateValue('IA', stateIdNumber, state),
            secondState: 'SD',
            secondSymbol: '24',
            secondValue: getStateValue('SD', stateIdNumber, state),
        },
        {
            firstState: 'KS',
            firstSymbol: '1',
            firstValue: getStateValue('KS', stateIdNumber, state),
            secondState: 'TN',
            secondSymbol: '',
            secondValue: getStateValue('TN', stateIdNumber, state),
        },
        {
            firstState: 'KY',
            firstSymbol: '11',
            firstValue: getStateValue('KY', stateIdNumber, state),
            secondState: 'TX',
            secondSymbol: '25',
            secondValue: getStateValue('TX', stateIdNumber, state),
        },
        {
            firstState: 'ME',
            firstSymbol: '12',
            firstValue: getStateValue('ME', stateIdNumber, state),
            secondState: 'UT',
            secondSymbol: '',
            secondValue: getStateValue('UT', stateIdNumber, state),
        },
        {
            firstState: 'MD',
            firstSymbol: '13',
            firstValue: getStateValue('MD', stateIdNumber, state),
            secondState: 'VT',
            secondSymbol: '',
            secondValue: getStateValue('VT', stateIdNumber, state),
        },
        {
            firstState: 'MI',
            firstSymbol: '14',
            firstValue: getStateValue('MI', stateIdNumber, state),
            secondState: 'WA',
            secondSymbol: '26',
            secondValue: getStateValue('WA', stateIdNumber, state),
        },
        {
            firstState: 'MN',
            firstSymbol: '15',
            firstValue: getStateValue('MN', stateIdNumber, state),
            secondState: 'WI',
            secondSymbol: '27',
            secondValue: getStateValue('WI', stateIdNumber, state),
        },
    ];
    return (_jsx(View, { style: styles.container, children: _jsxs(View, { style: styles.table, children: [_jsxs(View, { style: styles.tableRow, children: [_jsx(View, { style: styles.colHeader1, children: _jsx(Text, { style: styles.tableCellHeader, children: "State" }) }), _jsx(View, { style: styles.colHeader2, children: _jsx(Text, { style: styles.tableCellHeader, children: "State Registration, Seller's Permit, or ID Number of Purchaser" }) }), _jsx(View, { style: styles.colHeader1, children: _jsx(Text, { style: styles.tableCellHeader, children: "State" }) }), _jsx(View, { style: styles.colHeader2, children: _jsx(Text, { style: styles.tableCellHeader, children: "State Registration, Seller's Permit, or ID Number of Purchaser" }) })] }), rows.map((row, index) => _jsx(Row, { ...row }, index)), _jsx(View, { style: styles.lastTableRow })] }) }));
});
