export var DocumentType;
(function (DocumentType) {
    DocumentType[DocumentType["PM_BUSINESS_ASSOCIATE_AGREEMENT"] = -1] = "PM_BUSINESS_ASSOCIATE_AGREEMENT";
    DocumentType[DocumentType["PM_ONLINE_USER_AGREEMENT"] = 0] = "PM_ONLINE_USER_AGREEMENT";
    DocumentType[DocumentType["PM_BANK_TRANSFER_AUTHORIZATION"] = 1] = "PM_BANK_TRANSFER_AUTHORIZATION";
    DocumentType[DocumentType["CAPITAL_DRUG_CREDIT_APPLICATION"] = 2] = "CAPITAL_DRUG_CREDIT_APPLICATION";
    DocumentType[DocumentType["CAPITAL_BANK_DEBIT_AUTHORIZATION"] = 3] = "CAPITAL_BANK_DEBIT_AUTHORIZATION";
    DocumentType[DocumentType["CAPITAL_PURCHASING_DIRECTIVE_AUTHORIZATION"] = 4] = "CAPITAL_PURCHASING_DIRECTIVE_AUTHORIZATION";
    DocumentType[DocumentType["BLUPAX_ACH_AUTHORIZATION"] = 5] = "BLUPAX_ACH_AUTHORIZATION";
    DocumentType[DocumentType["BLUPAX_CREDIT_APPLICATION"] = 6] = "BLUPAX_CREDIT_APPLICATION";
    DocumentType[DocumentType["RXSUPPLY_CREDIT_APPLICATION"] = 7] = "RXSUPPLY_CREDIT_APPLICATION";
    DocumentType[DocumentType["RXSUPPLY_SECURITY_AGREEMENT"] = 8] = "RXSUPPLY_SECURITY_AGREEMENT";
    DocumentType[DocumentType["RXSUPPLY_DEBT_GUARANTY_AGREEMENT"] = 9] = "RXSUPPLY_DEBT_GUARANTY_AGREEMENT";
    DocumentType[DocumentType["RXSUPPLY_BANK_DEBIT_AUTHORIZATION"] = 10] = "RXSUPPLY_BANK_DEBIT_AUTHORIZATION";
    DocumentType[DocumentType["RXSUPPLY_UNIFORM_SALES_AND_USE_CERTIFICATE"] = 11] = "RXSUPPLY_UNIFORM_SALES_AND_USE_CERTIFICATE";
    DocumentType[DocumentType["RXSUPPLY_STREAMLINED_SALES_AND_USE_CERTIFICATE"] = 12] = "RXSUPPLY_STREAMLINED_SALES_AND_USE_CERTIFICATE";
    DocumentType[DocumentType["KEYSOURCE_CREDIT_APPLICATION"] = 13] = "KEYSOURCE_CREDIT_APPLICATION";
    DocumentType[DocumentType["KEYSOURCE_TERMS_AND_CONDITIONS"] = 14] = "KEYSOURCE_TERMS_AND_CONDITIONS";
    DocumentType[DocumentType["KEYSOURCE_CC_AND_ACH_AUTHORIZATION"] = 15] = "KEYSOURCE_CC_AND_ACH_AUTHORIZATION";
    DocumentType[DocumentType["BLOODWORTH_CREDIT_APPLICATION"] = 16] = "BLOODWORTH_CREDIT_APPLICATION";
    DocumentType[DocumentType["BLOODWORTH_DEBT_GUARANTY_AGREEMENT"] = 17] = "BLOODWORTH_DEBT_GUARANTY_AGREEMENT";
    DocumentType[DocumentType["BLOODWORTH_ACH_AUTHORIZATION"] = 18] = "BLOODWORTH_ACH_AUTHORIZATION";
    DocumentType[DocumentType["TOPRX_CREDIT_APPLICATION"] = 19] = "TOPRX_CREDIT_APPLICATION";
    // TOPRX_ACH_AUTHORIZATION = 20,
    DocumentType[DocumentType["AUBURN_CREDIT_APPLICATION"] = 21] = "AUBURN_CREDIT_APPLICATION";
    DocumentType[DocumentType["AUBURN_CC_AND_ACH_AUTHORIZATION"] = 22] = "AUBURN_CC_AND_ACH_AUTHORIZATION";
    DocumentType[DocumentType["AUBURN_UNIFORM_SALES_AND_USE_CERTIFICATE"] = 23] = "AUBURN_UNIFORM_SALES_AND_USE_CERTIFICATE";
    DocumentType[DocumentType["AUBURN_COMPLIANCE_QUESTIONNAIRE"] = 24] = "AUBURN_COMPLIANCE_QUESTIONNAIRE";
})(DocumentType || (DocumentType = {}));
;
