import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
;
export const PageHeader = memo(({ imgSrc, }) => {
    const styles = StyleSheet.create({
        container: {
            paddingTop: 20,
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        image: {
            width: 200,
        },
        text: {
            color: 'blue',
            fontSize: 14,
            marginTop: 10,
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsx(Image, { style: styles.image, src: imgSrc }), _jsx(Text, { style: styles.text, children: "CREDIT APPLICATION AND AGREEMENT" })] }));
});
