import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { ImageHeader } from './components';
;
export const DocumentThirteen = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        titleContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            marginBottom: 0,
            padding: 10,
        },
        title: {
            paddingTop: 0,
            paddingBottom: 8,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 22,
            paddingLeft: 3,
        },
        entryContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            padding: 10,
        },
        entry: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 12,
        },
        entryBold: {
            paddingLeft: 0,
            paddingBottom: 10,
            fontSize: 13,
            fontWeight: 'demibold',
            textDecoration: 'underline',
        },
        entrySm: {
            paddingLeft: 0,
            paddingBottom: 5,
            fontSize: 10,
        },
        entryLg: {
            paddingLeft: 0,
            paddingBottom: 12,
            fontSize: 14,
        },
    });
    const { ap, banking, owner, pic, } = traderInfo;
    const questions = [
        { text: '1) Business Contact Information', style: styles.entryBold, },
        { text: `Company Name:  ${pharmacy.name}`, style: styles.entry, },
        { text: `Billing Address:  ${pharmacy.address}`, style: styles.entry, },
        { text: `City:  ${pharmacy.city}     State:  ${pharmacy.state}     Zip:  ${pharmacy.zipCode}`, style: styles.entry, },
        { text: `Shipping Address (if different):  ${pharmacy.shippingAddress || '_____________________'}`, style: styles.entry, },
        { text: `City:  ${pharmacy.shippingCity || '_____________'}     State:  ${pharmacy.shippingState || '______________'}     Zip:  ${pharmacy.shippingZip || '_____________'}`, style: styles.entry, },
        { text: `Phone:  ${pharmacy.phone}     DEA #:  ${pharmacy.deaNumber}`, style: styles.entry, },
        { text: `Fax:  ${pharmacy.fax || '_____________________'}     State License:  ${pharmacy.stateLicense}`, style: styles.entry, },
        { text: `Email:  ${pharmacy.email}`, style: styles.entry, },
        { text: `Owner / Officer:  ${owner.firstName} ${owner.lastName}     Position:  Owner     Business Type:  ${pharmacy.pharmacyType}`, style: styles.entry, },
        { text: `Authorized Buyer:  ${pic.firstName} ${pic.lastName}     Position:  Pharmacist-in-Charge`, style: styles.entry, },
        { text: `Accounts Payable Contact:  ${ap.firstName} ${ap.lastName}     Phone:  ${ap.phone}`, style: styles.entry, },
        { text: `Monthly Statements:  Yes     Statement Delivery:  ${banking.receiveInvoices}`, style: styles.entry, },
    ];
    const Agreement = useMemo(() => (_jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.entrySm, children: "The above information is for the purpose of obtaining commercial credit and is warranted to be true and correct. If KeySource or its agents consider a credit report relevant and necessary to " }), _jsx(Text, { style: styles.entrySm, children: "assisting this request for credit, the undersigned authorizes KeySource or its agents to obtain from a credit reporting agency a credit report containing personal credit information about the applicant. The undersigned authorizes KeySource or its agents to investigate the references listed pertaining to the applicant\u2019s credit and financial responsibility, and PERSONALLY guarantees payment. A copy of this application shall be deemed as an original." })] })), []);
    const signature = useMemo(() => {
        const { owner, } = traderInfo;
        const date = dayjs().format('MM/DD/YYYY');
        return [
            { text: 'Authorized Signature:', style: styles.entryLg, },
            { text: `Printed Name:  ${owner.firstName} ${owner.lastName}`, style: styles.entryLg, },
            { text: `Date:  ${date}`, style: styles.entryLg, },
            { text: '', style: styles.entry, },
            { text: 'Territory (office use only): _____________________', style: styles.entry, },
        ];
    }, [traderInfo]);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsx(ImageHeader, {}), _jsx(View, { style: styles.titleContainer, children: _jsx(Text, { style: styles.title, children: "Business Account Information Form" }) }), _jsx(View, { style: styles.entryContainer, children: questions.map((question, index) => (_jsx(Text, { style: question.style, children: question.text }, index))) }), Agreement, _jsx(View, { style: styles.entryContainer, children: signature.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) })] }) }));
});
