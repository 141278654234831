import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Image, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { PageFooter } from '../PageFooter';
import BluePax from '../../../../../../images/bluepax-logo.png';
;
export const PageOne = memo(({ pharmacy, traderInfo, user, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        imgContainer: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 5,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: 2,
            borderBottomColor: '#003399',
        },
        image: {
            width: 200,
        },
        textBold: {
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            marginRight: 20,
            marginTop: 10,
            paddingBottom: 2,
            fontSize: 14,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            color: '#003399',
        },
        information: {
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            fontSize: 12,
            color: 'dodgerblue',
        },
        accountNumber: {
            paddingTop: 3,
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            fontSize: 12,
            color: '#003399',
        },
        boxParent: {
            paddingLeft: 25,
            paddingRight: 25,
        },
        boxContainer: {
            marginBottom: 5,
            marginTop: 5,
            border: 1.5,
            borderColor: '#003399',
        },
        boxHeader: {
            backgroundColor: '#003399',
            color: 'white',
            textAlign: 'center',
            fontSize: 12,
        },
        boxEntry: {
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
            fontSize: 10,
        },
        termsContainer: {
            paddingLeft: 30,
            paddingTop: 5,
            paddingRight: 30,
        },
        terms: {
            fontSize: 12,
            paddingTop: 3,
            paddingBottom: 3,
        },
        termsBold: {
            fontSize: 12,
            paddingTop: 3,
            paddingBottom: 3,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        versionText: {
            fontSize: 10,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
            paddingBottom: 0,
        },
    });
    const entries = useMemo(() => {
        const { banking, owner, ap, pp, primary, } = traderInfo;
        const { legalName, name, address, phone, fax, city, state, zipCode, mckessonId, shippingAddress, dateBusinessBegan, npiNumber, deaNumber, hinNumber, stateLicense, } = pharmacy;
        const mailingAddress = shippingAddress && shippingAddress !== address
            ? shippingAddress
            : '_____________________';
        const businessBeganDayjs = dayjs(dateBusinessBegan);
        const formattedDateBusinessBegan = businessBeganDayjs.isValid()
            ? businessBeganDayjs.format('MM/DD/YYYY')
            : dateBusinessBegan;
        const { firstName, lastName, } = pp;
        const buyerName = (firstName && lastName)
            ? `${firstName} ${lastName}`
            : 'N/A';
        return [
            { text: 'BUSINESS CONTACT INFORMATION', style: styles.boxHeader, },
            { text: `Pharmacy Name:  ${legalName}     DBA:  ${name}`, style: styles.boxEntry, },
            { text: `Person completing this application:  ${user.firstName} ${user.lastName}     Title:  ${user.title}`, style: styles.boxEntry, },
            { text: `Phone:  ${phone}     Fax:  ${fax || '_______________'}     Email:  ${user.email}`, style: styles.boxEntry, },
            { text: `Physical Pharmacy Address:  ${address}`, style: styles.boxEntry, },
            { text: `City:  ${city}     State:  ${state}     Zip:  ${zipCode}`, style: styles.boxEntry, },
            { text: `Mailing Address (if different than above):  ${mailingAddress}`, style: styles.boxEntry, },
            { text: `Date Business Commenced:  ${formattedDateBusinessBegan}       How long at current address? ____ Years ____ Months`, style: styles.boxEntry, },
            { text: `Business Type:  ${owner.ownershipType}`, style: styles.boxEntry, },
            { text: `Owner's Name:  ${owner.firstName} ${owner.lastName}     DEA # or HIN #:  ${deaNumber || hinNumber}`, style: styles.boxEntry, },
            { text: `NPI #:  ${npiNumber}     State License #:  ${stateLicense}`, style: styles.boxEntry, },
            { text: `Buyer's Name:  ${buyerName}`, style: styles.boxEntry, },
            { text: `Buyer's Phone:  ${pp.phone}     Buyer's Email:  ${pp.email || 'N/A'}`, style: styles.boxEntry, },
            { text: `A/P Contact Name:  ${ap.firstName} ${ap.lastName}`, style: styles.boxEntry, },
            { text: `A/P Phone:  ${ap.phone}     A/P E-mail:  ${ap.email}`, style: styles.boxEntry, },
            { text: 'BANK AND CREDIT INFORMATION', style: styles.boxHeader, },
            { text: `Bank Name:  ${banking.bankName}     Phone:  ${banking.bankPhone}`, style: styles.boxEntry, },
            { text: `Address:  ${banking.bankAddress}     City:  ${banking.bankCity}     State:  ${banking.bankState}     Zip:  ${banking.bankZip}`, style: styles.boxEntry, },
            { text: `Type of Account:  ${banking.accountType}     Account Number:  ${banking.accountNumber}`, style: styles.boxEntry, },
            { text: 'BUSINESS / TRADE REFERENCES', style: styles.boxHeader, },
            { text: `Name:  ${primary.name}`, style: styles.boxEntry, },
            { text: `Address:  ${primary.address}     City:  ${primary.city}     State:  ${primary.state}     Zip:  ${primary.zip}`, style: styles.boxEntry, },
            { text: `Phone:  ${primary.phone}     Fax:  ${primary.fax || '________________'}     Account #:  ${mckessonId || '___________'}`, style: styles.boxEntry, },
        ];
    }, [pharmacy]);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.imgContainer, children: [_jsx(View, { children: _jsx(Image, { style: styles.image, src: BluePax }) }), _jsxs(View, { children: [_jsx(Text, { style: styles.textBold, children: "Credit Application" }), _jsx(Text, { style: styles.information, children: "Sr. National Account Manager: Adam Sloan" }), _jsx(Text, { style: styles.information, children: "P: 732.515.6207" }), _jsx(Text, { style: styles.information, children: "E: adam@blupaxpharma.com" }), _jsx(Text, { style: styles.accountNumber, children: "Account #: _______________" }), _jsx(Text, {})] })] }), _jsxs(View, { style: styles.termsContainer, children: [_jsx(Text, { style: styles.termsBold, children: "Thank you for your interest in BluPax Pharma. In order to open an account with us, please fill out this form in its entirety." }), _jsx(Text, { style: styles.terms, children: "To expedite credit approval, please submit a legible copy of your State license and DEA license. The name and address on your licenses must match the name and address on the account." }), _jsx(Text, { style: styles.termsBold, children: "Failure to fill out parts of this application or submit the required forms and licenses will cause a delay in processing your account." })] }), _jsx(View, { style: styles.boxParent, children: _jsx(View, { style: styles.boxContainer, children: entries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index))) }) }), _jsx(View, { style: styles.boxParent, children: _jsx(Text, { style: styles.versionText, children: "V:04.21" }) }), _jsx(PageFooter, {})] }) }));
});
