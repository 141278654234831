import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import CapitalDrug from '../../../../../../images/capital-drug-logo.png';
;
export const PageTwo = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        entryContainer: {
            flexDirection: 'column',
            border: 1,
            marginBottom: 10,
        },
        entryDisclosure: {
            padding: '0px 8px',
            fontSize: 10,
        },
        entryDisclosureLast: {
            padding: '0px 8px 4px',
            fontSize: 10,
        },
        entry: {
            paddingLeft: 20,
            paddingBottom: 12,
            fontSize: 10,
        },
        nestedEntry: {
            paddingLeft: 36,
            paddingBottom: 12,
            fontSize: 10,
        },
        title: {
            paddingBottom: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 10,
            paddingLeft: 3,
        },
    });
    const sectionOneEntries = useMemo(() => {
        const { banking, dispensing, } = traderInfo;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const estimatedMonthlyGeneric = formatter.format(dispensing.estimatedMonthlyGeneric);
        const estimatedMonthlyBrand = formatter.format(dispensing.estimatedMonthlyBrand);
        return [
            { text: '10. Bank Relationships:', style: styles.entry, },
            { text: `Bank Name:  ${banking.bankName}     City:  ${banking.bankCity}     State:  ${banking.bankState}`, style: styles.nestedEntry, },
            { text: `Account Officer:  ${banking.accountOfficer}     Phone #:  ${banking.bankPhone}     Account #:  ${banking.accountNumber}`, style: styles.nestedEntry, },
            { text: '', style: styles.entry, },
            { text: `11. Type of Business:  ${pharmacy.pharmacyType}`, style: styles.entry, },
            { text: `12. Years In Business:  ${traderInfo.yearsInBusiness}     Monthly Rx:  ${dispensing.estimatedMonthlyRx}`, style: styles.entry, },
            { text: `Monthly Generic:  ${estimatedMonthlyGeneric}     Monthly Brand:  ${estimatedMonthlyBrand}`, style: styles.nestedEntry, }
        ];
    }, [pharmacy]);
    const sectionTwoEntries = useMemo(() => {
        return [
            {
                text: '1. As an inducement for Capital Wholesale Drug Co. (“Capital”) to accept orders from or otherwise extend or make available credit to Applicant, which includes all Applicant owned stores as listed in Exhibit A attached hereto, the undersigned Applicant hereby agrees to comply with the following terms of sale, should Capital elect to extend such credit.',
                style: styles.entryDisclosure,
            },
            { text: '', style: styles.entry, },
            {
                text: '2. Pricing and payment terms are determined at the time an offer is presented to Applicant and as reflected on any accompanying invoice.',
                style: styles.entryDisclosure,
            },
            { text: '', style: styles.entry, },
            {
                text: '3. If, (a) Capital does not receive payment in accordance with the payment terms or based upon credit considerations deemed relevant to Capital, (b) applicant sells any part of its business; (c) the ownership structure of the applicant changes or (d) Capital deems itself insecure for any reason; then Capital may refuse to deliver the product covered by this Agreement (the "Product"), refuse additional orders, modify payment terms, place the Applicant on C.O.D., modify Applicant’s costs of goods, limit or terminate the extension of credit and will be entitled to any other remedies available at law or equity.',
                style: styles.entryDisclosure,
            },
            { text: '', style: styles.entry, },
            {
                text: '4. All payments shall be made in full, in good funds, either by check or electronic funds transfer (either by wire or automated clearinghouse), and in accordance with the payment terms. Capital may assess a service charge calculated at the rate of 1.5% per month (or the maximum rate allowed by law, if such rate is less than 1.5% per month) on any amount not paid by Applicant to Capital when due under the terms of this Agreement from the first day of delinquency. Failure or delay by Capital to bill Applicant for any such service charge will not waive Capital’s right to receive the same. In the event of default in payments on any invoices, Capital does not receive payment in accordance with the payment terms or based upon credit considerations deemed relevant to Capital, applicant sells any part of its business, the ownership structure of the applicant changes or Capital deems itself insecure for any reason, Capital shall have the right to declare all invoices immediately due and payable and Applicant gives Capital the authorization to use any credit card, debit card or electronic funds transfer to satisfy any past due invoices. Applicant shall pay all out-of-pocket expenses, including attorney’s fees, court costs, expenses and disbursements, incurred by Capital to collect any amounts due under this Agreement or to otherwise enforce any of the terms of this agreement.',
                style: styles.entryDisclosure,
            },
            { text: '', style: styles.entry, },
            {
                text: '5. The Product is shipped FOB point of shipment. Applicant’s obligation to pay for Product begins on the date of shipment. Applicant agrees to place orders in the specified minimum dollar amount. If the order size is less than specified minimum, Applicant agrees to pay a minimum order charge. Capital reserves the right at all times to determine what Product it will carry based upon product quality, manufacture indemnity, insurance, and other policies, and other standards determined by it, and may delete Product from its available inventory at any time. No schedule II orders will be delivered other than in compliance with DEA regulations.',
                style: styles.entryDisclosureLast,
            }
        ];
    }, [pharmacy]);
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsx(PageHeader, { imgSrc: CapitalDrug }), _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.title, children: "SECTION II: FINACIAL INFORMATION (cont.)" }), sectionOneEntries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index)))] }), _jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.title, children: "SECTION III: AGREEMENT & DISCLOSURES" }), sectionTwoEntries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index)))] }), _jsx(PageFooter, {})] })] }));
});
