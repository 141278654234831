import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import has from 'lodash/has';
import { Initials } from '../../../DocumentSeven/components/Initials';
import { ListItem, LetterListItem } from '../ListItem';
;
export const PageTwo = memo(({ pharmacy, traderInfo, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '0px 30px',
        },
        entryContainer: {
            padding: '0px 20px',
        },
        entry: {
            paddingBottom: 12,
            fontSize: 10,
        },
        entryItalic: {
            paddingBottom: 12,
            fontSize: 10,
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
        boldUnderlineHeader: {
            fontSize: 11,
            textDecoration: 'underline',
            fontWeight: 'demibold',
        },
        divider: {
            borderBottom: 1.5,
        },
        spacer: {
            paddingBottom: 25,
        },
    });
    const sectionOne = useMemo(() => {
        return [
            {
                text: 'waives and releases Secured Party from any and all claims in connection therewith or arising there from.',
                letter: '',
                number: -1,
            },
            {
                text: 'The Secured Party may require Buyers to take any and all action necessary to assemble and make the collateral available to PSI.',
                letter: 'c.',
                number: -1,
            },
            {
                text: 'The Secured Party may sell the Collateral or any part hereof at public or private sale, for cash, upon credit or for future delivery, and at such price or prices as the Secured Party may deem satisfactory. The Debtor agrees that this is a non-consumer transaction and that ten (10) days prior written notice of the time and place of any sale or other intended disposition of any of the Collateral constitutes reasonable notification within the meaning of Section 9-612 of the UCC except that shorter or no notice shall be reasonable as to any Collateral which is perishable or threatens to decline Speedily in value or is of a type customarily sold on a recognized market.',
                letter: 'd.',
                number: -1,
            },
            {
                text: 'Debtor shall pay all costs that may be incurred in the Collection of all or part of the indebtedness owing to insured party. In the event that suit is instituted, the Debtor shall pay all legal fees, including attorneys\' fees, and interest in such amount as the court adjudge reasonable.',
                letter: 'e.',
                number: -1,
            },
            {
                text: 'The validity of this Security Agreement and any provision herein shall be determined according to the Uniform Commercial Code and other applicable laws of the state of Ohio as they may apply. Any Action or proceeding seeking to enforce any provision of, or based on any right arising out of , this Agreement shall be brought solely in the courts of the State of Ohio, Wood County, or, if it has or can acquire jurisdiction, in the United States District Court for the Northern District of Ohio, Western Division; and each of the parties consents to the jurisdiction of such courts in any such action or proceeding and waives any objection to venue laid therein.',
                number: 16,
            },
            {
                text: 'The Debtor and Prescription Supply, Inc. agree that the use of electronic transmission, including but not limited to e-mail, facsimile or other transmissions via the Internet or World Wide Web, in entering into and in exercising their rights and performing their obligations under this Agreement is in the best interests of all of the parties and shall be permissible, notwithstanding any requirement of the Uniform Commercial Code or any other applicable law that might otherwise be considered unsatisfied as a result of the utilization of such media. The Debtor and Prescription Supply, Inc. therefore agree that the Uniform Electronic Transactions Act, as promulgated by the National Conference of Commissions on Uniform State Laws, shall be applicable to the construction of this Agreement and any transactions hereunder, whether or not such Act shall have been adopted in any jurisdiction. By way of illustration and not of limitation the Debtor and Prescription Supply, Inc. agree to the following:',
                number: 17,
            },
            {
                text: '(a) any document (including this agreement) transmitted by electronic transmission shall be treated in all respects as an original signed document; (b) the signature (including an electronic signature) of any Party shall be considered for these purposes as an original signature: (c) any such electronic transmission shall be considered to have the same binding legal effect as an original document; and (d) neither Party shall raise the use of electronic transmission as a defense to this Agreement or in matters relating to any transaction pursuant to this Agreement, and each Party waives such defense.',
                number: 18,
            },
        ];
    }, []);
    const date = dayjs().format('MM/DD/YYYY');
    const { owner, } = traderInfo;
    const debtor = [
        { text: '', style: styles.spacer, },
        { text: 'Debtor', style: styles.boldUnderlineHeader, },
        { text: '', style: styles.spacer, },
        {
            text: 'Officer Signature:',
            style: styles.entryItalic,
        },
        {
            text: `Printed Name:  ${owner.firstName} ${owner.lastName}`,
            style: styles.entryItalic,
        },
        { text: 'Title:  Owner', style: styles.entryItalic, },
        {
            text: `Home Address:  ${owner.address}, ${owner.city}, ${owner.state}, ${owner.zip}`,
            style: styles.entryItalic,
        },
        {
            text: `Corporation Name:  ${pharmacy.name}`,
            style: styles.entryItalic,
        },
        {
            text: `Address:  ${pharmacy.address}, ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`,
            style: styles.entryItalic,
        },
        {
            text: `Date:  ${date}`,
            style: styles.entryItalic,
        },
        { text: '', style: styles.spacer, },
        { text: '', style: styles.divider, },
    ];
    const securedParty = useMemo(() => {
        return [
            { text: '', style: styles.spacer, },
            { text: 'Secured Party', style: styles.boldUnderlineHeader, },
            { text: '', style: styles.spacer, },
            { text: 'Officer Signature: ________________________', style: styles.entryItalic, },
            { text: '2233 Tracy Rd. Northwood, OH 43619', style: styles.entry, },
            { text: 'Date: ________________________', style: styles.entryItalic, },
        ];
    }, []);
    return (_jsx(Page, { size: 'A4', wrap: false, children: _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.spacer }), sectionOne.map((item, index) => has(item, 'letter')
                            ? _jsx(LetterListItem, { ...item }, index)
                            : _jsx(ListItem, { ...item }, index))] }), _jsx(View, { style: styles.entryContainer, children: debtor.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(View, { style: styles.entryContainer, children: securedParty.map((item, index) => (_jsx(Text, { style: item.style, children: item.text }, index))) }), _jsx(Initials, { pageNum: 2 })] }) }));
});
