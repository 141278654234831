import palette from '../palette';

const { secondary } = palette;

export default {
	styleOverrides: {
		contained: {
			boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
		},
		containedPrimary: {
			boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
			background: 'linear-gradient(210deg, #FFA726 0%, #FF8F00 100%)',
		},
		containedSecondary: {
			boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
			background: `linear-gradient(45deg, ${secondary.dark} 30%, ${secondary.light} 90%)`,
		},
	},
};
