import './styles.css';
import { FC, useMemo, memo } from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { StyledGrid, classes } from './Loader.styles';

interface Props {
	absolutelyPositioned?: boolean;
	color?: 'white' | 'black';
	ellipsisClass?: string;
	title: string;
};

export const Loader: FC<Props> = memo(({
	absolutelyPositioned = true, color = 'white', ellipsisClass, title,
}) => {
	const isWhite = color === 'white';
	const dots = useMemo(() => [0, 1, 2, 3].map((key) => <div key={key} />), []);

	return (
		<StyledGrid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			className={clsx({
				[classes.absoluteContainer]: absolutelyPositioned,
				[classes.staticContainer]: !absolutelyPositioned,
			})}
		>
			<Grid item>
				<Typography
					variant='h6'
					className={clsx({
						[classes.white]: isWhite,
						[classes.black]: !isWhite,
					})}
				>
					{title}
				</Typography>
			</Grid>
			<Grid item>
				<div
					className={clsx(
						'lds-ellipsis',
						ellipsisClass,
						{ 'white-bg': isWhite, 'black-bg': !isWhite },
					)}
				>
					{dots}
				</div>
			</Grid>
		</StyledGrid>
	);
});
