import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { TableHeader } from './TableHeader';
import { TableRows } from './TableRows';
import { TableFooter } from './TableFooter';
;
export const ItemsTable = memo(({ invoice, }) => {
    const { drugs, } = invoice;
    const styles = StyleSheet.create({
        tableContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderWidth: 1,
            borderColor: '#1565c0',
        }
    });
    return (_jsxs(View, { style: styles.tableContainer, children: [_jsx(TableHeader, {}), _jsx(TableRows, { items: drugs }), _jsx(TableFooter, { items: drugs })] }));
});
