import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
	onContinue(): void;
	onFix(): void;
}
export default NiceModal.create<Props>((props) => {
	const { onContinue, onFix } = props;
	const modal = useModal();

	return (
		<Dialog
			open={modal.visible}
			onClose={modal.hide}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{'Are you sure?'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					There are field errors on this step that will need to be addressed before the final form submission. Continue to the next step or fix field errors now?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					modal.hide();
					onFix();
				}} data-testid='dialog-cancel'>Fix</Button>
				<Button onClick={() => {
					modal.hide();
					onContinue();
				}} autoFocus color='error' data-testid='dialog-continue'>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
});
