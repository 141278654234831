import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
};

export const CurrencyInput = forwardRef<NumberFormat<any>, CustomProps>(
	(props, ref) => {
		const { onChange, ...other } = props;
		const Format = NumberFormat as any;
		return (
			<Format
				{...other}
				getInputRef={ref}
				onValueChange={(values: any) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				isNumericString
				prefix='$'
			/>
		);
	},
);
