import { FC, useMemo, memo } from 'react';
import { useSetRecoilState } from 'recoil';
import {
	Button, Divider, List, ListItem, ListItemText, PopperProps, Tooltip, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { SubscriptionType } from '@pm/shared';
import { registrationFormStepState, registrationFormState } from '../../../../atoms';
import {
	Card, CardBody, CardFooter, CardHeader,
} from '../../../../components';
import { StyledGrid, classes } from './FreeTierCard.styles';
import { PharmacyPostData } from '../../../../services/types';

export const FreeTierCard: FC = memo(() => {
	const popperProps: Partial<PopperProps> = { style: { zIndex: 100000, position: 'absolute' } };
	const setFormStep = useSetRecoilState(registrationFormStepState);
	const setForm = useSetRecoilState(registrationFormState);

	const onClickStart = () => {
		setForm((form: PharmacyPostData) => ({
			...form,
			subscription: SubscriptionType.BASIC,
			banking: {
				...form.banking,
			},
			dispensing: {
				...form.dispensing,
				managementSystem: '',
			},
		}));
		setFormStep((step) => step + 1);
	};

	const items: any[] = useMemo(() => [
		{
			primary: 'Shop Competitive Drug Prices',
			secondary: <>
				<Typography component='span' variant='body2'>
					Proprietary and intelligent drug matching
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='bottom'
					title={
						<Typography>
							Our proprietary drug matching algorithm is baked into all of our inventory search
							features. By searching one drug you can view all generically equivalent products available
							from each wholesaler and sort them by unit price, package sizes, and much more.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
		{ divider: true },
		{
			primary: 'Integrated EDI & Order Management',
			secondary: <>
				<Typography component='span' variant='body2'>
					Simply click, order and wait for your shipments
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='bottom'
					title={
						<Typography>
							Wholesaler backend EDI is fully integrated into Pharmacy Marketplace.
							Simply click, order and wait for your shipments to arrive at your doorstep
							while receiving relevant notifications along the way. Export your complete order history
							at any time in the event of an audit, and generate invoice PDFs for each completed order.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
		{ divider: true },
		{
			primary: 'Multiple Wholesalers',
			secondary: <>
				<Typography component='span' variant='body2'>
					Shop multiple NABP accredited wholesalers
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='top'
					title={
						<Typography>
							Easily search all wholesaler inventory in real-time using one convenient
							centralized platform and login. New wholesalers are constantly being added
							per customer requests.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
		{ divider: true },
		{
			primary: 'Mobile App',
			secondary: <>
				<Typography component='span' variant='body2'>
					Includes a camera-based NDC barcode scanner
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='top'
					title={
						<Typography>
							You can use a camera-based NDC barcode scanner that searches all
							equivalent inventory of the scanned product and conveniently ranks
							search results by unit price. The desktop web application will also work
							with most conventional barcode scanners.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
		{ divider: true },
		{
			primary: 'Robust Filters & Order Minimums',
			secondary: <>
				<Typography component='span' variant='body2'>
					Quickly find specific products using relevant criteria
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='top'
					title={
						<Typography>
							Filter products by brand/generic, specific wholesalers, dosage forms and more.
							With transparent order minimum rules by wholesaler, you can eliminate waste by
							no longer randomly ordering products to satisfy order minimums.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
		{ divider: true },
		{
			primary: 'Customizable Notifications & Settings',
			secondary: <>
				<Typography component='span' variant='body2'>
					Configure push and email notification preferences
				</Typography>
				<Tooltip
					PopperProps={popperProps}
					placement='top'
					title={
						<Typography>
							You can set monthly budgets to help prevent over spending outside of your primary.
							Several other settings are available such as Multi-Factor Authentication to enhance
							the security of your account.
						</Typography>
					}
				>
					<InfoIcon className={classes.icon} fontSize='small' />
				</Tooltip>
			</>,
		},
	], []);

	return (
		<StyledGrid item xs={12} md={4}>
			<Card className={classes.card}>
				<CardHeader color='rose' className={classes.cardHeader}>
					<Typography variant='h5'>Basic Shopper</Typography>
					<div className={classes.tierTitle}>
						<Typography variant='subtitle1'>FREE</Typography>
					</div>
				</CardHeader>
				<Typography className={classes.divider}>
					Start for free, upgrade at any time
				</Typography>
				<CardBody className={classes.cardBody}>
					<List dense>
						{items.map((item, i) => (item.divider
							? <Divider key={i} component='li' />
							: (
								<ListItem key={i} alignItems='flex-start'>
									<ListItemText
										primary={item.primary}
										secondary={item.secondary}
									/>
								</ListItem>
							)))}
					</List>
				</CardBody>
				<CardFooter className={classes.cardFooter}>
					<Button
						variant='text'
						size='large'
						className={classes.btn}
						onClick={onClickStart}
					>
						Get started
					</Button>
				</CardFooter>
			</Card>
		</StyledGrid>
	);
});
