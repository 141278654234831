import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import PMLogo from '../../../../images/PM-logo-black.png';
export const Title = memo(({ title, }) => {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '16px 0px 24px',
        },
        image: {
            width: 150,
        },
        title: {
            color: '#1565c0',
            letterSpacing: 2,
            fontSize: 16,
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsx(Image, { src: PMLogo, style: styles.image }), _jsx(Text, { style: styles.title, children: title })] }));
});
