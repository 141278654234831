import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import CapitalDrug from '../../../../../../images/capital-drug-logo.png';
export const PageThree = memo(() => {
    const styles = StyleSheet.create({
        container: {
            padding: 40,
        },
        entryContainer: {
            flexDirection: 'column',
            border: 1,
            marginBottom: 10,
        },
        entryDisclosure: {
            padding: '0px 8px',
            fontSize: 10
        },
        entryDisclosureLast: {
            padding: '0px 8px 4px',
            fontSize: 10,
        },
        entry: {
            paddingLeft: 20,
            paddingBottom: 12,
            fontSize: 10,
        },
        title: {
            paddingBottom: 10,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            fontSize: 10,
            paddingLeft: 3,
        },
    });
    const entries = [
        { text: '', style: styles.entry, },
        { text: '', style: styles.entry, },
        {
            text: '6. Capital will accept returns of Product within three months after the Product expiration. A 25% restocking fee applies to all Product returns except for returns due to shipping damages or errors. Applicant must notify Capital within 24 hours of receipt of product of any discrepancies or shortages on the order. Capital may refuse any returns if not notified as herein stated.',
            style: styles.entryDisclosure,
        },
        { text: '', style: styles.entry, },
        {
            text: '7. Applicant attests to Capital that it is properly licensed with applicable state licensing agencies to receive, dispense, distribute and otherwise legally dispose of the Product. Applicant understands that by attesting to this, Capital is complying with the “good faith inquiry” standard to ensure that the Product is distributed to properly licensed and/or registered pharmacy locations. Prior to purchasing the Product from Capital hereunder, Applicant must provide Capital with copies of all such licenses and any renewals, revocations or other changes to the same.',
            style: styles.entryDisclosure,
        },
        { text: '', style: styles.entry, },
        {
            text: '8. Applicant agrees to all the terms and conditions of the Agreement. This Agreement, together with all invoices, purchase orders, and the exhibits and addenda thereto constitute the entire agreement and understanding of the parties with respect to the subject matter hereof and supersede all prior written and oral arguments, proposals, bid responses, and understandings between the parties relative to the subject matter hereof. No changes to this Agreement or any purchase orders will be made or be binding upon either party unless made in writing and signed by each party. By signing this Agreement, Capital and Applicant each represent that it has the authority to bind its respective party to this agreement.',
            style: styles.entryDisclosure,
        },
        { text: '', style: styles.entry, },
        {
            text: '9. All information provided in this Application or otherwise submitted is true and correct and is being (or will be) furnished for the purpose of obtaining/retaining credit from Capital. Applicant authorizes its banks and suppliers to release any and all information as requested by Capital so as to ascertain credit worthiness. Applicant shall provide Capital with financial statements and such further information as may reasonably be requested from time to time.',
            style: styles.entryDisclosure,
        },
        { text: '', style: styles.entry, },
        {
            text: '10. If there is a lawsuit, Applicant agrees to submit to the jurisdiction of the state courts of Franklin County, Ohio or to the jurisdiction of the United States District Court for the Southern District of Ohio. Applicant further consents to venue in either of the preceding courts.',
            style: styles.entryDisclosureLast,
        }
    ];
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsx(PageHeader, { imgSrc: CapitalDrug }), _jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.entryContainer, children: [_jsx(Text, { style: styles.title, children: "SECTION III: AGREEMENT & DISCLOSURES (cont.)" }), entries.map((entry, index) => (_jsx(Text, { style: entry.style, children: entry.text }, index)))] }), _jsx(PageFooter, {})] })] }));
});
