import { styled } from '@mui/material/styles';

const PREFIX = 'Footer';
export const classes = {
	block: `${PREFIX}-block`,
	left: `${PREFIX}-left`,
	right: `${PREFIX}-right`,
	footer: `${PREFIX}-footer`,
	a: `${PREFIX}-a`,
	container: `${PREFIX}-container`,
	list: `${PREFIX}-list`,
	inlineBlock: `${PREFIX}-inlineBlock`,
	icon: `${PREFIX}-icon`,
};
export const Root = styled('footer')(({ theme }) => ({
	[`&.${classes.footer}`]: {
		padding: theme.spacing(1),
		zIndex: 2,
		position: 'absolute',
		bottom: -70,
		width: '100%',
	},
	[`& .${classes.block}`]: {
		color: 'inherit',
		padding: '0.9375rem',
		fontWeight: 500,
		fontSize: '12px',
		textTransform: 'uppercase',
		borderRadius: '3px',
		textDecoration: 'none',
		position: 'relative',
		display: 'block',
	},
	[`& .${classes.left}`]: {
		float: 'left',
		display: 'block',
		marginRight: 'auto',
	},
	[`& .${classes.right}`]: {
		padding: '15px 0px',
		marginLeft: 'auto',
		float: 'right',
		fontSize: 12,
	},
	[`& .${classes.a}`]: {
		color: '#1e88e5',
		textDecoration: 'none',
		backgroundColor: 'transparent',
	},
	[`& .${classes.container}`]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: '15px',
		paddingLeft: '15px',
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '100%',
		'@media (min-width: 576px)': {
			maxWidth: '540px',
		},
		'@media (min-width: 768px)': {
			maxWidth: '720px',
		},
		'@media (min-width: 992px)': {
			maxWidth: '960px',
		},
		'@media (min-width: 1200px)': {
			maxWidth: '1140px',
		},
	},
	[`& .${classes.list}`]: {
		marginBottom: '0',
		padding: '0',
		marginTop: '0',
	},
	[`& .${classes.inlineBlock}`]: {
		display: 'inline-block',
		padding: '0px',
		width: 'auto',
	},
	[`& .${classes.icon}`]: {
		width: '18px',
		height: '18px',
		position: 'relative',
		top: '3px',
		color: '#ec407a',
	},
}));
