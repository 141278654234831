import { styled } from '@mui/material/styles';

const PREFIX = 'CardFooter';
export const classes = {
	cardFooter: `${PREFIX}-cardFooter`,
};
export const Root = styled('div')(() => ({
	[`&.${classes.cardFooter}`]: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'transparent',
	},
}));
