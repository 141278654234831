import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
;
export const PageTwo = memo(({ pharmacy, user, }) => {
    const styles = StyleSheet.create({
        container: {
            padding: '20px 30px',
        },
        p: {
            fontSize: 12,
            fontFamily: 'Roboto',
        },
        p2: {
            fontSize: 11,
            fontFamily: 'Roboto',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        footer: {
            marginTop: 160,
            textAlign: 'center',
        },
        footerText: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
    });
    const title = user.title === 'Owner' ? 'Owner' : 'Representative';
    const name = `${user.firstName} ${user.lastName}`;
    const date = dayjs().format('MM/DD/YYYY');
    return (_jsxs(Page, { size: 'A4', wrap: false, children: [_jsxs(View, { style: styles.container, children: [_jsx(RequirementsBox, { email: pharmacy.email }), _jsx(Text, { style: { ...styles.p2, margin: '40px 0px', }, children: `I, ${name} as the ${title}, have completed this form to the best of my knowledge and ability.` }), _jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.p2, children: `Printed Name: ${name}` }), _jsx(Text, { style: styles.p2, children: "Signature: ________________________________________________" })] }), _jsxs(View, { style: { ...styles.row, justifyContent: 'flex-start', margin: '20px 0px 30px', }, children: [_jsx(Text, { style: { ...styles.p2, marginRight: 146, }, children: `Title: ${title}` }), _jsx(Text, { style: styles.p2, children: `Date: ${date}` })] }), _jsx(LicensesBox, {}), _jsxs(View, { style: styles.footer, children: [_jsx(Text, { style: styles.footerText, children: "Compliance Email: COMPLIANCE@AUBURNPHARM.COM" }), _jsx(Text, { style: styles.footerText, children: "Compliance Fax: (248)247-3272 **PLEASE DO NOT FAX PICTURES**" })] })] }), _jsx(Text, { style: { ...styles.p, position: 'absolute', bottom: 30, left: 30, }, children: "Revised 03/10/22" })] }));
});
const LicensesBox = () => {
    const styles = StyleSheet.create({
        box: {
            border: 1.5,
            borderColor: '#333',
        },
        boxRow: {
            width: '100%',
            borderBottom: 1.5,
            borderColor: '#333',
            padding: 3,
        },
        title: {
            fontSize: 12,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
        subtitle: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
    });
    return (_jsxs(View, { style: styles.box, children: [_jsx(View, { style: styles.boxRow, children: _jsx(Text, { style: styles.title, children: "***FOR OFFICE USE ONLY***" }) }), _jsxs(View, { style: styles.boxRow, children: [_jsx(Text, { style: { ...styles.subtitle, marginBottom: 10, }, children: "State License Verification     Date: ________ Initials: ________ City Population: ________" }), _jsx(Text, { style: { ...styles.subtitle, marginBottom: 10, }, children: "DEA License Verification       Date: ________ Initials: ________ Customer Ratio: ________" }), _jsx(Text, { style: styles.subtitle, children: "ARCOS Lookup                        Date: ________ Initials: ________" })] }), _jsxs(View, { style: styles.boxRow, children: [_jsx(Text, { style: { ...styles.subtitle, textAlign: 'center', }, children: "Notes" }), _jsx(View, { style: { height: 40, width: '100%', } }), _jsx(Text, { style: styles.subtitle, children: "Approved By: ________________________________________                                      Date: _____________________" })] })] }));
};
;
const RequirementsBox = ({ email, }) => {
    const styles = StyleSheet.create({
        box: {
            border: 1.5,
            borderColor: '#333',
        },
        boxRow: {
            width: '100%',
            borderBottom: 1.5,
            borderColor: '#333',
            padding: 3,
        },
        title: {
            fontSize: 12,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
            textAlign: 'center',
        },
        subtitle: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'bold',
            fontWeight: 700,
        },
        italic: {
            fontSize: 11,
            fontFamily: 'Roboto',
            fontStyle: 'italic',
        },
    });
    return (_jsxs(View, { style: styles.box, children: [_jsx(View, { style: styles.boxRow, children: _jsx(Text, { style: styles.title, children: "DISPENSING REPORT REQUIREMENT" }) }), _jsx(View, { style: styles.boxRow, children: _jsx(Text, { style: styles.subtitle, children: `A recent all drug dispensing report is required for consideration to purchase controlled substances. Please provide an email address for Auburn to send the request to: ${email}` }) }), _jsx(View, { style: styles.boxRow, children: _jsx(Text, { style: styles.title, children: "PHARMACY PICTURE REQUIREMENT" }) }), _jsxs(View, { style: styles.boxRow, children: [_jsx(Text, { style: { ...styles.italic, marginBottom: 15, }, children: "Please send the following pictures of your pharmacy: 1- Building with sign, 1- Street view, 1- Posted business hours, 2- Prescription product storage and shelving, 2- Inside of pharmacy retail and waiting area." }), _jsx(Text, { style: styles.subtitle, children: "Questionnaires can be submitted via fax, email, or upload portal on the Auburn website. Pictures can be emailed or submitted through the upload portal on the Auburn website. Pictures cannot be faxed. The questionnaire will be pending approval until pictures are received." })] })] }));
};
