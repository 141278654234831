import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, memo, useState, } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Tooltip, Typography, useMediaQuery, } from '@mui/material';
const UpDialogTransition = forwardRef((props, ref) => _jsx(Slide, { direction: 'up', ref: ref, ...props }));
;
export const ConfirmationDialog = memo(({ handleConfirm, handleToggleOpen, showActions = true, closeOnConfirm = true, text = '', title, titleActions, confirmBtnDisabled = false, positiveActionText, positiveBtnColor = 'primary', negativeActionText, negativeBtnColor = 'error', open, children, endIcon, contentStyles = {}, removeToggle = false, ...rest }) => {
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
    const [isConfirmed, setIsConfirmed] = useState(removeToggle);
    const handleChange = (event) => {
        setIsConfirmed(event.target.checked);
    };
    return (_jsxs(Dialog, { open: open, fullScreen: mdDown, onClose: handleToggleOpen, TransitionComponent: UpDialogTransition, "aria-labelledby": 'alert-dialog-slide-title', "aria-describedby": 'alert-dialog-slide-description', ...rest, children: [_jsxs(DialogTitle, { id: 'alert-dialog-slide-title', sx: { m: 0, p: 2 }, children: [title, titleActions && titleActions, _jsx(Tooltip, { title: 'Close', children: _jsx(IconButton, { onClick: handleToggleOpen, sx: { position: 'absolute', top: 8, right: 8 }, size: 'large', children: _jsx(CloseIcon, {}) }) })] }), _jsxs(DialogContent, { style: contentStyles, children: [children && children, !children
                        && _jsx(DialogContentText, { id: 'alert-dialog-slide-description', children: text })] }), showActions
                && _jsxs(DialogActions, { children: [!removeToggle
                            && _jsxs("span", { children: [_jsx(Typography, { variant: 'body1', sx: { display: 'inline' }, children: "Are you sure?" }), _jsx(Checkbox, { checked: isConfirmed, onChange: handleChange })] }), _jsx(Button, { onClick: handleToggleOpen, color: negativeBtnColor, children: negativeActionText }), _jsx(Button, { endIcon: endIcon, onClick: () => {
                                if (closeOnConfirm) {
                                    handleToggleOpen();
                                }
                                if (handleConfirm) {
                                    handleConfirm();
                                }
                            }, disabled: confirmBtnDisabled || !isConfirmed, variant: 'outlined', color: positiveBtnColor, children: positiveActionText })] })] }));
});
