import { FC, memo } from 'react';
import {
	Link, Grid, PopperProps, Tooltip, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StyledDiv, classes } from './Requirements.styles';

export const Requirements: FC = memo(() => {
	const PopperProps: Partial<PopperProps>		= { style: { zIndex: 100000, position: 'absolute' } };

	return (
		<StyledDiv className={classes.container}>
			<div className={classes.header}>
				<Typography variant='subtitle1'>
					Compatible Management Systems
				</Typography>
				<Tooltip
					PopperProps={PopperProps}
					placement='bottom'
					title=' We are diligently working to add support for more primary wholesalers
                        and pharmacy management systems every day at the service of our customers.
                        If we do not yet support your primary/PMS, you can still enjoy the free
                        version of our software and choose to help us petition the industry
                        to grant us more API integrations.'
				>
					<InfoIcon fontSize='small' className={classes.icon} />
				</Tooltip>
			</div>
			<Grid
				container
				wrap='nowrap'
				alignItems='center'
				justifyContent='space-evenly'
			>
				<Grid item>
					<Typography variant='body2' align='center'>
						<Link
							underline='none'
							target='_blank'
							rel='noreferrer'
							href='https://www.rx30.com/'
						>
							Rx30
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body2' align='center'>
						<Link
							underline='none'
							target='_blank'
							rel='noreferrer'
							href='https://libertysoftware.com/'
						>
							Liberty
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body2' align='center'>
						<Link
							underline='none'
							target='_blank'
							rel='noreferrer'
							href='https://www.pioneerrx.com/'
						>
							PioneerRx
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</StyledDiv>
	);
});
