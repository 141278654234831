export default {
    styleOverrides: {
        tooltip: {
            padding: '8px 16px',
            color: '#000',
            lineHeight: '1.7em',
            backgroundColor: '#FFFFFF',
            opacity: 1,
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
            maxWidth: 300,
            fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
            fontSize: '0.875em',
            fontStyle: 'normal',
            fontWeight: 400,
            textShadow: 'none',
        },
    },
};
