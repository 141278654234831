import { atom } from 'recoil';
import { localStorageEffect } from './effects';

type State = string | null;

const defaultState: State = null;
const onboardIdKey = 'onboardId';
const pharmacyIdKey = 'pharmacyId';
const userIdKey = 'userId';

export const onboardIdState = atom<State>({
	key: onboardIdKey,
	default: defaultState,
	effects: [
		localStorageEffect<State>(onboardIdKey),
	],
});

export const pharmacyIdState = atom<State>({
	key: pharmacyIdKey,
	default: defaultState,
	effects: [
		localStorageEffect<State>(pharmacyIdKey),
	],
});

export const userIdState = atom<State>({
	key: userIdKey,
	default: defaultState,
	effects: [
		localStorageEffect<State>(userIdKey),
	],
});
