import { styled } from '@mui/material/styles';

const PREFIX = 'Card';
export const classes = {
	card: `${PREFIX}-card`,
	cardPlain: `${PREFIX}-cardPlain`,
	cardCarousel: `${PREFIX}-cardCarousel`,
};
export const Root = styled('div')(({ theme }) => ({
	[`&.${classes.card}`]: {
		border: 0,
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		borderRadius: 6,
		color: 'rgba(0, 0, 0, 0.87)',
		background: '#fff',
		width: '100%',
		boxShadow:
            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		wordWrap: 'break-word',
		fontSize: '.875rem',
		transition: 'all 300ms linear',
	},
	[`&.${classes.cardPlain}`]: {
		background: 'transparent',
		boxShadow: 'none',
	},
	[`&.${classes.cardCarousel}`]: {
		overflow: 'hidden',
	},
}));
