import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarMonth from '@mui/icons-material/CalendarViewMonth';
import dayjs from 'dayjs';
import enUS from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useEffect, useState, } from 'react';
import { Stack, Avatar, CardContent, CardHeader, Divider, Grid, Typography, useTheme, } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Description, Medication } from '@mui/icons-material';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { getWholesalerDisplayName } from '../getWholesalerDisplayName';
import { StyledCard, classes } from './Calendar.styles';
import { DispensingDetailsDialog, DispensingCalendarSkeleton } from './components';
dayjs.extend(utc);
dayjs.extend(timezone);
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { 'en-US': enUS },
});
;
const Calendar = ({ getDispensing, getMcKessonPurchases, getDispensingByDate, pharmacyId, npi, action, sx = {}, title, wholesaler, startDate, lastUpdated, }) => {
    const id = pharmacyId;
    const { enqueueSnackbar } = useSnackbar();
    const [fetching, setFetching] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const wholesalerDisplay = getWholesalerDisplayName(wholesaler);
    const theme = useTheme();
    const fetchCalendarEvents = async (type, start) => {
        const isDispensing = type === 'dispensing';
        try {
            const opts = start
                ? { params: { start: encodeURIComponent(start.toISOString()), wholesaler } }
                : { params: { wholesaler } };
            const series = isDispensing
                ? await getDispensing(npi, opts)
                : await getMcKessonPurchases(id, opts);
            const tz = dayjs.tz.guess();
            const color = isDispensing ? theme.palette.primary.main : theme.palette.success.main;
            const icon = isDispensing ? _jsx(Medication, {}) : _jsx(Description, {});
            const nextEvents = series.map((event) => {
                const { date, totalPrescriptionsFilled, count } = event;
                const displayTitle = isDispensing
                    ? totalPrescriptionsFilled.toString()
                    : count.toString();
                const day = dayjs(date).tz(tz).toDate();
                return {
                    title: displayTitle, start: day, end: day, allDay: false, color, type, icon,
                };
            });
            setEvents((oldEvents) => [...oldEvents.filter((event) => event.type !== type), ...nextEvents]);
            setFetching(false);
        }
        catch (error) {
            setFetching(false);
            enqueueSnackbar(`Something went wrong while loading ${isDispensing ? 'dispensing' : 'McKesson purchase'} history`, { variant: 'error' });
        }
    };
    useEffect(() => {
        fetchCalendarEvents('dispensing');
        fetchCalendarEvents('mckessonpurchases');
    }, [lastUpdated]);
    useEffect(() => {
        fetchCalendarEvents('dispensing');
        fetchCalendarEvents('mckessonpurchases');
    }, []);
    const onRangeChange = ({ start }) => {
        fetchCalendarEvents('dispensing', start);
        fetchCalendarEvents('mckessonpurchases', start);
    };
    const onCloseDialog = () => {
        setSelectedDate(null);
    };
    const onSelectEvent = (event) => {
        if (event.type === 'dispensing') {
            setSelectedDate(event.start);
        }
    };
    const eventPropGetter = useCallback(({ color }, _start, _end, isSelected) => ({
        style: {
            opacity: isSelected ? 1 : 0.9,
            borderColor: color,
            backgroundColor: color,
            width: 70,
            textAlign: 'center',
            margin: '0px auto',
        },
    }), []);
    const CalendarEventComponent = ({ event }) => {
        const { title: displayTitle, color, icon } = event;
        return (_jsxs(Stack, { direction: 'row', justifyContent: 'center', children: [icon, _jsx(Typography, { align: 'center', variant: 'subtitle2', style: { backgroundColor: color }, children: displayTitle })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(StyledCard, { sx: sx, className: classes.root, children: [fetching && _jsx(DispensingCalendarSkeleton, {}), !fetching
                        && _jsxs(_Fragment, { children: [_jsx(CardHeader, { action: action, avatar: _jsx(Avatar, { children: _jsx(CalendarMonth, {}) }), title: _jsx(Typography, { variant: 'h5', children: title }), subheader: _jsxs("div", { children: ["This calendar displays the total number of prescriptions filled (blue chips) and product purchased from your primary wholesaler (green chips) each day.", _jsx("br", {}), "Click on the blue chips to view all prescriptions dispensed that day."] }) }), _jsx(Divider, {}), _jsxs(CardContent, { children: [_jsx(Grid, { container: true, flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center', sx: { mt: 1, mb: 3 }, children: [
                                                { color: theme.palette.primary.main, label: 'Dispensed Prescriptions' },
                                                { color: theme.palette.success.main, label: `${wholesalerDisplay} Purchases` },
                                            ].map(({ color, label }) => (_jsxs(Grid, { item: true, container: true, justifyContent: 'center', alignItems: 'center', children: [_jsx("div", { style: { backgroundColor: color, height: 15, width: 60 } }), _jsx(Typography, { variant: 'body2', sx: { ml: 1 }, children: label })] }, color))) }), _jsx(BigCalendar, { components: { event: CalendarEventComponent }, localizer: localizer, events: events, eventPropGetter: eventPropGetter, startAccessor: 'start', endAccessor: 'end', style: { height: 600 }, onRangeChange: onRangeChange, onSelectEvent: onSelectEvent, views: ['month'], defaultDate: startDate ?? undefined })] })] })] }), _jsx(DispensingDetailsDialog, { date: selectedDate, npiNumber: npi, onClose: onCloseDialog, getDispensingByDate: getDispensingByDate })] }));
};
export default Calendar;
