import { FC, memo } from 'react';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { Card } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { onboardIdState, onboardStepState, registrationFormState } from '../../atoms';
import { useDidUpdate, useValidateStep } from '../../hooks';
import { api } from '../../services';
import { splitUserName } from '../../util';
import { OnboardPutData } from '../../services/types';
import { FormContent, FormHeader } from './components';
import { StyledGrid, classes } from './Intro.styles';

export const Intro: FC = memo(() => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [onboardId] = useRecoilState(onboardIdState);
	const [step, setStep] = useRecoilState(onboardStepState);
	const setForm = useSetRecoilState(registrationFormState);

	useValidateStep();

	useDidUpdate(() => {
		history.push(`/step/${step}`);
	}, [step]);

	const mapFieldsToForm = (fields: OnboardPutData) => {
		const {
			userName, pharmacyName, position, ...rest
		} = omit(fields, 'password');
		const [firstName, lastName] = splitUserName(userName);
		return {
			firstName, lastName, name: pharmacyName, title: position, ...rest,
		};
	};

	const validateNPI = async (helpers: FormikHelpers<OnboardPutData>, npiNumber: number): Promise<Boolean> => {
		try {
			if (import.meta.env.PROD) {
				const validation = await api.get.npiValidate({ params: { sf: 'NPI', terms: npiNumber } });
				const validResult = validation[0] === 1;
				if (!validResult) {
					helpers.setFieldError('npiNumber', 'NPI was not able to be verified');
					return false;
				}
				helpers.setFieldError('npiNumber', undefined);
			}
			return true;
		} catch (error) {
			enqueueSnackbar(
				'Something went wrong while verifying your NPI number',
				{ variant: 'error' });
		}
		return true;
	};

	const onSubmit = async (
		fields: OnboardPutData,
		helpers: FormikHelpers<OnboardPutData>,
	) => {
		helpers.setSubmitting(true);
		try {
			const isValid = await helpers.validateForm();
			const referralAgent = localStorage.getItem('agent');

			if (!isEmpty(isValid)) {
				return;
			}
			const npiValid = await validateNPI(helpers, fields.npiNumber);
			if (!npiValid) {
				return;
			}

			const onboardPatchData = fields.isMultiStoreOwner
				? { ...omit(fields, 'password') } : fields;

			await api.patch.onboard(
				onboardId as string,
				{ ...onboardPatchData, referralAgent, step: step + 1 });

			const { analytics } = (window as any);
			analytics.identify({
				email: fields.email,
				onboarding_intro_completed: true,
			});

			setForm((form) => ({ ...form, ...mapFieldsToForm(fields) }));
			setStep(3);
		} catch (error: any) {
			if (error.status === 409) {
				helpers.setFieldError('npiNumber', 'NPI already exists. Please try again.');
				return;
			}
			helpers.setSubmitting(false);
			enqueueSnackbar(`Something went wrong during submission. Please try again. Refer error ${error.message} to support.`, { variant: 'error' });
		}
	};

	return (
		<StyledGrid
			container
			direction='column'
			alignItems='center'
			justifyContent='center'
			className={classes.root}
		>
			<Card className={classes.card}>
				<FormHeader />
				<FormContent onSubmit={onSubmit} />
			</Card>
		</StyledGrid>
	);
});
