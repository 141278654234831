import { FC, useState, useMemo, memo } from 'react';
import { FormikProps } from 'formik';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { PharmacyPostData, Signer } from '../../../../../../services/types';
import { ErrorSummary } from './components';

interface Props {
	formik: FormikProps<PharmacyPostData>;
};
type PersonSigning = PharmacyPostData['owner'] | PharmacyPostData['pic'] | PharmacyPostData['pp'];

export const DesignateSigner: FC<Props> = memo(({ formik, }) => {
	const other = 'Other' as const;
	const { values: { signer = { name: '', title: '', } as Signer, }, touched, errors, } = formik;
	const { signer: signerErrors = {}, } = errors;
	const { signer: signerTouched = {}, } = touched;

	const onInit = async ({ name, title, }: Record<string, string>) => {
		await formik.setFieldValue('signer.name', name);
		await formik.setFieldValue('signer.title', title);
	};
	const signers = useMemo<Signer[]>(() => {
		const { values, } = formik;
		const { firstName, lastName, title, } = values;
		const opts = [{ name: `${firstName} ${lastName}`, title, }];

		const keys = ['owner', 'pic', 'pp'] as (keyof PharmacyPostData)[];
		for (const key of keys) {
			const person = values[key] as PersonSigning | null;
			if (person && person.firstName) {
				opts.push({
					name: `${person.firstName} ${person.lastName}`,
					title: key.toUpperCase(),
				});
			}
		}

		opts.push({ name: other, title: '', });
		onInit(opts[0]);
		return opts;
	}, []);

	let initIndex = signers.findIndex(({ title, name, }) =>
		(signer.name.length > 0 ? name === signer.name : title === 'OWNER'));
	initIndex = initIndex < 0 ? signers.length - 1 : initIndex;
	const [selectedIndex, setSelectedIndex] = useState<number>(initIndex);

	const handleChange = async (event: any) => {
		const { value, } = event.target;
		setSelectedIndex(value);
		const newSigner = signers[value];
		const { name, title, } = newSigner;
		await formik.setFieldValue('signer.name', name);
		await formik.setFieldValue('signer.title', title);
	};
	const getLabel = ({ name, title, }: Signer) =>
		`${name}${title ? ` (${title})` : ''}`;
	const isOther = signers[selectedIndex].name === other;

	return (
		<Grid
			item
			xs={12}
			container
			spacing={1}
			alignItems='flex-start'
			justifyContent='space-evenly'
		>
			<Grid item xs={12}>
				<Typography align='center' sx={{ mb: 3, }}>
					Signer information discrepancies may result in a rejected application by a wholesaler.
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<TextField
					select
					required
					fullWidth
					name='signer'
					label='Select Signer'
					type='text'
					variant='outlined'
					value={selectedIndex}
					onChange={handleChange}
					onBlur={formik.handleBlur}
				>
					{signers.map((_, index) => (
						<MenuItem key={index} value={index}>
							{getLabel(signers[index])}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			{isOther
				&& <>
					<Grid item xs={4}>
						<TextField
							fullWidth
							required
							name='signer.name'
							label='Signer Full Name'
							type='text'
							variant='outlined'
							value={signer.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={signerTouched.name && Boolean(signerErrors.name)}
							helperText={signerTouched.name && signerErrors.name}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							select
							required
							name='signer.title'
							label='Signer Title'
							type='text'
							variant='outlined'
							value={signer.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={signerTouched.title && Boolean(signerErrors.title)}
							helperText={signerTouched.title && signerErrors.title}
						>
							{['Owner', 'Purchaser', 'PIC', 'Technician', other].map((label) => (
								<MenuItem key={label} value={label}>
									{label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</>
			}
			<ErrorSummary formik={formik} />
		</Grid>
	);
});
