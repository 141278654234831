import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
;
export const BillTo = memo(({ wholesaler, pharmacy, }) => {
    const value = {
        fontSize: 12,
        fontFamily: 'Roboto',
        paddingBottom: 4,
    };
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '20px 0px',
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0px 8px',
            maxWidth: '50%',
        },
        label: {
            paddingBottom: 6,
            fontSize: 14,
            fontWeight: 700,
            fontStyle: 'bold',
            fontFamily: 'Roboto',
        },
        value,
        value2: {
            ...value,
            fontSize: 10,
        },
        value3: {
            ...value,
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
        },
    });
    return (_jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.item, children: [_jsx(Text, { style: styles.label, children: "Buyer" }), _jsx(Text, { style: styles.value, children: pharmacy.name }), _jsx(Text, { style: styles.value2, children: `${pharmacy.address} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}` }), _jsx(Text, { style: styles.value3, children: pharmacy.email }), _jsx(Text, { style: styles.value3, children: pharmacy.phone })] }), _jsxs(View, { style: styles.item, children: [_jsx(Text, { style: styles.label, children: "Seller" }), _jsx(Text, { style: styles.value, children: wholesaler.name }), _jsx(Text, { style: styles.value2, children: `${wholesaler.address.street} ${wholesaler.address.city}, ${wholesaler.address.state} ${wholesaler.address.zip}` }), _jsx(Text, { style: styles.value3, children: wholesaler.email }), _jsx(Text, { style: styles.value3, children: wholesaler.phone })] })] }));
});
