import { colors } from '@mui/material';
export default {
    styleOverrides: {
        contained: {
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        },
        containedPrimary: {
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
            background: `linear-gradient(45deg, ${colors.green[700]} 40%, ${colors.green[400]} 80%)`,
        },
        containedSecondary: {
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
            background: `linear-gradient(45deg, ${colors.blue[900]} 30%, ${colors.blue.A400} 90%)`,
        },
    },
};
