import { Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { OnboardPutData } from '../../../../services/types';

interface Props {
	formik: FormikProps<OnboardPutData>;
}

const PasswordEntry: React.FC<Props> = ({ formik }) => {
	const { handleChange, handleBlur, errors, values, touched } = formik;
	const { password, confirmPassword } = values;

	if (values.isMultiStoreOwner) {
		return null;
	}

	return (
		<Stack spacing={2} mt={2}>
			<Typography variant='subtitle1' color='textSecondary'>
				Enter your desired password below. Your password <b>must</b>
				&nbsp;be at least 8 characters. We recommend including an uppercase, digit, and/or special character.
			</Typography>
			<Stack direction='row' spacing={2} mt={3}>
				<TextField
					label="Password"
					type="password"
					variant="outlined"
					name='password'
					required
					value={password}
					data-testid="passwordField"
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.password && Boolean(errors.password)}
					helperText={touched.password && errors.password}
					margin="normal"
					fullWidth
				/>
				<TextField
					label="Confirm Password"
					type="password"
					variant="outlined"
					name='confirmPassword'
					required
					value={confirmPassword}
					data-testid="confirmPasswordField"
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.confirmPassword && Boolean(errors.confirmPassword)}
					helperText={touched.confirmPassword && errors.confirmPassword}
					margin="normal"
					fullWidth
				/>
			</Stack>
		</Stack>
	);
};

export default PasswordEntry;
